import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getUsersFollowers = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/user/getFollowerList`, data, headers)

const getMyExclusiveList = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/user/getExclusiveList`, data, headers)

const getDeleteMyFollowers = (data, headers) =>
  fetch('post', `${API_HOST}/users/api/v1/admin/DeleteExclusiveMemberByEmailAdmin`, data, headers)

const getDeleteMyExclusive = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/user/deleteExclusiveMember`, data, headers)

const saveCsvFile = (data, headers) =>
  fetch('post', `${API_HOST}/users/api/v1/add/exclusive/member-csv`, data, headers)

const addExclusiveMember = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/user/add/exclusive-member`, data, headers)

export const ExclusiveService = {
  getUsersFollowers,
  getMyExclusiveList,
  getDeleteMyFollowers,
  getDeleteMyExclusive,
  saveCsvFile,
  addExclusiveMember,
}
