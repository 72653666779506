import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class LoaderComponent extends Component {
  render() {
    if (this.props.loading || this.props.isLoading) {
      return (
        <div className="overlayDiv">
          <Loader
            type="Circles"
            color="#FFFFFF"
            height={60}
            width={60}
            visible={true}
          />
        </div>
      );
    } else return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(LoaderComponent);
