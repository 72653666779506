import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import './CustomAccordion.scss'

const CustomAccordion = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <>
      <div className='CustomAccordion'>
        <Accordion>
          <Accordion.Title
            active={activeIndex === props.active}
            index={props.index}
            onClick={handleClick}
          >
            <div className='TitlesList'>
              <img src={props.IconTitle} alt='Icons' />
              {props.title}
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === props.active}>
            {props.children}
          </Accordion.Content>
        </Accordion>
      </div>
    </>
  )
}

export default CustomAccordion
