import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import './BoxInfo.scss'

const BoxInfo = ({ data }) => {
  const {
    wineName,
    country,
    caseType,
    nftType,
    paymentDate,
    whoPaid,
    location,
    deliveryType,
    deliveryTerm,
    deliveryCompany,
  } = data
  return (
    <div className='boxInfo'>
      {wineName && <h2 className='boxInfo__title'>{wineName}</h2>}
      {country && <div className='boxInfo__country'>{country}</div>}

      <Row className='boxInfo__row'>
        <Col span={12}>
          <p className='boxInfo__pTitle'>Case type:</p>
          <p className='boxInfo__pDetails'>{caseType}</p>
        </Col>
        <Col span={12}>
          <p className='boxInfo__pTitle'>NFT type:</p>
          <p className='boxInfo__pDetails'>{nftType}</p>
        </Col>
      </Row>
      <h2 className='boxInfo__rowName'>Payment Info:</h2>
      <Row className='boxInfo__row'>
        <Col span={12}>
          <p className='boxInfo__pDate'>Payment date:</p>
          <p className='boxInfo__pPayment'>{paymentDate}</p>
        </Col>
        <Col span={10}>
          <p className='boxInfo__pDate'>Who paid:</p>
          <p className='boxInfo__pPaid'>{whoPaid}</p>
        </Col>
      </Row>
      <h2 className='boxInfo__rowName'>Delivery Info:</h2>
      <Row>
        <Col span={12}>
          <p className='boxInfo__pDate'>Location:</p>
          <p className='boxInfo__pPayment'>{location}</p>
        </Col>
        <Col span={10}>
          <p className='boxInfo__pDate'>Delivery type:</p>
          <p className='boxInfo__pPaid'>{deliveryType}</p>
        </Col>
        <Col span={24}>
          <div className='boxInfo__emptyCell'></div>
        </Col>
        <Col span={12}>
          <p className='boxInfo__pDate'>Delivery terms:</p>
          <p className='boxInfo__pPayment'>{deliveryTerm}</p>
        </Col>
        <Col span={10}>
          <p className='boxInfo__pDate'>Delivery company:</p>
          <p className='boxInfo__pPaid'>{deliveryCompany}</p>
        </Col>
      </Row>
    </div>
  )
}
export default BoxInfo

BoxInfo.propTypes = {
  data: PropTypes.shape({
    wineName: PropTypes.string,
    country: PropTypes.string,
    caseType: PropTypes.string,
    nftType: PropTypes.string,
    paymentDate: PropTypes.string,
    whoPaid: PropTypes.string,
    location: PropTypes.string,
    deliveryType: PropTypes.string,
    deliveryTerm: PropTypes.string,
    deliveryCompany: PropTypes.string,
  }).isRequired,
}
