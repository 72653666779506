import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NoGuard = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.persist.isLoggedIn)

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/panel/Dashboard',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  )
}

export default NoGuard
