import React from 'react'
import './Notes.scss'
import { DeleteIcon, DocIcon } from '../../../../Assets/image/svgimages/sVGImg'
import Message from '../../../../components/Message/Message'
import wineft_Icon from '../../../../Assets/image/wineft_Icon.png'
import moment from 'moment'
import { Pagination } from 'semantic-ui-react'
import { NOTIFICATION_LIMT } from '../../../../utils/constants'

const Unread = (props) => {
  let {
    data,
    setDeletedItem,
    onCallDeleteAll,
    onMarkReadSingle,
    onCallMarkReadAll,
    setOffset,
    totalCount,
  } = props

  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage - 1
    setOffset(offset)
  }
  return (
    <>
      <div className='Unread'>
        {data.length > 0 && (
          <div className='btnDeleteRead'>
            <button className='read_btn' onClick={() => onCallMarkReadAll(0)}>
              <DocIcon /> Make all as read
            </button>
            <button className='dlt_btn' onClick={() => onCallDeleteAll(0)}>
              <DeleteIcon /> Delete all
            </button>
          </div>
        )}
        {data.map((item) => (
          <Message
            key={`ur-${item.id}`}
            Icon_profile={<img src={wineft_Icon} alt='wineft_Icon' />}
            Containt_Text={item?.content?.msg}
            date_Icon={moment(item?.createdAt).format('DD-MM-YYYY hh:mm A')}
            customClass='Mailunread'
            setDeletedItem={setDeletedItem}
            onMarkReadSingle={onMarkReadSingle}
            item={item}
            type='unread'
          />
        ))}
        {data.length === 0 && <p>No notification found!</p>}
      </div>

      {totalCount > 10 && (
        <div className='MessagePagi'>
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            onPageChange={handlePageClick}
            defaultActivePage={1}
            totalPages={Math.ceil(parseInt(totalCount) / NOTIFICATION_LIMT)}
          />
        </div>
      )}
    </>
  )
}

export default Unread
