import React, {useEffect, useState} from "react";
import { Steps } from "antd";
import "./BottleSteps.scss";

import { ReactComponent as WOne } from "./assets/w1.svg";
import { ReactComponent as WTwo } from "./assets/w2.svg";
import { ReactComponent as WThree } from "./assets/w3.svg";

const BottleSteps = ({ data }) => {
  const { Step } = Steps;
  const stepsCompletedCompletedInitData = {
    'title': false,
    'details': false,
    'notes': false,
  }
  const [stepsCompletedCompleted, setStepsCompletedCompleted] = useState(stepsCompletedCompletedInitData);
  const finishedStepClass = 'antd-custom-step--finished';

  useEffect(() => {
    getStepsCompletedCompleted(data);
  }, [data]);

  const getStepsCompletedCompleted = (data) => {
    const titleStepCompleted = !!(data?.brand_name && data?.bottle_image);
    const detailsStepCompleted = !!(data?.winery_name && data?.wine_color_id && data?.sweetness_wine_id && data?.size_id);
    const notesStepCompleted = !!data?.description;

    setStepsCompletedCompleted({
      ...stepsCompletedCompletedInitData,
      title: titleStepCompleted,
      details: detailsStepCompleted,
      notes: notesStepCompleted,
    })
  }

  const getStep = (isFinished) => {
    if (isFinished) {
      return "finish";
    }

    return "wait";
  };

  return (
    <Steps className="steps-bottle" direction="vertical" current={1}>
      <Step
        className={stepsCompletedCompleted.title ? finishedStepClass: ''}
        title="Step 1:"
        description="Add wine bottle"
        status={getStep(stepsCompletedCompleted.title)}
        icon={<WOne />}
      />
      <Step
        className={stepsCompletedCompleted.details ? finishedStepClass: ''}
        title="Step 2:"
        description="Details"
        steps={2}
        status={getStep(stepsCompletedCompleted.details)}
        icon={<WTwo />}
      />
      <Step
        className={stepsCompletedCompleted.notes ? finishedStepClass: ''}
        title="Step 3:"
        description="Notes"
        steps={3}
        status={getStep(stepsCompletedCompleted.notes)}
        icon={<WThree />}
      />
    </Steps>
  );
};
export default BottleSteps
