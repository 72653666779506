import { Input } from "antd";
import React from "react";

const InputCalendar = ({
  isRequired,
  label,
  validate,
  placeholder,
  name,
  value,
  onChangeHandler,
}) => {
  return (
    <>
      <div className="calender">
        <label className="inputField__label">
          {label}
          {isRequired && <sup>*</sup>}
        </label>
        <Input
          name={name}
          value={value}
          className="Calender_input"
          placeholder={placeholder}
          type="date"
          onChange={(e) => onChangeHandler(e.target.value)}
        />
      </div>
    </>
  );
};

export default InputCalendar;
