export default function getWineName(data) {
  const { winery_name = '', cuvee, cru, classification, nft_name = '' } = data

  // nft_name is used as custom name from admin panel - use it if exist
  if (nft_name) {
    return nft_name
  }

  let name = ''
  let nameDescription = []

  if (cuvee) {
    nameDescription.push(cuvee)
  }

  if (cru) {
    nameDescription.push(cru)
  }

  if (classification) {
    nameDescription.push(classification)
  }

  nameDescription = nameDescription.map((word) => word.trim()).join(' ')
  nameDescription = nameDescription ? `«${nameDescription}»` : ''
  name = `${winery_name} ${nameDescription}`

  return name
}
