import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Tabs } from 'antd'

import { getPallets } from 'redux/_actions/pallets.action'
import { getShippingOrders } from 'redux/_actions/shippingOrders.action'
import useDebounce from 'hooks/useDebounce'

import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import WareTabs from './WareTabs'

import './WareHouse.scss'
import ShippingOrders from './shippingOrders/ShippingOrders'

const STORAGE = 'STORAGE'
const ORDER = 'ORDER'

const WareHouse = () => {
  const [searchKey, setSearchKey] = useState('')
  const [status, setStatus] = useState('')
  const [limit, setLimit] = useState('10')
  const [offset, setOffset] = useState(1)
  const [startDate, setStartDate] = useState(moment('2023-01-01', 'YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment(new Date(), 'YYYY-MM-DD'))
  const [selectedTab, setSelectedTab] = useState('')
  const [triggerSearch, setTriggerSearch] = useState(false)

  const { palletsList, totalPallets } = useSelector((state) => state?.pallets)
  const shippingOrders = useSelector((state) => state?.shippingOrders)

  const debaounceWinerySearch = useDebounce(searchKey, 800)

  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem(STORAGE)) {
      setSelectedTab(localStorage.getItem(STORAGE))
    } else {
      localStorage.setItem('activeTab', STORAGE)
      setSelectedTab(STORAGE)
    }
  }, [])

  useEffect(() => {
    const loadData = (params) => {
      switch (selectedTab) {
        case STORAGE:
          dispatch(getPallets(params))
          break
        case ORDER:
          dispatch(getShippingOrders(params))
          break

        default:
          break
      }
    }

    if (selectedTab !== '') {
      loadData({ limit, offset, searchKey: debaounceWinerySearch, status })
    }
  }, [offset, limit, status, selectedTab, dispatch, debaounceWinerySearch])

  const tabsData = [
    {
      label: 'Storage',
      key: STORAGE,
      children: (
        <WareTabs
          selectedTab={selectedTab} // +
          status={status} // +
          searchKey={searchKey} // +
          setSearchKey={setSearchKey} // +
          setLimit={setLimit} // +
          setStatus={setStatus} // +
          setOffset={setOffset} // +
          offset={offset} // +
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          limit={limit} // +
          data={palletsList} // +
          totalNft={totalPallets} // +
          setTriggerSearch={setTriggerSearch}
          triggerSearch={triggerSearch}
        />
      ),
    },
    {
      label: 'Order',
      key: ORDER,
      children: (
        <ShippingOrders
          data={shippingOrders}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          setLimit={setLimit}
          setStatus={setStatus}
          setOffset={setOffset}
          setTimeRange={setStatus}
          limit={limit}
        />
      ),
    },
  ]

  return (
    <MainLayout>
      <div className='wareHouse'>
        <h3 className='common-sub-heading mb30'>Warehouse</h3>
      </div>
      <Tabs
        className='wareContainer_tabs__tabOver'
        defaultActiveKey='1'
        activeKey={selectedTab}
        onChange={(e) => {
          localStorage.setItem('activeTab', e)
          setSelectedTab(e)
        }}
        items={tabsData}
      />
    </MainLayout>
  )
}
export default WareHouse
