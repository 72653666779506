import { Link } from 'react-router-dom'
import './LabelDropdownMenu.scss'
import {
  DeleteSolidIcon,
  EditProfileIcon,
  IconDiamond,
} from '../../../../../Assets/image/svgimages/sVGImg'

const LabelDropdownMenu = ({ data, deleteuserBottle }) => {
  return (
    <div className='labelDropdownMenu'>
      <Link
        to={{
          pathname: '/panel/bottles/edit-bottle',
          state: data,
        }}
      >
        <EditProfileIcon />
        Edit Bottle
      </Link>
      {!data.is_drop_exist && data?.wallet_authorization === 1 && (
        <Link
          to={{
            pathname: '/panel/createNftwine',
            state: data,
          }}
        >
          <IconDiamond />
          Create NFT
        </Link>
      )}
      <Link to='#' onClick={() => deleteuserBottle(data.id)}>
        <DeleteSolidIcon />
        Delete
      </Link>
    </div>
  )
}

export default LabelDropdownMenu
