import React from 'react'
import { Grid } from 'semantic-ui-react'
import './PreviewWiNeFT.scss'
import wineimg2 from '../../../Assets/image/wineimg2.png'
import CustomAccordion from '../../../components/common/CustomAccordion/CustomAccordion'
import RoyaltyIcon from '../../../Assets/image/svgimages/RoyaltyIcon.svg'
import descriptionIcon from '../../../Assets/image/svgimages/descriptionIcon.svg'
import aboutIcon from '../../../Assets/image/svgimages/aboutIcon.svg'
import UserSelected from './Common/UserSelected'
import NftLayout from '../../../components/HOC/NftLayout'

const ListedNft = () => {
  const DetailsKeyContent = [
    {
      title: 'Winery:',
      Info: 'Chateau de Baucastel',
    },
    {
      title: 'Cuvee:',
      Info: 'Les Combettles',
    },
    {
      title: 'Country:',
      Info: 'France',
    },
    {
      title: 'Region:',
      Info: 'Sonoma or Bordeaux',
    },
    {
      title: 'Appellation:',
      Info: 'Russian river valley or Pomerol',
    },
    {
      title: 'Color:',
      Info: 'Red',
    },
    {
      title: 'Vineyard name or Cru:',
      Info: 'Jacuzzi Family Vineyards',
    },
    {
      title: 'Size:',
      Info: 'Jacuzzi Family Vineyards',
    },
    {
      title: 'Vintage:',
      Info: '2020',
    },
    {
      title: 'Sweetness of wine:',
      Info: 'Dry',
    },
    {
      title: 'Classification:',
      Info: 'Grand Cru',
    },
    {
      title: 'Classification:',
      Info: 'Still',
    },
    {
      title: 'Availability:',
      Info: 'Upcoming:',
    },
  ]
  return (
    <>
      <NftLayout>
        <div className='ListedNft'>
          <UserSelected
            className='ScreenforMb'
            userTitle='Chateau de Baucastel'
            headingTitle='CHÂTEAU DE BEAUCASTEL CÔTES DU RHÔNE COUDOULET BLANC 2020'
            selectedUser='LISTED'
            classUserSe='ListedIcon'
            amount='35'
            editNft
          />
          <Grid>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <div className='BottleView'>
                <img src={wineimg2} alt='wine_image' className='BottleImg' />
              </div>
              <CustomAccordion active={0} index={0} title='Key Details' IconTitle={RoyaltyIcon}>
                <div className='Containt'>
                  <ul className='KeyValue'>
                    {DetailsKeyContent.map((item) => (
                      <li className='Vauleitem' key={`k-${item.title}`}>
                        <label>{item?.title}</label>
                        <h6>{item?.Info}</h6>
                      </li>
                    ))}
                  </ul>
                </div>
              </CustomAccordion>
              <CustomAccordion active={1} index={1} title='About winery' IconTitle={aboutIcon}>
                <div className='Containt'>
                  <p>
                    In 1321, under the reign of Pope Jean XXII, four barrels were brought from the
                    papal cellar to be filled with wine in Châteauneuf. Then, the Popes planted new
                    vines and the legend of the papal wine began.
                  </p>
                </div>
              </CustomAccordion>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <UserSelected
                className='ScreenTbpluse'
                userTitle='Chateau de Baucastel'
                headingTitle='CHÂTEAU DE BEAUCASTEL CÔTES DU RHÔNE COUDOULET BLANC 2020'
                selectedUser='LISTED'
                classUserSe='ListedIcon'
                amount='352'
                editNft
              />
              <CustomAccordion
                active={2}
                index={2}
                title='Royalty Payments for Secondary Sale'
                IconTitle={RoyaltyIcon}
              >
                <div className='Containt'>
                  <p>
                    Please note that secondary sale of wine will involve paying out royalties to the
                    winery and the Winechain platform.
                  </p>
                  <div className='listine'>
                    <div className='VauleSelect'>
                      <label>Winery:</label>
                      <h6>20%</h6>
                    </div>
                    <div className='VauleSelect'>
                      <label>WineChain:</label>
                      <h6>10%</h6>
                    </div>
                  </div>
                  <ul className='BtnList'>
                    <li>
                      <button>Learn more</button>
                    </li>
                    <li>
                      <button>View exemple</button>
                    </li>
                  </ul>
                </div>
              </CustomAccordion>
              <CustomAccordion active={3} index={3} title='Description' IconTitle={descriptionIcon}>
                <div className='Containt'>
                  <p>
                    Château de Beaucastel has long been considered one of the greatest wines in
                    France, known for its elegance, balance and great aging potential. Rich, rounded
                    with a deep, complex aroma and a high level of tannins, but well structured, and
                    remarkable acidity. An elegant, wonderfully balanced wine with great aging
                    potential, delightful finesse, length and richness typical of great vintages.
                    The wine is a blend of 30% Grenache, 30% Mourvedre, 10% Syrah, 10% Counoise and
                    5% Senso, with a small content of the other nine varieties permitted in the
                    Châteauneuf-du-Pap appellation.
                  </p>
                </div>
              </CustomAccordion>
            </Grid.Column>
          </Grid>
        </div>
      </NftLayout>
    </>
  )
}

export default ListedNft
