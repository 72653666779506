import moment from "moment";
import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import { DeleteIcon, IconMember } from "../../../Assets/image/svgimages/sVGImg";
import CheckBox from "../../../components/common/CheckBox/CheckBox";
import InputField from "../../../components/common/InputField/InputField";
import AccessModal from "./AccessModal/AccessModal";
import { getDeleteMyExclusive } from "../../../redux/_actions/exclusive.action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const AccessTab = (props) => {
  let {
    exclusive,
    setExclusive,
    userId,
    getExclusiveList,
    getDeleteMyExclusive,
    search,
    setSearch,
    file,
    setFile,
    handleOnChangeCsv,
    handleOnSubmit,
    open,
    setOpen,
  } = props;
  const [isAll, setIsAll] = useState(false);

  const onChooseExclusive = (index) => {
    let selected = [...exclusive];
    setIsAll(false); // so that seletcted all unchecked
    selected[index]["checked"] = !selected[index]["checked"];
    setExclusive(selected);
  };

  const deleteMyRecords = () => {
    let selected = [...exclusive];
    let deletedItems = [];
    selected.forEach((element) => {
      if (element.checked) {
        let item = {
          id: element.id,
        };
        deletedItems.push(item);
      }
    });
    if (deletedItems.length > 0) {
      let final = {
        data: deletedItems,
      };

      // call api here
      callDeleteExclusiveApi(final);
    }
  };

  const deleteMeAsSingle = (id) => {
    let item = {
      data: [
        {
          id: id,
        },
      ],
    };

    callDeleteExclusiveApi(item);
  };

  const callDeleteExclusiveApi = (final) => {
    getDeleteMyExclusive(final).then((res) => {
      setIsAll(false);
      getExclusiveList();
    });
  };
  const setAllCheckedUnchecked = (e, data) => {
    setIsAll(data.checked);
    let selected = [...exclusive];
    selected.forEach((element) => {
      element.checked = data.checked;
    });
    setExclusive(selected);
  };
  const openAddModal = () => {
    setIsAll(false);
    let selected = [...exclusive];
    selected.forEach((element) => {
      element.checked = false;
    });
    setExclusive(selected);
    setOpen(true);
  };
  return (
    <div className="tabs__content">
      <h3 className="dflex">
        Invitee List ({exclusive.length} persons)
        <button className="add-btn" onClick={() => openAddModal()}>
          <IconMember /> Add members
        </button>
        <AccessModal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          userId={userId}
          getExclusiveList={getExclusiveList}
          file={file}
          setFile={setFile}
          handleOnChangeCsv={handleOnChangeCsv}
          handleOnSubmit={handleOnSubmit}
        />
      </h3>
      <InputField
        placeholder="Ex. infogmail@gmail.com"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="Table_Editing">
        <Table className="custom_table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="1">
                <CheckBox
                  checked={isAll}
                  onChange={(e, data) => setAllCheckedUnchecked(e, data)}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="2">ID</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Email</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Date</Table.HeaderCell>
              <Table.HeaderCell colSpan="2" textAlign="right">
                <span
                  className="flex light-text fw-600"
                  onClick={() => deleteMyRecords()}
                >
                  <DeleteIcon /> Delete all
                </span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {exclusive.map((data, index) => {
              return (
                <Table.Row key={data?.id}>
                  <Table.Cell
                    collapsing
                    colSpan="1"
                    textAlign="left"
                    data-title="Code"
                  >
                    <CheckBox
                      checked={data.checked}
                      id={data.id}
                      onChange={() => onChooseExclusive(index)}
                    />
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    colSpan="2"
                    textAlign="left"
                    data-title="Code"
                  >
                    {data.id}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    colSpan="3"
                    textAlign="left"
                    data-title="Code"
                  >
                    {data.email}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    colSpan="2"
                    textAlign="left"
                    className="light-text"
                    data-title="Code"
                  >
                    {data.createdAt
                      ? moment(data.createdAt).format("DD/MM/YYYY")
                      : "N/A"}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    colSpan="2"
                    textAlign="right"
                    data-title="Code"
                  >
                    <Link to="#" className="flex deleteIcon clickable-icon" onClick={() => deleteMeAsSingle(data.id)}>
                      <DeleteIcon />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}

            {exclusive.length === 0 && (
              <Table.Row>
                <Table.Cell
                  collapsing
                  colSpan="1"
                  textAlign="left"
                  data-title="Code"
                >
                  No Records found
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeleteMyExclusive: (data) => dispatch(getDeleteMyExclusive(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessTab);
