import React from 'react'
import { Checkbox, Input } from 'semantic-ui-react'
import { ButtonCustom } from '../../button/ButtonCustom'
import ModalStyle from '../../Modal/ModalStyle'
import './CustomDropdown.scss'

const CustomDropdown = (props) => {
  let {
    option,
    onSelect,
    searchedText,
    onSearch,
    onClose,
    onOpen,
    open,
    onSelectSearchedCountry,
    clearAllCountries,
    onAccept,
    getTotalSectected,
    setAllSelected,
  } = props

  return (
    <>
      <div className='CustomDropdown'>
        <ModalStyle
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          title='All countries'
          className='NftWine_Modal'
          classFooter='BtnSendaccess'
        >
          <div className='Wineft__modal--content'>
            <div className='Wineft__modal--head'>
              <Input
                icon='search'
                iconPosition='left'
                name='searched'
                value={searchedText}
                onChange={(e) => onSearch(e.target.value)}
              />
              <div className='header'>
                <button type='button' onClick={() => setAllSelected()}>
                  Select All
                </button>
                <span>{getTotalSectected()} Selected</span>
              </div>
              <div className='ListingDropm'>
                {option.map((row, index) => (
                  <div className='ItemList' key={row.value}>
                    <Checkbox
                      label={row.label}
                      checked={row.isChecked}
                      onChange={(e, data) =>
                        searchedText === '' ? onSelect(index) : onSelectSearchedCountry(data)
                      }
                    />
                  </div>
                ))}
              </div>
              <div className='FooterDrop'>
                <button className='BrderBtn' type='button' onClick={() => clearAllCountries()}>
                  Clear all
                </button>
                <div className='AddAccept'>
                  {/* <button className="BrderBtn">Add restriction</button> */}
                  <ButtonCustom
                    onClick={() => onAccept()}
                    type='button'
                    title='Accept'
                    customClass='BtnAccept'
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalStyle>
      </div>
    </>
  )
}

export default CustomDropdown
