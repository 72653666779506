import Types from '../_actions/_types'

const initialState = {
  isLoggedIn: false,
  user: {},
  token: '',
}

const persist = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case Types.SAVE_LOGIN_DETAILS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token,
      }

    case Types.RESET_PERSIST_ON_LOGOUT: {
      // return initialState;
      return { ...state, isLoggedIn: false, token: '', user: {} }
    }

    default:
      return state
  }
}

export default persist
