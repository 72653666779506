import './MarketplaceFilters.scss'
import { Icon, Input } from "semantic-ui-react";

const MarketplaceFilters = ({ search, setSearch, setOffset }) => {
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setOffset(0) // reset pagination on change search criteria
  }

  return (
    <div className='marketplace-filters'>
      <Input
        className="search_fld"
        icon={<Icon name="search" inverted link />}
        value={search}
        onChange={onChangeSearch}
        placeholder="Ex. Search id, account email"
      />
    </div>
  )
};

export default MarketplaceFilters;
