import { actionTypes } from '../_actions/nft.action'

const initialState = {
  nftList: [],
  totalNft: 0,
}

const nft = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_NFTS:
      return { ...state, nftList: payload.data, totalNft: payload.count }

    default:
      return state
  }
}

export default nft
