import React from 'react'
import { Input, TextArea } from 'semantic-ui-react'
import './InputField.scss'

const InputField = ({
  isRequired,
  label,
  validate,
  placeholder,
  type,
  showLimit,
  greyText,
  onChange,
  name,
  formik,
  value,
  disabled,
  maxLength,
  className,
  readOnly,
  id,
  classNameInput,
  max,
}) => {
  const disableScroll = (e) => {
    e.preventDefault()
  }
  return (
    <div className={`inputField__wrap ${className}`}>
      {/* // <div className="inputField__wrap"> */}
      <label className='inputField__label'>
        {label}
        {isRequired && <sup>*</sup>}
      </label>
      {type === 'textArea' ? (
        <TextArea
          disabled={disabled}
          name={name}
          validate={validate}
          value={value || ''}
          className={`inputField__input ${classNameInput}`}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          maxLength={maxLength}
          readOnly={readOnly}
          max={max}
        ></TextArea>
      ) : (
        <Input
          disabled={disabled}
          name={name}
          validate={validate}
          value={value || ''}
          className={`inputField__input ${classNameInput}`}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          maxLength={maxLength}
          readOnly={readOnly}
          max={max}
          onFocus={(e) => e.target.addEventListener('wheel', disableScroll, { passive: false })}
          onBlur={(e) => e.target.removeEventListener('wheel', disableScroll, { passive: false })}
        />
      )}
      {showLimit && (
        <p className={`inputField__LimitMsg ${greyText && 'inputField__LimitMsg--grey'}`}>
          {showLimit}
        </p>
      )}

      {formik?.errors[id] && <p>{formik?.errors[id]}</p>}
    </div>
  )
}

export default InputField
