import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { BackTo } from '../../../../Assets/image/svgImg'
import './PalletNftId.scss'
import { Link, useParams } from 'react-router-dom'
import BoxInfo from '../boxInfo/BoxInfo'
import PalletItem from '../palletItem/PalletItem'
import { WareHouseService } from '../../../../Services/warehouse.service'
import { getCountryById } from 'utils/countries'

const statusDetail = {
  Storage: (
    <div
      style={{
        color: '#FFFFFF',
        width: '75px',
        height: '32px',
        border: '1px solid #EDEDED',
        borderRadius: '8px',
        backgroundColor: '#6DBB45',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Storage
    </div>
  ),
  Shipped: (
    <div
      style={{
        color: '#FFFFFF',
        width: '75px',
        height: '32px',
        border: '1px solid #EDEDED',
        borderRadius: '8px',
        backgroundColor: '#F97245',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Shipped
    </div>
  ),
  Prepared: (
    <div
      style={{
        color: '#726C14',
        width: '75px',
        height: '32px',
        border: '1px solid #EDEDED',
        borderRadius: '8px',
        backgroundColor: '#EFE736',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Prepared
    </div>
  ),
  PENDING: (
    <div
      style={{
        color: '#726C14',
        width: '75px',
        height: '32px',
        border: '1px solid #EDEDED',
        borderRadius: '8px',
        backgroundColor: '#EFE736',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Pending
    </div>
  ),
}
const PalletNftId = () => {
  const [itemPallet, setItemPallet] = useState([])
  const { id } = useParams()

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await WareHouseService.getOrdersNftId(id)
        setItemPallet(response?.data?.data)
      } catch (err) {
        setItemPallet([])
      }
    }
    fetchData()
  }, [])

  const boxItemInfo = {
    id: itemPallet.id,
    wineName: itemPallet.nftName,
    country:
      itemPallet.wineryCountry &&
      itemPallet.wineryContinent &&
      `${itemPallet.wineryCountry}, ${itemPallet.wineryContinent}`,
    caseType: itemPallet.caseType,
    nftType: itemPallet.nftType,
    paymentDate:
      itemPallet.orderCreatedAt && itemPallet.orderCreatedAt !== 'Invalid date'
        ? itemPallet.orderCreatedAt
        : '---',
    whoPaid: itemPallet.email,
    location: getCountryById(itemPallet.country || '')?.label,
    deliveryType: itemPallet.deliveryType,
    deliveryTerm: itemPallet.deliveryTerm,
    deliveryCompany: itemPallet.deliveryCompany,
  }

  return (
    <MainLayout>
      <div className='wareContainer'>
        <Col className='wareContainer_container'>
          <Row className='wareContainer_container__wrapper'>
            <Col className='wareContainer_container__wrapper__sidebar'>
              <Link to='/panel/ware-house'>
                <div className='wareContainer_btn'>
                  <span>
                    <BackTo />
                  </span>
                  Back to list
                </div>
              </Link>
              <PalletItem
                key={itemPallet?.id}
                wine_name={itemPallet?.wineName}
                nft_id={itemPallet?.id}
                status={statusDetail[itemPallet?.statusDelivery]}
                first_name={itemPallet?.first_name}
                last_name={itemPallet?.last_name}
                winery_id={itemPallet?.vineyardName}
                createdAt={itemPallet?.createdAt}
                burned_date={itemPallet?.burnDate}
                owner={itemPallet?.address}
                requestedBurn={itemPallet?.requestedToBurnDate}
                palletId={itemPallet?.palleteId}
                typeBox={itemPallet?.typeBox}
                physicalBoxId={
                  itemPallet?.physicalId !== null
                    ? `${itemPallet?.physicalId.slice(0, 10)}..`
                    : '----'
                }
                trackingId={itemPallet?.trackingId}
                trackingLink={itemPallet?.trackingLink}
                dateArrival={itemPallet?.dateOfArrival}
                estDeliveryDate={itemPallet?.estDeliveryDate}
                actDelDay={itemPallet?.actDelDay}
                actShippDay={itemPallet?.actShippDay}
                estShippedDay={itemPallet?.estShippedDay}
                caseType={itemPallet?.case}
                deliveryCompany={itemPallet.deliveryCompany}
              />
            </Col>
            <Col className='wareContainer_container__wrapper__content'>
              <div className='wareContainer_container__checkContent'>
                <h2 className='wareContainer_container__title'>Check information</h2>
                <div className='wareContainer_container__box'>
                  <BoxInfo data={boxItemInfo} />
                </div>
                <div className='wareContainer_container__cubic'>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </MainLayout>
  )
}
export default PalletNftId
