import React from "react";
import { Checkbox } from "semantic-ui-react";

import "./CheckBox.scss";

const CheckBox = ({ label, id, onChange, checked }) => {
  return (
    <Checkbox
      className="checkbox--style"
      label={label}
      id={id}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default CheckBox;
