import { startLoading, stopLoading } from "./loading.action";
import { MarketplaceService } from "../../Services/marketplace.service";
import {toast} from "../../components/Toast/Toast";

export const actionTypes = {
  FETCH_MARKETPLACE_NFTS: "FETCH_MARKETPLACE_NFTS",
  UPDATE_MARKETPLACE_NFT: "UPDATE_MARKETPLACE_NFT",
};

export function fetchMarketplaceNfts(data) {
  return {
    type: actionTypes.FETCH_MARKETPLACE_NFTS,
    payload: data,
  };
}

export function updateMarketplaceNftData(data) {
  return {
    type: actionTypes.UPDATE_MARKETPLACE_NFT,
    payload: data,
  };
}

export function getMarketplaceNfts(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading());
      MarketplaceService.getAllMarketplaceNfts(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          dispatch(fetchMarketplaceNfts(res.data));
          dispatch(stopLoading());

          resolve(res);
        })
        .catch((ex) => {
          dispatch(stopLoading());
          reject(ex);
        });
    });
}

export function getMarketplaceNft(data) {
  return () =>
    new Promise((resolve, reject) => {
      MarketplaceService.getMarketplaceNft(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
}

export function updateMarketplaceNft(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      MarketplaceService.updateMarketplaceNft(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          if (!res?.data?.error && res?.data?.message) {
            toast.success(res?.data?.message);
          }

          dispatch(updateMarketplaceNftData(res.data));
          resolve(res);
        })
        .catch((ex) => {
          toast.error('Save attempt failed due to unknown issue. Please try again');
          reject(ex);
        });
    });
}
