import React, { useState, useEffect } from 'react'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'

import LoaderComponent from '../../../components/LoaderComponent/LoaderComponent'
import { useHistory } from 'react-router'
import CreateWineryForm from './CreateWineryForm'
import 'antd/dist/antd.min.css'
import '../CreateWinemakers/CreateWinemakers.scss'

const CreateWineryRequest = (props) => {
  const [isLoader, setIsLoader] = useState(false)
  const history = useHistory()
  const userDetails = history?.location?.state

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  return (
    <>
      <LoaderComponent loading={isLoader} />

      <MainLayout>
        <div className='winemakers-main'>
          <div className='right'>
            <CreateWineryForm setIsLoader={setIsLoader} userDetails={userDetails} />
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export default CreateWineryRequest
