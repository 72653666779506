import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'

import './StatusButton.scss'

const statusClassName = {
  DELIVERED: 'detail-tab_shippingOrderStatusDelivered',
  SHIPPED: 'detail-tab_shippingOrderStatusShipped',
  PREPARED: 'detail-tab_shippingOrderStatusPrepared',
  STORAGE: 'detail-tab_shippingOrderStatusStorage',
  PENDING: 'detail-tab_shippingOrderStatusPending',
}

const StatusButton = ({ status = '' }) => {
  if (!status) return null

  return (
    <div className={`detail-tab_shippingOrderStatus ${statusClassName[status.toUpperCase()]}`}>
      {capitalize(status)}
    </div>
  )
}

StatusButton.defaultProps = {
  status: '',
}
StatusButton.propTypes = {
  status: PropTypes.string,
}

export default StatusButton
