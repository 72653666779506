import { PersistActions, LoadingActions } from '.'
import { toast } from '../../components/Toast/Toast'
import ApiService from '../../Services/Api.service'

/**
 * @function login
 * @param {email, password} data
 * @returns /login Api response
 */

export const login = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions
  dispatch(startLoading())
  try {
    const { saveLoginDetails } = PersistActions
    let res = await ApiService.login(data)
    if (res) {
      dispatch(stopLoading())

      const {
        data: {
          data: { userId, accessToken },
          message,
        },
      } = res
      /** Save login details */
      localStorage.setItem('authorizations', accessToken)
      toast.success(message)

      dispatch(saveLoginDetails({ user: userId, token: accessToken }))
    }
  } catch (error) {
    dispatch(stopLoading())

    throw error
  }
}

export const reset = (data) => async (dispatch) => {
  try {
    let res = await ApiService.reset(data)
    if (res) {
      const {
        data: { message },
      } = res
      toast.success(message)
    }
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export const confirm = (data) => async (dispatch) => {
  try {
    let res = await ApiService.confirm(data)
    if (res) {
      const {
        data: { message },
      } = res
      toast.success(message)
    }
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export const searchBar = async (data) => {
  try {
    let res = await ApiService.searchBar(data)
    return res.data
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export const createWiner = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions

  try {
    dispatch(startLoading())
    const { setHeaders } = PersistActions

    const headers = await dispatch(setHeaders({ isFormdata: false }))

    const res = await ApiService.createWiner(data, headers)
    dispatch(stopLoading())
    return res
  } catch (error) {
    dispatch(stopLoading())
    throw error
  }
}

export const getWinerDetails = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    dispatch(startLoading())
    const { setHeaders } = PersistActions

    const headers = await dispatch(setHeaders({ isFormdata: false }))

    const res = await ApiService.getWinerDetails(data, headers)
    dispatch(stopLoading())
    return res
  } catch (error) {
    dispatch(stopLoading())
    throw error
  }
}

export const saveProfileOldData = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    dispatch(startLoading())
    const { setHeaders } = PersistActions

    const headers = await dispatch(setHeaders({ isFormdata: false }))

    const res = await ApiService.saveProfileOldData(data, headers)
    dispatch(stopLoading())
    return res
  } catch (error) {
    dispatch(stopLoading())
    throw error
  }
}

export const updateWinerProfileData = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    dispatch(startLoading())
    const { setHeaders } = PersistActions

    const headers = await dispatch(setHeaders({ isFormdata: false }))

    const res = await ApiService.updateWinerProfileData(data, headers)
    dispatch(stopLoading())
    return res
  } catch (error) {
    dispatch(stopLoading())
    throw error
  }
}

export const getDetailsLogs = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    dispatch(startLoading())
    const { setHeaders } = PersistActions

    const headers = await dispatch(setHeaders({ isFormdata: false }))

    const res = await ApiService.getDetailsLogs(data, headers)
    dispatch(stopLoading())
    return res
  } catch (error) {
    dispatch(stopLoading())
    throw error
  }
}

export const getPublicDetailsLogs = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    dispatch(startLoading())
    const { setHeaders } = PersistActions

    const headers = await dispatch(setHeaders({ isFormdata: false }))

    const res = await ApiService.getPublicDetailsLogs(data, headers)
    dispatch(stopLoading())
    return res
  } catch (error) {
    dispatch(stopLoading())
    throw error
  }
}
