import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Confirm } from "semantic-ui-react";
import {
  IconEditDoc,
} from "../../../Assets/image/svgimages/sVGImg";
import "./DropdownEvents.scss";
import close from "../../../Assets/image/close.svg";
import WineftModal from "../../../pages/private/WineMakers /WineftModal/WineftModal";
import { toast } from "../../Toast/Toast";
import {
  sendToApproveNft,
  deleteDrop,
} from "../../../redux/_actions/nft.action";
import { useDispatch } from "react-redux";

const DropdownEvents = ({
  icon,
  postion,
  rowObject,
  deleteuserBottle,
  selectedTab,
  getAllNft,
}) => {
  rowObject.type = selectedTab;

  const dispatch = useDispatch();

  const [wineftopen, setWineftopen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const sendNftToApprove = () => {
    let data = {
      id: rowObject?.id,
      status: "SENT",
    };
    dispatch(sendToApproveNft(data)).then(
      (res) => {
        setWineftopen(false);
        toast.success(res.data.message);
        getAllNft();
      },
      (error) => {
        setWineftopen(false);
        toast.error(error.data.message);
      }
    );
  };

  const openDeleteConfirm = () => {
    setOpenConfirm(true);
  };

  const onCloseDelele = () => {
    setOpenConfirm(false);
  };

  const onConfirm = () => {
    let data = {
      id: rowObject?.id,
    };
    dispatch(deleteDrop(data)).then((res) => {
      getAllNft();
    });
  };
  return (
    <>
      <Dropdown
        icon={icon}
        className="dropdown-edit"
      >
        <Dropdown.Menu className={postion}>
          {selectedTab === "DRAFT" && (
            <>
            </>
          )}
          {selectedTab === "CURRENT" && (
            <>
              <Dropdown.Item>
                <Link
                  to={{
                    pathname: "/panel/TransferNFT",
                    state: rowObject,
                  }}
                >
                  <IconEditDoc />
                  Transfer NFT
                </Link>
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item>
            <Link
              to={{
                pathname: "/panel/EditWineft",
                state: rowObject,
              }}
            >
              <IconEditDoc />
              Edit NFT details
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link
              to="#"
              onClick={() => openDeleteConfirm()}>
              <img src={close} alt="close" className="iconCrose" />
              Remove
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <WineftModal
        onClose={() => setWineftopen(false)}
        onWineftopen={() => setWineftopen(true)}
        wineftopen={wineftopen}
        handleOnSubmit={sendNftToApprove}
      />
      <Confirm
        open={openConfirm}
        onCancel={onCloseDelele}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default DropdownEvents;
