import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Form, Table, Popup } from 'semantic-ui-react'
import { Row, Col } from 'antd'
import cn from 'classnames'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import InputField from '../../../../components/common/InputField/InputField'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { ReactComponent as IconBack } from '../../../../Assets/image/svgimages/icon_back.svg'
import TextAreaField from '../../../../components/common/TextareaField/TextAreaField'
import { toast } from '../../../../components/Toast/Toast'
import { Facebook, Instagram, Twitter, PrevIcon } from '../../../../Assets/image/svgimages/sVGImg'
import { useDispatch } from 'react-redux'
import { updateComingSoonStatus } from '../../../../redux/_actions/winemaker.action'
import { WinerMakerAction } from '../../../../redux/_actions'
import PublicPageLogs from './PublicPageLogs'
import { EDIT_ADMIN, EDIT_USER } from '../../../../utils/constants'
import '../../winemakers/addWinemaker/AddWinemaker.scss'
import './CreatePublicPage.scss'

const CreatePublicPage = () => {
  const dispatch = useDispatch()

  const [logsData, setLogsData] = useState({})
  const { updateProfileStatus, getWineMakerDetails, getPublicDetailsLogs } = WinerMakerAction

  const [winerDetails, setwinerDetails] = useState([])
  const [showBottom, setShowBottom] = useState(true)
  const history = useHistory()
  const userId = history?.location?.state?.userId
  const status = history?.location?.state?.profile_status
  const coming_soon_enabled = history?.location?.state?.coming_soon_enabled
  const [isActive, setActive] = useState('false')

  const ToggleClass = () => {
    setActive(!isActive)
  }

  useEffect(() => {
    if (!userId) {
      history.push('/panel/WineList')
    } else {
      getWinerAllDetails()
      getLogsDetails()
    }
  }, [userId])

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const publishUnPublishWineMaker = () => {
    const isUnPublish = status === 2
    let data = {
      userId: userId,
      status: status || 2,
      isUnPublish,
    }

    const successAction = (message) => {
      toast.success(message)
      history.push('/panel/WineList')
    }
    //called if user has completed all the step
    dispatch(updateProfileStatus(data)).then((res) => {
      if (isUnPublish) {
        // also clear coming_soon_enabled status after unpublished
        const comingSoonData = {
          user_id: userId,
          coming_soon_enabled: 0,
        }

        return dispatch(updateComingSoonStatus(comingSoonData)).then((_res) => {
          successAction(res.data.message)
        })
      }

      return successAction(res.data.message)
    })
  }

  const getWinerAllDetails = () => {
    const postInfo = {
      userId: userId,
    }

    dispatch(getWineMakerDetails(postInfo)).then((res) => {
      let winerAllDetails = res.data?.data?.winerDetails
      setwinerDetails(winerAllDetails)
    })
  }

  const getLogsDetails = () => {
    let data = {
      userId: userId,
    }
    dispatch(getPublicDetailsLogs(data)).then((res) => {
      setLogsData(res?.data.data)
    })
  }

  const getEditStatus = () => {
    return (
      logsData.form_1 !== null ||
      logsData.form_2 !== null ||
      logsData.form_3 !== null ||
      logsData.form_5 !== null
    )
  }

  const windowOpenUrl = (url) => {
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url
    }
    window.open(url, '_blank')
  }

  return (
    <MainLayout>
      {winerDetails.length > 0 && (
        <Row>
          <Col xs={24}>
            <div className='winemakers-main create-public-page'>
              <div className={cn('left', { brdr: isActive })}>
                <div className='top-controll'>
                  <Link to='/panel/WineList' className='back-btn'>
                    <IconBack />
                    Back to winery list
                  </Link>

                  <Link
                    to={{
                      pathname: '/panel/public-page-prev',
                      state: history?.location?.state,
                      prevPath: history?.location.pathname,
                    }}
                    className={cn('publicprev', {
                      disabled: history?.location?.state?.role_type !== 2,
                    })}
                  >
                    <PrevIcon /> Preview public page
                  </Link>
                </div>
                <div className='logs-controller'>
                  <div className='top'>
                    <h3 className='common-sub-heading'>Logs</h3>
                    <Link
                      to='#'
                      className={cn('logs-toggler', { active: isActive })}
                      onClick={() => {
                        setShowBottom((currentShow) => !currentShow)
                        ToggleClass()
                      }}
                    ></Link>
                  </div>
                  {showBottom && (
                    <div className='bottom'>
                      <Row>
                        <Col xs={24} md={12} lg={24}>
                          {getEditStatus() ? (
                            <ul>
                              <li className='active'>
                                <PublicPageLogs data={logsData} />
                              </li>
                            </ul>
                          ) : (
                            <p>No logs found</p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
              <div className='right'>
                <div className='top-controll'>
                  <Link to='/panel/WineList' className='back-btn'>
                    <IconBack /> Back to winemaker list
                  </Link>
                  <Link
                    to={{
                      pathname: '/panel/public-page-prev',
                      state: history?.location?.state,
                      prevPath: history?.location.pathname,
                    }}
                    className={cn('publicprev', {
                      disabled: history?.location?.state?.role_type !== 2,
                    })}
                  >
                    <PrevIcon /> Preview public page
                  </Link>
                </div>
                <div className='block' id='a'>
                  <h1 className='common-heading'>Public Page</h1>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Cover Section</h3>
                  </div>
                  <Form>
                    {/* edit_Bar  */}
                    <InputField
                      readOnly={true}
                      className={cn({
                        'dot-bar':
                          logsData?.form_1 !== null &&
                          logsData?.form_1?.newValues?.header &&
                          logsData?.form_1.added_by === EDIT_ADMIN,
                        edit_Bar:
                          logsData?.form_1 !== null &&
                          logsData?.form_1?.newValues?.header &&
                          logsData?.form_1.added_by === EDIT_USER,
                      })}
                      label='Winery Name'
                      value={winerDetails[0]?.creator_heading_meta?.header}
                      showLimit={`${winerDetails[0]?.creator_heading_meta?.header?.length} / 100`}
                      type='text'
                    />
                    <InputField
                      readOnly={true}
                      className={cn({
                        'dot-bar':
                          logsData?.form_1 !== null &&
                          logsData?.form_1?.newValues?.description &&
                          logsData?.form_1.added_by === EDIT_ADMIN,
                        edit_Bar:
                          logsData?.form_1 !== null &&
                          logsData?.form_1?.newValues?.description &&
                          logsData?.form_1.added_by === EDIT_USER,
                      })}
                      label='Winery/Wine Description'
                      type='text'
                      value={winerDetails[0]?.creator_heading_meta?.description}
                      showLimit={`${winerDetails[0]?.creator_heading_meta?.description?.length} / 150`}
                    />
                    <div
                      className={cn({
                        Header:
                          logsData?.form_1 !== null &&
                          logsData?.form_1?.newValues?.background_img &&
                          (logsData?.form_1.added_by === EDIT_ADMIN ||
                            logsData?.form_1.added_by === EDIT_USER),
                      })}
                    >
                      <h3 className='common-sub-heading'>Cover Image</h3>
                    </div>
                    <div className='uploadField'>
                      <div className='profilePic'>
                        <div className='profilePic-inner large'>
                          <input
                            disabled={true}
                            type='file'
                            name=' profile_image'
                            id=' profile_image'
                            className='input__input'
                            formEncType='multipart/form-data'
                          />
                          <label
                            className={cn('file-input__prof', {
                              'IconHidd BAckImageSet':
                                winerDetails[0]?.creator_heading_meta?.background !== '',
                            })}
                            htmlFor='file-input'
                          >
                            <img
                              style={{ width: 64, height: 64 }}
                              src={winerDetails[0]?.creator_heading_meta?.background}
                              alt=''
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className='block' id='b'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Key content details</h3>
                  </div>
                  <Form>
                    <InputField
                      className={cn({
                        'dot-bar':
                          logsData?.form_2 !== null &&
                          logsData?.form_2?.newValues?.header &&
                          logsData?.form_2.added_by === EDIT_ADMIN,
                        edit_Bar:
                          logsData?.form_2 !== null &&
                          logsData?.form_2?.newValues?.header &&
                          logsData?.form_2.added_by === EDIT_USER,
                      })}
                      readOnly={true}
                      label="Winery's background story"
                      value={winerDetails[0]?.creator_ourstory_meta?.header}
                      type='text'
                      showLimit={`${winerDetails[0]?.creator_ourstory_meta?.header?.length} /150`}
                    />
                    <TextAreaField
                      className={cn({
                        'dot-bar':
                          logsData?.form_2 !== null &&
                          logsData?.form_2?.newValues?.body_text &&
                          logsData?.form_2.added_by === EDIT_ADMIN,
                        edit_Bar:
                          logsData?.form_2 !== null &&
                          logsData?.form_2?.newValues?.body_text &&
                          logsData?.form_2.added_by === EDIT_USER,
                      })}
                      label='Text'
                      name='aa'
                      value={winerDetails[0]?.creator_ourstory_meta?.text}
                      rows='10'
                      showLimit={`${winerDetails[0]?.creator_ourstory_meta?.text?.length} /1000`}
                    />
                    <div
                      className={cn({
                        'Header dot-bar':
                          logsData?.form_2 !== null &&
                          logsData?.form_2?.newValues?.background_img &&
                          logsData?.form_2.added_by === EDIT_ADMIN,
                        'Header edit_Bar':
                          logsData?.form_2 !== null &&
                          logsData?.form_2?.newValues?.background_img &&
                          logsData?.form_2.added_by === EDIT_USER,
                      })}
                    >
                      <h3 className='common-sub-heading'>Section image</h3>
                    </div>
                    <div className='uploadField'>
                      <div className='profilePic'>
                        <div className='profilePic-inner big'>
                          <input
                            type='file'
                            disabled={true}
                            name='profile_image'
                            id=' profile_image'
                            className='input__input'
                            formEncType='multipart/form-data'
                          />
                          <label
                            className={cn('file-input__prof', {
                              'IconHidd BAckImageSet':
                                winerDetails[0]?.creator_ourstory_meta?.imageUrl !== '',
                            })}
                            htmlFor='file-input'
                          >
                            <img
                              style={{ width: 64, height: 64 }}
                              src={winerDetails[0]?.creator_ourstory_meta?.imageUrl}
                              alt=''
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className='block' id='c'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Add winery photo</h3>
                  </div>
                  <Form>
                    <InputField
                      className={cn({
                        'Header dot-bar':
                          logsData?.form_3 !== null &&
                          logsData?.form_3?.newValues?.header &&
                          logsData?.form_3.added_by === EDIT_ADMIN,
                        'Header edit_Bar':
                          logsData?.form_3 !== null &&
                          logsData?.form_3?.newValues?.header &&
                          logsData?.form_3.added_by === EDIT_USER,
                      })}
                      readOnly={true}
                      label='Gallery'
                      value={winerDetails[0]?.creator_gallery_meta?.header}
                      type='text'
                      showLimit={`${winerDetails[0]?.creator_gallery_meta?.header?.length} /50`}
                    />
                    <div className='Header'>
                      <h3 className='common-sub-heading'>GALLERY PHOTOS</h3>
                    </div>
                    <div className='galleryField'>
                      {winerDetails[0]?.creator_gallery_meta?.creator_gallery_images_meta.map(
                        (img) => (
                          <div className='uploadField' key={img?.imageId}>
                            <div className='profilePic'>
                              <div className='profilePic-inner'>
                                <label
                                  className={
                                    img?.imageUrl !== ''
                                      ? 'file-input__prof IconHidd BAckImageSet'
                                      : 'file-input__prof'
                                  }
                                  htmlFor='file-input'
                                >
                                  <img
                                    style={{ width: 64, height: 64 }}
                                    src={img?.imageUrl}
                                    alt=''
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </Form>
                </div>
                <div className='block border-0 mb-0' id='e'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Winery vision and philosophy</h3>
                  </div>
                  <Form>
                    <InputField
                      className={cn({
                        'Header dot-bar':
                          logsData?.form_5 !== null &&
                          logsData?.form_5?.newValues?.header &&
                          logsData?.form_5.added_by === EDIT_ADMIN,
                        'Header edit_Bar':
                          logsData?.form_5 !== null &&
                          logsData?.form_5?.newValues?.header &&
                          logsData?.form_5.added_by === EDIT_USER,
                      })}
                      readOnly={true}
                      label='Title for the section'
                      value={winerDetails[0].creator_our_strategy?.header}
                      type='text'
                      showLimit={`${winerDetails[0]?.creator_our_strategy?.header?.length} /150`}
                    />
                    <InputField
                      className={cn({
                        'Header dot-bar':
                          logsData?.form_5 !== null &&
                          logsData?.form_5?.newValues?.text &&
                          logsData?.form_5.added_by === EDIT_ADMIN,
                        'Header edit_Bar':
                          logsData?.form_5 !== null &&
                          logsData?.form_5?.newValues?.text &&
                          logsData?.form_5.added_by === EDIT_USER,
                      })}
                      readOnly={true}
                      label='Text'
                      value={winerDetails[0].creator_our_strategy?.text}
                      showLimit={`${winerDetails[0]?.creator_our_strategy?.text.length} /900`}
                    />
                  </Form>
                </div>
                <div className='block' id='f'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Features</h3>
                  </div>

                  <div className='new-table'>
                    <Table striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Feature Title</Table.HeaderCell>
                          <Table.HeaderCell>Feature Description</Table.HeaderCell>
                          <Table.HeaderCell>Section Image</Table.HeaderCell>
                          {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {winerDetails[0].creator_features_details.map((feature, index) => (
                          <Table.Row key={feature.id}>
                            <Popup
                              content={feature.featuresTitle}
                              on='click'
                              pinned
                              trigger={
                                <Table.Cell>{feature.featuresTitle.slice(0, 10)} ...</Table.Cell>
                              }
                            />
                            <Popup
                              content={feature.featuresDescription}
                              on='click'
                              pinned
                              trigger={
                                <Table.Cell>
                                  {feature.featuresDescription.slice(0, 10)} ..
                                </Table.Cell>
                              }
                            />

                            <Table.Cell>
                              <img className='pro' src={feature.imageUrl} alt='' />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div className='block' id='d'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Our team</h3>
                  </div>

                  <div className='new-table'>
                    <Table striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Full Name</Table.HeaderCell>
                          <Table.HeaderCell>Job</Table.HeaderCell>
                          <Table.HeaderCell>Social Media</Table.HeaderCell>
                          <Table.HeaderCell>Profile</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {winerDetails[0].creator_team_meta.map((team, index) => (
                          <Table.Row key={team.memberId}>
                            <Table.Cell>{team.fullName}</Table.Cell>
                            <Table.Cell>{team.jobTitle}</Table.Cell>
                            <Table.Cell>
                              {team.instagramUrl ? (
                                <Link
                                  to='#'
                                  title={team.instagramUrl}
                                  className='sociali'
                                  onClick={() => windowOpenUrl(team.instagramUrl)}
                                >
                                  <Instagram />
                                </Link>
                              ) : (
                                <Link to='#' className='sociali'>
                                  ---
                                </Link>
                              )}

                              {team.twitterUrl ? (
                                <Link
                                  to='#'
                                  title={team.twitterUrl}
                                  className='sociali'
                                  onClick={() => windowOpenUrl(team.twitterUrl)}
                                >
                                  <Twitter />
                                </Link>
                              ) : (
                                <Link to='#' className='sociali'>
                                  ---
                                </Link>
                              )}

                              {team.facebookUrl ? (
                                <Link
                                  to='#'
                                  className='sociali'
                                  onClick={() => windowOpenUrl(team.facebookUrl)}
                                >
                                  <Facebook title={team.facebookUrl} />
                                </Link>
                              ) : (
                                <Link to='#' className='sociali'>
                                  ---
                                </Link>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <img className='pro' src={team.imgUrl} alt='' />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div className='block' id='h'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Other details of the winery</h3>
                  </div>
                  <Form>
                    <InputField
                      readOnly={true}
                      label='Title'
                      value={winerDetails[0].creator_details_meta?.header}
                      type='text'
                      showLimit={`${winerDetails[0]?.creator_details_meta?.header?.length} /100`}
                    />
                    {winerDetails[0].creator_details_meta?.creator_details_questions_meta.map(
                      (query) => (
                        <div key={query.id}>
                          <InputField
                            readOnly={true}
                            label='Question'
                            value={query.question}
                            type='text'
                            // showLimit="0 / 39"
                            showLimit={`${query.question?.length} /150`}
                          />
                          <InputField
                            readOnly={true}
                            label='Answer'
                            value={query.answer}
                            type='text'
                            showLimit={`${query.answer?.length} /300`}
                          />
                        </div>
                      ),
                    )}

                    <div className='Header'>
                      <h3 className='common-sub-heading'>Section Image</h3>
                    </div>
                    <div className='uploadField'>
                      <div className='profilePic'>
                        <div className='profilePic-inner big'>
                          <input
                            disabled={true}
                            type='file'
                            name=' profile_image'
                            id=' profile_image'
                            className='input__input'
                            formEncType='multipart/form-data'
                          />
                          <label
                            className={cn('file-input__prof', {
                              'IconHidd BAckImageSet':
                                winerDetails[0].creator_details_meta?.imageUrl !== '',
                            })}
                            htmlFor='file-input'
                          >
                            <img
                              style={{ width: 64, height: 64 }}
                              src={winerDetails[0].creator_details_meta?.imageUrl}
                              alt=''
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='btns-spacing'>
                      <ButtonCustom
                        onClick={() => publishUnPublishWineMaker()}
                        title={cn({
                          Publish: status === 0 || status === 1 || coming_soon_enabled,
                          Unpublish: status === 2,
                        })}
                        customClass='saveBtn'
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </MainLayout>
  )
}
export default CreatePublicPage
