import React from "react";
import DtPicker from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/index.css";
import "./CalendarDateTime.scss";

const CalendarDateTime = ({
  isRequired,
  label,
  placeholder,
  onChangeHandler,
  value,
  withTime,
  initValue,
  isDisabled,
  minDate,
  onCalenderShow,
  onCalenderHide,
}) => {
  // prevent bug with minDate is greater than initValue
  const validMinDate = isDisabled ? undefined : minDate

  return (
    <>
      <div className="CalendarDateTime">
        <label className="inputField__label">
          {label}
          {isRequired && <sup>*</sup>}
        </label>
        <DtPicker
          value={value}
          initValue={initValue}
          placeholder={placeholder}
          onChange={(e) => onChangeHandler(e)}
          withTime={withTime}
          showTimeInput={withTime}
          isDisabled={isDisabled}
          minDate={validMinDate}
          onCalenderShow={onCalenderShow}
          onCalenderHide={onCalenderHide}
        // isClearable={false}
        />
      </div>
    </>
  );
};

export default CalendarDateTime;
