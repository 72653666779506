import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ButtonCustom } from "../../../../components/button/ButtonCustom";
import { IconBackLong } from "../../../../Assets/image/svgimages/sVGImg";
import { useDispatch } from "react-redux";

import { WinerMakerAction } from "../../../../redux/_actions";
import { toast } from "../../../../components/Toast/Toast";

const BackToPrev = (props) => {
  let { pathname, userDetails } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateProfileStatus } = WinerMakerAction;

  const publishMe = () => {
    let data = {
      userId: userDetails?.userId,
      status: 2,
    };
    //called if user has completed all the step
    dispatch(updateProfileStatus(data)).then((res) => {
      toast.success(res.data.message);
      history.push("/panel/WineList");
    });
  };

  return (
    <>
      <div className="back-to-prev">
        <div className="inner steps_buuton">
          <Link
            to={{
              pathname: pathname,
              state: userDetails,
            }}
          >
            <ButtonCustom
              title="Previous step"
              customClass="previous"
              leftIcon={<IconBackLong />}
            />
          </Link>
          {userDetails?.profile_status !== 3 && (
            <ButtonCustom
              onClick={() => publishMe()}
              title="Sent to publish"
              customClass="Continue"
            />
          )}
        </div>
      </div>
    </>
  );
};
export default BackToPrev;
