import Types from '../_actions/_types'

const initialState = {
  artist: {},
}

const brand = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case Types.SAVE_ARTIST:
      return { ...state, artist: payload.artist }

    default:
      return state
  }
}

export default brand
