import { actionTypes } from '../_actions/notification.action'

const initialState = {
  notificationList: [],
  pendingNotificationList: [],
  pendingTotal: 0,
  total: 0,
}

const notification = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_NOTIFICATION:
      return {
        ...state,
        notificationList: [...payload?.data],
        total: payload?.count,
      }
    case actionTypes.SAVE_PENDING_NOTIFICATION:
      return {
        ...state,
        pendingNotificationList: payload?.data,
        pendingTotal: payload.count,
      }

    default:
      return state
  }
}

export default notification
