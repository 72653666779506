import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MainLayout from 'components/HOC/MainLayout/MainLayout'
import DropCollectionForm from './dropCollectionForm/DropCollectionForm'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from '../../../../components/Toast/Toast'
import {
  getAllDropCollections,
  getDropCollection,
  updateDropCollection,
} from 'redux/_actions/dropCollection.action'

import updateOrder from '../utils/updateOrder'

const DropCollectionEdit = () => {
  const { id } = useParams()
  const [dropCollection, setDropCollection] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const { entries } = useSelector((state) => state?.dropCollection)

  useEffect(() => {
    dispatch(getAllDropCollections())
  }, [dispatch])

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  }, [])

  useEffect(() => {
    if (id) {
      const successFn = (res) => {
        setDropCollection(res?.data?.data || null)
      }
      const errorFn = (res) => {
        toast.error(res?.data?.message || 'Failed')
      }
      dispatch(getDropCollection({ id }, successFn, errorFn))
    }
  }, [id, dispatch])

  const onSubmit = async (values) => {
    const successFn = (res) => {
      toast.success(res.data?.message)
      history.push('/panel/drop-collections')
    }
    const errorFn = (res) => {
      toast.error(res?.data?.message || 'Failed')
    }
    await updateOrder(dropCollection, { ...values, id: dropCollection?.id }, entries)
    dispatch(
      updateDropCollection(
        { ...values, order: Math.min(values.order, entries.length), id: dropCollection?.id },
        successFn,
        errorFn,
      ),
    )
  }

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Drop Collection Edit</h3>
      <Row>
        <Col span={14} offset={5}>
          {dropCollection ? <DropCollectionForm data={dropCollection} onSubmit={onSubmit} /> : null}
        </Col>
      </Row>
    </MainLayout>
  )
}

export default DropCollectionEdit
