import { Button } from "semantic-ui-react";
import React from "react";
import "./ButtonCustomStyle.scss";

const ButtonCustom = (props) => {
  const { title, style, customClass, leftIcon, onClick, type, disabled, minimal, lesspadding } = props;
  return (
    <Button
      onClick={onClick}
      className={`btnCustom ${customClass}`}
      style={style}
      type={type}
      disabled={disabled}
      minimal={minimal}
      lesspadding={lesspadding}
    >
      {leftIcon && <span className="leftIcon">{leftIcon}</span>}
      {title}
    </Button>
  );
};

export { ButtonCustom };
