import { Card, Col } from 'antd'
import './CardGrid.scss'
import bottleDemo from '../../../Assets/image/bottleDemo.png'
import ReactCountryFlag from 'react-country-flag'

const CardGrid = (props) => {
  const getSize = (str) => {
    const index = str.indexOf(' ', str.indexOf(' ') + 1)
    const firstChunk = str.substring(0, index)
    return firstChunk
  }

  return (
    <Card className='gridCardStyle'>
      <div className='gridCardStyle__bottleWrap'>
        <div className='winebottle editBottle'>
          <img
            src={props.wineBottleImage === '' ? bottleDemo : props.wineBottleImage}
            alt='bottle'
          />
        </div>
        <div className='winebottle editTime'>
          <div className='winebottle countryCode'>
            {' '}
            <ReactCountryFlag
              countryCode={props.countrycode === '' ? 'N/A' : props.countrycode}
              svg
              style={{ width: '2em', height: '2em' }}
            />
          </div>
          <div className='winebottle sizeBottle'>
            <p className='winebottle sizeTitle'>Drop starts in:</p>
            <p className='winebottle timeColor'>
              {props?.startDate?.time === null ? 'N/A' : props?.startDate?.time}
            </p>
          </div>
          <div className='winebottle sizeBottle'>
            <p className='winebottle sizeTitle'>Price</p>
            <p className='winebottle sizeDetails'>
              {props.price === null ? 'N/A' : props.price} USDC
            </p>
          </div>
          <div className='winebottle sizeBottle'>
            <p className='winebottle sizeTitle'>Bottle Size</p>
            <p className='winebottle sizeDetails'>
              {' '}
              {props.size === '' ? 'N/A' : getSize(props.size)}
            </p>
          </div>
        </div>
      </div>
      <Col className='gridCardStyle__details'>
        <div className='gridCardStyle__wineryTitles'>
          <h2>
            {props.wineTitle === '' ? ' ' : props.wineTitle}&nbsp;
            {props.wine_name === null ? '' : '«' + props.wine_name + '»'}
          </h2>
        </div>
        <div>
          <p>
            {props.grape === null ? '' : props.grape + ','}&nbsp;
            {props.appellation === null ? '' : props.appellation + ','}&nbsp;
            {props.region_name === null ? '' : props.region_name}&nbsp;
            {props.vintage === null ? ' ' : props.vintage}
          </p>
        </div>
      </Col>
    </Card>
  )
}

export default CardGrid
