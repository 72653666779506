import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import BorderButton from '../../../../components/button/BorderButton'
import { toast } from '../../../../components/Toast/Toast'
import { withRouter } from 'react-router'
import { useDispatch } from 'react-redux'
import { WinerMakerAction } from '../../../../redux/_actions'
import { tagCustomEventSendPulse } from '../../../../utils/sendPulseApi/sendPulseApi'
import { SENDPULSE_EVENTS } from '../../../../utils/sendPulseApi/constants/constants'

const DetailsStepSeven = (props) => {
  let {
    userId,
    setStep,
    winerDetails,
    getWinerAllDetails,
    history,
    status,
    setIsLoader,
    setError,
    error,
  } = props
  const dispatch = useDispatch()
  const { updateProfileStatus, updateDetailWinerDetail } = WinerMakerAction
  const [missingFields, setMissingFields] = useState(false)

  const wineTeamFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      header:
        winerDetails.length > 0 && winerDetails[0]?.creator_details_meta !== null
          ? winerDetails[0]?.creator_details_meta.header
          : '',
    },
  })

  useEffect(() => {
    if (
      !winerDetails[0]?.creator_heading_meta?.header ||
      !winerDetails[0]?.creator_heading_meta?.background ||
      !winerDetails[0]?.creator_ourstory_meta?.header ||
      !winerDetails[0]?.creator_ourstory_meta?.imageUrl ||
      !winerDetails[0]?.creator_ourstory_meta?.text ||
      !winerDetails[0]?.creator_ourstory_meta?.text_json
    ) {
      setMissingFields(true)
    }
  }, [winerDetails])

  const addDetailsWinerDetailApi = (finalObj, type) => {
    setIsLoader(true)

    if (type === 'final') {
      let data = {
        userId: userId,
        status: 2,
        isUnPublish: false,
      }

      dispatch(updateDetailWinerDetail(finalObj)).then((res) => {
        setIsLoader(false)
        toast.success(res.data.message)
        // updateProfileStatus
        dispatch(updateProfileStatus(data)).then(async (res) => {
          setIsLoader(false)

          toast.success(res.data.message)
          await tagCustomEventSendPulse({
            eventLink: SENDPULSE_EVENTS.WINERY_PROFILE_IS_LIVE,
            email: winerDetails[0]?.email,
          })

          history.push('/panel/WineList')
        })
        getWinerAllDetails()
      })
      //called if user has completed all the step
    } else {
      // updateDetailWinerDetail

      dispatch(updateDetailWinerDetail(finalObj)).then((res) => {
        setIsLoader(false)
        toast.success(res.data.message)
        getWinerAllDetails()
      })
    }
  }

  const onSave = () => {
    let finalData = {
      header: wineTeamFormik.values.header,
      userId: userId,
      data: [],
    }

    addDetailsWinerDetailApi(finalData, '')
  }

  const onSaveAndPublish = () => {
    setError(false)
    let finalData = {
      header: wineTeamFormik.values.header,
      userId: userId,
      data: [],
    }

    addDetailsWinerDetailApi(finalData, 'final')
  }

  return (
    <div className='block' id='s6'>
      <div className='descriptionPoint'>
        <div className='Header sectionInfo Header-about'>
          <h3 className='heading'>About winery</h3>
        </div>
        <Form onSubmit={wineTeamFormik.handleSubmit}>
          <InputField
            id='header'
            label='Description'
            placeholder='Ex. Learn about us…'
            type='textArea'
            onChange={(event) => {
              wineTeamFormik.setFieldValue('header', event.target.value)
            }}
            value={wineTeamFormik.values.header}
            maxLength={10000}
          />

          <div className='btns-spacing'>
            <BorderButton
              text='Previous step'
              className='addNew_Btn'
              type='button'
              onClick={() => setStep(4)}
            />
            <ButtonCustom type='button' onClick={onSave} title='Save' customClass='saveBtn' />
            {/* show button only if public page is not published */}
            {status !== 3 ? (
              <ButtonCustom
                disabled={missingFields || error}
                type='button'
                onClick={onSaveAndPublish}
                title='Save and Publish'
                customClass='saveBtn'
              />
            ) : null}
            {/* 0-pending 1-sent 2 recieved 3 publish  */}
          </div>
        </Form>
      </div>
    </div>
  )
}
export default withRouter(DetailsStepSeven)
