import React, { useState } from "react";
import { IconRight } from "../../../../Assets/image/svgimages/sVGImg";
import Modalaction from "../../Profile/EditProfilePage/ActualVersion/ModalAction/Modalaction.jsx";
import moment from "moment";

import "./WineftLogs.scss";

const WineftLogs = (props) => {
  let { data } = props;
  const [moreField, setMoreField] = useState({});
  const [open, setOpen] = useState(false);

  const setViewFieldData = (oldItem, newItem, title) => {
    let item = {
      oldItem: oldItem,
      newItem: newItem,
      title: title,
    };
    setMoreField(item);
    setOpen(true);
  };

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes}`;
    let dates = moment(date).format("ddd DD-MMM-YYYY");

    return dates + " " + strTime;
  };

  return (
    <>
      {data.newValues !== undefined &&
        Object.keys(data.newValues).length > 0 && (
          <div className="Wineftlogs">
            <div className="actualVersion">
              <div className="Admin_By">
                <div className={"Admin" ? "Byadmin" : "ByUser"}>
                  <p>{moment(data.updated_at).format("Do MMM, h:mm a")}</p>
                  <h6>{data.added_by === "Admin" ? "Admin" : "User"}</h6>
                </div>
                <ul>
                  {data?.newValues?.vintage && (
                    <li>
                      <div className="InformShow">
                        <strong>Vintage*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.vintage.vintage,
                              data?.newValues?.vintage.vintage,
                              "Vintage*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.vintage?.vintage}</p>
                        <IconRight />
                        <p>{data?.newValues?.vintage?.vintage}</p>
                      </div>
                    </li>
                  )}
                  {/* ///  */}
                  {data?.newValues?.no_of_bottles && (
                    <li>
                      <div className="InformShow">
                        <strong>Number of bottles in a case*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.no_of_bottles,
                              data?.newValues?.no_of_bottles,
                              "Number of bottles in a case*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.no_of_bottles}</p>
                        <IconRight />
                        <p>{data?.newValues?.no_of_bottles}</p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.no_of_nft && (
                    <li>
                      <div className="InformShow">
                        <strong>Number of WiNeFTs to be minted*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.no_of_nft,
                              data?.newValues?.no_of_nft,
                              "Number of WiNeFTs to be minted*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.no_of_nft}</p>
                        <IconRight />
                        <p>{data?.newValues?.no_of_nft}</p>
                      </div>
                    </li>
                  )}

                  {(data?.newValues?.is_visible_count === 0 ||
                    data?.newValues?.is_visible_count === 1) && (
                    <li>
                      <div className="InformShow">
                        <strong>Is NFT count visible to user?</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.is_visible_count ? "Yes" : "No",
                              data?.newValues?.is_visible_count ? "Yes" : "No",
                              "Is NFT count visible to user?"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>
                          {data?.oldValues?.is_visible_count ? "Yes" : "No"}
                        </p>
                        <IconRight />
                        <p>
                          {data?.newValues?.is_visible_count ? "Yes" : "No"}
                        </p>
                      </div>
                    </li>
                  )}

                  {(data?.newValues?.is_exclusive_list === 0 ||
                    data?.newValues?.is_exclusive_list === 1) && (
                    <li>
                      <div className="InformShow">
                        <strong>
                          Will the Drop event be specifically meant ONLY for
                          Winery’s exclusive access list?
                        </strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.is_exclusive_list ? "Yes" : "No",
                              data?.newValues?.is_exclusive_list ? "Yes" : "No",
                              "Will the Drop event be specifically meant ONLY for Winery’s exclusive access list?"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>
                          {data?.oldValues?.is_exclusive_list ? "Yes" : "No"}
                        </p>
                        <IconRight />
                        <p>
                          {data?.newValues?.is_exclusive_list ? "Yes" : "No"}
                        </p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.price_ex_vat && (
                    <li>
                      <div className="InformShow">
                        <strong>Price ex VAT*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.price_ex_vat,
                              data?.newValues?.price_ex_vat,
                              "Price ex VAT*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.price_ex_vat}</p>
                        <IconRight />
                        <p>{data?.newValues?.price_ex_vat}</p>
                      </div>
                    </li>
                  )}


                  {data?.newValues?.price && (
                    <li>
                      <div className="InformShow">
                        <strong>Price*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.price,
                              data?.newValues?.price,
                              "Price*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.price}</p>
                        <IconRight />
                        <p>{data?.newValues?.price}</p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.max_buy_limit && (
                    <li>
                      <div className="InformShow">
                        <strong>Max. number of NFTs one Wallet can buy?</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.max_buy_limit,
                              data?.newValues?.max_buy_limit,
                              "Max. number of NFTs one Wallet can buy?"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.max_buy_limit}</p>
                        <IconRight />
                        <p>{data?.newValues?.max_buy_limit}</p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.timezone && (
                    <li>
                      <div className="InformShow">
                        <strong>Time Zone</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.timezone,
                              data?.newValues?.timezone,
                              "Time Zone"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.timezone}</p>
                        <IconRight />
                        <p>{data?.newValues?.timezone}</p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.bottle_release_date && (
                    <li>
                      <div className="InformShow">
                        <strong>
                          Date the physical wine bottle will be produced and
                          available to be sold on the platform, i.e., bottle
                          release date*
                        </strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.bottle_release_date,
                              data?.newValues?.bottle_release_date,
                              "Date the physical wine bottle will be produced and available to be sold on the platform, i.e., bottle release date*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.bottle_release_date}</p>
                        <IconRight />
                        <p>{data?.newValues?.bottle_release_date}</p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.royality_payment_profilt && (
                    <li>
                      <div className="InformShow">
                        <strong>
                          Royalty payments for Secondary sale, on profits from
                          sale
                        </strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              data?.oldValues?.royality_payment_profilt,
                              data?.newValues?.royality_payment_profilt,
                              "Royalty payments for Secondary sale, on profits from sale"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>{data?.oldValues?.royality_payment_profilt} %</p>
                        <IconRight />
                        <p>{data?.newValues?.royality_payment_profilt} %</p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.start_drop_event && (
                    <li>
                      <div className="InformShow">
                        <strong>Date and time Start drop event*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              formatAMPM(
                                new Date(data?.oldValues?.start_drop_event)
                              ),
                              formatAMPM(
                                new Date(data?.newValues?.start_drop_event)
                              ),
                              "Date and time Start drop event*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>
                          {formatAMPM(
                            new Date(data?.oldValues?.start_drop_event)
                          )}
                        </p>
                        <IconRight />
                        <p>
                          {formatAMPM(
                            new Date(data?.newValues?.start_drop_event)
                          )}
                        </p>
                      </div>
                    </li>
                  )}

                  {data?.newValues?.finish_drop_event && (
                    <li>
                      <div className="InformShow">
                        <strong>Date and time Finish drop event*</strong>
                        <button
                          onClick={() =>
                            setViewFieldData(
                              formatAMPM(
                                new Date(data?.oldValues?.finish_drop_event)
                              ),
                              formatAMPM(
                                new Date(data?.newValues?.finish_drop_event)
                              ),
                              "Date and time Finish drop event*"
                            )
                          }
                        >
                          ...More
                        </button>
                      </div>
                      <div className="listing">
                        <p>
                          {formatAMPM(
                            new Date(data?.oldValues?.finish_drop_event)
                          )}
                        </p>
                        <IconRight />
                        <p>
                          {formatAMPM(
                            new Date(data?.newValues?.finish_drop_event)
                          )}
                        </p>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}

      {open && (
        <Modalaction
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          data={moreField}
        />
      )}
    </>
  );
};

export default WineftLogs;
