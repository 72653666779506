import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout/MainLayout";
import { WinerMakerAction } from "../../../redux/_actions";
import UsertList from "./List/UsertList";
import useDebounce from "../../../hooks/useDebounce";
import { USER_TYPE } from "../../../utils/constants";
import "./user.scss";

const UserListMain = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState({ count: 10, rows: [] });
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("");
  const debaounceUserSearch = useDebounce(search, 500);

  useEffect(() => {
    getUserList();
  }, [debaounceUserSearch, page, order, status, limit]);

  //getting List of all users from api

  const getUserList = async () => {
    let dataFields = {
      limit: limit,
      offset: page,
      role_type: USER_TYPE, // 1=>user , 2=>winermaker
      searchkey: search,
      status: status,
      order: order,
    };
    const { getAllWineMakers } = WinerMakerAction;

    dispatch(getAllWineMakers(dataFields)).then((res) => {
      setUserList(res.data);
    });
  };

  return (
    <>
      <MainLayout>
        <UsertList
          userList={userList}
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          status={status}
          setStatus={setStatus}
          order={order}
          setOrder={setOrder}
          limit={limit}
          setLimit={setLimit}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(UserListMain);
