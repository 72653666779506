import React, { useState } from 'react'
import { IconRight } from '../../../../Assets/image/svgimages/sVGImg'
import Modalaction from '../../Profile/EditProfilePage/ActualVersion/ModalAction/Modalaction.jsx'
import moment from 'moment'
import './BottleLogs.scss'

const BottleLogs = (props) => {
  let {
    data,
    sizeText,
    sizeTextOld,
    badgeText,
    badgeTextOld,
    regionText,
    regionTextOld,
    appellationOldText,
    appellationNewText,
    colorTextNew,
    colorTextOld,
  } = props
  const [moreField, setMoreField] = useState({})
  const [open, setOpen] = useState(false)

  const setViewFieldData = (oldItem, newItem, title) => {
    let item = {
      oldItem: oldItem,
      newItem: newItem,
      title: title,
    }
    setMoreField(item)
    setOpen(true)
  }
  return (
    <>
      {data?.bottle?.newValues && Object.keys(data?.bottle?.newValues).length > 0 && (
        <div className='actualVersion'>
          <div className='Admin_By'>
            <div className={data.bottle.added_by === 'Admin' ? 'Byadmin' : 'ByUser'}>
              <p>{moment(data.bottle.updated_at).format('Do MMM, h:mm a')}</p>
              <h6>{data.bottle.added_by}</h6>
            </div>
            {data?.bottle.newValues?.brand_name && <h3>Name of Wine Bottle</h3>}
            <ul>
              {data?.bottle.newValues?.brand_name && (
                <li>
                  <div className='InformShow'>
                    <strong>Name of Wine Bottle*</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.brand_name,
                          data?.bottle.newValues?.brand_name,
                          'Name of Wine Bottle*',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.brand_name}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.brand_name}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Details fields  */}
            {(data?.bottle.newValues?.winery_name ||
              data?.bottle.newValues?.wine_name ||
              data?.bottle.newValues?.country ||
              data?.bottle.newValues?.region_id ||
              data?.bottle.newValues?.appellation ||
              data?.bottle.newValues?.wine_color_id ||
              data?.bottle.newValues?.size_id) && <h3>Details</h3>}
            <ul>
              {data?.bottle.newValues?.winery_name && (
                <li>
                  <div className='InformShow'>
                    <strong>Wine Producer</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.winery_name,
                          data?.bottle.newValues?.winery_name,
                          'Wine Producer',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.winery_name}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.winery_name}</p>
                  </div>
                </li>
              )}
            </ul>

            <ul>
              {data?.bottle.newValues?.country && (
                <li>
                  <div className='InformShow'>
                    <strong>Country</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.country,
                          data?.bottle.newValues?.country,
                          'Country',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.country}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.country}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Rigion */}

            <ul>
              {data?.bottle.newValues?.region_id && (
                <li>
                  <div className='InformShow'>
                    <strong>Region</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          regionTextOld,
                          regionText,

                          'Rigion',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{regionTextOld}</p>
                    <IconRight />
                    <p>{regionText}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Appellation */}

            <ul>
              {data?.bottle.newValues?.appellation && (
                <li>
                  <div className='InformShow'>
                    <strong>Appellation</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(appellationOldText, appellationNewText, 'Appellation')
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{appellationOldText}</p>
                    <IconRight />
                    <p>{appellationNewText}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Vineyard name or Cru */}

            {data?.bottle.newValues?.vineyard_name && (
              <ul>
                <li>
                  <div className='InformShow'>
                    <strong>Vineyard name or Cru</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.vineyard_name,
                          data?.bottle.newValues?.vineyard_name,
                          'Vineyard name or Cru',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.vineyard_name}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.vineyard_name}</p>
                  </div>
                </li>
              </ul>
            )}

            {/* cuvee  */}
            <ul>
              {data?.bottle.newValues?.wine_name && (
                <li>
                  <div className='InformShow'>
                    <strong>Cuvee</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.wine_name,
                          data?.bottle.newValues?.wine_name,
                          'wine_name',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.wine_name}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.wine_name}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Choose color of the wine*/}
            <ul>
              {data?.bottle.newValues?.wine_color_id && (
                <li>
                  <div className='InformShow'>
                    <strong>Color of the wine</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(colorTextOld, colorTextNew, 'Choose color of the wine')
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{colorTextOld}</p>
                    <IconRight />
                    <p>{colorTextNew}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Sweetness of wine*  */}
            {data?.bottle.newValues?.consist && (
              <ul>
                <li>
                  <div className='InformShow'>
                    <strong>Sweetness of wine</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.wine_sweetness?.name,
                          data?.bottle.newValues?.wine_sweetness?.name,
                          'Sweetness of wine',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.wine_sweetness?.name}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.wine_sweetness?.name}</p>
                  </div>
                </li>
              </ul>
            )}

            {/* Consist */}

            {data?.bottle.newValues?.consist && (
              <ul>
                <li>
                  <div className='InformShow'>
                    <strong>Consist</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.consist,
                          data?.bottle.newValues?.consist,
                          'Consist',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.consist === 1 ? 'Still' : 'Sparking'}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.consist === 1 ? 'Still' : 'Sparking'}</p>
                  </div>
                </li>
              </ul>
            )}

            {/* classification   */}
            {data?.bottle.newValues?.classification && (
              <ul>
                <li>
                  <div className='InformShow'>
                    <strong>Classification</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.classification,
                          data?.bottle.newValues?.classification,
                          'Classification',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.classification}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.classification}</p>
                  </div>
                </li>
              </ul>
            )}

            {/* //size  */}

            <ul>
              {data?.bottle.oldValues?.size_id && (
                <li>
                  <div className='InformShow'>
                    <strong>Size</strong>
                    <button onClick={() => setViewFieldData(sizeTextOld, sizeText, 'Size')}>
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{sizeTextOld}</p>
                    <IconRight />
                    <p>{sizeText}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* //Badge  */}

            <ul>
              {data?.bottle.oldValues?.badge && (
                <li>
                  <div className='InformShow'>
                    <strong>Badge</strong>
                    <button onClick={() => setViewFieldData(badgeTextOld, badgeText, 'Badge')}>
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{badgeTextOld}</p>
                    <IconRight />
                    <p>{badgeText}</p>
                  </div>
                </li>
              )}
            </ul>

            {/* Notes  */}
            {data?.bottle.oldValues?.description ||
              data?.bottle.oldValues?.wine_spectator_point ||
              data?.bottle.oldValues?.wine_advocate_point ||
              data?.bottle.oldValues?.wine_vinous_point ||
              data?.bottle.oldValues?.wine_james_suckling_point ||
              (data?.bottle.oldValues?.wine_burghound_point && <h3>Notes</h3>)}
            <ul>
              {data?.bottle.oldValues?.description && (
                <li>
                  <div className='InformShow'>
                    <strong>Description of the bottle</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.bottle.oldValues?.description,
                          data?.bottle.newValues?.description,
                          'Description of the bottle',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.bottle.oldValues?.description}</p>
                    <IconRight />
                    <p>{data?.bottle.newValues?.description}</p>
                  </div>
                </li>
              )}
            </ul>

            <ul>
              {data?.bottle.oldValues?.wine_spectator_point &&
                data?.bottle.oldValues?.wine_spectator_point > 0 && (
                  <li>
                    <div className='InformShow'>
                      <strong>Wine Spectator</strong>
                      <button
                        onClick={() =>
                          setViewFieldData(
                            data?.bottle.oldValues?.wine_spectator_point,
                            data?.bottle.newValues?.wine_spectator_point,
                            'Wine Spectator',
                          )
                        }
                      >
                        ...More
                      </button>
                    </div>
                    <div className='listing'>
                      <p>{data?.bottle.oldValues?.wine_spectator_point}</p>
                      <IconRight />
                      <p>{data?.bottle.newValues?.wine_spectator_point}</p>
                    </div>
                  </li>
                )}
            </ul>

            <ul>
              {data?.bottle.oldValues?.wine_advocate_point &&
                data?.bottle.oldValues?.wine_advocate_point > 0 && (
                  <li>
                    <div className='InformShow'>
                      <strong>Wine Advocate</strong>
                      <button
                        onClick={() =>
                          setViewFieldData(
                            data?.bottle.oldValues?.wine_advocate_point,
                            data?.bottle.newValues?.wine_advocate_point,
                            'Wine Advocate',
                          )
                        }
                      >
                        ...More
                      </button>
                    </div>
                    <div className='listing'>
                      <p>{data?.bottle.oldValues?.wine_advocate_point}</p>
                      <IconRight />
                      <p>{data?.bottle.newValues?.wine_advocate_point}</p>
                    </div>
                  </li>
                )}
            </ul>

            <ul>
              {data?.bottle.oldValues?.wine_vinous_point &&
                data?.bottle.oldValues?.wine_vinous_point > 0 && (
                  <li>
                    <div className='InformShow'>
                      <strong>Vinouss</strong>
                      <button
                        onClick={() =>
                          setViewFieldData(
                            data?.bottle.oldValues?.wine_vinous_point,
                            data?.bottle.newValues?.wine_vinous_point,
                            'Vinous',
                          )
                        }
                      >
                        ...More
                      </button>
                    </div>
                    <div className='listing'>
                      <p>{data?.bottle.oldValues?.wine_vinous_point}</p>
                      <IconRight />
                      <p>{data?.bottle.newValues?.wine_vinous_point}</p>
                    </div>
                  </li>
                )}
            </ul>

            <ul>
              {data?.bottle.oldValues?.wine_james_suckling_point &&
                data?.bottle.oldValues?.wine_james_suckling_point > 0 && (
                  <li>
                    <div className='InformShow'>
                      <strong>James Suckling</strong>
                      <button
                        onClick={() =>
                          setViewFieldData(
                            data?.bottle.oldValues?.wine_james_suckling_point,
                            data?.bottle.newValues?.wine_james_suckling_point,
                            'James Suckling',
                          )
                        }
                      >
                        ...More
                      </button>
                    </div>
                    <div className='listing'>
                      <p>{data?.bottle.oldValues?.wine_james_suckling_point}</p>
                      <IconRight />
                      <p>{data?.bottle.newValues?.wine_james_suckling_point}</p>
                    </div>
                  </li>
                )}
            </ul>

            <ul>
              {data?.bottle.oldValues?.wine_burghound_point &&
                data?.bottle.oldValues?.wine_burghound_point > 0 && (
                  <li>
                    <div className='InformShow'>
                      <strong>Burghound</strong>
                      <button
                        onClick={() =>
                          setViewFieldData(
                            data?.bottle.oldValues?.wine_burghound_point,
                            data?.bottle.newValues?.wine_burghound_point,
                            'Burghound',
                          )
                        }
                      >
                        ...More
                      </button>
                    </div>
                    <div className='listing'>
                      <p>{data?.bottle.oldValues?.wine_burghound_point}</p>
                      <IconRight />
                      <p>{data?.bottle.newValues?.wine_burghound_point}</p>
                    </div>
                  </li>
                )}
            </ul>
          </div>
        </div>
      )}

      {open && (
        <Modalaction
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          data={moreField}
        />
      )}
    </>
  )
}

export default BottleLogs
