import React, { useEffect, useState } from 'react'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import { Tabs } from 'antd'
import Unread from './Notes/Unread'
import Read from './Notes/Read'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationAction } from '../../../redux/_actions'
import { NOTIFICATION_LIMT } from '../../../utils/constants'
import './Notifications.scss'

const Notifications = (props) => {
  const notificationList = useSelector((state) => state.notification?.notificationList)
  const pendingNotificationList = useSelector(
    (state) => state.notification?.pendingNotificationList,
  )

  const totalCount = useSelector((state) => state.notification?.total)
  const totalCountPending = useSelector((state) => state.notification?.pendingTotal)

  const [notificationStatus, setNotificationStatus] = useState(0) // 0 unread and 1 read

  const [offset, setOffset] = useState(0)

  const dispatch = useDispatch()
  const { getMyAllNotifications, deleteNotification, markReadNotification } = NotificationAction

  const { TabPane } = Tabs
  const onChange = (key) => {
    setNotificationStatus(key)
    setOffset(0)
  }

  useEffect(() => {
    getAllNotifications()
  }, [notificationStatus, offset])

  const getAllNotifications = () => {
    let data = {
      status: notificationStatus,
      offset: offset,
      limit: NOTIFICATION_LIMT,
    }
    dispatch(getMyAllNotifications(data)).then((res) => {})
  }

  // DELETE NOTIFICATIONS
  const onCallDeleteAll = (status) => {
    deleteNotificationApi([], status)
  }
  const onCallDeleteSingle = (id, status) => {
    let data = [id]
    deleteNotificationApi(data, status)
  }

  const deleteNotificationApi = (data, status) => {
    let record = {
      data: data,
      status: status,
    }
    dispatch(deleteNotification(record)).then((res) => {
      getAllNotifications()
    })
  }

  //MARK AS READ
  const onCallMarkReadAll = (status) => {
    markAsReadApi([], status)
  }
  const onCallMarkReadSingle = (id, status) => {
    let data = [id]
    markAsReadApi(data, status)
  }

  const markAsReadApi = (data, status) => {
    let record = {
      data: data,
      status: status,
    }
    dispatch(markReadNotification(record)).then((res) => {
      getAllNotifications()
    })
  }

  return (
    <>
      <MainLayout>
        <div className='Notification'>
          <h1 className='common-heading'>Notifications</h1>
          <Tabs
            className='exclusive__tabs'
            defaultActiveKey={notificationStatus}
            onChange={onChange}
          >
            <TabPane tab='Unread' key='0'>
              <Unread
                data={pendingNotificationList}
                setDeletedItem={onCallDeleteSingle}
                onCallDeleteAll={onCallDeleteAll}
                onCallMarkReadAll={onCallMarkReadAll}
                onMarkReadSingle={onCallMarkReadSingle}
                totalCount={totalCountPending}
                setOffset={setOffset}
              />
            </TabPane>
            <TabPane tab='Read' key='1'>
              <Read
                data={notificationList}
                setDeletedItem={onCallDeleteSingle}
                onCallDeleteAll={onCallDeleteAll}
                onMarkReadSingle={onCallMarkReadSingle}
                totalCount={totalCount}
                setOffset={setOffset}
              />
            </TabPane>
          </Tabs>
        </div>
      </MainLayout>
    </>
  )
}

export default Notifications
