import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Dropdown, Table, Pagination, Popup } from 'semantic-ui-react'
import moment from 'moment'

import '../../../WinemakerDetails/WinemakerDetails.scss'
import { DISCOUNT_TYPES } from '../../const'
import DropdownMenu from '../dropdownMenu/DropdownMenu'

const optionLimit = [
  { key: '1', text: 10, value: '10' },
  { key: '2', text: 15, value: '15' },
  { key: '3', text: 20, value: '20' },
]

const Discounts = ({ limit, setLimit, offset, setOffset, getAllDiscounts }) => {
  const { codes, total: totalDiscounts } = useSelector((state) => state?.discountCodes)
  const [menuOffset, setMenuOffset] = useState(null)
  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage
    setOffset(offset)
  }

  const onMenuClick = (e) => {
    const element = e.target
    setMenuOffset(element.offsetLeft)
  }

  const getDiscountValue = (el) => {
    if (!el.discount_value) return '---'

    if (el.discount_type === DISCOUNT_TYPES.NUMBER) {
      return `$${el.discount_value}`
    }
    if (el.discount_type === DISCOUNT_TYPES.PERCENT) {
      return `${el.discount_value}%`
    }
    return el.discount_value
  }

  if (!codes?.length) return null
  return (
    <>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>Id</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Discount Code</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Start Date</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>End Date</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Discount</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Sale Type</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Code Issuer</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Is Valid</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {codes?.map((item) => (
              <Popup
                key={item.id}
                className='labelTableModal'
                offset={menuOffset}
                on={['click']}
                position='bottom left'
                trigger={
                  <Table.Row onClick={onMenuClick}>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.id || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='3' textAlign='left'>
                      {item.discount_code || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.start_date ? moment(item.start_date).format('DD/MM/YYYY') : '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.end_date ? moment(item.end_date).format('DD/MM/YYYY') : '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {getDiscountValue(item)}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.sale_type || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.code_issuer || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.is_valid ? 'Yes' : 'No'}
                    </Table.Cell>
                  </Table.Row>
                }
              >
                <DropdownMenu data={item} getAllDiscounts={getAllDiscounts} />
              </Popup>
            ))}
            {totalDiscounts === 0 && (
              <Table.Row>
                <Table.Cell collapsing colSpan='20' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className='table_pagi new'>
        <div className='sorting'>
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={optionLimit}
            onChange={(e, data) => {
              setLimit(data.value)
            }}
            className='autowidth SortingIcon'
          />
        </div>

        {totalDiscounts > limit && (
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            defaultActivePage={1}
            onPageChange={handlePageClick}
            totalPages={Math.ceil(parseInt(totalDiscounts) / limit)}
          />
        )}
      </div>
    </>
  )
}

Discounts.defaultProps = {
  limit: 10,
}
Discounts.propTypes = {
  limit: PropTypes.number,
  setLimit: PropTypes.func.isRequired,
  getAllDiscounts: PropTypes.func.isRequired,
}

export default Discounts
