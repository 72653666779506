import React from "react";
import "./ButtonCustomStyle.scss";

const BorderButton = ({ onClick, text, className, icon }) => {
  return (
    <button onClick={onClick} className={`borderBtn ${className}`}>
      {icon && <img src={icon} alt={icon} />}
      {text}
    </button>
  );
};

export default BorderButton;
