import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST, REACT_APP_API_HOST_CORE } = Environments

const getAllWineMakers = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/user/userlists`, data, headers)
}

const getAllUsers = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/user/userlists`, data, headers)
}

const addWinemaker = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/creator/createWiner`, data, headers)
}

const addWineryPageCMS = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/creator/create-winery-page`, data, headers)
}

const uploadFileGc = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/upload/gc`, data, headers)
}

const getWinerDetails = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/admin/winery-details`, data, headers)
}

const saveProfileOldData = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/save/logs`, data, headers)
}

const updateWinerProfileData = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/creator/updateWiner`, data, headers)
}

const getDetailsLogs = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/get/logs`, data, headers)
}

const updateProfileStatus = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/creator/update-profile/status`, data, headers)
}

const updateComingSoonStatus = (data, headers) => {
  return fetch('put', `${API_HOST}/admin/api/v1/user/coming-soon-enabled`, data, headers)
}

const getWineMakerDetails = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/admin/winemaker/details`, data, headers)
}

const getPublicDetailsLogs = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/get/public-page/logs`, data, headers)
}

const getWineryRequestList = (data, headers) => {
  return fetch('put', `${API_HOST}/admin/api/v1/creator/creatorRequest`, data, headers)
}

const blockWiner = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/addStatus`, data, headers)

const updatHeaderWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updatHeaderWinerDetail`, data, headers)

const updatStoryWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updatStoryWinerDetail`, data, headers)

const updateGalleryWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updateGalleryWinerDetail`, data, headers)

const updatTeamWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updatTeamWinerDetail`, data, headers)

const updatStatergyWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updatStatergyWinerDetail`, data, headers)

const updatFeatureWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updatFeatureWinerDetail`, data, headers)

const updateDetailWinerDetail = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updateDetailWinerDetail`, data, headers)

const editMyTeamMemberDetails = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/updatTeamMember`, data, headers)

const editFeatureDetails = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/updatFeatureMember`, data, headers)

const editQuestionarie = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/updatFeatureMember`, data, headers)

const deleteMember = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/deleteTeamMember`, data, headers)

const deleteFeatures = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/deleteFeatureMember`, data, headers)

const updateMyFeature = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/updatDetailMember`, data, headers)

const deleteQuestions = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/deleteDetailMember`, data, headers)

const deleteUser = (data, headers) =>
  fetch('post', `${API_HOST}/users/api/v1/deleteUser`, data, headers)

const getPendingWinerCount = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/creator/getPendingRequestCount`, {}, headers)

const getAllWineryCount = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/creator/getProfileStatusCount`, {}, headers)

const getWinerRegistrationCountries = (data, headers) =>
  fetch('get', `${REACT_APP_API_HOST_CORE}/api/v2/registration-countries`, {}, headers)

export const WineMakersService = {
  getAllWineMakers,
  getAllUsers,
  addWinemaker,
  addWineryPageCMS,
  uploadFileGc,
  getWinerDetails,
  saveProfileOldData,
  updateWinerProfileData,
  getDetailsLogs,
  updateProfileStatus,
  updateComingSoonStatus,
  getWineMakerDetails,
  getPublicDetailsLogs,
  getWineryRequestList,
  blockWiner,
  updatHeaderWinerDetail,
  updatStoryWinerDetail,
  updateGalleryWinerDetail,
  updatTeamWinerDetail,
  updatStatergyWinerDetail,
  updatFeatureWinerDetail,
  updateDetailWinerDetail,
  editMyTeamMemberDetails,
  editFeatureDetails,
  editQuestionarie,
  deleteMember,
  deleteFeatures,
  updateMyFeature,
  deleteQuestions,
  deleteUser,
  getPendingWinerCount,
  getAllWineryCount,
  getWinerRegistrationCountries
}
