import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import LabelTab from './LabelTab'
import useDebounce from '../../../hooks/useDebounce'
import { useDispatch } from 'react-redux'
import {
  getAllBottleList,
  deleteBottle,
  getBottleDropDownSpecifications,
} from '../../../redux/_actions/bottles.action'
const { TabPane } = Tabs
const onChange = (key) => {}

const LabelPage = () => {
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [order, setOrder] = useState('')
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(1)
  const [vintageList, setVintageList] = useState('')

  const debaounceWinerySearch = useDebounce(search, 500)

  const dispatch = useDispatch()
  useEffect(() => {
    getBottlesSpecification()
  }, [])

  useEffect(() => {
    getAllBottle()
  }, [offset, limit, order, status, debaounceWinerySearch])

  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      let details = res.data
      setVintageList(details.wineVintageModelList)
    })
  }
  const getVintageText = (id) => {
    let vintage = '---'
    vintageList.forEach((element) => {
      if (element.id === id) {
        vintage = element.vintage
      }
    })
    return vintage
  }

  const getAllBottle = async () => {
    let dataFields = {
      limit: limit,
      offset: offset,
      searchKey: search,
      status: status,
      order: order,
    }
    dispatch(getAllBottleList(dataFields)).then((res) => {})
  }

  const onDeleteBottle = (id) => {
    let data = {
      id: id,
    }
    dispatch(deleteBottle(data)).then((res) => {
      getAllBottle()
    })
  }

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Bottles Of Wine</h3>
      <Tabs className='exclusive__tabs' defaultActiveKey='1' onChange={onChange}>
        <TabPane tab='Bottles' key='1'>
          {vintageList.length > 0 && (
            <LabelTab
              search={search}
              setSearch={setSearch}
              status={status}
              setStatus={setStatus}
              order={order}
              setOrder={setOrder}
              limit={limit}
              setLimit={setLimit}
              offset={offset}
              setOffset={setOffset}
              onDeleteBottle={onDeleteBottle}
              getVintageText={getVintageText}
            />
          )}
        </TabPane>
      </Tabs>
    </MainLayout>
  )
}

export default LabelPage
