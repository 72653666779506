export const AUTOMATED_TYPES = {
  TOP_SELLERS: 'TOP_SELLERS',
  HIDDEN_GEMS: 'HIDDEN_GEMS',
  ICONS: 'ICONS',
  RISING_STARS: 'RISING_STARS',
  ENDING_SOON: 'ENDING_SOON',
  EXCEPTIONAL_PRICE: 'EXCEPTIONAL_PRICE',
  REGION_SPECIFIC_WINES: 'REGION_SPECIFIC_WINES',
}

export const AUTOMATED_TYPES_OPTIONS = [
  {
    value: '',
    label: '---',
  },
  {
    value: AUTOMATED_TYPES.TOP_SELLERS,
    label: 'Top Sellers',
  },
  {
    value: AUTOMATED_TYPES.HIDDEN_GEMS,
    label: 'Hidden Gems',
  },
  {
    value: AUTOMATED_TYPES.ICONS,
    label: 'Icons',
  },
  {
    value: AUTOMATED_TYPES.RISING_STARS,
    label: 'Rising Stars',
  },
  {
    value: AUTOMATED_TYPES.ENDING_SOON,
    label: 'Ending Soon',
  },
  {
    value: AUTOMATED_TYPES.EXCEPTIONAL_PRICE,
    label: 'Exceptional Price',
  },
  {
    value: AUTOMATED_TYPES.REGION_SPECIFIC_WINES,
    label: 'Region Specific Wines',
  },
]
