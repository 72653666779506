import * as Yup from 'yup'

export const createBottleSchema = Yup.object().shape({
  // 1: Brand of Wine

  brand_name: Yup.string().required('This field is required').trim(),
  bottle_image: Yup.string().required('This field is required'),
  // label_image: Yup.string(),

  // 2: Details
  wine_name: Yup.string().trim(),
  winery_name: Yup.string().required('This field is required'),
  // country: Yup.string().required("This field is required"),
  // region_id: Yup.string().required("This field is required"),
  // appellation: Yup.string(),
  wine_color_id: Yup.string().required('This field is required'),
  size_id: Yup.string().required('This field is required'),
  //newely added
  grape: Yup.string(),
  vineyard_name: Yup.string(),
  sweetness_wine_id: Yup.string().required('This field is required'),
  classification: Yup.string(),
  consist: Yup.string(),

  // 3. Notes
  description: Yup.string().trim(),
  ageability: Yup.string(),
  rarity: Yup.string(),
  wine_spectator_point: Yup.string(),
  wine_advocate_point: Yup.string(),
  wine_vinous_point: Yup.string(),
  wine_james_suckling_point: Yup.string(),
  wine_burgbound_point: Yup.string(),
  wine_falstaff_point: Yup.string(),
  bottle_image_title: Yup.string(),
  bottle_image_alt: Yup.string(),
  strength: Yup.string(),
  tannins: Yup.string(),
  texture: Yup.string(),
  review: Yup.string(),
  about_winery: Yup.string(),
  terroir_and_aging_method: Yup.string(),
  tasting_notes: Yup.string(),
  pairing: Yup.string(),
})

export const editBottleSchema = Yup.object().shape({
  // 1: Brand of Wine

  brand_name: Yup.string().required('This field is required'),
  bottle_image: Yup.string().required('This field is required'),
  // label_image: Yup.string(),

  // 2: Details
  wine_name: Yup.string(),
  winery_name: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
  region_id: Yup.string().required('This field is required'),
  appellation: Yup.string(),
  wine_color_id: Yup.string().required('This field is required'),
  size_id: Yup.string().required('This field is required'),
  //newely added
  grape: Yup.string(),
  vineyard_name: Yup.string(),
  sweetness_wine_id: Yup.string().required('This field is required'),
  classification: Yup.string(),
  consist: Yup.string(),

  // 3. Notes
  description: Yup.string(),
  ageability: Yup.string(),
  rarity: Yup.string(),
  wine_spectator_point: Yup.string(),
  wine_falstaff_point: Yup.string(),
  wine_vinous_point: Yup.string(),
  wine_james_suckling_point: Yup.string(),
  wine_burgbound_point: Yup.string(),
  // new fields
  wine_advocate_point: Yup.string(),
  bottle_image_title: Yup.string(),
  bottle_image_alt: Yup.string(),
  strength: Yup.string(),
  tannins: Yup.string(),
  texture: Yup.string(),
  review: Yup.string(),
  about_winery: Yup.string(),
  terroir_and_aging_method: Yup.string(),
  tasting_notes: Yup.string(),
  pairing: Yup.string(),
})
