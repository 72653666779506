import Environments from "../../../_constants/Environment";
const { API_HOST } = Environments;


// local storage token name
export const SENDPULSE_STORAGE_TOKEN = 'accessTokenSendPulse'

// urls
export const SENDPULSE_URLS = {
  AUTH: `${API_HOST}/users/api/v1/sendpulse/auth`,
  TAG_EVENT: `${API_HOST}/users/api/v1/sendpulse/tag-custom-event`,
  SUBSCRIBE_EMAIL: `${API_HOST}/users/api/v1/sendpulse/subscribe-email`,
}

export const SENDPULSE_EVENTS = {
  USER_ACCOUNT: 'user_account', // creates account and not a winery
  WINERY_ACCOUNT: 'winery_account', // create public winery page
  USER_SUBSCRIBE: 'user_subscribe', // subscribes to newsletter but NOT yet a customer
  USER_FIRST_SIGN_IN: 'user_first_sign_in', // Customer who signed it for the first time
  USER_SIGN_IN: 'user_sign_in', // Customer signs in any time
  USER_FOLLOW_WINERY: 'user_follow_winery', // dynamic value based on whichever winery they follower
  USER_FOLLOW_DROP: 'user_follow_drop', // dynamic value based on which drop they follow
  USER_PURCHASE: 'user_purchase', // customer buys a drop
  USER_SUBSCRIBED_DROP: 'user_subscribed_drop', // subscribed to a drop but did not participate
  USER_FIRST_PURCHASE: 'user_first_purchase', // customer buys first drop
  USER_CHECKOUT_ABANDON: 'user_checkout_abandon', // Customer who starts the buys process but does not complete purchase
  USER_SHIPCOMPLETED: 'user_Shipcomplete', // Customer who has completed their 1st shipment
  USER_USDC_FIRST_TIME: 'user_usdc_firsttime', // added usdc to wallet for first time
  USER_SHIP_ABANDON: 'user_ship_abandon', // Customer who starts the shipping flow but does not complete
  WINERY_APPLY: 'winery_apply', // customer applies as winery
  WINERY_APPROVED: 'winery_approved', // winery application is approved
  WINERY_PROFILE_IS_LIVE: 'winery_profile_live', // winery profile is live
  DROP_CREATED: 'drop_created', // dynamic value based on New Drop is created
  DROP_PUBLISHED: 'drop_publised', // dynamic value based on New Drop is published
}
