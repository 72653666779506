import { startLoading, stopLoading } from './loading.action'
// import { toast } from '../../components/Toast/Toast'
import { DropCollectionService } from 'Services/dropCollections.service'
/** seting action types */
export const actionTypes = {
  SAVE_ALL_DROP_COLLECTIONS: 'SAVE_ALL_DROP_COLLECTIONS',
}

export function saveAllDropCollections(data) {
  return {
    type: actionTypes.SAVE_ALL_DROP_COLLECTIONS,
    payload: data,
  }
}

export function getAllDropCollections(succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DropCollectionService.getAllDropCollections({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveAllDropCollections(res.data?.data))
          dispatch(stopLoading())
          succesFn(res.data?.data)

          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getDropCollection(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DropCollectionService.getDropCollection(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function createDropCollection(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DropCollectionService.createDropCollection(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function updateDropCollection(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DropCollectionService.updateDropCollection(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
