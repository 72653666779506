import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Form, Popup } from 'semantic-ui-react'
import ReactCrop from 'react-image-crop'
import useDebounce from '../../../../../src/hooks/useDebounce'
import { canvasPreview } from '../../../../components/canvasPreview'

import { useFormik } from 'formik'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import { toast } from '../../../../components/Toast/Toast'
import { WinerMakerAction } from '../../../../redux/_actions'
import { useDispatch } from 'react-redux'
import Info_Icon from '../../../../Assets/image/Info_Icon.svg'
import { IconDelete, IconCrop, AddIcon } from '../../../../Assets/image/svgimages/sVGImg'
import { Checkbox } from 'antd'
import './AddWinemaker.scss'

const winerSchema = Yup.object().shape({
  header: Yup.string().required('This field is required').max(100, 'Maximum 100 character allowed'),
  background_img: Yup.string().optional(),
  headline_dark: Yup.string(),
  tagline_dark: Yup.string(),
})

const AddWinStepFirst = (props) => {
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const blobUrlRef = useRef('')
  const { updatHeaderWinerDetail, saveProfileOldData, uploadFileGc } = WinerMakerAction
  const [oldDataStep1, setOldDataStep1] = useState({})
  const dispatch = useDispatch()
  const inputRefCoverImage = React.useRef()

  let { userId, setStep, winerDetails, getWinerAllDetails, status, setIsLoader, setError, error } =
    props

  useDebounce(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  const uploadCropped = () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current.toBlob(
      (blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current)
        }
        blobUrlRef.current = URL.createObjectURL(blob)
        uploadImageApi(blob)
      },
      { useCORS: true },
      'image/jpeg',
    )
  }

  useEffect(() => {
    if (winerDetails.length > 0 && status !== 0) {
      let stepData = {
        body_text: winerDetails[0]?.creator_heading_meta?.body,
        description: winerDetails[0]?.creator_heading_meta?.description,
        header: winerDetails[0]?.creator_heading_meta?.header,
        headline_dark: winerDetails?.creator_heading_meta?.headline_dark,
        tagline_dark: '',
      }
      setOldDataStep1(stepData)
    }
  }, [winerDetails, status])

  const getHeader = () => {
    if (winerDetails.length > 0 && winerDetails[0]?.creator_heading_meta !== null)
      return winerDetails[0]?.creator_heading_meta?.header

    if (winerDetails.length > 0) return winerDetails[0]?.creator_meta?.winery_name
    return ''
  }

  const wineFormik = useFormik({
    enableReinitialize: true,
    validationSchema: winerSchema,
    initialValues: {
      description:
        winerDetails.length > 0 && winerDetails[0]?.creator_heading_meta !== null
          ? winerDetails[0]?.creator_heading_meta.description
          : '',
      header: getHeader(),
      background_img:
        winerDetails.length > 0 && winerDetails[0]?.creator_heading_meta !== null
          ? winerDetails[0]?.creator_heading_meta?.background
          : '',
      headline_dark: winerDetails[0]?.creator_heading_meta?.headline_dark,
      tagline_dark: winerDetails[0]?.creator_heading_meta?.tagline_dark,
    },
    onSubmit: (values) => {
      setError(false)
      values['userId'] = userId
      addWineryDetailApi(values)
    },
  })

  const addWineryDetailApi = (postInfo) => {
    setIsLoader(true)

    dispatch(updatHeaderWinerDetail(postInfo)).then((res) => {
      setIsLoader(false)
      getWinerAllDetails()
      //call save logs api
      if (status !== 0) {
        saveStepFirstData(postInfo)
      }

      setStep(2)
    })
  }

  const saveStepFirstData = (wineMakerNew) => {
    let newData = { ...wineMakerNew }
    delete newData.userId
    let details = {
      prevData: oldDataStep1,
      newData: newData,
      formType: 'form_1',
      userId: userId,
    }

    dispatch(saveProfileOldData(details)).then((res) => {})
  }
  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadImageApi = (file) => {
    let formData = new FormData()

    formData.append('folderName', 'testing')
    formData.append('file', file)

    dispatch(uploadFileGc(formData)).then((res) => {
      wineFormik.setFieldValue('background_img', res?.data.data[0]?.url)
      setIsLoader(false)
    })
  }

  const uploadFileToS3 = async (input) => {
    input.persist()

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      toast.error('File extension not supported!')
      input.target.value = null

      setIsLoader(false)
    } else {
      uploadImageApi(file)
    }
  }

  const gotToNextPage = () => {
    if (winerDetails.length > 0) {
      setStep(2)
    } else {
      toast.error('Please add required information')
    }
  }

  const deleteCoverImage = () => {
    setError(true)
    inputRefCoverImage.current.value = ''
    wineFormik.setFieldValue('background_img', '')
  }

  return (
    <div className='block' id='s1'>
      <h1 className='common-heading'>{status === 0 ? 'Create Public Page' : 'Edit Public Page'}</h1>

      <div className='Header sectionInfo'>
        <h3 className='Header heading'>Cover Section</h3>
      </div>
      <Form onSubmit={wineFormik.handleSubmit}>
        <InputField
          id='header'
          label='Winery'
          isRequired
          placeholder='Enter header to your winery page'
          type='text'
          onChange={(event) => {
            if (event.target.value === '' || event.target.value === null) {
              setError(true)
            }
            wineFormik.setFieldValue('header', event.target.value)
          }}
          value={wineFormik.values.header}
          maxLength={100}
          formik={wineFormik}
        />
        <InputField
          id='description'
          label='Winery tagline'
          placeholder='Enter subheader to your winery page'
          type='text'
          onChange={(event) => {
            wineFormik.setFieldValue('description', event.target.value)
          }}
          value={wineFormik.values.description}
          maxLength={150}
          formik={wineFormik}
        />
        <div className='checkbox_wrapp'>
          <p>Winery name dark color</p>
          <Checkbox
            name='headline_dark'
            label='Winery name dark color'
            checked={wineFormik.values.headline_dark}
            onChange={(e) => wineFormik.setFieldValue('headline_dark', e.target.checked)}
          />
          <p>Tagline dark color</p>
          <Checkbox
            name='tagline_dark'
            label='Headline dark color'
            checked={wineFormik.values.tagline_dark}
            onChange={(e) => wineFormik.setFieldValue('tagline_dark', e.target.checked)}
          />
        </div>
        <div>
          <div className='sectionInfo'>
            <div className='sectionInf detailsRul'>
              <label className='inputField__label'>
                Cover Image<sup>*</sup>
              </label>
              <p className='sectionInfo sizeImg mt-24'>
                Image: Max size 1920 x 1080, max weight 20mb, ratio: 2.85 / 1
              </p>
            </div>

            <Popup
              className='trigerEdit'
              trigger={
                <div className='InfoToggle'>
                  <img src={Info_Icon} alt='Info_Icon' />
                </div>
              }
              // header='Movie Search'
              content='This section covers details of the winery such as history of the winery, their philosophy, their specialities, etc. Please work with wineries to incorporate relevant and critical winery details'
              on={['hover', 'click']}
              position='bottom right'
            />
          </div>
        </div>
        <div className='sectionInfo'>
          <div className='uploadField'>
            <div className='profilePic'>
              <div className='profilePic-inner big'>
                <input
                  id='background_img'
                  type='file'
                  name='image'
                  className='input__input'
                  onChange={uploadFileToS3}
                  formEncType='multipart/form-data'
                  ref={inputRefCoverImage}
                  disabled={wineFormik.values?.background_img !== ''}
                  title={wineFormik.values?.background_img === '' ? 'No file choosen' : ''}
                />
                <label
                  className={
                    wineFormik.values?.background_img !== ''
                      ? 'file-input__prof hidden BAckImageSet'
                      : 'file-input__prof'
                  }
                  htmlFor='file-input'
                />
                <div className='down-button' />

                <ReactCrop
                  crop={crop}
                  onComplete={(c) => setCompletedCrop(c)}
                  onChange={(c) => setCrop(c)}
                  aspect={2.85 / 1}
                  style={{ zIndex: 9 }}
                >
                  <img
                    ref={imgRef}
                    src={`${wineFormik.values?.background_img}`}
                    alt=''
                    crossOrigin='anonymous'
                    width='100%'
                    height='auto'
                  />
                </ReactCrop>
                {!!completedCrop && (
                  <>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    </div>
                  </>
                )}
                {wineFormik.errors.background_img && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      margin: 'auto',
                      position: 'absolute',
                      textAlign: 'center',
                      marginTop: '5px',
                      width: '100%',
                    }}
                  >
                    {wineFormik.errors.background_img}
                  </p>
                )}
                <div className='imageActions'>
                  {!!completedCrop && (
                    <button
                      className='imageActions__button'
                      type='button'
                      onClick={() => uploadCropped()}
                    >
                      <IconCrop />
                    </button>
                  )}
                  {wineFormik.values?.background_img !== '' && (
                    <button
                      className='imageActions__button'
                      type='button'
                      onClick={() => deleteCoverImage()}
                    >
                      <IconDelete />
                    </button>
                  )}
                  {wineFormik.values?.background_img !== '' && (
                    <button
                      className='imageActions__button'
                      type='button'
                      onClick={() => inputRefCoverImage.current.click()}
                    >
                      <AddIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btns-spacing'>
          <ButtonCustom
            disabled={!(wineFormik.isValid && wineFormik.dirty)}
            type='submit'
            title='Save'
            customClass='saveBtn'
          />

          {winerDetails.length > 0 && winerDetails[0]?.creator_heading_meta !== null && (
            <ButtonCustom
              type='button'
              title='Skip'
              customClass='saveBtn'
              onClick={() => gotToNextPage()}
              disabled={error}
            />
          )}
        </div>
      </Form>
    </div>
  )
}
export default AddWinStepFirst
