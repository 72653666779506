import React from "react";
import "./CountProgress.scss";
import Countdown from "react-countdown";

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="CounteBoxOuter">
      <div className="dFlex__Style">
        <span className="borderBox">
          <p>{days}</p>{" "}
        </span>
        <span className="alternateBoxTxt">Days</span>
      </div>
      <span className="dotsBox">:</span>
      <div className="dFlex__Style">
        <span className="borderBox">
          <p>{hours}</p>{" "}
        </span>
        <span className="alternateBoxTxt">Hours</span>
      </div>
      <span className="dotsBox">:</span>
      <div className="dFlex__Style">
        <span className="borderBox">
          <p>{minutes}</p>{" "}
        </span>
        <span className="alternateBoxTxt">Minutes</span>
      </div>
      <span className="dotsBox">:</span>
      <div className="dFlex__Style">
        <span className="borderBox">
          <p>{seconds}</p>{" "}
        </span>
        <span className="alternateBoxTxt">Seconds</span>
      </div>
    </div>
  );
};
function CountProgress() {
  return (
    <div className="countdownSec">
      <Countdown
        date={Date.now() + 100000000}
        renderer={renderer}
        zeroPadDays={2}
      />
    </div>
  );
}
export default CountProgress;
