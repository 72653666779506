import { fetch } from "./Fetch";
import Environments from "../_constants/Environment";

const { API_HOST } = Environments;

const getMyAllNotifications = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/winers/api/v1/getAllAdminNotifications`,
    data,
    headers
  );

const deleteNotification = (data, headers) =>
  fetch("post", `${API_HOST}/winers/api/v1/DeleteNotification`, data, headers);

const markReadNotification = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/winers/api/v1/updateNotificationReadStatus`,
    data,
    headers
  );

export const NotificationService = {
  getMyAllNotifications,
  markReadNotification,
  deleteNotification,
};
