import React, { useState } from 'react'
import { Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Accordion, Icon } from 'semantic-ui-react'

import { walletIdHide } from '../../../../utils/helper'
import StatusButton from '../statusButton/StatusButton'

import './ItemOrderCard.scss'

const ItemOrderCard = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState()
  const { orderId, status, ownerName, walletAddress, outBoundShipmentId, burnDate, nfts } = data

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div className='detail-tab_itemOrderCard'>
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
          <Row className='estimate-col-header' align={'middle'}>
            <Col span={4} className='detail-tab'>
              <div className='detail-tab_shippingOrderId'>{orderId}</div>
            </Col>
            <Col span={3}>
              <StatusButton status={status} />
            </Col>
            <Col span={4} className='estimate-period'>
              <div>{ownerName || ''}</div>
            </Col>
            <Col span={3} className='estimate-cost'>
              <div>@{walletAddress ? walletIdHide(walletAddress) : ''}</div>
            </Col>
            <Col span={4} className='estimate-cost'>
              <div>{outBoundShipmentId || ''}</div>
            </Col>
            <Col span={4} className='estimate-cost'>
              <div>{burnDate ? moment(burnDate).format('DD MMM, YYYY') : ''}</div>
            </Col>

            <Col span={2} className='estimate-cost'>
              <div className='detail-tab_shippingOrderFlexJustifyEnd'>
                <Icon name='dropdown' />
              </div>
            </Col>
          </Row>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <div className='detail-tab_shippingOrderNftsWrapper'>
            <div className='detail-tab_shippingOrderNftsHeader'>
              <Row align={'middle'}>
                <Col span={3}>
                  <div>NFT ID</div>
                </Col>
                <Col span={9}>
                  <div>Nft Name</div>
                </Col>
                <Col span={4}>
                  <div>Physical ID</div>
                </Col>
                <Col span={3}></Col>
              </Row>
            </div>
            <div className='detail-tab_shippingOrderNftsBody'>
              {nfts.map((nft) => (
                <Row key={nft.id} align={'middle'}>
                  <Col span={3}>
                    <div>{nft.id}</div>
                  </Col>
                  <Col span={9}>
                    <div>{nft.nft_name}</div>
                  </Col>
                  <Col span={4}>
                    <div>{nft.physicalId}</div>
                  </Col>
                  <Col span={2} offset={6}>
                    <div className='detail-tab_shippingOrderFlexJustifyEnd'>
                      <Link
                        to={`/panel/Warenft/${nft.id}`}
                        className='detail-tab_shippingOrderNftLink'
                      >
                        <Button type='primary'>Details</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
    </div>
  )
}
export default ItemOrderCard

ItemOrderCard.propTypes = {
  data: PropTypes.shape({
    orderId: PropTypes.number,
    status: PropTypes.string,
    ownerName: PropTypes.string,
    walletAddress: PropTypes.string,
    burnDate: PropTypes.string,
    outBoundShipmentId: PropTypes.number,
    nfts: PropTypes.arrayOf(
      PropTypes.shape({
        drop_id: PropTypes.number,
        id: PropTypes.number,
        nft_name: PropTypes.string,
        physicalId: PropTypes.number,
      }),
    ),
  }).isRequired,
}
