import React from 'react'
import { Modal } from 'semantic-ui-react'
import { CloseIcon } from '../../Assets/image/svgimages/sVGImg'
import BorderButton from '../button/BorderButton'
import { ButtonCustom } from '../button/ButtonCustom'
import './ModalStyle.scss'

const ModalStyle = ({
  onClose,
  onOpen,
  children,
  title,
  open,
  className,
  cancelBtn,
  actionText,
  handleOnSubmit,
  size,
  classFooter,
  cancelBtnSec,
  CancelText,
}) => {
  return (
    <Modal onOpen={onOpen} open={open} className={`modal__style ${className}`} size={size}>
      <Modal.Header>
        {title}
        <button className='close-btn' onClick={onClose}>
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions className={`modal__style--footer ${classFooter}`}>
        {cancelBtn && <BorderButton text='Cancel' onClick={onClose} />}
        {cancelBtnSec && <BorderButton text={CancelText} onClick={onClose} />}
        <ButtonCustom type='button' title={actionText} onClick={handleOnSubmit} />
      </Modal.Actions>
    </Modal>
  )
}

export default ModalStyle
