import moment from 'moment'

export const symbolsHide = (str) => {
  return (str || '').substring(0, 10) + '...'
}
export const ownerNameHide = (str) => {
  return str ? str.substring(0, 14) + '...' : ''
}
export const nftIdHide = (str) => {
  return (str || '').substring(0, 7)
}
export const nftOrderHide = (str) => {
  return (str || '').substring(0, 9)
}
export const walletIdHide = (str) => {
  if (!str) return ''
  return str.substring(0, 6) + '...' + str.substring(str.length - 4)
}
export const walletsHide = (str) => {
  return str.substring(0, 8) + '...' + str.substring(str.length - 8)
}

export const sliceDateMonthTo3Symbol = (date) => {
  return moment(date).format('D MMM YYYY')
}
