import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import useDebounce from '../../../../hooks/useDebounce'

import Filters from './filters/Filters'
import { getAllDiscountCodes } from 'redux/_actions/discountCodes.action'
import Discounts from './discounts/Discounts'

const DiscountCodes = () => {
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [order, setOrder] = useState('')
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(1)
  const debounceWinerySearch = useDebounce(search, 500)

  const dispatch = useDispatch()

  const getAllDiscounts = () => {
    const dataFields = {
      limit,
      offset,
      searchKey: debounceWinerySearch,
      status,
      order,
    }
    dispatch(getAllDiscountCodes(dataFields))
  }
  useEffect(() => {
    const dataFields = {
      limit,
      offset,
      searchKey: debounceWinerySearch,
      status,
      order,
    }
    dispatch(getAllDiscountCodes(dataFields))
  }, [offset, limit, order, status, debounceWinerySearch, dispatch])

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Discount Codes</h3>
      <Tabs
        className='exclusive__tabs'
        defaultActiveKey='1'
        items={[
          {
            tab: 'Discount Codes',
            key: 'Discount Codes',
            children: (
              <div className='detail-tab'>
                <Filters
                  search={search}
                  setSearch={setSearch}
                  status={status}
                  setStatus={setStatus}
                  order={order}
                  setOrder={setOrder}
                  limit={limit}
                  setLimit={setLimit}
                  offset={offset}
                  setOffset={setOffset}
                />
                <Discounts
                  limit={limit}
                  setLimit={setLimit}
                  offset={offset}
                  setOffset={setOffset}
                  getAllDiscounts={getAllDiscounts}
                />
              </div>
            ),
          },
        ]}
      />
    </MainLayout>
  )
}

export default DiscountCodes
