import axios from 'axios'
import { toast } from "../../components/Toast/Toast";

import { getSendPulseAuthorization } from './actions/actions'
import { SENDPULSE_URLS } from "./constants/constants";

/*
function tag custom events by email

params:
eventLink: string
email: string
customData?: { key: string, value: string }
 */
export const tagCustomEventSendPulse = async ({ eventLink, email, customData = null }) => {
  try {
    const SENDPULSE_HEADER = await getSendPulseAuthorization()

    let data = {
      email,
      phone: '', // required field for request, but can be empty if no data provided
    }

    if (customData) {
      data = { ...data, ...customData }
    }

    return await axios.post(SENDPULSE_URLS.TAG_EVENT, {
      eventLink,
      data,
      token: SENDPULSE_HEADER,
    })

  } catch (err) {
    toast.error(`Something went wrong with tag sendpulse ${eventLink} custom event`)
  }
}

export const subscribeEmailToSendPulse = async ({
  listId = 237780,
  email,
  customVariables = null,
  }) => {
  try {
    const SENDPULSE_HEADER = await getSendPulseAuthorization()

    const userEmailData = {
      email,
      variables: customVariables,
    }

    return await axios.post(SENDPULSE_URLS.SUBSCRIBE_EMAIL, {
      listId,
      token: SENDPULSE_HEADER,
      emails: [userEmailData],
    })
  } catch (err) {
    toast.error(`Something went wrong with subscribing email to sendpulse, ${err}`)
  }
}
