export const vatModes = [
  {
    id: 'bond-vat-exluding',
    label: 'VAT ex',
    value: 'VAT ex',
  },
  {
    id: 'bond-vat-including',
    label: 'VAT incl',
    value: 'VAT incl',
  },
]
