import React from "react";
import ModalStyleLogs from "../../../../../../components/Modal/ModalStyleLogs.jsx";
import { IconRight } from "../../../../../../Assets/image/svgimages/sVGImg";
import "./Modalaction.scss";

const Modalaction = ({ open, onClose, onOpen, data }) => {
  return (
    <>
      <div className="ModalAction">
        <ModalStyleLogs
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          title={data.title}
          className="addRegion__modal"
        >
          {data.title === "Profile Image" ? (
            <div className="image-wrapper">
              <img src={data.oldItem} alt="" />
              <IconRight />
              <img src={data.newItem} alt="" />
            </div>
          ) : (
            <div className="listing">
              <p>{data.oldItem}</p>
              <IconRight />
              <p>{data.newItem}</p>
            </div>
          )}
        </ModalStyleLogs>
      </div>
    </>
  );
};

export default Modalaction;
