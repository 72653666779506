import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Button, Icon, Pagination, Table, Input, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import '../WineryRequestStyle.scss'
import moment from 'moment'
import { AddIcon, CloseIcon } from '../../../../Assets/image/svgimages/sVGImg'
import DeclineModal from '../../../../components/Modal/DeclineModal/DeclineModal.jsx'
import { toast } from '../../../../components/Toast/Toast'
import 'react-phone-number-input/style.css'

const UserList = ({
  artist,
  history,
  setStatus,
  setOrder,
  setLimit,
  limit,
  setSearch,
  search,
  setPage,
  blockUser,
}) => {
  const [selectedUser, setSelectedUser] = useState({})
  const [artistId, setArtistId] = useState(null)
  const [activeuserstatus, setActiveuserstatus] = useState(1)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(``)

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage
    setPage(page)
  }

  const showOptions = [
    { key: '1', text: '10', value: '10' },
    { key: '2', text: '15', value: '15' },
    { key: '3', text: '20', value: '20' },
  ]

  const sortByOption = [
    { key: '1', text: 'Newest', value: 'desc' },
    { key: '2', text: 'Oldest', value: 'asc' },
  ]

  const option2 = [
    { key: '1', text: 'Last Updated', value: '0' },
    { key: '2', text: 'Last Week', value: '1' },
    { key: '3', text: 'Last Month', value: '2' },
  ]

  let status = activeuserstatus === 0 ? 'approve' : 'reject'

  let statuses = activeuserstatus === 0 ? 'Add' : 'Decline'

  const onSubmitDeclineReason = () => {
    if (message !== '') {
      let payload = {
        id: artistId,
        status: status,
        message: message,
      }
      setOpen(false)
      blockUser(payload)
    } else {
      toast.error('Please Choose the reason')
    }
  }
  return (
    <>
      {statuses === 'Decline' && (
        <DeclineModal
          open={open}
          onClose={() => setOpen(false)}
          setMessage={setMessage}
          message={message}
          onSubmit={onSubmitDeclineReason}
          selectedUser={selectedUser}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className='hd_title'>Request List</h3>
      </div>
      <div className='filltersUser'>
        <div className='filltersBottom'>
          <div className='left_fill'>
            <Dropdown
              defaultValue='0'
              selection
              options={option2}
              onChange={(e, data) => {
                if (data.value === '0') {
                  setStatus('')
                } else {
                  setStatus(data.value)
                }
              }}
            />
            <div className='sorting'>
              <span>Sort by</span>

              <Dropdown
                defaultValue='desc'
                placeholder='Oldest'
                selection
                options={sortByOption}
                onChange={(e, data) => {
                  if (data.value === 'desc') {
                    setOrder('')
                  } else {
                    setOrder(data.value)
                  }
                }}
              />
            </div>

            <Input
              className='search_fld'
              icon={<Icon name='search' inverted link />}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder='Ex. Search id, email, country'
            />
          </div>
          <div className='rightfill'>
            <div className='sorting'>
              <span>Show</span>
              <Dropdown
                placeholder={limit.toString()}
                selection
                value={limit.toString()}
                options={showOptions}
                className='autowidth'
                onChange={(e, data) => {
                  setLimit(data.value)
                }}
              />
            </div>
            <div className='sorting'>
              <span>Entries</span>
              <Dropdown
                placeholder={artist?.count.toString()}
                selection
                disabled
                options={showOptions}
                className='autowidth'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>ID</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Winery Name</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Main Contact</Table.HeaderCell>

              <Table.HeaderCell colSpan='3'>Email</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Phone</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Country</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Status</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Request Date</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {artist?.data?.length > 0 ? (
              artist?.data?.map((row) => (
                <Table.Row key={row.unique_id}>
                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    {row.unique_id || '---'}
                  </Table.Cell>
                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    {row.creator_meta?.winery_name || 'N/A'}
                  </Table.Cell>

                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    {row.contact || 'N/A'}
                  </Table.Cell>

                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    {row.email || 'N/A'}
                  </Table.Cell>
                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    {row.phone_code ? `(${row.phone_code})` : ''}
                    {row.phone || 'N/A'}
                  </Table.Cell>
                  <Table.Cell collapsing colSpan='3' textAlign='left'>
                    {row.country_code}
                  </Table.Cell>
                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    <div
                      className={cn({
                        declineBtn: row.status === 'DECLINE',
                        approvedBtn: row.status === 'APPROVED',
                        processBtn: row.status === 'PROCESSING',
                        pendingBtn: row.status === 'PENDING',
                      })}
                    >
                      {row.status ? row.status : 'N/A'}
                    </div>
                  </Table.Cell>
                  <Table.Cell collapsing colSpan='3' textAlign='left'>
                    {row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY') : 'N/A'}
                  </Table.Cell>

                  <Table.Cell collapsing colSpan='2' textAlign='left'>
                    <span className='action_area'>
                      {row.status !== 'DECLINE' &&
                      row.status !== 'PROCESSING' &&
                      row.status !== 'APPROVED' ? (
                        <>
                          <Button
                            className='ui primary button'
                            onClick={() => {
                              setSelectedUser(row)
                              setArtistId(row?.id)
                              setActiveuserstatus(row?.status === true ? 0 : 1)
                              setOpen(true)
                            }}
                          >
                            <CloseIcon />
                            &nbsp; &nbsp; Decline
                          </Button>

                          <Button
                            className='ui primary button'
                            onClick={() => {
                              setSelectedUser(row)
                              setArtistId(row?.id)
                              history.push({
                                pathname: '/panel/CreateWinemakersRequest',
                                state: row,
                              })
                            }}
                          >
                            <AddIcon />
                            &nbsp; &nbsp; Add
                          </Button>
                        </>
                      ) : (
                        <p>
                          {row.status === 'PROCESSING' && (
                            <Link
                              to={{
                                pathname: '/panel/CreateWinemakersRequest',
                                state: row,
                                prevPath: history?.location.pathname,
                              }}
                            >
                              <Button>Process</Button>
                            </Link>
                          )}
                        </p>
                      )}
                      {row.status === 'APPROVED' && 'APPROVED'}
                      {row.status === 'DECLINE' && 'DECLINED'}
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell collapsing colSpan='18' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className='table_pagi new'>
        <div className='sorting'>
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={showOptions}
            className='autowidth SortingIcon'
            onChange={(e, data) => {
              setLimit(data.value)
            }}
          />
        </div>
        {artist?.count && artist?.count >= limit ? (
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            onPageChange={handlePageClick}
            defaultActivePage={1}
            totalPages={Math.ceil(parseInt(artist?.count) / limit) || 1}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default withRouter(UserList)
