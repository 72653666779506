import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import Login from '../../pages/public/login/Login'
import Reset from '../../pages/public/forgot/Reset'
import Confirm from '../../pages/public/forgot/Confirm'

class PublicRoutes extends Component {
  state = {}

  render() {
    return (
      <div className='PublicArea__content'>
        <Route path='/' component={Login} exact />
        <Route path='/reset' component={Reset} exact />
        <Route path='/resetpassword/:id' component={Confirm} exact />
      </div>
    )
  }
}

export default withRouter(PublicRoutes)
