import { startLoading, stopLoading } from "./loading.action";
import { NotificationService } from "../../Services/notifications.service";

export const actionTypes = {
  SAVE_ALL_NOTIFICATION: "SAVE_ALL_NOTIFICATION",
  SAVE_PENDING_NOTIFICATION: "SAVE_PENDING_NOTIFICATION",
};

export function saveMyAllNotifications(data) {
  return {
    type: actionTypes.SAVE_ALL_NOTIFICATION,
    payload: data,
  };
}

export function saveMyPendingNotifications(data) {
  return {
    type: actionTypes.SAVE_PENDING_NOTIFICATION,
    payload: data,
  };
}

export function loginUserRequest() {
  return {
    type: actionTypes.LOGIN_USER_REQUEST,
  };
}

export function getMyAllNotifications(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading());
      NotificationService.getMyAllNotifications(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          if (data.status === 0) {
            dispatch(saveMyPendingNotifications(res.data));
          } else {
            dispatch(saveMyAllNotifications(res.data));
          }

          dispatch(stopLoading());

          resolve(res);
        })
        .catch((ex) => {
          dispatch(stopLoading());
          reject(ex);
        });
    });
}

export function markReadNotification(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading());
      NotificationService.markReadNotification(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          dispatch(stopLoading());

          resolve(res);
        })
        .catch((ex) => {
          dispatch(stopLoading());
          reject(ex);
        });
    });
}

export function deleteNotification(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading());
      NotificationService.deleteNotification(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          dispatch(stopLoading());

          resolve(res);
        })
        .catch((ex) => {
          dispatch(stopLoading());
          reject(ex);
        });
    });
}
