import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MainLayout from 'components/HOC/MainLayout/MainLayout'
import DiscountCodeForm from './discountCodeForm/DiscountCodeForm'
import { Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { getDiscountCode, updateDiscountCode } from 'redux/_actions/discountCodes.action'
import { toast } from '../../../../components/Toast/Toast'

const DiscountCodeEdit = () => {
  const { id } = useParams()
  const [discountCode, setDiscountCode] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  }, [])

  useEffect(() => {
    if (id) {
      const successFn = (res) => {
        setDiscountCode(res?.data || null)
      }
      const errorFn = (res) => {
        toast.error(res?.data?.message || 'Failed')
      }
      dispatch(getDiscountCode({ id }, successFn, errorFn))
    }
  }, [id, dispatch])

  const onSubmit = (values) => {
    const successFn = (res) => {
      toast.success(res.data?.message)
      history.push('/panel/discount-codes')
    }
    const errorFn = (res) => {
      toast.error(res?.data?.message || 'Failed')
    }
    dispatch(
      updateDiscountCode({ discountCode: { ...values, id: discountCode?.id } }, successFn, errorFn),
    )
  }

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Discount Code Edit</h3>
      <Row>
        <Col span={14} offset={5}>
          {discountCode ? <DiscountCodeForm data={discountCode} onSubmit={onSubmit} /> : null}
        </Col>
      </Row>
    </MainLayout>
  )
}

export default DiscountCodeEdit
