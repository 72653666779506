import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { DollIconCard } from '../../../../../Assets/image/svgImg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import './ItemSell.scss'

const ItemSell = ({ data }) => {
  const { wineImg, wineName, country, price, quantity, avatarImg, ownerId } = data
  return (
    <div className='blueWineftCard container'>
      <Col xs={24} md={12} lg={6}>
        <div className='blueWineftCard__card'>
          <div className='blueWineftCard__cardsBackground'></div>
          <div className='blueWineftCard__img'>
            <img src={wineImg} alt='wine' className='bottle' />
          </div>
          <div className='blueWineftCard__info'>
            <div className='blueWineftCard-title-container'>
              <h4 className='blueWineftCard__info_title'>{wineName}</h4>

              <span className='blueWineftCard__info_country'>{country}</span>
            </div>
            <Row justify='blueWineftCard' className='blueWineftCard__info_block'>
              <Col span={12}>
                <div className='blueWineftCard__info_price'>Price</div>
                <div className='blueWineftCard__info_value'>
                  <DollIconCard />
                  {price}
                  <span>USDC</span>
                </div>
              </Col>
              <Col span={12} className='blueWineftCard__info_details_quantity'>
                <div>
                  <div className='blueWineftCard__info_price'>Quantity</div>
                  <div className='blueWineftCard__info_value'>{quantity}</div>
                </div>
              </Col>
            </Row>
            <Row className='blueWineftCard__info_sellerBox'>
              <Col>
                <div className='blueWineftCard__info_winmaker'>
                  {avatarImg ? (
                    <img src={avatarImg} alt='winmaker' />
                  ) : (
                    <div className='blueWineftCard__info_winmaker_av_default'>
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  )}
                </div>
              </Col>
              <Col className='blueWineftCard__info_sellerId'>
                <div>
                  <div className='blueWineftCard__info_seller'>Owner</div>
                  <div className='blueWineftCard__info_idNumber'>{ownerId}</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </div>
  )
}
export default ItemSell

ItemSell.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    wineImg: PropTypes.string,
    wineName: PropTypes.string,
    country: PropTypes.string,
    price: PropTypes.string,
    quantity: PropTypes.string,
    avatarImg: PropTypes.string,
    ownerId: PropTypes.string,
  }).isRequired,
}
