import React from 'react'
import { Form, Input, Select } from 'antd'
import { countryList } from '../../../../utils/countries'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './PhoneInput.scss'

const BillingFields = () => {
  const { Option } = Select

  return (
    <>
      <Form.Item name='country' label='Country' rules={[{ required: true }]}>
        <Select showSearch placeholder='Select country...' className='customSelect'>
          {countryList.map((c, i) => {
            return (
              <Option value={c.value.toLowerCase()} key={c.value.toLowerCase()}>
                {c.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name='state' label='State/County'>
        <Input placeholder='State/County...' />
      </Form.Item>
      <Form.Item name='city' label='City' rules={[{ required: true }]}>
        <Input placeholder='City...' />
      </Form.Item>
      <Form.Item name='zip' label='Zip code' rules={[{ required: true }]} className='zip-code'>
        <Input placeholder='Zip code...' />
      </Form.Item>
      <Form.Item name='streetDetails' label='Street Details' rules={[{ required: true }]}>
        <Input placeholder='Street Details...' />
      </Form.Item>
      <Form.Item name='phone' label='Phone Number' rules={[{ required: true }]}>
        <PhoneInput
          id={'phone-number'}
          name='phone'
          country={'us'}
          rules={[{ required: true }]}
          placeholder='+ 1 (121) 123 - 1233'
          className='phone-input'
          enableSearch
        />
      </Form.Item>
    </>
  )
}
export default BillingFields
