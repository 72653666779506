import React, { useEffect, useState } from 'react'
import { Col, Row, Tabs } from 'antd'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { BackTo } from '../../../../Assets/image/svgImg'
import { Link, useParams } from 'react-router-dom'
import DetailsItem from '../detailsItem/DetailsItem'
import BoxInfo from '../boxInfo/BoxInfo'
import { WareHouseService } from '../../../../Services/warehouse.service'
import ShipmentDetails from '../shipmentDetails/ShipmentDetails'

import './WareNftId.scss'
import ItemSell from './itemSell/ItemSell'
import ItemOverCard from '../itemOver/ItemOverCard'
import { getCountryById } from 'utils/countries'
import { walletIdHide } from 'utils/helper'

const OVERVIEW = 'OVERVIEW'
const SHIPPMENT = 'SHIPPMENT'

const WareNftId = () => {
  const [selectedTab, setSelectedTab] = useState('')
  const [nft, setNft] = useState({})
  const { id } = useParams()
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await WareHouseService.getOrdersNftId(id)
        setNft(response?.data?.data)
      } catch (err) {
        setNft({})
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (localStorage.getItem(OVERVIEW)) {
      setSelectedTab(localStorage.getItem(OVERVIEW))
    } else {
      localStorage.setItem('activeTab', OVERVIEW)
      setSelectedTab(OVERVIEW)
    }
  }, [])

  const boxItemInfo = {
    id: nft.id,
    wineName: nft.nftName,
    country:
      nft.wineryCountry && nft.wineryContinent && `${nft.wineryCountry}, ${nft.wineryContinent}`,
    caseType: nft.caseType,
    nftType: nft.nftType,
    paymentDate: nft.orderCreatedAt ? nft.orderCreatedAt : '---',
    whoPaid: nft.email,
    location: getCountryById(nft.country || '')?.label,
    deliveryType: nft.deliveryType,
    deliveryTerm: nft.deliveryTerm,
    deliveryCompany: nft.deliveryCompany,
  }

  const cardWineft = {
    id: nft.id,
    wineImg: nft.bottleImage,
    wineName: nft.nftName,
    country:
      nft.wineryCountry && nft.wineryContinent && `${nft.wineryCountry}, ${nft.wineryContinent}`,
    price: nft.price_USD,
    quantity: nft.caseType,
    avatarImg: nft.profileImage,
    ownerId: walletIdHide(nft.walletId),
  }

  const tabsData = [
    {
      label: 'Overview',
      key: OVERVIEW,
      children: <ItemOverCard data={nft} />,
    },
    {
      label: 'Shippment details',
      key: SHIPPMENT,
      children: <ShipmentDetails data={nft} />,
    },
  ]
  return (
    <MainLayout>
      <div className='wareContainer'>
        <Col className='wareContainer_container'>
          <Row className='wareContainer_container__wrapper'>
            <Col className='wareContainer_container__wrapper__sidebar'>
              <Link to='/panel/ware-house'>
                <div className='wareContainer_btn'>
                  <span>
                    <BackTo />
                  </span>
                  Back to list
                </div>
              </Link>
              <DetailsItem
                wine_name={nft.nftName}
                ownerName={`${nft.first_name} ${nft.last_name}`}
                status={nft.statusDelivery}
                nft_id={nft.id}
              />
              <div className='wareContainer_tabs'>
                <Tabs
                  className='wareContainer_tabs__tabOver'
                  defaultActiveKey='1'
                  activeKey={selectedTab}
                  onChange={(e) => {
                    localStorage.setItem('activeTab', e)
                    setSelectedTab(e)
                  }}
                  items={tabsData}
                />
              </div>
            </Col>
            <Col className='wareContainer_container__wrapper__content'>
              <div className='wareContainer_container__checkContent'>
                <h2 className='wareContainer_container__title'>Check information</h2>
                <div className='wareContainer_container__box'>
                  <div>
                    <BoxInfo data={boxItemInfo} />
                  </div>
                </div>
                <div className='wareContainer_container__cubic'>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                  <div className='wareContainer_container__cubics'></div>
                </div>
              </div>
              {selectedTab === SHIPPMENT && (
                <div className='wareContainer_container__checkContent'>
                  <h2 className='wareContainer_container__nftTitle'>NFT information</h2>
                  <div className='sellCard_item'>
                    <ItemSell data={cardWineft} />
                  </div>
                  <h2 className='wareContainer_container__qr'>QR-Code</h2>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </div>
    </MainLayout>
  )
}
export default WareNftId
