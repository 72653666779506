import React from "react";
import { Radio } from "semantic-ui-react";
import "./RadioButton.scss";

const RadioButton = ({
  label,
  className,
  onChange,
  name,
  value,
  checked,
  disabled,
}) => {
  const handleChange = (event, { value }) => onChange(value);

  return (
    <Radio
      value={value}
      name={name}
      checked={checked}
      onChange={handleChange}
      className={`radioBtn_style ${className}`}
      label={label}
      disabled={disabled}
    />
  );
};

export default RadioButton;
