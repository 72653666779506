import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getAllNftList = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/nft/get/drop`, data, headers)
}

const createNft = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/nft/save/drop`, data, headers)
}
const bulkCreateNfts = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/nft/bulk/create`, data, headers)
}
const bulkUpdateNfts = (data, headers) => {
  return fetch('put', `${API_HOST}/admin/api/v1/nft/bulk/update`, data, headers)
}

const getNftDetails = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/nft/get/drop/detail`, data, headers)
}
const updateNftDetails = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/nft/editDrop`, data, headers)
}
const sendToApproveNft = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/nft/updateDropEventStatus`, data, headers)
}

const deleteDrop = (data, headers) => {
  return fetch('delete', `${API_HOST}/admin/api/v1/nft/deleteDropData`, data, headers)
}

const saveDropLogs = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/save/logs`, data, headers)
}

const getDropLogs = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/get/drop-logs`, data, headers)
}

const getTransferNftData = (data, headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/getTransferNftData?dropId=${data}`, {}, headers)
}

const transferNft = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/transferNft`, data, headers)
}

export const NftService = {
  createNft,
  getNftDetails,
  updateNftDetails,
  getAllNftList,
  sendToApproveNft,
  deleteDrop,
  saveDropLogs,
  getDropLogs,
  draftNftPrev: getNftDetails,
  transferNft,
  getTransferNftData,
  bulkCreateNfts,
  bulkUpdateNfts,
}
