/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout/MainLayout";
import { WinerMakerAction } from "../../../redux/_actions";

import WinersList from "./List/WinersList";
import useDebounce from "../../../hooks/useDebounce";
import "./wineList.scss";
import { WINER_TYPE } from "../../../utils/constants";

const WineList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("desc");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const debaounceWinerySearch = useDebounce(search, 500);

  useEffect(() => {
    getWinerList();
  }, [page, order, status, limit]);


  useEffect(() => {
    setPage(1);
    setLimit(10);
    getWinerList();
  }, [debaounceWinerySearch])

  //getting List of all wineMakers from api
  const getWinerList = async () => {
    let dataFields = {
      limit: limit,
      offset: page,
      role_type: WINER_TYPE, //role_type 1=>user , 2=>winermaker
      searchkey: search,
      status: status,
      order: order,
    };
    const { getAllWineMakers } = WinerMakerAction;

    dispatch(getAllWineMakers(dataFields)).then((res) => {
      getAllWineryCount();
    });
  };

  const deleteWinemaker = (data) => {
    const { deleteUser } = WinerMakerAction;
    dispatch(deleteUser(data)).then((res) => {
      getWinerList();
    });
  };

  const getAllWineryCount = () => {
    const { getAllWineryCount } = WinerMakerAction;
    dispatch(getAllWineryCount()).then((res) => {});
  };

  return (
    <>
      <MainLayout>
        <WinersList
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          status={status}
          setStatus={setStatus}
          order={order}
          setOrder={setOrder}
          limit={limit}
          setLimit={setLimit}
          deleteWinemaker={deleteWinemaker}
          getWinerList={getWinerList}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(WineList);
