import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import InputField from '../../../components/common/InputField/InputField'
import { ReactComponent as IconBack } from '../../../Assets/image/svgimages/icon_back.svg'
import { ButtonCustom } from '../../../components/button/ButtonCustom'
import { toast } from '../../../components/Toast/Toast'
import { WinerMakerAction, Bottles } from '../../../redux/_actions'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import BorderButton from '../../../components/button/BorderButton'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {
  subscribeEmailToSendPulse,
  tagCustomEventSendPulse,
} from '../../../utils/sendPulseApi/sendPulseApi'
import { SENDPULSE_EVENTS } from '../../../utils/sendPulseApi/constants/constants'

const winerSchema = Yup.object().shape({
  wallet_id: Yup.string(),
  country: Yup.string(),
  winery_name: Yup.string(),
  location: Yup.string(),
  winemaker_name: Yup.string(),
  contact: Yup.string(),
  email: Yup.string(),
  phone_no: Yup.string(),
})
const CreateWineryForm = (props) => {
  const dispatch = useDispatch()

  const [country, setCountry] = useState('')
  const [location, setLocation] = useState('')
  const [countryWithRegion, setCountryWithRegion] = useState([])
  const [regionList, setRegionList] = useState([])

  const [allCountry, setAllCountry] = useState([])

  let { history, setIsLoader, userDetails } = props
  const { updateWinerProfileData } = WinerMakerAction
  const { getBottleDropDownSpecifications } = Bottles

  const [country_code, setCountryCode] = useState('')
  const [phone_code, setPhoneCode] = useState('')

  useEffect(() => {
    setPhoneCode(userDetails?.phone_code ? userDetails?.phone_code : '') // for mobile input field
    setCountryCode(userDetails.country_code ? userDetails.country_code : '') //for mobile input field
  }, [])

  useEffect(() => {
    getBottlesSpecification()
  }, [])

  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      let details = res.data
      const keys = Object.keys(details.regionList)
      setCountryWithRegion(details.regionList)
      setAllCountry(keys)

      wineFormik.values.country = userDetails?.creator_meta?.country
      wineFormik.values.location = userDetails?.creator_meta?.location

      setCountry(userDetails?.creator_meta?.country)
      setLocation(userDetails?.creator_meta?.location)
    })
  }

  useEffect(() => {
    if (Object.keys(countryWithRegion).length > 0) {
      handleCountryChange({ target: { value: userDetails?.creator_meta?.country } })
      handleRegionChange({ target: { value: userDetails?.creator_meta?.location } }, 'location')
    }
  }, [countryWithRegion])

  const handleCancel = () => {
    history.goBack()
  }

  const wineFormik = useFormik({
    enableReinitialize: true,
    validationSchema: winerSchema,
    initialValues: {
      wallet_id: userDetails?.wallet?.address,
      country: userDetails?.creator_meta?.country,
      location: userDetails?.creator_meta?.location,
      winery_name: userDetails?.creator_meta?.winery_name,
      email: userDetails?.creator_meta?.commercial_email,
      phone_no: userDetails.phone ? '+' + userDetails?.phone_code + userDetails.phone : '',
      winemaker_name: userDetails?.name,
      contact: userDetails?.contact,
    },
    onSubmit: (values) => {
      let data = {
        commercial_email: values.email,
        commercial_phone_number: values.phone_no.replace('+' + phone_code, ''),
        phone_code: phone_code,
        country_code: country_code,
        country: values.country,
        location: values.location,
        user_id: userDetails.userId,
        wallet_id: userDetails?.wallet?.address ? userDetails?.wallet?.address : '',
        winery_name: values.winery_name,
        winemaker_name: values.winemaker_name,
        main_contact_person: values.contact,
        isAlreadyWinemaker: false,
      }

      if (wineFormik.values.phone_no !== '') {
        addWinemaker(data)
      }
    },
  })

  const addWinemaker = (wineMakerData) => {
    setIsLoader(true)
    try {
      dispatch(updateWinerProfileData(wineMakerData)).then(async (res) => {
        toast.success(res.data.message)
        await Promise.all([
          tagCustomEventSendPulse({
            eventLink: SENDPULSE_EVENTS.WINERY_APPROVED,
            email: wineMakerData?.commercial_email,
            customData: { winery_name: wineMakerData?.winery_name },
          }),
          subscribeEmailToSendPulse({
            email: wineMakerData?.commercial_email,
            customVariables: {
              Winery: 'Yes',
              'No USDC': 'Yes',
              'First Name': wineMakerData?.winemaker_name,
              Country: wineMakerData?.country,
            },
          }),
        ])

        history.push('/panel/WineList')
      })
    } finally {
      setIsLoader(false)
    }
  }

  const handleCountryChange = (e) => {
    wineFormik.setFieldValue('country', e.target.value)
    setCountry(e.target.value)
    wineFormik.setFieldValue('location', '') //location is region
    for (let key in countryWithRegion) {
      if (key === e.target.value) {
        setRegionList(countryWithRegion[key])
      }
    }
  }

  const handleRegionChange = (e, field) => {
    wineFormik.setFieldValue(field, e.target.value)
    setLocation(e.target.value)
  }

  return (
    <div className='block ' id='blockone'>
      <Link to='/panel/winery-requests' className='back-btn'>
        <IconBack /> Back to winery list
      </Link>
      <h1 className='common-heading'>Create A New Winery</h1>

      <Form onSubmit={wineFormik.handleSubmit}>
        <div className='AdduserInfo'>
          <InputField
            id='winery_name'
            name='winery_name'
            disabled
            label='Winery Name'
            value={wineFormik.values.winery_name}
            formik={wineFormik}
          />
        </div>
        <div className='cus_drop inputField__wrap'>
          <label className='drop_label'>Country</label>
          <select value={country || ''} disabled>
            {allCountry.map((option) => (
              <option key={`c-${option.value}`} value={option.index}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className='cus_drop inputField__wrap p-0'>
          <label className='drop_label'>Region</label>
          <select
            value={location || ''}
            onChange={(e) => handleRegionChange(e, 'location')}
            disabled
          >
            {regionList.map((option) => (
              <option key={`r-${option.value}`} value={option.id}>
                {option.region_name}
              </option>
            ))}
          </select>
        </div>

        <div className='AdduserInfo'>
          <div className='genBottle_Id inputField__wrap'>
            <InputField
              id='winemaker_name'
              name='Winemaker_name'
              label='Winemaker name'
              disabled
              type='text'
              onChange={(event) => {
                wineFormik.setFieldValue('winemaker_name', event.target.value)
              }}
              value={wineFormik.values.winemaker_name}
              formik={wineFormik}
            />
          </div>
        </div>

        <InputField
          id='contact'
          name='contact'
          label='Main contact'
          disabled
          type='text'
          onChange={(event) => {
            wineFormik.setFieldValue('contact', event.target.value)
          }}
          value={wineFormik.values.contact}
          formik={wineFormik}
        />

        <InputField
          className='p-0'
          id='email'
          name='commercial_email'
          label='Commercial Email'
          disabled
          onChange={(event) => {
            wineFormik.setFieldValue('email', event.target.value)
          }}
          value={wineFormik.values.email}
          formik={wineFormik}
        />
        <div className='PhoneInputList'>
          <label>Commercial Phone Number</label>
          <PhoneInput
            disabled
            defaultCountry={country_code}
            id='phone_no'
            international
            value={wineFormik.values.phone_no}
          />
        </div>

        <div className='btns-spacing steps_buuton'>
          <ButtonCustom type='submit' title='Approve' customClass='Continue BtnSend' />
        </div>
      </Form>
      <BorderButton text='Cancel' className='addNew_Btn' onClick={handleCancel} />
    </div>
  )
}

export default withRouter(CreateWineryForm)
