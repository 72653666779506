import React from 'react'
import { withRouter } from 'react-router'
import { Icon, Pagination, Table, Input, Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from '../../../../components/Toast/Toast'
import '../user.scss'
const showOptions = [
  { key: '1', text: '10', value: '10' },
  { key: '2', text: '15', value: '15' },
  { key: '3', text: '20', value: '20' },
]

const sortByOption = [
  { key: '1', text: 'Newest', value: 'desc' },
  { key: '2', text: 'Oldest', value: 'asc' },
]

const option2 = [
  { key: '1', text: 'Last Updated', value: '0' },
  { key: '2', text: 'Last Week', value: '1' },
  { key: '3', text: 'Last Month', value: '2' },
]

const UserList = ({
  userList,
  search,
  setSearch,
  page,
  setPage,
  setStatus,
  setOrder,
  setLimit,
  limit,
}) => {
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage
    setPage(page)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className='hd_title'>User List</h3>
      </div>

      <div className='filltersUser'>
        <div className='filltersBottom'>
          <div className='left_fill'>
            <Dropdown
              defaultValue='0'
              selection
              options={option2}
              onChange={(e, data) => {
                if (data.value === '0') {
                  setStatus('')
                } else {
                  setStatus(data.value)
                }
              }}
            />
            <div className='sorting'>
              <span>Sort by</span>
              <Dropdown
                defaultValue='desc'
                placeholder='Oldest'
                selection
                options={sortByOption}
                onChange={(e, data) => {
                  if (data.value === 'desc') {
                    setOrder('')
                  } else {
                    setOrder(data.value)
                  }
                }}
              />
            </div>
            <Input
              className='search_fld'
              icon={<Icon name='search' inverted link />}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder='Ex. Search id, email, country'
            />
          </div>
          <div className='rightfill'>
            <div className='sorting'>
              <span>Show</span>
              <Dropdown
                value={limit.toString()}
                selection
                options={showOptions}
                className='autowidth'
                onChange={(e, data) => {
                  setLimit(data.value)
                }}
              />
            </div>
            <div className='sorting'>
              <span>Entries</span>
              <Dropdown
                placeholder={userList?.totalRecords?.toString()}
                selection
                options={showOptions}
                className='autowidth'
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>ID</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Wallet Id</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Name</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Email</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Country</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Created</Table.HeaderCell>
              <Table.HeaderCell colSpan='1' className='TableEditBtn'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {userList?.data?.map((row) => (
              <Table.Row key={row.userId}>
                <Table.Cell collapsing colSpan='2' textAlign='left' title={row.userId}>
                  {row.unique_id || '---'}
                </Table.Cell>
                <CopyToClipboard
                  text={row.wallet?.address || ''}
                  onCopy={() => {
                    toast.success('Copied')
                  }}
                >
                  <Table.Cell
                    collapsing
                    colSpan='2'
                    textAlign='left'
                    title={row.wallet?.address || ''}
                  >
                    {row.wallet?.address !== null
                      ? `${row.wallet?.address.slice(0, 10)}..`
                      : '----'}
                  </Table.Cell>
                </CopyToClipboard>
                <Table.Cell collapsing colSpan='2' textAlign='left'>
                  {row?.creator_meta?.winery_name || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='3' textAlign='left'>
                  {row.email || 'N/A'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='2' textAlign='left'>
                  {row?.creator_meta?.country || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='2' textAlign='left'>
                  {row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : 'N/A'}
                </Table.Cell>
              </Table.Row>
            ))}
            {!userList?.data?.length ? (
              <Table.Row>
                <Table.Cell collapsing colSpan='18' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : null}
          </Table.Body>
        </Table>
      </div>
      <div className='table_pagi new'>
        <div className='sorting'>
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={showOptions}
            className='autowidth SortingIcon'
            onChange={(e, data) => {
              setLimit(data.value)
            }}
          />
        </div>
        {userList?.totalRecords && userList?.totalRecords >= limit && (
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            onPageChange={handlePageClick}
            defaultActivePage={1}
            totalPages={Math.ceil(parseInt(userList?.totalRecords) / limit) || 1}
          />
        )}
      </div>
    </>
  )
}

export default withRouter(UserList)
