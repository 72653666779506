import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getAllDropCollections = (headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/dropCollectionAll`, {}, headers)
}

const getDropCollection = (params, headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/dropCollection/${params.id}`, {}, headers)
}

const createDropCollection = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/dropCollection`, data, headers)
}

const updateDropCollection = (data, headers) => {
  const { id } = data
  delete data.id
  return fetch('put', `${API_HOST}/admin/api/v1/dropCollection/${id}`, data, headers)
}

export const DropCollectionService = {
  getAllDropCollections,
  getDropCollection,
  createDropCollection,
  updateDropCollection,
}
