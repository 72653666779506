import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IconArrowRight } from '../../Assets/image/svgimages/sVGImg'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import './BreadCrumPage.scss'

const BreadCrumbPage = () => {
  const location = useLocation()
  const breadCrumPage = useSelector((state) => state.breadCrum.bredCrum)

  return (
    <nav className='BreadCrumbList'>
      <Link
        to='/panel/Dashboard'
        className={cn({
          'breadcrumb-active': location.pathname === '/panel/Dashboard',
          'breadcrumb-not-active': location.pathname !== '/panel/Dashboard',
        })}
      >
        Dashboard
      </Link>
      {breadCrumPage === 'dashboard' && location.pathname === '/panel/bottles/create-bottle' && (
        <>
          <div className='iconSpace'>
            <IconArrowRight />
          </div>
          <Link
            to='/panel/bottles/create-bottle'
            className={cn({
              'breadcrumb-active': location.pathname === '/panel/bottles/create-bottle',
              'breadcrumb-not-active': location.pathname !== '/panel/bottles/create-bottle',
            })}
          >
            Create bottle
          </Link>
        </>
      )}
      {breadCrumPage === 'dashboard' && location.pathname === '/panel/CreateWinemakers' && (
        <>
          <div className='iconSpace'>
            <IconArrowRight />
          </div>
          <Link
            to='/panel/CreateWinemakers'
            className={cn({
              'breadcrumb-active': location.pathname === '/panel/CreateWinemakers',
              'breadcrumb-not-active': location.pathname !== '/panel/CreateWinemakers',
            })}
          >
            Create Winemakers
          </Link>
        </>
      )}

      {/* Winery details pages  */}

      {breadCrumPage === 'winelist' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/WineList'
              className={cn({
                'breadcrumb-active': location.pathname.startsWith('/panel/WineList'),
                'breadcrumb-not-active': !location.pathname.startsWith('/panel/WineList'),
              })}
            >
              Winery List
            </Link>
          </>

          {location.pathname === '/panel/exclusive-access' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='#'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/exclusive-access',
                  'breadcrumb-not-active': location.pathname !== '/panel/exclusive-access',
                })}
              >
                Exclusive access
              </Link>
            </>
          )}

          {location.pathname === '/panel/CreateWinemakers' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='/panel/CreateWinemakers'
                className={
                  cn({
                    'breadcrumb-active': location.pathname === '/panel/CreateWinemakers',
                    'breadcrumb-not-active': location.pathname !== '/panel/CreateWinemakers',
                  })
                    ? 'breadcrumb-active'
                    : 'breadcrumb-not-active'
                }
              >
                Add Winemaker
              </Link>
            </>
          )}
          {location.pathname === '/panel/edit-profile-page' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='/panel/edit-profile-page'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/edit-profile-pages',
                  'breadcrumb-not-active': location.pathname !== '/panel/edit-profile-pages',
                })}
              >
                Add Winemaker
              </Link>
            </>
          )}
          {location.pathname === '/panel/add-winemaker' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='#'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/add-winemaker',
                  'breadcrumb-not-active': location.pathname !== '/panel/add-winemaker',
                })}
              >
                Create Public Page
              </Link>
            </>
          )}
          {location.pathname === '/panel/exclusive-access' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='#'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/exclusive-access',
                  'breadcrumb-not-active': location.pathname !== '/panel/exclusive-access',
                })}
              >
                Edit Profile
              </Link>
            </>
          )}
          {location.pathname === '/panel/bottles/create-bottle' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='/panel/bottles/create-bottle'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/bottles/create-bottle',
                  'breadcrumb-not-active': location.pathname !== '/panel/bottles/create-bottle',
                })}
              >
                Create Bottle
              </Link>
            </>
          )}
        </>
      )}

      {/* USERS PAGES  */}
      {breadCrumPage === 'users' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/WineList'
              className={cn({
                'breadcrumb-active': location.pathname.startsWith('/panel/WineList'),
                'breadcrumb-not-active': !location.pathname.startsWith('/panel/WineList'),
              })}
            >
              Users
            </Link>
          </>
        </>
      )}

      {/* BOTTLES PAGE  */}
      {breadCrumPage === 'bottles' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/bottles'
              className={cn({
                'breadcrumb-active': location.pathname === '/panel/bottles',
                'breadcrumb-not-active': location.pathname !== '/panel/bottles',
              })}
            >
              Bottles
            </Link>
          </>
          {location.pathname === '/panel/bottles/create-bottle' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='/panel/bottles/create-bottle'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/bottles/create-bottle',
                  'breadcrumb-not-active': location.pathname !== '/panel/bottles/create-bottle',
                })}
              >
                Create bottle
              </Link>
            </>
          )}

          {location.pathname === '/panel/bottles/edit-bottle' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='#'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/bottles/edit-bottle',
                  'breadcrumb-not-active': location.pathname !== '/panel/bottles/edit-bottle',
                })}
              >
                Edit Bottle
              </Link>
            </>
          )}
        </>
      )}
      {/* bottles/edit-bottle */}
      {/* WINERY REQUEST PAGES  */}
      {breadCrumPage === 'wineryRequest' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/winery-requests'
              className={cn({
                'breadcrumb-active': location.pathname.startsWith('/panel/winery-requests'),
                'breadcrumb-not-active': !location.pathname.startsWith('/panel/winery-requests'),
              })}
            >
              Request List
            </Link>
          </>
          {location.pathname === '/panel/CreateWinemakersRequest' && (
            <>
              <div className='iconSpace'>
                <IconArrowRight />
              </div>
              <Link
                to='#'
                className={cn({
                  'breadcrumb-active': location.pathname === '/panel/CreateWinemakersRequest',
                  'breadcrumb-not-active': location.pathname !== '/panel/CreateWinemakersRequest',
                })}
              >
                Add Winemaker
              </Link>
            </>
          )}
        </>
      )}
      {/* NOTIFICATIONS PAGES  */}
      {breadCrumPage === 'notifications' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/notifications'
              className={cn({
                'breadcrumb-active': location.pathname.startsWith('/panel/notifications'),
                'breadcrumb-not-active': !location.pathname.startsWith('/panel/notifications'),
              })}
            >
              Notifications
            </Link>
          </>
        </>
      )}
      {/* //drop page  */}

      {breadCrumPage === 'dropEvents' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/Dropevents'
              className={cn({
                'breadcrumb-active': location.pathname.startsWith('/panel/dropEvents'),
                'breadcrumb-not-active': !location.pathname.startsWith('/panel/dropEvents'),
              })}
            >
              Drop
            </Link>
          </>
        </>
      )}
      {/* warehouse page */}
      {breadCrumPage === 'ware-house' && (
        <>
          <>
            <div className='iconSpace'>
              <IconArrowRight />
            </div>
            <Link
              to='/panel/ware-house'
              className={cn({
                'breadcrumb-active': location.pathname.startsWith('/panel/ware-house'),
                'breadcrumb-not-active': !location.pathname.startsWith('/panel/ware-house'),
              })}
            >
              Warehouse
            </Link>
          </>
        </>
      )}
    </nav>
  )
}
export default BreadCrumbPage
