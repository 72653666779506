import React from "react";
export function Plusicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <path
        id="Union_1"
        data-name="Union 1"
        d="M4,9V6H1A1,1,0,0,1,1,4H4V1A1,1,0,0,1,6,1V4H9A1,1,0,0,1,9,6H6V9A1,1,0,0,1,4,9Z"
        fill="#fff"
      />
    </svg>
  );
}
export function IconAdd() {
  return (
    <svg id='Black' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path
        id='Contour_1782'
        data-name='Contour 1782'
        d='M29847.2,386.816a.82.82,0,0,0-.8-.787.759.759,0,0,0-.8.787v4.722h-4.8a.787.787,0,1,0,0,1.573h4.8v4.834a.759.759,0,0,0,.8.787.82.82,0,0,0,.8-.787v-4.834h4.7a.787.787,0,1,0,0-1.573h-4.7Z'
        transform='translate(-29834 -380.029)'
        fillRule='evenodd'
      />
      <rect
        id='Rectangle_389'
        data-name='Rectangle 389'
        width='24'
        height='24'
        fill='none'
      />
    </svg>
  )
}
export function CrossPicker() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.676"
      height="12.703"
      viewBox="0 0 12.676 12.703"
    >
      <path
        id="Contour_1781"
        data-name="Contour 1781"
        d="M29914.6,387.234a.9.9,0,0,0,0-1,.705.705,0,0,0-1,0l-5.2,5.146-5.1-5.146a.707.707,0,0,0-1,0,.665.665,0,0,0,0,1l5.1,5.146-5.1,5.147a.665.665,0,0,0,0,1,.707.707,0,0,0,1,0l5.1-5.147,5.2,5.147a.705.705,0,0,0,1,0,.9.9,0,0,0,0-1l-5.2-5.147Z"
        transform="translate(-29902.074 -386.029)"
        fillRule="evenodd"
      />
    </svg>
  );
}
export function DollIconCard() {
  return (
    <svg
      className="dollIconCard"
      id="ETR-icon-black"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_208"
        data-name="Rectangle 208"
        width="24"
        height="24"
        fill="none"
      />
      <g id="usdc" transform="translate(-6.236 -7.939)">
        <path
          id="Contour_9933"
          data-name="Contour 9933"
          d="M49.077,34.721c.016-1.127-1.108-1.919-2.558-2.076-.852-.089-1.315-.34-1.315-.921a1.031,1.031,0,0,1,1.156-.934c1.107.064,1.267.772,1.267,1.2,0,0,1.2.014,1.2,0a2.2,2.2,0,0,0-1.785-2.25V28.5H45.853v1.2a2.255,2.255,0,0,0-1.936,2.111c-.017,1.161.922,1.807,2.481,2.04.969.137,1.4.52,1.39,1.027-.008.548-.354,1.073-1.352,1.073A1.2,1.2,0,0,1,45.1,34.71H43.8a2.054,2.054,0,0,0,2.046,2.177v1.2H47.04V36.9A2.176,2.176,0,0,0,49.077,34.721Z"
          transform="translate(-28.243 -13.427)"
          fill="#130207"
        />
        <path
          id="Contour_9934"
          data-name="Contour 9934"
          d="M12.454,19.94A5.963,5.963,0,0,1,16.4,14.275V12.961a7.3,7.3,0,0,0,0,13.959V25.6A5.963,5.963,0,0,1,12.454,19.94Zm7.557-7v1.311a6.042,6.042,0,0,1,0,11.379V26.94a7.3,7.3,0,0,0,0-14Z"
          fill="#130207"
        />
      </g>
    </svg>
  );
}
export function PickerIcon() {
  return (
    <svg
      className="pickerIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="12.71"
      height="14.114"
      viewBox="0 0 12.71 14.114"
    >
      <path
        className="pickerIcon"
        id="Contour_2413"
        data-name="Contour 2413"
        d="M7362.07,323.22a.7.7,0,0,0-.7.706v.705h-.71a2.119,2.119,0,0,0-2.12,2.117v8.469a2.119,2.119,0,0,0,2.12,2.117h8.47a2.119,2.119,0,0,0,2.12-2.117v-8.469a2.119,2.119,0,0,0-2.12-2.117h-.71v-.705a.7.7,0,0,0-.7-.706.71.71,0,0,0-.71.706v.705h-4.23v-.705A.709.709,0,0,0,7362.07,323.22Zm5.65,2.823h-7.06a.7.7,0,0,0-.7.7v.706h9.88v-.706a.709.709,0,0,0-.71-.7Zm-7.76,9.174v-6.351h9.88v6.351a.709.709,0,0,1-.71.706h-8.47A.7.7,0,0,1,7359.96,335.217Zm1.41-4.234a.7.7,0,0,1,.7-.706h1.41a.709.709,0,0,1,.71.706v1.411a.709.709,0,0,1-.71.706h-1.41a.7.7,0,0,1-.7-.706Z"
        transform="translate(-7358.54 -323.22)"
        fillRule="evenodd"
      />
    </svg>
  );
}
export function FolderPlus() {
  return (
    <svg
      className="folderPlus"
      xmlns="http://www.w3.org/2000/svg"
      width="14.109"
      height="14.114"
      viewBox="0 0 14.109 14.114"
    >
      <path
        className="folderPlus"
        id="Contour_3150"
        data-name="Contour 3150"
        d="M9073.15,820.047h-6.471a2.133,2.133,0,0,0-1.5.62l-1.7,1.7a2.1,2.1,0,0,0-.62,1.5v8.176a2.119,2.119,0,0,0,2.12,2.117h9.88a2.11,2.11,0,0,0,2.109-2.117v-8.176a2.1,2.1,0,0,0-.62-1.5l-1.7-1.7A2.133,2.133,0,0,0,9073.15,820.047Zm1.71,2.823-1.21-1.205a.7.7,0,0,0-.5-.207h-6.471a.7.7,0,0,0-.5.207l-1.2,1.205Zm-10.591,2.117v7.057a.71.71,0,0,0,.711.706h9.88a.7.7,0,0,0,.7-.706v-7.057a.7.7,0,0,0-.7-.706h-5.65v1.412h1.41a.706.706,0,1,1,0,1.411h-1.41v1.412h1.41a.706.706,0,1,1,0,1.411h-1.41v.706a.709.709,0,0,1-.71.705.7.7,0,0,1-.7-.705v-2.117h-1.41a.706.706,0,1,1,0-1.412h1.41v-2.823h-2.819A.71.71,0,0,0,9064.27,824.987Z"
        transform="translate(-9062.86 -820.047)"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
export function ArrowLinkRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.647"
      height="9.88"
      viewBox="0 0 5.647 9.88"
    >
      <path
        id="Contour_2818"
        data-name="Contour 2818"
        d="M.2,5.44a.716.716,0,0,1,0-1L4.443.205a.7.7,0,0,1,.99,0L9.673,4.44a.707.707,0,0,1-1,1L4.943,1.7,1.2,5.44a.709.709,0,0,1-1,0Z"
        transform="translate(5.647 0) rotate(90)"
        fill="#23262c"
        fillRule="evenodd"
      />
    </svg>
  );
}
export function ArrowFilter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.502"
      height="13.284"
      viewBox="0 0 7.502 13.284"
    >
      <g
        id="Сгруппировать_39"
        data-name="Сгруппировать 39"
        transform="translate(-1334.399 -212.601)"
      >
        <path
          id="Icon_material-play-arrow"
          data-name="Icon material-play-arrow"
          d="M0,7.5V0L5.894,3.751Z"
          transform="translate(1334.399 218.495) rotate(-90)"
          opacity="0.5"
        />
        <path
          id="Icon_material-play-arrow-2"
          data-name="Icon material-play-arrow"
          d="M0,0V7.5L5.894,3.751Z"
          transform="translate(1341.901 219.99) rotate(90)"
        />
      </g>
    </svg>
  );
}
export function BackTo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.109"
      height="8.469"
      viewBox="0 0 14.109 8.469"
    >
      <path
        id="Contour_2812"
        data-name="Contour 2812"
        d="M1982.23,334.3a.708.708,0,0,1-1,.009l-3.59-3.528a.708.708,0,0,1,0-1.007l3.59-3.529a.706.706,0,1,1,.99,1.007l-2.36,2.319h10.979a.706.706,0,0,1,0,1.412H1979.86l2.36,2.319A.707.707,0,0,1,1982.23,334.3Z"
        transform="translate(-1977.43 -326.043)"
        fill="#6f6f6f"
        fillRule="evenodd"
      />
    </svg>
  );
}
