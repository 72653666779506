import React, { useState } from 'react'
import { IconRight } from '../../../../Assets/image/svgimages/sVGImg'
import Modalaction from '../../Profile/EditProfilePage/ActualVersion/ModalAction/Modalaction'
import moment from 'moment'

import '../../bottles/EditBottle/BottleLogs.scss'

const PublicPageLogs = (props) => {
  let { data } = props
  const [moreField, setMoreField] = useState({})
  const [open, setOpen] = useState(false)

  const setViewFieldData = (oldItem, newItem, title) => {
    let item = {
      oldItem: oldItem,
      newItem: newItem,
      title: title,
    }
    setMoreField(item)
    setOpen(true)
  }
  return (
    <>
      {data.form_1 !== null && (
        <div className='actualVersion'>
          <div className={data.form_1.added_by === 'Admin' ? 'Admin_By' : 'User_By'}>
            <div className={data.form_1.added_by === 'Admin' ? 'Byadmin' : 'ByUser'}>
              <p>
                {
                  moment(data.form_1.updated_at).format('Do MMM, h:mm a') // August 30, 2022 8:57 PM
                }
              </p>
              <h6>{data.form_1.added_by}</h6>
            </div>

            <h3>Cover Section </h3>
            <ul>
              {data?.form_1.newValues?.header && (
                <li>
                  <div className='InformShow'>
                    <strong>Winery name</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_1.oldValues?.header,
                          data?.form_1.newValues?.header,
                          'Winery name',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_1.oldValues?.header}</p>
                    <IconRight />
                    <p>{data?.form_1.newValues?.header}</p>
                  </div>
                </li>
              )}
              {data?.form_1.newValues?.description && (
                <li>
                  <div className='InformShow'>
                    <strong>Winery tagline</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_1.oldValues?.description,
                          data?.form_1.newValues?.description,
                          'Winery tagline',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_1.oldValues?.description}</p>
                    <IconRight />
                    <p>{data?.form_1.newValues?.description}</p>
                  </div>
                </li>
              )}

              {data?.form_1.newValues?.body_text && (
                <li>
                  <div className='InformShow'>
                    <strong>Body</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_1.oldValues?.body_text,
                          data?.form_1.newValues?.body_text,
                          'Body',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_1.oldValues?.body_text}</p>
                    <IconRight />
                    <p>{data?.form_1.newValues?.body_text}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {/* <hr className="spcificLine" /> */}

      {data.form_2 !== null && (
        <div className='actualVersion'>
          <div className={data.form_2.added_by === 'Admin' ? 'Admin_By' : 'User_By'}>
            <div className={data.form_2.added_by === 'Admin' ? 'Byadmin' : 'ByUser'}>
              <p>
                {
                  moment(data.form_2.updated_at).format('Do MMM, h:mm a') // August 30, 2022 8:57 PM
                }
              </p>
              <h6>{data.form_2.added_by}</h6>
            </div>
            <h3>Key Content Details</h3>

            <ul>
              {data?.form_2.newValues?.header && (
                <li>
                  <div className='InformShow'>
                    <strong>Winery`s background story</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_2.oldValues?.header,
                          data?.form_2.newValues?.header,
                          'Winery`s background story',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_2.oldValues?.header}</p>
                    <IconRight />
                    <p>{data?.form_2.newValues?.header}</p>
                  </div>
                </li>
              )}

              {data?.form_2.newValues?.body_text && (
                <li>
                  <div className='InformShow'>
                    <strong>Text</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_2.oldValues?.body_text,
                          data?.form_2.newValues?.body_text,
                          'Text',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_2.oldValues?.body_text}</p>
                    <IconRight />
                    <p>{data?.form_2.newValues?.body_text}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {/* <hr className="spcificLine" /> */}

      {data.form_3 !== null && data?.form_3.newValues?.header && (
        <div className='actualVersion'>
          <div className={data.form_3.added_by === 'Admin' ? 'Admin_By' : 'User_By'}>
            <div className={data.form_3.added_by === 'Admin' ? 'Byadmin' : 'ByUser'}>
              <p>
                {
                  moment(data.form_3.updated_at).format('Do MMM, h:mm a') // August 30, 2022 8:57 PM
                }
              </p>
              <h6>{data.form_3.added_by}</h6>
            </div>
            <h3>Add Winery Photo </h3>

            <ul>
              {data?.form_3.newValues?.header && (
                <li>
                  <div className='InformShow'>
                    <strong>Gallery</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_3.oldValues?.header,
                          data?.form_3.newValues?.header,
                          'Gallery',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_3.oldValues?.header}</p>
                    <IconRight />
                    <p>{data?.form_3.newValues?.header}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}

      {data.form_5 !== null && (
        <div className='actualVersion'>
          <div className={data?.form_5.added_by === 'Admin' ? 'Admin_By' : 'User_By'}>
            <div className={data.form_5.added_by === 'Admin' ? 'Byadmin' : 'ByUser'}>
              <p>
                {
                  moment(data.form_5.updated_at).format('Do MMM, h:mm a') // August 30, 2022 8:57 PM
                }
              </p>
              <h6>{data.form_5.added_by}</h6>
            </div>
            <h3>Winery Vision & Philosophy </h3>

            <ul>
              {data?.form_5.newValues?.header && (
                <li>
                  <div className='InformShow'>
                    <strong>Title for the section</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_5?.oldValues?.header,
                          data?.form_5?.newValues?.header,
                          'Title for the section',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_5?.oldValues?.header}</p>
                    <IconRight />
                    <p>{data?.form_5?.newValues?.header}</p>
                  </div>
                </li>
              )}

              {data?.form_5.newValues?.text && (
                <li>
                  <div className='InformShow'>
                    <strong>Text</strong>
                    <button
                      onClick={() =>
                        setViewFieldData(
                          data?.form_5?.oldValues?.text,
                          data?.form_5?.newValues?.text,
                          'Text',
                        )
                      }
                    >
                      ...More
                    </button>
                  </div>
                  <div className='listing'>
                    <p>{data?.form_5?.oldValues?.text}</p>
                    <IconRight />
                    <p>{data?.form_5?.newValues?.text}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {open && (
        <Modalaction
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          data={moreField}
        />
      )}
    </>
  )
}

export default PublicPageLogs
