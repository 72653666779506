import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const login = (data, headers = {}) => fetch('post', `${API_HOST}/admin/api/v1/login`, data, headers)

const reset = (data, headers = {}) =>
  fetch('post', `${API_HOST}/admin/api/v1/forget-password`, data, headers)

const confirm = (data, headers = {}) =>
  fetch('post', `${API_HOST}/admin/api/v1/reset-password`, data, headers)

const getCollections = (data, headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/collection/list/${data.limit}/${data.page}`, {}, headers)
const createCollection = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/collection/add`, data, headers)
const getCollectionById = (data, headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/collection/${data.id}`, {}, headers)
const updateCollection = (data, headers) =>
  fetch('put', `${API_HOST}/admin/api/v1/collection/${data.id}`, data, headers)

const createNft = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/nft/add`, data, headers)

const getNfts = (data, headers) =>
  fetch(
    'get',
    `${API_HOST}/admin/api/v1/nft/list/${data.limit}/${data.page}`,
    //`${API_HOST}/nft/api/v1/list/${data.limit}/${data.page}`,
    {},
    headers,
  )

const getNftReports = (data, headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/reporting/listing/10/1`, {}, headers)

const getPhotoGrapherReports = (data, headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/reporting/photographer-list/10/1`, {}, headers)

const getNftById = (data, headers) =>
  fetch('get', `${API_HOST}/nft/api/v1/nft/${data?.id}`, {}, headers)

const getExtraTypes = (data, headers) =>
  fetch('get', `${API_HOST}/nft/api/v1/extras/extra_list`, {}, headers)
const createExtraType = (data, headers) =>
  fetch('post', `${API_HOST}/nft/api/v1/extras/extras_add`, data, headers)

const deleteExtraType = (data, headers) =>
  fetch('delete', `${API_HOST}/nft/api/v1/extras/delete/${data}`, data, headers)
const getExtraTypeById = (data, headers) =>
  fetch('get', `${API_HOST}/nft/api/v1/extras//extra_list_by_id/${data.id}`, {}, headers)

const updateExtraType = (data, headers) =>
  fetch('put', `${API_HOST}/nft/api/v1/extras/${data.id}`, data, headers)

const uploadImage = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/upload/category`, data, headers)

const updateNftStatus = (data, headers) =>
  fetch('put', `${API_HOST}/admin/api/v1/nft/${data?.id}`, data, headers)

const uploadCsv = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/upload/bulkMinting`, data, headers)
const uploadBulkImages = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/upload/images`, data, headers)

const deleteBanner = (data, headers) =>
  fetch('delete', `${API_HOST}/admin/api/v1/banner/delete/${data}`, data, headers)
const getErrorLogData = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/upload/bulkMinting/error-logs/10/1`, {}, headers)

const getMintStatus = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/upload/bulkMinting/status`, {}, headers)

const getBrandArtistList = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/brand-artist/list`, {}, headers)

const getCreatorList = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/creator/list`, {}, headers)

const getRaffles = (data, headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/category/list/${data.limit}/${data.page}`, {}, headers)

const updateRaffleStatus = (id, data, headers) =>
  fetch('put', `${API_HOST}/admin/api/v1/category/update/${id}`, data, headers)

const createRaffles = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/category/add`, data, headers)

const getRafflesById = (data, headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/category/get-category/${data?.id}`, {}, headers)

const deleteCategoryById = (id, headers) =>
  fetch('delete', `${API_HOST}/admin/api/v1/category/delete/${id}`, {}, headers)

const getBlockChainList = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/nft/blockchain/list`, {}, headers)

const getActiveCoinList = (headers) =>
  fetch('get', `${API_HOST}/wallet/admin/active-all-coins`, {}, headers)

const getRafflesNftList = (headers) =>
  fetch('get', `${API_HOST}/admin/api/v1/raffle/drop_down_list`, {}, headers)

const getWithdrawTransactions = (data, headers) =>
  fetch(
    'get',
    `${API_HOST}/wallet/${data.coins}/admin/get_withdraw_transactions/${data.page}`,
    {},
    headers,
  )

//AD = A: Aproved ,D:Decline
const withdrawTransApprovalDecline = (data, headers) =>
  fetch('post', `${API_HOST}/wallet/${data.coin}/admin/withdraw-approval-request`, data, headers)

const getMysteryBoxList = (data, headers) =>
  fetch(
    'get',
    `http://10.1.5.205:3444/admin/api/v1/mysterybox/mysteryboxes/${data.limit}/${data.page}`,
    {},
    data,
    headers,
  )

const searchBar = (data, headers) =>
  fetch('put', `${API_HOST}/admin/api/v1/user/userlists/${data.limit}/${data.page}`, data, headers)

const createWiner = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/createCreator`, data, headers)

const collection = (data, headers) =>
  fetch('put', `${API_HOST}/admin/api/v1/collection/${data.id}`, data.status, headers)

const nftUpdateUser = (data, headers) =>
  fetch(
    'put',
    `${API_HOST}/admin/api/v1/nft/${data.id}`,
    { isActive: data.status.isActive ? 1 : 4, creator: data.creator },
    headers,
  )

const getWinerDetails = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/admin/winery-details`, data, headers)

const saveProfileOldData = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/save/logs`, data, headers)

const updateWinerProfileData = (data, headers) =>
  fetch('post', `${API_HOST}/admin/api/v1/creator/updateWiner`, data, headers)
const getDetailsLogs = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/get/logs`, data, headers)

const getPublicDetailsLogs = (data, headers) =>
  fetch('post', `${API_HOST}/winers/api/v1/get/public-page/logs`, data, headers)

const ApiService = {
  login,
  reset,
  confirm,
  getCollections,
  uploadImage,
  createCollection,
  getCollectionById,
  updateCollection,
  createNft,
  getNfts,
  getNftReports,
  getPhotoGrapherReports,
  getExtraTypes,
  getExtraTypeById,
  createExtraType,
  deleteExtraType,
  updateExtraType,
  updateNftStatus,
  uploadCsv,
  deleteBanner,
  getErrorLogData,
  uploadBulkImages,
  getMintStatus,
  getNftById,
  getBrandArtistList,
  getCreatorList,
  getRaffles,
  updateRaffleStatus,
  createRaffles,
  getRafflesById,
  getBlockChainList,
  getActiveCoinList,
  getWithdrawTransactions,
  withdrawTransApprovalDecline,
  getRafflesNftList,
  getMysteryBoxList,
  deleteCategoryById,
  searchBar,
  collection,
  nftUpdateUser,
  createWiner,
  getWinerDetails,
  saveProfileOldData,
  updateWinerProfileData,
  getDetailsLogs,
  getPublicDetailsLogs,
}

export default ApiService
