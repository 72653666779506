import { withRouter } from 'react-router'
import { Icon, Input, Dropdown, Pagination } from 'semantic-ui-react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'

import ItemOrderCard from '../itemOrderCard/ItemOrderCard'

import '../../WinemakerDetails/WinemakerDetails.scss'

const option2 = [
  { key: '1', text: 'Last Updated', value: '0' },
  { key: '2', text: 'Last Week', value: '1' },
  { key: '3', text: 'Last Month', value: '2' },
]
const showOptions = [
  { key: '1', text: '10', value: '10' },
  { key: '2', text: '15', value: '15' },
  { key: '3', text: '20', value: '20' },
]

const ShippingOrders = ({
  data,
  setTimeRange,
  searchKey,
  setSearchKey,
  setOffset,
  setLimit,
  limit,
}) => {
  const { orders, total } = data
  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage
    setOffset(offset)
  }

  return (
    <div className='detail-tab'>
      <div className='StatusWarrap'>
        <div className='filltersUser'>
          <div className='filltersBottom'>
            <div className='left_fill'>
              <Dropdown
                placeholder='Last Updated'
                selection
                options={option2}
                onChange={(e, data) => {
                  if (data?.value === '0') {
                    setTimeRange('')
                  } else {
                    setTimeRange(data?.value)
                  }
                }}
              />
              <Input
                className='search_fldWare'
                icon={<Icon name='search' inverted link />}
                placeholder='Ex. search winery ID, bottle type, NFT name'
                value={searchKey}
                onChange={(e) => {
                  setSearchKey(e.target.value)
                  setOffset(1)
                }}
              />
            </div>
            <div className='rightfill'>
              <div className='sorting'>
                <span>Show</span>
                <Dropdown
                  value={limit}
                  selection
                  options={showOptions}
                  className='autowidth'
                  onChange={(e, data) => {
                    setLimit(data.value)
                    setOffset(1)
                  }}
                />
              </div>
              <div className='sorting'>
                <span>Entries</span>
                <Dropdown
                  placeholder={(total || 0).toString()}
                  selection
                  disabled
                  options={showOptions}
                  className='autowidth'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='Table_Editing'>
          <div className='detail-tab_table'>
            <Row className='detail-tab_header'>
              <Col span={4} className='estimate-event'>
                Order ID
              </Col>
              <Col span={3} className='estimate-options'>
                Status order
              </Col>
              <Col span={4} className='estimate-period'>
                Owner Name &nbsp;
              </Col>
              <Col span={3} className='estimate-cost'>
                Wallet ID &nbsp;
              </Col>
              <Col span={4} className='estimate-cost'>
                Outbound Shipment ID
              </Col>
              <Col span={4} className='estimate-cost'>
                Burn Date
              </Col>
              <Col span={2} className='estimate-cost'></Col>
            </Row>
          </div>
          {orders.map((order) => (
            <div className='sellCard_itemCard' key={order.orderId}>
              <ItemOrderCard data={order} />
            </div>
          ))}
          {total === 0 && (
            <Row>
              <Col>
                <div>No Record Found.</div>
              </Col>
            </Row>
          )}
        </div>
        <div>
          <div className='table_pagi new'>
            <div className='sorting_page'>
              <span>Show On Page &nbsp;</span>
              <Dropdown
                value={limit}
                selection
                options={showOptions}
                className='autowidth SortingIcon'
                onChange={(e, data) => {
                  setLimit(data.value)
                  setOffset(1)
                }}
              />
            </div>
            {total && total >= limit && (
              <Pagination
                className='ListPagi'
                firstItem={false}
                lastItem={false}
                boundaryRange={1}
                siblingRange={0}
                defaultActivePage={1}
                onPageChange={handlePageClick}
                totalPages={Math.ceil(parseInt(total) / limit) || 1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ShippingOrders)

ShippingOrders.defaultProp = {
  searchKey: '',
}

ShippingOrders.propTypes = {
  data: PropTypes.shape({
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        orderId: PropTypes.number,
      }),
    ),
    total: PropTypes.number,
  }).isRequired,
  searchKey: PropTypes.string,
  setTimeRange: PropTypes.func.isRequired,
  setSearchKey: PropTypes.func.isRequired,
  setOffset: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
}
