import { Button, Modal } from "semantic-ui-react";
import React from "react";
import Loader from "react-loader-spinner";

const DoubleConfirmation = ({
  onClick,
  showWarningDataChangedMessage,
  onCloseWithWarningMessage,
  disabled,
  text,
}) => {
  return (
    <Modal.Content scrolling>
      <div className="double-confirmation-text">{text}</div>
      {showWarningDataChangedMessage &&
        <div className='warning-message'>
          NFT attributed have changed recently. Please restart override
        </div>
      }
      <div className="btns-spacing">
        {showWarningDataChangedMessage ?
          <Button
            onClick={onCloseWithWarningMessage}
            className="ui button btnCustom saveBtn"
            positive
          >
            Ok
          </Button> :
          <>
            <Button
              onClick={onClick}
              className="ui button btnCustom saveBtn"
              disabled={disabled}
              positive
            >
              Confirm
              {disabled &&
                <Loader
                  type="Circles"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                  visible={true}
                />
              }
            </Button>
          </>
        }
      </div>
    </Modal.Content>
  )
};

export default DoubleConfirmation;
