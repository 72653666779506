import { useEffect, useState, useRef } from 'react'
import { urlsOfFrontend } from '../../../utils/constants'

const CardIframe = ({ data }) => {
  const iframeRef = useRef(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const handleIframeLoad = () => {
    setIframeLoaded(true)
  }
  const sendMessage = () => {
    const { contentWindow } = iframeRef.current

    setTimeout(() => {
      contentWindow.postMessage(data, process.env.REACT_APP_FRONTEND_DOMAIN)
    }, 1000)
  }

  useEffect(() => {
    if (iframeLoaded) {
      sendMessage()
    }
  }, [iframeLoaded, data])

  return (
    <iframe
      title='card-iframe'
      width='100%'
      height='720px'
      src={`${
        urlsOfFrontend[process.env.REACT_APP_ENVIRONMENT]
      }/drop-details-iframe-display?no-layout=true`}
      ref={iframeRef}
      onLoad={handleIframeLoad}
    ></iframe>
  )
}

export default CardIframe
