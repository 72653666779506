import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import { WareHouseService } from "Services/warehouse.service";


export const actionTypes = {
  SAVE_ALL_PALLETS: "SAVE_ALL_PALLETS",
};

export function saveMyAllPallets(data) {
  return {
    type: actionTypes.SAVE_ALL_PALLETS,
    payload: data,
  };
}


export function getPallets(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading());
      WareHouseService.getPallets(data, {
        Authorization: localStorage.getItem("authorizations"),
      })
        .then((res) => {
          dispatch(saveMyAllPallets(res?.data));
          dispatch(stopLoading());
          resolve(res);
        })
        .catch((ex) => {
          toast.error(ex?.message);
          dispatch(stopLoading());
          reject(ex);
        });
    });
}
