const getPluralEnd = (value) => {
  if (!value) {
    return ''
  }
  return value > 1 ? 's' : ''
}
export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000)

  if (seconds < 60) {
    return seconds === 0 ? 'Just Now' : `${seconds} second${getPluralEnd(seconds)} ago`
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return `${minutes} minute${getPluralEnd(minutes)} ago`
  }

  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    return `${hours} hour${getPluralEnd(hours)} ago`
  }

  const days = Math.floor(hours / 24)
  if (days < 30) {
    return `${days} day${getPluralEnd(days)} ago`
  }

  const months = Math.floor(days / 30)
  if (months < 12) {
    return `${months} month${getPluralEnd(months)} ago`
  }

  const years = Math.floor(months / 12)
  return `${years} year${getPluralEnd(years)} ago`
}
