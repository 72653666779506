import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MainLayout from 'components/HOC/MainLayout/MainLayout'
import DiscountCodeForm from './discountCodeForm/DiscountCodeForm'
import { Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { createDiscountCode } from 'redux/_actions/discountCodes.action'
import { toast } from '../../../../components/Toast/Toast'

const DiscountCodeCreate = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const onSubmit = (values) => {
    const successFn = (res) => {
      toast.success(res.data?.message)
      history.push('/panel/discount-codes')
    }
    const errorFn = (res) => {
      toast.error(res?.data?.message || 'Failed')
    }

    dispatch(createDiscountCode({ discountCode: values }, successFn, errorFn)) // check query and response
  }
  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Discount Code Create</h3>
      <Row>
        <Col span={14} offset={5}>
          <DiscountCodeForm onSubmit={onSubmit} />
        </Col>
      </Row>
    </MainLayout>
  )
}

export default DiscountCodeCreate
