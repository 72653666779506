import React, { useEffect } from "react";
import "./mainLayout.scss";
import MobileHeader from "../MobileHeader";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import HeaderNft from "../DesktopHeader/HeaderNft";

const NftLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="NtfLayout">
        <HeaderNft
        />
        <div className="for_mobile">
          <MobileHeader />
        </div>
        <Container className="content_view pt-50">{children}</Container>
      </div>
    </>
  );
};

export default withRouter(NftLayout);
