import getWineName from "../../../../Helpers/getWineName";

const getWineNameFromBottleDetails = ({ bottleDetails, nft_name }) => {
  const wineNameData = {
    winery_name: bottleDetails?.winery_name,
    cuvee: bottleDetails?.wine_name,
    cru: bottleDetails?.vineyard_name,
    classification: bottleDetails?.classification,
    nft_name,
    };

  return getWineName(wineNameData)
};

export default getWineNameFromBottleDetails;
