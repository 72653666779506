import React, { useEffect, useState, useRef } from 'react'
import BackToPrev from './BackToPrev'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { WinerMakerAction } from '../../../../redux/_actions'
import { urlsOfFrontend } from '../../../../utils/constants'

import './WineMakerStyle.scss'

const WineMaker = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [iframeLoaded, setIframeLoaded] = useState(false)
  let { getWineMakerDetails } = WinerMakerAction

  const userDetails = history?.location?.state
  const [winerDetails, setwinerDetails] = useState([])

  const iframeRef = useRef(null)

  const sendMessage = () => {
    const { contentWindow } = iframeRef.current
    setTimeout(() => {
      contentWindow.postMessage(winerDetails, process.env.REACT_APP_FRONTEND_DOMAIN)
    }, 1000)
  }

  useEffect(() => {
    if (iframeLoaded && winerDetails.length > 0) {
      sendMessage()
    }
  }, [iframeLoaded, winerDetails.length])

  useEffect(() => {
    getWinerAllDetails()
  }, [])
  const getWinerAllDetails = () => {
    const postInfo = {
      userId: userDetails?.userId,
    }

    dispatch(getWineMakerDetails(postInfo)).then((res) => {
      let winerAllDetails = res.data?.data?.winerDetails
      setwinerDetails(winerAllDetails)
    })
  }

  const handleIframeLoad = () => {
    setIframeLoaded(true)
  }

  return (
    <div className='winemaker_wrapper'>
      <>
        <BackToPrev pathname='/panel/view-public-page' userDetails={userDetails} />

        <iframe
          title='winemaker'
          width='100%'
          src={`${urlsOfFrontend[process.env.REACT_APP_ENVIRONMENT]}/wineries-iframe-display`}
          ref={iframeRef}
          onLoad={handleIframeLoad}
          className='winemaker_wrapper__iframe'
        ></iframe>
      </>
    </div>
  )
}
export default WineMaker
