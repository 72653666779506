import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'
import './EditStatusModal.scss'

import { MARKETPLACE_NFT_STATUSES } from '../../../../../../../utils/constants'
import {
  getMarketplaceNft,
  updateMarketplaceNft,
} from '../../../../../../../redux/_actions/marketplace.action'
import DoubleConfirmation from '../DoubleConfirmation/DoubleConfirmation'

const EditStatusModal = (props) => {
  let { setOpen, open, item, getAllMarketplaceNft } = props
  const dispatch = useDispatch()
  const [newStatus, setNewStatus] = useState()
  const [confirmSave, setConfirmSave] = useState(false)
  const [showWarningDataChangedMessage, setShowWarningDataChangedMessage] = useState(false)
  const [disabledSaveButton, setDisabledSaveButton] = useState(false)

  const statuses = [MARKETPLACE_NFT_STATUSES.DRAFT, MARKETPLACE_NFT_STATUSES.LISTED]

  const onOpenConfirmSave = () => {
    // if try to save the same status - return without any action to prevent BE error
    if (newStatus === item?.sale_status) {
      return onClosePopup()
    }

    setConfirmSave(true)
  }

  const onClosePopup = () => {
    setDisabledSaveButton(false)
    setOpen(false)
    setConfirmSave(false)
  }

  const onUpdateStatus = (e) => {
    setNewStatus(e.target.value)
  }

  const onSaveDataOrReject = async (currentStatus) => {
    if (currentStatus !== item?.sale_status) {
      setDisabledSaveButton(false)
      return setShowWarningDataChangedMessage(true) // reject popup
    }

    try {
      await dispatch(updateMarketplaceNft({ nft_id: item?.nft_id, status: newStatus }))
      onClosePopup()
    } catch (err) {
      onClosePopup()
    }
  }

  const onSave = async () => {
    setDisabledSaveButton(true)

    try {
      const currentNftData = await dispatch(getMarketplaceNft({ indexId: item?.indexId }))
      await onSaveDataOrReject(currentNftData?.data?.data?.sale_status)
    } catch (err) {
      console.log(err)
    }
  }

  const onCloseWithWarningMessage = () => {
    onClosePopup()
    setShowWarningDataChangedMessage(false)
    getAllMarketplaceNft() // update actual data
  }

  useEffect(() => {
    setNewStatus(item?.sale_status)
  }, [item?.sale_status])

  return (
    <Modal
      onClose={() => onClosePopup()}
      onOpen={() => setOpen(true)}
      open={open}
      className='editStatusModal'
    >
      <Modal.Header>Edit status</Modal.Header>

      {confirmSave ? (
        <DoubleConfirmation
          onClick={onSave}
          text='Are you sure you want to edit status?'
          disabled={disabledSaveButton}
          showWarningDataChangedMessage={showWarningDataChangedMessage}
          onCloseWithWarningMessage={onCloseWithWarningMessage}
        />
      ) : (
        <Modal.Content scrolling>
          <div className='cus_drop inputField__wrap'>
            <label className='inputField__label'>Marketplace nft status</label>
            <select value={newStatus} onChange={onUpdateStatus}>
              <option value='' disabled>
                Select nft status
              </option>
              {statuses.map((status) => {
                return (
                  <option value={status} key={`status-${status}`}>
                    {status}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='btns-spacing'>
            <Button color='black' onClick={() => onClosePopup()} className='borderBtn addNew_Btn'>
              Cancel
            </Button>
            <Button onClick={onOpenConfirmSave} className='ui button btnCustom saveBtn' positive>
              Save
            </Button>
          </div>
        </Modal.Content>
      )}
    </Modal>
  )
}
export default EditStatusModal
