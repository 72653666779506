import * as Yup from 'yup'

export const wineNftSchema = Yup.object().shape({
  nameOfWine: Yup.string(),
  // vintage: Yup.string().required('This field is required'),
  // abv: Yup.string().required("This field is required"),
  no_of_bottles: Yup.number()
    .required('This field is required')
    .integer(`Please enter a valid  number without decimal values`)
    .positive('Please enter no between 1-20')
    .max(20, 'Please enter no between 1-20'),
  no_of_nft: Yup.number()
    .required('This field is required')
    .integer(`Please enter a valid  number without decimal values`)
    .positive('Please enter no between 1-10000')
    .min(1, 'Please enter no between 1-10000')
    .max(10000, 'Please enter no between 1-10000'),
  price: Yup.number()
    .required('This field is required')
    .positive('Please enter no between 0.5 - 1000000')
    .min(0.5, 'Please enter no between 0.5 - 1000000')
    .max(1000000, 'Please enter no between 0.5 - 1000000'),
  price_ex_vat: Yup.number().required('This field is required').test('two-decimal-places', 'Must be no more than two decimal places', value => {
    if (value !== undefined) {
      return /^\d+(\.\d{1,2})?$/.test(value.toString());
    }
    return true;
  }),
  start_drop_event: Yup.object().required('This field is required'),
  finish_drop_event: Yup.object().required('This field is required'),
  bottle_release_date: Yup.object().required('This field is required'),
  royality_payment_profilt: Yup.number(),
  max_buy_limit: Yup.number()
    .required('This field is required')
    .integer(`Please enter a valid  number without decimal values`)
    .min(1, 'Min buy NFTs limit should be 1')
    .max(
      Yup.ref('no_of_nft'),
      'Max buy NFTs limit should not more than no of WiNeFTs to be minted',
    ),
  drop_mode: Yup.string().required('Drop mode is required'),
})

export const emptyWineNftSchema = Yup.object().shape({
  nft_name: Yup.string().required('NFT Name is required'),
})