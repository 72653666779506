import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import user from "../../../Assets/image/dummyImg.jpg";
import Types from "../../../redux/_actions/_types";

const trigger = (
  <span>
    <img src={user} alt="" className="admin-pic" />{" "}
    <span className="nm">Hello, Admin</span>
  </span>
);
const options = [
  { key: "profile", text: "Your Profile", value: "profile" },
  { key: "settings", text: "Settings", value: "settings" },
  { key: "logout", text: "Logout", value: "logout" },
];

const Userdrop = () => {
  const dispatch = useDispatch();

  const handleChange = (e, data) => {
    if (data.value === "logout") {
      localStorage.removeItem("authorizations");
      dispatch({
        type: Types.RESET_PERSIST_ON_LOGOUT,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    }
  };

  return (
    <Dropdown
      onChange={(e, data) => handleChange(e, data)}
      trigger={trigger}
      options={options}
    />
  );
};

export default Userdrop;
