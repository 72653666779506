import { FormikProps } from "formik/dist/types";
import TextAreaField from "../../../../../components/common/TextareaField/TextAreaField";
import RangeSlider from "../../../../../components/common/RangeSlider/RangeSlider";
import { ButtonCustom } from "../../../../../components/button/ButtonCustom";
import { WineStructureEnum, WineStructureType } from "../../store/model";
// @ts-ignore
import { useHistory } from "react-router";

interface NotesProps {
  bottleFormik: FormikProps<any>; // to do - add bottleFormik types instead of any
  action: "add" | "edit";
}

export default function Notes(props: NotesProps) {
  const history = useHistory();
  const { bottleFormik } = props;
  const onChangeSlider = (value: number | null, type: WineStructureType) => {
    bottleFormik.setFieldValue(type, value || '');
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    history.push("/panel/bottles");
  };

  const ageabilityOptions = [
    {
      label: '---',
      value: ''
    },
    {
      label: '0-5',
      value: '0-5'
    },
    {
      label: '5-15',
      value: '5-15'
    },
    {
      label: '15+',
      value: '15-60'
    },
  ];

  const oldAgeability = ageabilityOptions.find((option) => option.value === bottleFormik.values.ageability) ? '' : bottleFormik.values.ageability;


  return (
    <div className="block" id="c">
      <div className="Header">
        <h3 className="common-sub-heading">3. Notes</h3>
      </div>
      <div className="descriptList">
        <RangeSlider
          type={WineStructureEnum.WINE_RARITY}
          label="Rarity (from 1 to 10)"
          min={1}
          max={10}
          onChangeSlider={onChangeSlider}
          value={bottleFormik.values.rarity}
          placeholder="From 1 to 10"
          error={bottleFormik?.errors[WineStructureEnum.WINE_RARITY] as string}
        />
      </div>
      <div className="descriptList">
        <RangeSlider
          type={WineStructureEnum.WINE_STRENGTH}
          label="Strength (from 1 to 10)"
          min={1}
          max={10}
          onChangeSlider={onChangeSlider}
          value={bottleFormik.values.strength}
          placeholder="From 1 to 10"
          error={bottleFormik?.errors[WineStructureEnum.WINE_STRENGTH] as string}
        />
      </div>
      <div className="descriptList">
        <RangeSlider
          type={WineStructureEnum.WINE_TANNINS}
          label="Tannins (from 1 to 10)"
          min={1}
          max={10}
          onChangeSlider={onChangeSlider}
          value={bottleFormik.values.tannins}
          placeholder="From 1 to 10"
          error={bottleFormik?.errors[WineStructureEnum.WINE_TANNINS] as string}
        />
      </div>
      <div className="descriptList">
        <RangeSlider
          type={WineStructureEnum.WINE_TEXTURE}
          label="Texture (from 1 to 10)"
          min={1}
          max={10}
          onChangeSlider={onChangeSlider}
          value={bottleFormik.values.texture}
          placeholder="From 1 to 10"
          error={bottleFormik?.errors[WineStructureEnum.WINE_TEXTURE] as string}
        />
      </div>
      <div className="descriptList">
        <div className='cus_drop inputField__wrap'>
          <label className='inputField__label'>
            Ageability {oldAgeability ? `(Old: ${oldAgeability})` : ''}
          </label>
          <select
            id='ageability'
            className={bottleFormik.values.ageability ? '' : 'empty'}
            value={bottleFormik.values.ageability}
            onChange={(e) => onChangeSlider(e.target.value as unknown as number, WineStructureEnum.WINE_AGEABILITY)}
          >
            <option value='' disabled>
              Ageability Options
            </option>
            {ageabilityOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {bottleFormik.touched.ageability && (
            <p
              style={{
                color: "red",
                fontSize: "13px",
                marginRight: "auto",
                marginLeft: "0",
              }}
            >
              {/* @ts-ignore*/}
              {bottleFormik.errors.ageability}
            </p>
          )}
        </div>
      </div>
      {/* <div className="descriptList">
        <RangeSlider
          isRequired
          type={WineStructureEnum.WINE_AGEABILITY}
          label='Ageability (possible range: "5-60")'
          min={5}
          max={60}
          onChangeSlider={(value, type) => onChangeSlider((value as unknown as number[]).join('-') as unknown as number, type)}
          value={bottleFormik.values.ageability ? bottleFormik.values.ageability?.split('-') : null}
          useSlider
          range
          error={bottleFormik?.errors[WineStructureEnum.WINE_AGEABILITY] as string}
        />
      </div> */}
      <div className="descriptList">
        <TextAreaField
          label="Description of the bottle"
          isRequired={false}
          placeholder="Ex. Some information about this section"
          showLimit={`${bottleFormik.values.description?.length}`}
          maxLength={Infinity}
          rows="4"
          id="description"
          onChange={(event: any) => {
            bottleFormik.setFieldValue("description", event.target.value);
          }}
          value={bottleFormik.values.description}
          // TextAreaField is not using types yet, added mocked fields
          validate={undefined}
          greyText={undefined}
          name={undefined}
          disabled={undefined}
          className={undefined}
        />
      </div>
      {bottleFormik.touched.description && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginRight: "auto",
            marginLeft: "0",
          }}
        >
          {/* @ts-ignore*/}
          {bottleFormik.errors.description}
        </p>
      )}

      <div className="descriptList">
        <TextAreaField
          label="Our review"
          isRequired={false}
          placeholder="Ex. Some information about this section"
          showLimit={`${bottleFormik.values.review?.length}`}
          maxLength={Infinity}
          rows="4"
          id="review"
          onChange={(event: any) => {
            bottleFormik.setFieldValue("review", event.target.value);
          }}
          value={bottleFormik.values.review}
          // TextAreaField is not using types yet, added mocked fields
          validate={undefined}
          greyText={undefined}
          name={undefined}
          disabled={undefined}
          className={undefined}
        />
      </div>
      {bottleFormik.touched.review && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginRight: "auto",
            marginLeft: "0",
          }}
        >
          {/* @ts-ignore*/}
          {bottleFormik.errors.review}
        </p>
      )}


      <div className="descriptList">
        <TextAreaField
          label="About the winery"
          isRequired={false}
          placeholder="Ex. Some information about this section"
          showLimit={`${bottleFormik.values.about_winery?.length}`}
          maxLength={Infinity}
          rows="4"
          id="about_winery"
          onChange={(event: any) => {
            bottleFormik.setFieldValue("about_winery", event.target.value);
          }}
          value={bottleFormik.values.about_winery}
          // TextAreaField is not using types yet, added mocked fields
          validate={undefined}
          greyText={undefined}
          name={undefined}
          disabled={undefined}
          className={undefined}
        />
      </div>
      {bottleFormik.touched.about_winery && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginRight: "auto",
            marginLeft: "0",
          }}
        >
          {/* @ts-ignore*/}
          {bottleFormik.errors.about_winery}
        </p>
      )}


      <div className="descriptList">
        <TextAreaField
          label="Terroir and Aging Method"
          isRequired={false}
          placeholder="Ex. Some information about this section"
          showLimit={`${bottleFormik.values.terroir_and_aging_method?.length}`}
          maxLength={Infinity}
          rows="4"
          id="terroir_and_aging_method"
          onChange={(event: any) => {
            bottleFormik.setFieldValue("terroir_and_aging_method", event.target.value);
          }}
          value={bottleFormik.values.terroir_and_aging_method}
          // TextAreaField is not using types yet, added mocked fields
          validate={undefined}
          greyText={undefined}
          name={undefined}
          disabled={undefined}
          className={undefined}
        />
      </div>
      {bottleFormik.touched.terroir_and_aging_method && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginRight: "auto",
            marginLeft: "0",
          }}
        >
          {/* @ts-ignore*/}
          {bottleFormik.errors.terroir_and_aging_method}
        </p>
      )}

      <div className="descriptList">
        <TextAreaField
          label="Tasting notes"
          isRequired={false}
          placeholder="Ex. Some information about this section"
          showLimit={`${bottleFormik.values.tasting_notes?.length}`}
          maxLength={Infinity}
          rows="4"
          id="tasting_notes"
          onChange={(event: any) => {
            bottleFormik.setFieldValue("tasting_notes", event.target.value);
          }}
          value={bottleFormik.values.tasting_notes}
          // TextAreaField is not using types yet, added mocked fields
          validate={undefined}
          greyText={undefined}
          name={undefined}
          disabled={undefined}
          className={undefined}
        />
      </div>
      {bottleFormik.touched.tasting_notes && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginRight: "auto",
            marginLeft: "0",
          }}
        >
          {/* @ts-ignore*/}
          {bottleFormik.errors.tasting_notes}
        </p>
      )}

      <div className="descriptList">
        <TextAreaField
          label="Pairing"
          isRequired={false}
          placeholder="Ex. Some information about this section"
          showLimit={`${bottleFormik.values.pairing?.length}`}
          maxLength={Infinity}
          rows="4"
          id="pairing"
          onChange={(event: any) => {
            bottleFormik.setFieldValue("pairing", event.target.value);
          }}
          value={bottleFormik.values.pairing}
          // TextAreaField is not using types yet, added mocked fields
          validate={undefined}
          greyText={undefined}
          name={undefined}
          disabled={undefined}
          className={undefined}
        />
      </div>
      {bottleFormik.touched.pairing && (
        <p
          style={{
            color: "red",
            fontSize: "13px",
            marginRight: "auto",
            marginLeft: "0",
          }}
        >
          {/* @ts-ignore*/}
          {bottleFormik.errors.pairing}
        </p>
      )}


      <div className="blocks-between">
        <ButtonCustom
          title="Cancel"
          customClass="cancelBtn"
          onClick={onCancel}
        />
        <ButtonCustom
          style={{ margin: 0 }}
          type="submit"
          title="Save"
          customClass="saveBtn"
        />
      </div>
    </div>
  );
}
