import { DropCollectionService } from 'Services/dropCollections.service'

const updateOrder = async (prev, cur, collections = [], updateCur = false) => {
  const initialOrder = prev.order
  const destinationOrder = Math.min(cur.order, collections.length)
  if (initialOrder === destinationOrder) return

  const range = [initialOrder, destinationOrder].sort((a, b) => a - b)
  const collectionsToUpdate = []
  for (let index = Math.max(range[0] - 1, 0); index <= range[1] - 1; index++) {
    const element = collections[index]
    const direction = destinationOrder > initialOrder ? -1 : 1
    if (element.id && element.id !== cur.id) {
      collectionsToUpdate.push({ ...element, order: element.order + direction })
    }
  }
  if (updateCur) {
    collectionsToUpdate.push({ ...cur, order: destinationOrder })
  }
  await Promise.all(
    collectionsToUpdate.map((el) =>
      DropCollectionService.updateDropCollection(el, {
        Authorization: localStorage.getItem('authorizations'),
      }),
    ),
  )
  return collectionsToUpdate
}

export default updateOrder
