import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { updateDiscountCode } from 'redux/_actions/discountCodes.action'
import { toast } from '../../../../../components/Toast/Toast'

import '../../../Label/labelTableComponent/LabelTableComponent.scss'
import {
  DeleteSolidIcon,
  EditProfileIcon,
  IconDiamond,
} from '../../../../../Assets/image/svgimages/sVGImg'

const DropdownMenu = ({ data, getAllDiscounts }) => {
  const dispatch = useDispatch()

  const enableDisableDiscount = (values) => {
    const successFn = (res) => {
      toast.success(res.data?.message)
      getAllDiscounts()
    }
    const errorFn = (res) => {
      toast.error(res?.data?.message || 'Failed')
    }

    dispatch(
      updateDiscountCode(
        { discountCode: { id: values.id, is_valid: !values.is_valid } },
        successFn,
        errorFn,
      ),
    )
  }
  return (
    <div className='labelDropdownMenu'>
      <Link
        to={{
          pathname: `/panel/discount-codes/edit/${data.id}`,
          state: data,
        }}
      >
        <EditProfileIcon />
        Edit Discount
      </Link>

      <Link to='#' onClick={() => enableDisableDiscount(data)}>
        {data.is_valid ? (
          <>
            <DeleteSolidIcon />
            Disable Discount
          </>
        ) : (
          <>
            <IconDiamond />
            Enable Discount
          </>
        )}
      </Link>
    </div>
  )
}

DropdownMenu.defaultProps = {}
DropdownMenu.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    is_valid: PropTypes.number,
  }).isRequired,
  getAllDiscounts: PropTypes.func.isRequired,
}

export default DropdownMenu
