import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import './MarketplaceTable.scss'

import MarketplaceTableComponent from "./MarketplaceTableComponent/MarketplaceTableComponent";

const MarketplaceTable = ({ getAllMarketplaceNft }) => {
  const { marketplaceNfts, totalMarketplaceNfts } = useSelector((state) => state?.marketplace);

  return (
    <div className="marketplace-table">
      <Table className="custom_table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">NFT Name</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">NFT ID</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Contract address</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">From wallet</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">To wallet</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Listing status</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Purchase price</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Sale price</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Sale type</Table.HeaderCell>
            <Table.HeaderCell
              colSpan="1"
              className="TableEditBtn"
            ></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {marketplaceNfts?.map((item) => (
            <MarketplaceTableComponent
              key={item.id}
              item={item}
              getAllMarketplaceNft={getAllMarketplaceNft}
            />
          ))}
          {totalMarketplaceNfts === 0 && (
            <Table.Row>
              <Table.Cell collapsing colSpan="20" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  No Record Found.
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
};

export default MarketplaceTable;
