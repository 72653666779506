import { useState } from 'react'
import moment from 'moment/moment'
import { Popup, Table } from 'semantic-ui-react'
import './LabelTableComponent.scss'

import { BOTTLE_PUBLISHED, BOTTLE_RECIEVED, BOTTLE_SENT } from '../../../../utils/constants'
import { NotificationIcon } from '../../../../Assets/image/svgimages/sVGImg'
import LabelDropdownMenu from './labelDropdownMenu/LabelDropdownMenu'

const LabelTableComponent = ({ item, getVintageText, onDeleteBottle }) => {
  const [offset, setOffset] = useState(0)

  const getBottleStatus = (status) => {
    switch (status) {
      case BOTTLE_SENT: {
        return 'Sent'
      }
      case BOTTLE_RECIEVED: {
        return 'Recieved'
      }
      case BOTTLE_PUBLISHED: {
        return 'Published'
      }
      default:
        return 'Unknown'
    }
  }

  const deleteUserBottle = (id) => {
    onDeleteBottle(id)
  }

  const onMenuClick = (e) => {
    const element = e.target
    setOffset(element.offsetLeft)
  }

  return (
    <>
      <Popup
        className='labelTableModal'
        offset={offset}
        on={['click']}
        position='bottom left'
        trigger={
          <Table.Row onClick={onMenuClick}>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {item.bottle_id ? item.bottle_id : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='1' textAlign='left'>
              {item.winery_name ? item.winery_name : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {item.brand_name ? item.brand_name : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {item.country ? item.country : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {item.region_obj.region_name ? item.region_obj.region_name : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='1' textAlign='left'>
              {item.size ? item.size : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {getVintageText(item.vintage)}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {item.color_name ? item.color_name : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              <div className='AlertNoti'>
                <NotificationIcon />
              </div>
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {getBottleStatus(item.status)}
            </Table.Cell>
            <Table.Cell collapsing colSpan='1' className='TableEditBtn'></Table.Cell>
          </Table.Row>
        }
      >
        <LabelDropdownMenu data={item} deleteuserBottle={deleteUserBottle} />
      </Popup>
    </>
  )
}

export default LabelTableComponent
