import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { Col, Row } from 'antd'
import countryList from 'react-select-country-list'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { ReactComponent as IconBack } from '../../../../Assets/image/svgimages/icon_back.svg'
import InputField from '../../../../components/common/InputField/InputField'
import wineimg2 from '../../../../Assets/image/wineimg2.png'
import dummyImg from '../../../../Assets/image/dummyImg.jpg'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import './EditWineft.scss'
import RadioButton from '../../../../components/common/RadioButton/RadioButton'
import InputCalendar from '../../../../components/common/InputField/InputCalendar'
import Info_Icon from '../../../../Assets/image/Info_Icon.svg'
import { IconDiamond } from '../../../../Assets/image/svgimages/sVGImg'
import WineftLogs from './WineftLogs'
import WineftModal from '../WineftModal/WineftModal'
import CreateNftCard from 'components/common/createNftCard/CreateNftCard'

const EditWineft_Second = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const options = useMemo(() => countryList().getData(), [])
  const [country, setCountry] = useState('')

  const [wineftopen, setWineftopen] = useState(false)

  const [showBottom, setShowBottom] = useState(true)
  const [isActive, setActive] = useState(false)

  const handleCountryChange = (e) => {
    setCountry(e.target.value)
  }

  const ToggleClass = () => {
    setActive(!isActive)
  }
  return (
    <>
      <MainLayout>
        <div className='winemakers-main crtbtl EditWineft'>
          <div className={isActive ? 'brdr left' : 'left'}>
            <div className='logs-controller'>
              <div className='top'>
                <h3 className='common-sub-heading'>Logs</h3>

                <button
                  className={showBottom ? 'active logs-toggler' : 'logs-toggler'}
                  type='button'
                  onClick={() => {
                    setShowBottom(!showBottom)
                    ToggleClass()
                  }}
                ></button>
              </div>
              {showBottom && (
                <WineftLogs
                  datetime='19 July, 09:25'
                  usingTitle='By Admin'
                  mainTitle='Is NFT count visible to user?'
                  oldValues='Bottleold'
                  newValues='BottleNew'
                />
              )}
            </div>
          </div>

          <div className='right'>
            <Row className='rightrow'>
              <Col xl={12} className='crtbtl-left'>
                <div className='BtnPreview'>
                  <Link to='/panel/bottle-list' className='back-btn IconBack m-0'>
                    <IconBack /> Back to winemaker list
                  </Link>
                  <button>
                    <IconDiamond />
                    Preview WiNeFT
                  </button>
                </div>
                <h1 className='common-heading HeadingSpace'>Edit WiNeFT</h1>
                <div className='BtnList'>
                  <button className='ListedValue'>Listed</button>
                </div>
                <div className='block NftCreate' id='a'>
                  {/* <div className="Header">
                    <h3 className="common-sub-heading">1: Type of NFT</h3>
                  </div> */}
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Name of wine bottle<sup>*</sup>
                    </label>
                    <select value={country} onChange={(e) => handleCountryChange(e)} disabled>
                      {options.map((option) => (
                        <option key={`c-${option.label}`} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Vintage<sup>*</sup>
                    </label>
                    <select value={country} onChange={(e) => handleCountryChange(e)}>
                      {options.map((option) => (
                        <option key={`ch-${option.label}`} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <InputField
                    isRequired
                    name='Bottles case'
                    label='Number of bottles in a case'
                    placeholder='Enter number 1 - 20'
                  />
                  <div className='sectionInfo'>
                    <InputField
                      isRequired
                      name='WiNeFTs minted'
                      label='Number of WiNeFTs to be minted'
                      placeholder='Ex. 10'
                    />
                    <Popup
                      className='trigerEdit'
                      trigger={
                        <div className='InfoToggle'>
                          <img src={Info_Icon} alt='Info_Icon' />
                        </div>
                      }
                      header='The name of the wine consists of:'
                      content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                      on={['hover', 'click']}
                      position='bottom right'
                    />
                  </div>
                  <div className='RadioBtn'>
                    <label className='drop_label radiolabel'>Is NFT count visible to user?</label>
                    <div className='RadioInfo'>
                      <RadioButton label='Yes' className='btnRadio' />
                      <RadioButton label='No' className='btnRadio' />
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        header='The name of the wine consists of:'
                        content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                        on={['hover', 'click']}
                        position='bottom right'
                      />
                    </div>
                  </div>
                  <div className='RadioBtn'>
                    <label className='drop_label radiolabel'>
                      Will the Drop event be specifically meant ONLY for Winery’s exclusive access
                      list?
                    </label>
                    <div className='RadioInfo'>
                      <RadioButton label='Yes' className='btnRadio' />
                      <RadioButton label='No' className='btnRadio' />
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        header='The name of the wine consists of:'
                        content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                        on={['hover', 'click']}
                        position='bottom right'
                      />
                    </div>
                  </div>
                  <InputField name='Reserv price (USD)' label='Price (USD)' placeholder='Ex. 300' />
                  <div className='sectionInfo'>
                    <InputField
                      name='NFTs one Wallet'
                      label='Max. number of NFTs one Wallet can buy ?'
                      placeholder='Ex. 1'
                    />
                    <Popup
                      className='trigerEdit'
                      trigger={
                        <div className='InfoToggle'>
                          <img src={Info_Icon} alt='Info_Icon' />
                        </div>
                      }
                      header='The name of the wine consists of:'
                      content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                      on={['hover', 'click']}
                      position='bottom right'
                    />
                  </div>
                  <div className='cus_drop perameter_Inputs'>
                    <label className='drop_label'>Time Zone</label>
                    <select value={country} onChange={(e) => handleCountryChange(e)}>
                      {options.map((option) => (
                        <option key={`tz-${option.label}`} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='sectionInfo'>
                    <div className='perameter_Inputs'>
                      <InputCalendar
                        label='Date the physical wine bottle will be produced and available to be sold on the platform, i.e., bottle release date'
                        isRequired
                        placeholder='Ex. 13 May 2022'
                      />
                    </div>
                    <Popup
                      className='trigerEdit'
                      trigger={
                        <div className='InfoToggle'>
                          <img src={Info_Icon} alt='Info_Icon' />
                        </div>
                      }
                      header='The name of the wine consists of:'
                      content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                      on={['hover', 'click']}
                      position='bottom right'
                    />
                  </div>
                  <div className='sectionInfo'>
                    <div className='cus_drop perameter_Inputs'>
                      <label className='drop_label'>
                        Winery specific geographical restrictions, for shipping only
                      </label>
                      <select value={country} onChange={(e) => handleCountryChange(e)}>
                        {options.map((option) => (
                          <option key={`shp-${option.label}`} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Popup
                      className='trigerEdit'
                      trigger={
                        <div className='InfoToggle'>
                          <img src={Info_Icon} alt='Info_Icon' />
                        </div>
                      }
                      header='The name of the wine consists of:'
                      content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                      on={['hover', 'click']}
                      position='bottom right'
                    />
                  </div>
                  <div className='sectionInfo'>
                    <div className='InputPresent'>
                      <InputField
                        className='InfoInput'
                        name='Royalty Sale'
                        label='Royalty payments for Secondary sale, on profits from sale'
                        placeholder='Ex. 20% as like default'
                      />
                      <label className='Sublabel'>%</label>
                    </div>
                    <Popup
                      className='trigerEdit'
                      trigger={
                        <div className='InfoToggle'>
                          <img src={Info_Icon} alt='Info_Icon' />
                        </div>
                      }
                      header='The name of the wine consists of:'
                      content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                      on={['hover', 'click']}
                      position='bottom right'
                    />
                  </div>
                  <div className='sectionInfo'>
                    <div className='perameter_Inputs'>
                      <InputCalendar
                        label='Date of Start Drop Event'
                        isRequired
                        placeholder='Ex. 13 May 2022'
                      />
                    </div>
                    <Popup
                      className='trigerEdit'
                      trigger={
                        <div className='InfoToggle'>
                          <img src={Info_Icon} alt='Info_Icon' />
                        </div>
                      }
                      header='The name of the wine consists of:'
                      content='Producer (Winery name) + Region + appellatioN - Generate autometecly [Not on MVP]'
                      on={['hover', 'click']}
                      position='bottom right'
                    />
                  </div>
                  <div className='perameter_Inputs'>
                    <InputCalendar
                      label='Date and time finish drop event'
                      isRequired
                      placeholder='Ex. 13 May 2022'
                    />
                  </div>
                </div>
                <Row>
                  <Col lg={16}>
                    <div className='BtnListing'>
                      <ButtonCustom
                        type='submit'
                        title='Cancel'
                        customClass='borderBtn addNew_Btn'
                      />
                      <ButtonCustom type='submit' title='Save' customClass='saveBtn2' />
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className=''>
                      <ButtonCustom
                        type='submit'
                        onClick={() => setWineftopen(true)}
                        title='Send to approve'
                        customClass='SendBtn'
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} className='crtbtl-right'>
                <div className='inner'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Token card Preview</h3>
                  </div>
                  <CreateNftCard
                    bgColor='#e0ddff'
                    wineBottleImage={wineimg2}
                    wineTitle='CHATEAU DE BEAUCASTEL COTES DU RHONE COUDOULET ROUGE 2019'
                    winerTitle='Chateau Lafite-Rothschild, EU'
                    wineMakerImage={dummyImg}
                    wineMaker='IvanK'
                    size='750ml'
                    // owner="IvanK"
                  />
                </div>
              </Col>
            </Row>

            <WineftModal
              onClose={() => setWineftopen(false)}
              onWineftopen={() => setWineftopen(true)}
              wineftopen={wineftopen}
            />
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export default EditWineft_Second
