import React, { useState } from "react";
import InputField from "../../common/InputField/InputField";
import RadioButton from "../../common/RadioButton/RadioButton";
import ModalStyle from "../ModalStyle";
import "./DeclineModal.scss";
const reason1 = "We did not find you in the list of winemakers";
const reason2 = "We can not identify you as a winemaker";
const reason3 = "We need more information,we will contact you";

const DeclineModal = ({
  open,
  onClose,
  message,
  setMessage,
  onSubmit,
  selectedUser,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const getRadioValue = (value) => {
    setSelectedValue(value);
    if (value !== "Others") {
      setMessage(value);
    } else {
      setMessage("");
    }
  };
  return (
    <ModalStyle
      onClose={onClose}
      open={open}
      cancelBtn
      actionText="Decline"
      title="Are you sure, you want to decline?"
      className="decline__modal"
      handleOnSubmit={onSubmit}
    >
      <div className="decline__modal--content">
        <div className="decline__modal--head">
          <h3>{selectedUser.name ? selectedUser.name : "N/A"}</h3>
          <p>Id: {selectedUser.unique_id ? selectedUser.unique_id : "---"}</p>
        </div>
        <div className="decline__modal--reason">
          <h2>Choose the reason:</h2>
          <ul className="decline__modal--reasonitem">
            <li>
              <RadioButton
                className="SpacingRadio"
                name="radioGroup"
                label={reason1}
                value={reason1}
                checked={selectedValue === reason1}
                onChange={(e) => getRadioValue(e)}
              />
            </li>
            <li>
              <RadioButton
                className="SpacingRadio"
                name="radioGroup"
                label={reason2}
                value={reason2}
                checked={selectedValue === reason2}
                onChange={(e) => getRadioValue(e)}
              />
            </li>
            <li>
              <RadioButton
                className="SpacingRadio"
                name="radioGroup"
                label={reason3}
                value={reason3}
                checked={selectedValue === reason3}
                onChange={(e) => getRadioValue(e)}
              />
            </li>
            <li>
              <RadioButton
                className="SpacingRadio"
                name="radioGroup"
                label="Others"
                value="Others"
                checked={selectedValue === "Others"}
                onChange={(e) => getRadioValue(e)}
              />
            </li>
          </ul>

          {selectedValue === "Others" && (
            <InputField
              label="Enter your reason"
              placeholder="Ex. Some information about this section"
              type="textarea"
              showLimit={`${message.length} / 300`}
              greyText
              value={message}
              maxLength={300}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          )}
        </div>
      </div>
    </ModalStyle>
  );
};

export default DeclineModal;
