import React from 'react'
import { DeleteIcon, DocIcon } from '../../Assets/image/svgimages/sVGImg'
import './Message.scss'

const Message = (props) => {
  const {
    Icon_profile,
    Containt_Text,
    date_Icon,
    customClass,
    setDeletedItem,
    item,
    onMarkReadSingle,
    type,
  } = props

  return (
    <>
      <div className='Spacing'>
        <div className={`messages ${customClass}`}>
          <div className='mail_Icon'>{Icon_profile}</div>
          <div className='containt'>
            <p>{Containt_Text}</p>
          </div>
          <div className='btnscus'>
            <h6>{date_Icon}</h6>
            {type === 'unread' && (
              <button
                className='read_btn'
                title='Mark as read'
                onClick={() => onMarkReadSingle(item?.id, type === 'unread' ? 0 : 1)}
              >
                <DocIcon onClick={() => onMarkReadSingle(item?.id, type === 'unread' ? 0 : 1)} />
              </button>
            )}
            <button
              className='dlt_btn'
              onClick={() => setDeletedItem(item?.id, type === 'unread' ? 0 : 1)}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Message
