import React from 'react'
import './DropdownField.scss'

const DropdownField = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  options,
  className,
  isRequired,
  error,
  disabled,
}) => {
  return (
    <div className={`dropdownStyle drop_style mb30 ${className || ''}`}>
      <CustomDropdown
        label={label}
        isRequired={isRequired}
        placeholder={placeholder}
        name={name}
        // className={"" ""}
        // className={`dropdownStyle__dropdown ${className}`}
        options={options}
        value={value}
        // selected={selected}
        onChange={onChange}
        disabled={disabled}
      />
      {error && <p className='fieldError'>{error}</p>}
    </div>
  )
}

const CustomDropdown = ({
  label,
  name,
  value,
  options,
  onChange,
  placeholder,
  isRequired,
  disabled,
}) => {
  return (
    <div className='cus_drop'>
      <label className='drop_label'>
        {label}
        {isRequired && <sup>*</sup>}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options.map((option) => (
          <option value={option.value || option.label} key={option.value || option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DropdownField
