import { Popup } from "semantic-ui-react";
import { FormikProps } from "formik/dist/types";

import InputField from "../../../../../components/common/InputField/InputField";
import Info_Icon from "../../../../../Assets/image/Info_Icon.svg";

interface WineBottleNameProps {
  bottleFormik: FormikProps<any> // to do - add bottleFormik types instead of any
}

export default function WineBottleName(props: WineBottleNameProps) {
  const { bottleFormik } = props;

  return (
    <>
      <div className='Header sectionInfo vertical-top'>
        <h3 className='common-heading-3'>Name of Wine Bottle</h3>
        <Popup
          className='trigerEdit'
          trigger={
            <div className='InfoToggle bottles'>
              <img src={Info_Icon} alt='Info_Icon' />
            </div>
          }
          header='The name of the wine consists of:'
          content='Producer (Winery name) + Region + appellation - Generated automitcally'
          on={['hover', 'click']}
          position='bottom right'
        />
      </div>

      <div className='sectionInfo'>
        <InputField
          name='brand of wine'
          label='Name of Wine Bottle'
          isRequired
          placeholder='Ex. Name of the wine: Producer (Winery name) + Region + Appellation'
          id='brand_name'
          onChange={(event: any) => {
            bottleFormik.setFieldValue('brand_name', event.target.value);
          }}
          value={bottleFormik?.values.brand_name}
          formik={bottleFormik}
          // InputField is not using types yet, added mocked fields
          validate={undefined}
          type={undefined}
          showLimit={undefined}
          greyText={undefined}
          disabled={undefined}
          maxLength={undefined}
          className={undefined}
          readOnly={undefined}
          classNameInput={undefined}
          max={undefined}
        />
      </div>
    </>
  );
}
