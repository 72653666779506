import { actionTypes } from '../_actions/marketplace.action'

const initialState = {
  marketplaceNfts: [],
  totalMarketplaceNfts: 0,
}

const marketplace = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.FETCH_MARKETPLACE_NFTS: {
      return {
        ...state,
        marketplaceNfts: payload.data,
        totalMarketplaceNfts: payload.count,
      }
    }

    case actionTypes.UPDATE_MARKETPLACE_NFT: {
      const { nft_id, sale_status, sale_price } = payload.data

      const newMarketplaceNfts = [...state.marketplaceNfts]
      const index = newMarketplaceNfts.findIndex((w) => w.nft_id === nft_id)

      newMarketplaceNfts[index] = {
        ...newMarketplaceNfts[index],
        sale_status,
        sale_price,
      }

      return {
        ...state,
        marketplaceNfts: newMarketplaceNfts,
      }
    }

    default:
      return state
  }
}

export default marketplace
