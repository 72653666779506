import Types from '../_actions/_types'

const initialState = {
  orders: [],
  total: 0,
}

const shippingOrders = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case Types.SAVE_SHIPPING_ORDERS:
      return { ...state, orders: payload.data.rows, total: payload.data.count }

    default:
      return state
  }
}

export default shippingOrders
