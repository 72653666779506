import React from 'react'
import { Row, Col } from 'antd'
import { sliceDateMonthTo3Symbol, walletIdHide } from '../../../../utils/helper'
import './PalletItems.scss'

const PalletItem = ({
  wine_name,
  nft_id,
  status,
  first_name,
  last_name,
  winery_id,
  createdAt,
  burned_date,
  owner,
  requestedBurn,
  palletId,
  typeBox,
  physicalBoxId,
  trackingId,
  dateArrival,
  trackingLink,
  estDeliveryDate,
  actDelDay,
  actShippDay,
  estShippedDay,
  caseType,
  deliveryCompany,
}) => {
  return (
    <div className='detail-tab_itemDetails'>
      <h1 className='detail-tab_title'>
        NFT ID:<span>{nft_id}</span>
      </h1>
      <p className='detail-tab_wineName'>{wine_name}</p>
      <div className='detail-tab_statusOrder'>{status}</div>
      <p className='detail-tab_ownerTitle'>Owner Name: </p>
      <p className='detail-tab_ownerName'>
        {first_name} {last_name}
      </p>
      <div className='detail-tab_tabs'>
        <Row className='tabsOver_wrapp'>
          {winery_id && (
            <Col span={9} className='tabsOver'>
              <p className='tabsOver_title'>Winery Name:</p>
              <p className='tabsOver_details'>{winery_id}</p>
            </Col>
          )}
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Drop Date:</p>
            <p className='tabsOver_details'>{sliceDateMonthTo3Symbol(createdAt)}</p>
          </Col>
          <Col span={4} className='tabsOver'>
            <p className='tabsOver_title'>Date of Arrival:</p>
            <p className='tabsOver_details'>{sliceDateMonthTo3Symbol(dateArrival)}</p>
          </Col>
        </Row>
        <Row className='tabsOver_wrapp'>
          {owner && (
            <Col span={9} className='tabsOver'>
              <p className='tabsOver_title'>Owner:</p>
              <p className='tabsOver_details'>@{walletIdHide(owner)}</p>
            </Col>
          )}
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Requested to burn:</p>
            <p className='tabsOver_details'>{requestedBurn}</p>
          </Col>
          <Col span={4} className='tabsOver'>
            <p className='tabsOver_title'>Burn Date:</p>
            <p className='tabsOver_details'>{burned_date}</p>
          </Col>
        </Row>
        <Row className='tabsOver_wrapp'>
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Lot ID:</p>
            <p className='tabsOver_details'>{palletId}</p>
          </Col>
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Case:</p>
            <p className='tabsOver_details'>{caseType}</p>
          </Col>
          <Col span={4} className='tabsOver'>
            <p className='tabsOver_title'>Physical ID:</p>
            <p className='tabsOver_details'>{physicalBoxId}</p>
          </Col>
        </Row>
        <Row className='tabsOver_wrapp'>
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Tracking ID</p>
            <p className='tabsOver_details'>{trackingId ? trackingId : '---'}</p>
          </Col>
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Tracking Link</p>
            {trackingLink && (
              <a
                href={trackingLink}
                target='_blank'
                rel='noreferrer'
                className='tabsOver_details_link'
              >
                {trackingId}
              </a>
            )}
            {!trackingLink && <p className='tabsOver_details'>---</p>}
          </Col>
          <Col span={4} className='tabsOver'>
            <p className='tabsOver_title'>Carrier</p>
            <p className='tabsOver_details'>{deliveryCompany ? deliveryCompany : '-'}</p>
          </Col>
        </Row>
        <Row className='tabsOver_wrappLast'>
          <Col span={7} className='tabsOver'>
            <p className='tabsOver_title'>Est. delivery date</p>
            <p className='tabsOver_details'>{estDeliveryDate ? estDeliveryDate : '-'}</p>
          </Col>
          <Col span={6} className='tabsOver'>
            <p className='tabsOver_title'>Act. delivery date</p>
            <p className='tabsOver_details'>{actDelDay ? actDelDay : '-'}</p>
          </Col>
          <Col span={5} className='tabsOver'>
            <p className='tabsOver_title'>Act. shipped date</p>
            <p className='tabsOver_details'>{actShippDay ? actShippDay : '-'}</p>
          </Col>
          <Col span={5} className='tabsOver'>
            <p className='tabsOver_title'>Est. shipped date</p>
            <p className='tabsOver_details'>{estShippedDay ? estShippedDay : '-'}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PalletItem
