import React from "react";
import { TextArea } from "semantic-ui-react";
import "../InputField/InputField.scss";

const TextAreaField = ({
  isRequired,
  id,
  label,
  validate,
  placeholder,
  // type,
  showLimit,
  greyText,
  onChange,
  name,
  value,
  disabled,
  rows,
  className,
  maxLength,
}) => {
  return (
    <div className={`inputField__wrap textarea_wrap ${className}`} id={id}>
      <label className="inputField__label">
        {label}
        {isRequired && <sup>*</sup>}
      </label>
      <TextArea
        disabled={disabled}
        name={name}
        validate={validate}
        value={value}
        className="inputField__input"
        placeholder={placeholder}
        // type={type}
        onChange={onChange}
        rows={rows}
        maxLength={maxLength}
      />
      {showLimit && (
        <p
          className={`inputField__LimitMsg ${
            greyText && "inputField__LimitMsg--grey"
          }`}
        >
          {showLimit}
        </p>
      )}
    </div>
  );
};

export default TextAreaField;
