import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Tabs } from 'antd'
import { useHistory } from 'react-router'

import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { Plusicon } from '../../../../Assets/image/svgImg'

import { getAllDropCollections } from 'redux/_actions/dropCollection.action'
import Collections from './collections/Collections'
import { ButtonCustom } from 'components/button/ButtonCustom'
import { DropCollectionService } from 'Services/dropCollections.service'

const DropCollections = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const checkAndUpdateOrder = async (collections = []) => {
      const collectionsToUpdate = collections
        .map((el, i) => ({ ...el, order: i + 1 }))
        .filter((el, i) => el.order !== collections[i]?.order)
      if (collectionsToUpdate?.length) {
        await Promise.all(
          collectionsToUpdate.map((el) =>
            DropCollectionService.updateDropCollection(el, {
              Authorization: localStorage.getItem('authorizations'),
            }),
          ),
        )
        dispatch(getAllDropCollections())
      }
    }
    dispatch(getAllDropCollections(checkAndUpdateOrder))
  }, [dispatch])

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Drop Collections</h3>
      <Row justify='end'>
        <ButtonCustom
          onClick={() => history.push('/panel/drop-collections/create')}
          title='Add Drop Collection'
          leftIcon={<Plusicon />}
        />
      </Row>
      <Tabs
        className='exclusive__tabs'
        defaultActiveKey='1'
        items={[
          {
            tab: 'Drop Collections',
            key: 'Drop Collections',
            children: (
              <div className='detail-tab'>
                <Collections />
              </div>
            ),
          },
        ]}
      />
    </MainLayout>
  )
}

export default DropCollections
