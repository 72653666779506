import { actionTypes } from '../_actions/palletsNfts.action'

const initialState = {
  palletsNftsList: [],
  totalPalletsNfts: 0,
}

const palletsNfts = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_PALLETSNFTS:
      return { ...state, palletsNftsList: payload.data.list, totalPalletsNfts: payload.data.count }

    default:
      return state
  }
}

export default palletsNfts
