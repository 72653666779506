import React from 'react'
import './DetailsItem.scss'
import StatusButton from '../statusButton/StatusButton'

const DetailsItem = ({ wine_name, nft_id, status, ownerName }) => {
  return (
    <div className='detail-tab_itemDetails'>
      <h1 className='detail-tab_title'>
        NFT ID:<span>{nft_id}</span>
      </h1>
      {wine_name && <p className='detail-tab_wineName'>{wine_name}</p>}

      <div className='detail-tab_statusOrder'>
        <StatusButton status={status} />
      </div>
      <p className='detail-tab_ownerTitle'>Owner Name: </p>
      <p className='detail-tab_ownerName'>{ownerName}</p>
    </div>
  )
}
export default DetailsItem
