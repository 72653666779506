import { withRouter } from 'react-router'
import { Icon, Input, Dropdown, Pagination, Table } from 'semantic-ui-react'
import moment from 'moment'
import '../WinemakerDetails/WinemakerDetails.scss'
import { Link } from 'react-router-dom'

const option2 = [
  { key: '1', text: 'Last Updated', value: '0' },
  { key: '2', text: 'Last Week', value: '1' },
  { key: '3', text: 'Last Month', value: '2' },
]
const showOptions = [
  { key: '1', text: '10', value: '10' },
  { key: '2', text: '15', value: '15' },
  { key: '3', text: '20', value: '20' },
]

const WareTabs = ({
  setLimit,
  setSearchKey,
  selectedTab,
  offset,
  searchKey,
  status,
  setStatus,
  setOffset,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  limit,
  data,
  totalNft,
  setTriggerSearch,
  triggerSearch,
}) => {
  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage
    setOffset(offset)
  }
  return (
    <div className='detail-tab'>
      {selectedTab === 'STORAGE' && (
        <div className='StatusWarrap'>
          <div>
            <div className='filltersUser'>
              <div className='filltersBottom'>
                <div className='left_fill'>
                  <Dropdown
                    placeholder='Last Updated'
                    selection
                    options={option2}
                    onChange={(e, data) => {
                      if (data?.value === '0') {
                        setStatus('')
                      } else {
                        setStatus(data?.value)
                      }
                    }}
                  />

                  <Input
                    className='search_fldWare'
                    icon={<Icon name='search' inverted link />}
                    placeholder='Ex. search Iot id, Nft Name'
                    value={searchKey}
                    onChange={(e) => {
                      setSearchKey(e.target.value)
                      setOffset(1)
                    }}
                  />
                </div>
                <div className='rightfill'>
                  <div className='sorting'>
                    <span>Show</span>
                    <Dropdown
                      value={limit}
                      selection
                      options={showOptions}
                      className='autowidth'
                      onChange={(e, data) => {
                        setLimit(data.value)
                        setOffset(1)
                      }}
                    />
                  </div>
                  <div className='sorting'>
                    <span>Entries</span>
                    <Dropdown
                      placeholder={totalNft ? totalNft.toString() : '0'}
                      selection
                      disabled
                      options={showOptions}
                      className='autowidth'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='Table_Editing'>
              <Table className='custom_table'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='1'>Lot ID</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Date of Arrival</Table.HeaderCell>
                    <Table.HeaderCell colSpan='1'>Drop ID</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Nft Name</Table.HeaderCell>
                    <Table.HeaderCell colSpan='1'>Alc.%</Table.HeaderCell>
                    <Table.HeaderCell colSpan='1'>Owners</Table.HeaderCell>
                    <Table.HeaderCell colSpan='1'>NFT</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Package</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Case</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Case Description</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>In Storage</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Prepared</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Shipped</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>Request ID</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'> Receipt ID</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data?.map((itemWare) => (
                    <Link to={`/panel/Pallet/${itemWare.drop_id}`} key={itemWare.id}>
                      <Table.Row>
                        <Table.Cell collapsing colSpan='1' textAlign='left'>
                          {itemWare.id || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare.dateOfArrival
                            ? moment(itemWare.dateOfArrival).format('DD/MM/YYYY')
                            : '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='1' textAlign='left'>
                          {itemWare.drop_id || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare.nft_name || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='1' textAlign='left'>
                          {itemWare.abv || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='1' textAlign='left'>
                          {itemWare?.ownersCount || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='1' textAlign='left'>
                          {itemWare?.nftCount || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.package || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.case || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.case_description || '---'}
                        </Table.Cell>

                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.inStorageCount || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.preparedCount || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.shippedCount || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.inbound_request_id || '---'}
                        </Table.Cell>
                        <Table.Cell collapsing colSpan='2' textAlign='left'>
                          {itemWare?.inbound_receipt_id || '---'}
                        </Table.Cell>
                      </Table.Row>
                    </Link>
                  ))}
                  {!!data?.length ? (
                    <Table.Row>
                      <Table.Cell collapsing colSpan='20' textAlign='center'>
                        <div
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bolder',
                            fontSize: '15px',
                          }}
                        >
                          No Record Found.
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
              </Table>
            </div>
            <div className='table_pagi new'>
              <div className='sorting_page'>
                <span>Show On Page &nbsp;</span>
                <Dropdown
                  value={limit}
                  selection
                  options={showOptions}
                  className='autowidth SortingIcon'
                  onChange={(e, data) => {
                    setLimit(data.value)
                    setOffset(1)
                  }}
                />
              </div>
              {totalNft && totalNft >= limit && (
                <Pagination
                  className='ListPagi'
                  firstItem={false}
                  lastItem={false}
                  boundaryRange={1}
                  siblingRange={0}
                  defaultActivePage={1}
                  onPageChange={handlePageClick}
                  totalPages={Math.ceil(parseInt(totalNft) / limit) || 1}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default withRouter(WareTabs)
