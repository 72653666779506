import React from 'react'
import { Modal } from 'semantic-ui-react'
import { CloseIcon } from '../../Assets/image/svgimages/sVGImg'
import './ModalStyle.scss'

const ModalStyleLogs = ({
  onClose,
  onOpen,
  children,
  title,
  open,
  className,
  cancelBtn,
  actionText,
  onSubmit,
  handleOnSubmit,
  tab,
}) => {
  return (
    <Modal onOpen={onOpen} open={open} className={`modal__style ${className}`}>
      <Modal.Header>
        {title}
        <button className='close-btn' onClick={onClose}>
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  )
}

export default ModalStyleLogs
