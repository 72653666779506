import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout/MainLayout";
import { WinerMakerAction } from "../../../redux/_actions";
import UserList from "./List/UserList";
import useDebounce from "../../../hooks/useDebounce";
import "./WineryRequestStyle.scss";

const WineryRequest = () => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const debaounceWinerySearch = useDebounce(search, 500);

  const dispatch = useDispatch();
  const [artist, setArtist] = useState({ count: 10, rows: [] });
  const { getWineryRequestList, blockWiner } = WinerMakerAction;

  useEffect(() => {
    getWineryRequest();
  }, [page, limit, order, status, debaounceWinerySearch]);

  // get all winery request list from api
  const getWineryRequest = async () => {
    let dataFields = {
      limit: limit,
      offset: page,
      search: search,
      status: status,
      order: order,
    };

    dispatch(getWineryRequestList(dataFields)).then((res) => {
      setArtist(res.data);
    });
  };

  const blockUser = (data) => {
    dispatch(blockWiner(data)).then((_data) => {
      getWineryRequest();
    });
  };

  return (
    <>
      <MainLayout>
        <UserList
          artist={artist}
          setArtist={setArtist}
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          status={status}
          setStatus={setStatus}
          order={order}
          setOrder={setOrder}
          limit={limit}
          setLimit={setLimit}
          blockUser={blockUser}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(WineryRequest);
