import React from 'react'
import './Notes.scss'
import { DeleteIcon } from '../../../../Assets/image/svgimages/sVGImg'
import Message from '../../../../components/Message/Message'
import wineft_Icon from '../../../../Assets/image/wineft_Icon.png'
import moment from 'moment'
import { Pagination } from 'semantic-ui-react'
import { NOTIFICATION_LIMT } from '../../../../utils/constants'

const Read = (props) => {
  let { data, setDeletedItem, onCallDeleteAll, onMarkReadSingle, setOffset, totalCount } = props

  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage - 1
    setOffset(offset)
  }
  return (
    <>
      <div className='Unread'>
        {data.length > 0 && (
          <div className='btnDeleteRead'>
            <button className='dlt_btn' onClick={() => onCallDeleteAll(1)}>
              <DeleteIcon /> Delete all
            </button>
          </div>
        )}
        {data.map((item) => (
          <Message
            key={`r-${item.id}`}
            Icon_profile={<img src={wineft_Icon} alt='wineft_Icon' />}
            Containt_Text={item?.content?.msg}
            date_Icon={moment(item?.createdAt).format('DD-MM-YYYY ')}
            customClass='Mailunread'
            setDeletedItem={setDeletedItem}
            onMarkReadSingle={onMarkReadSingle}
            item={item}
            type='read'
          />
        ))}
        {data.length === 0 && <p>No notification found!</p>}
      </div>
      {totalCount > 10 && (
        <div className='MessagePagi'>
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            onPageChange={handlePageClick}
            defaultActivePage={1}
            totalPages={Math.ceil(parseInt(totalCount) / NOTIFICATION_LIMT)}
          />
        </div>
      )}
    </>
  )
}

export default Read
