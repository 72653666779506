import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { REACT_APP_API_HOST_CORE } = Environments

const getPallets = (body) => {
  return fetch('post', `${REACT_APP_API_HOST_CORE}/pallets/api/v1/get/pallets`, body)
}
const getShippingOrders = (body) => {
  return fetch('post', `${REACT_APP_API_HOST_CORE}/orders/api/v1/get/shipping-orders`, body, {
    Authorization: localStorage.getItem('authorizations'),
  })
}
const getOrders = (type) => {
  return fetch(
    'get',
    `${REACT_APP_API_HOST_CORE}/orders/api/v1/orders`,
    {},
    { Authorization: localStorage.getItem('authorizations') },
  )
}
const getOrdersNftId = (id) => {
  return fetch(
    'get',
    `${REACT_APP_API_HOST_CORE}/orders/api/v1/orders/${id}`,
    {
      id: id,
    },
    { Authorization: localStorage.getItem('authorizations') },
  )
}
const postShippOrder = (id, data) => {
  return fetch(
    'put',
    `${REACT_APP_API_HOST_CORE}/orders/api/v1/orders/${id}`,
    {
      deliveryInfo: data,
    },
    { Authorization: localStorage.getItem('authorizations') },
  )
}
const getOrderPalletId = (body) => {
  return fetch('post', `${REACT_APP_API_HOST_CORE}/orders/api/v1/get/pallet/nfts`, body)
}
export const WareHouseService = {
  getPallets,
  getOrders,
  getOrdersNftId,
  postShippOrder,
  getOrderPalletId,
  getShippingOrders,
}
