import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import { WareHouseService } from "Services/warehouse.service";

export const actionTypes = {
    SAVE_ALL_PALLETSNFTS: "SAVE_ALL_PALLETSNFTS",
  };
  
  export function saveMyAllPalletsNfts(data) {
    return {
      type: actionTypes.SAVE_ALL_PALLETSNFTS,
      payload: data,
    };
  }
  
  
  export function getOrderPalletId(data) {
    return (dispatch, getState) =>
      new Promise((resolve, reject) => {
        dispatch(startLoading());
        WareHouseService.getOrderPalletId(data, {
          Authorization: localStorage.getItem("authorizations"),
        })
          .then((res) => {
            dispatch(saveMyAllPalletsNfts(res?.data));
            dispatch(stopLoading());
            resolve(res);
          })
          .catch((ex) => {
            toast.error(ex?.message);
            dispatch(stopLoading());
            reject(ex);
          });
      });
  }