import Types from '../_actions/_types'

const initialState = {
  isLoading: false,
}

const loading = (state = initialState, { type } = {}) => {
  switch (type) {
    case Types.START_LOADING:
      return { ...state, isLoading: true }
    case Types.STOP_LOADING:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default loading
