import { actionTypes } from '../_actions/winemaker.action'

const initialState = {
  winerMakers: [],
  winerTotal: 0,
  users: [],
  userTotal: 0,
  pendingWineryCount: 0,
  wineryTotalCount: 0,
}

const wineMakers = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_USERS:
      return { ...state }

    case actionTypes.SAVE_ALL_WINEMAKERS: {
      return {
        ...state,
        winerMakers: payload?.data,
        winerTotal: payload?.totalRecords,
      }
    }

    case actionTypes.SAVE_PENDING_REQUEST:
      return { ...state, pendingWineryCount: payload }

    case actionTypes.SAVE_ALL_WINERY_COUNT:
      return { ...state, wineryTotalCount: payload }

    case actionTypes.UPDATE_COMING_SOON_STATUS: {
      const { coming_soon_enabled, profile_status, user_id } = payload.data

      const newWinerMakers = [...state.winerMakers]
      const index = newWinerMakers.findIndex((w) => w.userId === user_id)

      newWinerMakers[index] = {
        ...newWinerMakers[index],
        coming_soon_enabled,
        profile_status,
      }

      return {
        ...state,
        winerMakers: newWinerMakers,
      }
    }

    default:
      return state
  }
}

export default wineMakers
