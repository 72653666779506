import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useDebounce from '../../../hooks/useDebounce'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import { getMarketplaceNfts } from '../../../redux/_actions/marketplace.action'
import MarketplaceFilters from './MarketplaceFilters/MarketplaceFilters'
import MarketplaceTable from './MarketplaceTable/MarketplaceTable'
import MarketplacePagination from './MarketplacePagination/MarketplacePagination'

const Marketplace = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [limit] = useState(10)
  const [offset, setOffset] = useState(0)

  const debounceMarketplaceNftSearch = useDebounce(search, 500)

  const getAllMarketplaceNft = async () => {
    let dataFields = {
      // limit: limit, // we should not display anything unless a user searches for the email or NFT ID.
      offset: offset,
      searchKey: encodeURIComponent(search),
    }
    dispatch(getMarketplaceNfts(dataFields)).then((res) => {})
  }

  useEffect(() => {
    getAllMarketplaceNft()
  }, [offset, limit, debounceMarketplaceNftSearch])

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Marketplace</h3>
      <MarketplaceFilters search={search} setSearch={setSearch} setOffset={setOffset} />
      <MarketplaceTable getAllMarketplaceNft={getAllMarketplaceNft} />
      <MarketplacePagination limit={limit} setOffset={setOffset} />
    </MainLayout>
  )
}

export default Marketplace
