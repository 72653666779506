import { actionTypes } from '../_actions/dropCollection.action'

const initialState = {
  entries: [],
}

const dropCollection = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_DROP_COLLECTIONS:
      return { ...state, entries: payload }

    default:
      return state
  }
}

export default dropCollection
