import { io } from "socket.io-client";
import Environments from "../_constants/Environment";

const { SOCKET_URL } = Environments;
const token = localStorage.getItem("authorizations");

const socket = io.connect(SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999,
  transports: ["websocket"],
  query: { usertoken: token, user_type: "admin" },
});

socket.on("connect", () => {
  console.log("connected to server");
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

socket.on("disconnect", () => {
  console.log("disconnected to server");
});

export default socket;
