export const DISCOUNT_TYPES = {
  NUMBER: 'NUMBER',
  PERCENT: 'PERCENT',
}

export const DISCOUNT_TYPES_OPTIONS = [
  {
    label: 'Fixed Amount, $',
    value: DISCOUNT_TYPES.NUMBER,
  },
  {
    label: 'Percent',
    value: DISCOUNT_TYPES.PERCENT,
  },
]

export const SALES_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  BOTH: 'BOTH',
}

export const SALES_TYPES_OPTIONS = [
  {
    label: 'Primary',
    value: SALES_TYPES.PRIMARY,
  },
  {
    label: 'Secondary',
    value: SALES_TYPES.SECONDARY,
  },
  {
    label: 'Both: Primary&Secondary',
    value: SALES_TYPES.BOTH,
  },
]

export const CODE_ISSUER = {
  WINECHAIN: 'WINECHAIN',
  MERCHANT: 'MERCHANT',
}

export const CODE_ISSUER_OPTIONS = [
  {
    label: 'WineChain',
    value: CODE_ISSUER.WINECHAIN,
  },
  {
    label: 'Merchant',
    value: CODE_ISSUER.MERCHANT,
  },
]

export const CONDITION_TYPES = {
  AND: 'AND',
  OR: 'OR',
}

export const CONDITION_TYPES_OPTIONS = [
  {
    label: 'AND',
    value: CONDITION_TYPES.AND,
  },
  {
    label: 'OR',
    value: CONDITION_TYPES.OR,
  },
]
