import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import { ReactComponent as IconBack } from '../../../Assets/image/svgimages/icon_back.svg'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import AccessTab from './AccessTab'
import FollowersTab from './FollowersTab'
import { useHistory } from 'react-router'
import {
  getMyFollowers,
  getMyExclusiveList,
  saveCsvFile,
} from '../../../redux/_actions/exclusive.action'
import { connect } from 'react-redux'
import useDebounce from '../../../hooks/useDebounce'
import './ExclusiveAccess.scss'

const { TabPane } = Tabs

const ExclusiveAccess = (props) => {
  let { getMyFollowers, getMyExclusiveList, saveCsvFile } = props
  const [file, setFile] = useState('')
  const [open, setOpen] = useState(false)

  const [followers, setFollowers] = useState([])
  const [exclusive, setExclusive] = useState([])
  const [search, setSearch] = useState('')
  const [searchFollowers, setSearchFollowers] = useState('')

  const history = useHistory()
  const userId = history?.location?.state?.userId
  const debaounceExclusiveSearch = useDebounce(search, 500)
  const debaounceFollowerSearch = useDebounce(searchFollowers, 500)

  useEffect(() => {
    if (userId === undefined || userId === '') {
      history.push('/panel/WineList')
    }
  }, [])

  useEffect(() => {
    getExclusiveList()
  }, [debaounceExclusiveSearch])

  useEffect(() => {
    getFollowersList()
  }, [debaounceFollowerSearch])

  const onChange = (key) => {
    key === 1 ? getExclusiveList() : getFollowersList()
  }

  const getExclusiveList = () => {
    let data = {
      userId: userId,
      searchkey: search,
    }
    getMyExclusiveList(data).then((res) => {
      let records = res?.data?.data
      records.forEach((element) => {
        element['checked'] = false
      })
      setExclusive(records)
    })
  }
  const getFollowersList = () => {
    let data = {
      id: userId,
      searchkey: searchFollowers,
    }
    getMyFollowers(data).then((res) => {
      let records = res?.data?.data
      records.forEach((element) => {
        element['checked'] = false
      })
      setFollowers(records)
    })
  }

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const handleOnChangeCsv = (e) => {
    setFile(e.target.files[0])
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()

    if (file) {
      let formData = new FormData()
      let image = file
      formData.append('csvfile', image)
      formData.append('userId', userId)
      saveCsvFile(formData).then((res) => {
        setFile('')
        setOpen(false)
        getExclusiveList()
      })
    }
  }

  return (
    <MainLayout>
      <div className='exclusive__main'>
        <Link to='#' className='back-btn'>
          <IconBack /> Back to winemaker list
        </Link>
        <h1 className='common-heading'>Exclusive access</h1>
        <Tabs className='exclusive__tabs' defaultActiveKey='1' onChange={onChange}>
          <TabPane tab='Exclusive access' key='1'>
            <AccessTab
              exclusive={exclusive}
              setExclusive={setExclusive}
              userId={userId}
              getExclusiveList={getExclusiveList}
              search={search}
              setSearch={setSearch}
              file={file}
              setFile={setFile}
              handleOnChangeCsv={handleOnChangeCsv}
              handleOnSubmit={handleOnSubmit}
              open={open}
              setOpen={setOpen}
            />
          </TabPane>
          <TabPane tab='Followers' key='2'>
            <FollowersTab
              followers={followers}
              userId={userId}
              setFollowers={setFollowers}
              searchFollowers={searchFollowers}
              setSearchFollowers={setSearchFollowers}
              getFollowersList={getFollowersList}
            />
          </TabPane>
        </Tabs>
      </div>
    </MainLayout>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMyFollowers: (data) => dispatch(getMyFollowers(data)),
    getMyExclusiveList: (data) => dispatch(getMyExclusiveList(data)),
    saveCsvFile: (data) => dispatch(saveCsvFile(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExclusiveAccess)
