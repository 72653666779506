import ApiService from '../../Services/Api.service'
import Types from './_types'

export const saveLoginDetails = (payload) => ({
  type: Types.SAVE_LOGIN_DETAILS,
  payload,
})

export const logout = () => ({
  type: Types.RESET_PERSIST_ON_LOGOUT,
})

/**
 * @function setHeaders
 * @param {{ isFormdata }} data
 * @returns object: headers
 */
export const setHeaders = (data) => async (dispatch, getState) => {
  try {
    const { isFormdata } = data
    const {
      persist: { token },
    } = getState()

    if (!isFormdata) return { Authorization: token }

    return {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    }
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

/**
 * @function uploadFile
 * @param file
 * @param folderName
 * @returns
 */

export const uploadFile =
  (file, folderName = 'file') =>
  async (dispatch, getState) => {
    try {
      const headers = dispatch(setHeaders({ isFormdata: true }))
      const formdata = new FormData()
      formdata.append('folderName', folderName)
      formdata.append('file', file)
      const res = await ApiService.uploadImage(formdata, headers)
      if (res) {
        const {
          data: { data },
        } = res
        if (data.length > 0) return data[0].url
        return false
      }
    } catch (error) {
      console.log('error', error)
      throw error
    }
  }

export const uploadBannerImages =
  (file, folderName = 'file') =>
  async (dispatch, getState) => {
    try {
      const headers = dispatch(setHeaders({ isFormdata: true }))
      const formdata = new FormData()
      formdata.append('folderName', folderName)
      formdata.append('file', file)
      const res = await ApiService.uploadBanner(formdata, headers)
      if (res) {
        const {
          data: { data },
        } = res
        if (data.length > 0) return data[0].url
        return false
      }
    } catch (error) {
      console.log('error', error)
      throw error
    }
  }
