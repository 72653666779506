import { startLoading, stopLoading } from './loading.action'
// import { toast } from '../../components/Toast/Toast'
import { DiscountCodeService } from '../../Services/discountCode.service'
/** seting action types */
export const actionTypes = {
  SAVE_ALL_DISCOUNT_CODES: 'SAVE_ALL_DISCOUNT_CODES',
}

export function saveAllDiscountCodes(data) {
  return {
    type: actionTypes.SAVE_ALL_DISCOUNT_CODES,
    payload: data,
  }
}

export function getAllDiscountCodes(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DiscountCodeService.getAllDiscountCodes(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveAllDiscountCodes(res.data?.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getDiscountCode(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DiscountCodeService.getDiscountCode(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
export function checkIfDiscountCodeExists(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DiscountCodeService.checkIfDiscountCodeExists(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function createDiscountCode(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DiscountCodeService.createDiscountCode(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function updateDiscountCode(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DiscountCodeService.updateDiscountCode(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function deleteDiscountCode(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      DiscountCodeService.deleteDiscountCode(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
