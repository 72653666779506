import { useState } from "react";
import { MARKETPLACE_NFT_STATUSES } from "../../../../../utils/constants";
import { Popup, Table } from "semantic-ui-react";
import './MarketplaceTableComponent.scss';

import getWineName from "../../../../../Helpers/getWineName";
import MarketplaceDropdownMenu from "./MarketplaceDropdownMenu/MarketplaceDropdownMenu";
import EditStatusModal from "./MarketplaceDropdownMenu/EditStatusModal/EditStatusModal";
import EditPriceModal from "./MarketplaceDropdownMenu/EditPriceModal/EditPriceModal";

const MarketplaceTableComponent = ({ item, getAllMarketplaceNft }) => {
  const [menuPopupOpen, setMenuPopupOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);

  const availableStatusMenu = item?.sale_status !== MARKETPLACE_NFT_STATUSES.SOLD
  const availablePriceMenu = item?.sale_status === MARKETPLACE_NFT_STATUSES.DRAFT

  const getNftStatus = (status) => {
    switch (status) {
      case MARKETPLACE_NFT_STATUSES.SOLD: {
        return "Sold";
      }
      case MARKETPLACE_NFT_STATUSES.LISTED: {
        return "Listed";
      }
      case MARKETPLACE_NFT_STATUSES.DRAFT: {
        return "Draft";
      }
      default:
        return "Unknown";
    }
  };

  const getMarketplaceWineName = () => {
    const wineNameData = {
      winery_name: item?.winery_name,
      cuvee: item?.cuvee,
      cru: item?.cru,
      classification: item?.classification,
      nft_name: item?.nft_name,
    };

    return getWineName(wineNameData)
  }

  const onMenuClick = (e) => {
    if (!availableStatusMenu && !availablePriceMenu) {
      return
    }

    const element = e.target;
    setOffset(element.offsetLeft)
    setMenuPopupOpen(true)
  }

  const onEditStatusMenuClick = () => {
    setStatusModalOpen(true)
    setMenuPopupOpen(false)
  }

  const onEditPriceMenuClick = () => {
    setPriceModalOpen(true)
    setMenuPopupOpen(false)
  }

  return (
    <>
      <Popup
        className="labelTableModal"
        offset={offset}
        on={["click"]}
        position="bottom left"
        open={menuPopupOpen}
        onClose={() => setMenuPopupOpen(false)}
        trigger={
          <Table.Row onClick={onMenuClick} className="marketplace-table-row">
            <Table.Cell collapsing colSpan="3" textAlign="left" className="marketplace-table-row__nft-name">
              {getMarketplaceWineName()}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {item?.nft_id}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left" className="marketplace-table-row__contract-address">
              {item?.contract_address}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {item?.from_wallet || '-'}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {item?.to_wallet || '-'}
            </Table.Cell>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {getNftStatus(item?.sale_status)}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {item?.purchase_price}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {item?.sale_price}
            </Table.Cell>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {item?.sale_type || '-'}
            </Table.Cell>
            <Table.Cell collapsing colSpan="1" className="TableEditBtn">
            </Table.Cell>
          </Table.Row>
        }
      >
        <MarketplaceDropdownMenu
          availableStatusMenu={availableStatusMenu}
          availablePriceMenu={availablePriceMenu}
          onEditStatusMenuClick={onEditStatusMenuClick}
          onEditPriceMenuClick={onEditPriceMenuClick}
        />
      </Popup>
      <EditStatusModal
        open={statusModalOpen}
        setOpen={setStatusModalOpen}
        item={item}
        getAllMarketplaceNft={getAllMarketplaceNft}
      />
      <EditPriceModal
        open={priceModalOpen}
        setOpen={setPriceModalOpen}
        item={item}
        getAllMarketplaceNft={getAllMarketplaceNft}
      />
    </>
  );
};

export default MarketplaceTableComponent;
