import { startLoading, stopLoading } from './loading.action'
import { toast } from '../../components/Toast/Toast'
import { ExclusiveService } from '../../Services/exclusive.service'
/** seting action types */
export const actionTypes = {
  SAVE_EXCLISIVE: 'SAVE_EXCLISIVE',
  SAVE_FOLLOWERS: 'SAVE_FOLLOWERS',
}

export function getMyFollowers(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ExclusiveService.getUsersFollowers(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getMyExclusiveList(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ExclusiveService.getMyExclusiveList(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getDeleteMyFollowers(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ExclusiveService.getDeleteMyFollowers(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getDeleteMyExclusive(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ExclusiveService.getDeleteMyExclusive(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function saveCsvFile(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ExclusiveService.saveCsvFile(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          toast.success(res.data.message)
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function addExclusiveMember(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ExclusiveService.addExclusiveMember(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          toast.success(res.data?.message)
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
