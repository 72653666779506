import "./RangeSlider.scss";
import { InputNumber, Slider } from "antd";
import { WineStructureEnum, WineStructureType } from "../../../pages/private/bottles/store/model";

interface RangeSliderProps {
  value: number;
  min: number;
  max: number;
  label: string;
  type: WineStructureType;
  onChangeSlider: (value: number | null, type: WineStructureType) => void
  isRequired?: boolean;
  useSlider?: boolean;
  placeholder?: string;
  range?: boolean;
  error?: string;
}

const RangeSlider = (props: RangeSliderProps) => {
  let {
    value,
    min,
    max,
    label,
    type,
    onChangeSlider,
    isRequired = false,
    useSlider = false,
    placeholder,
    range,
    error
  } = props;

  const onChangeInput = (value: number | null, type: WineStructureType) => {
    onChangeSlider(value, type)
  }

  if (useSlider && range) {
    const sliderNumber = value as unknown as [number, number]
    return <div className="range-slider-outer">
      <label className="inputField__label">
        {label}
        {isRequired && <sup>*</sup>}
      </label>
      <div className="inner">
        <div className="right">
          <Slider
            range
            defaultValue={[5, 10]}
            // tooltip={{ open: true }}
            min={min}
            max={max}
            value={sliderNumber}
            onChange={(e) => onChangeSlider(e as unknown as number, WineStructureEnum.WINE_AGEABILITY)} />
          <p className="range-slider__text">Chosen range: {sliderNumber?.join ? sliderNumber?.join('-') : ''}</p>
          {error && <p className="error">{error}</p>}
        </div>
      </div>

    </div>
  }

  const disableScroll = (e: any) => {
    e.preventDefault()
  }

  return (
    <>
      <div className="range-slider-outer">
        <label className="inputField__label">
          {label}
          {isRequired && <sup>*</sup>}
        </label>
        <div className="inner">
          <div className={`left ${useSlider ? '' : 'auto'}`}>
            <InputNumber
              min={min}
              max={max}
              value={value}
              id={type}
              onChange={(value) => onChangeInput(value, type)}
              type="number"
              placeholder={placeholder}
              onFocus={(e) => e.target.addEventListener('wheel', disableScroll, { passive: false })}
              onBlur={(e) => e.target.removeEventListener('wheel', disableScroll)}
            />
            {error && <p className="error">{error}</p>}
          </div>
          {
            useSlider ?
              <div className="right">
                <Slider
                  min={min}
                  max={max}
                  onChange={(value) => onChangeSlider(value, type)}
                  value={value}
                />
              </div> : null
          }
        </div>
      </div>
    </>
  );
};
export default RangeSlider;
