import { startLoading, stopLoading } from './loading.action'
import { toast } from '../../components/Toast/Toast'
import { WareHouseService } from 'Services/warehouse.service'
import Types from './_types'

export function saveShippingOrders(data) {
  return {
    type: Types.SAVE_SHIPPING_ORDERS,
    payload: data,
  }
}

export const getShippingOrders = (data) => async (dispatch) => {
  dispatch(startLoading())
  try {
    const res = await WareHouseService.getShippingOrders(data)
    dispatch(saveShippingOrders(res?.data))
    dispatch(stopLoading())
    return res
  } catch (error) {
    toast.error(error?.message)
    dispatch(stopLoading())
    throw error
  }
}
