import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationAction } from '../../redux/_actions'
import dd from '../../Assets/image/dd.png'
import closeicon from '../../Assets/image/close.png'
import logo from '../../Assets/image/Brand_Logo.png'
import logoicon from '../../Assets/image/Brand_Logo_m.png'
import notification from '../../Assets/image/notification.svg'
import BreadCrumbPage from '../BreadCrumb/BreadCrumbPage'
import Userdrop from '../common/DropdownField/Userdrop'
import socket from '../../Socket'
import wineft_Icon from '../../Assets/image/wineft_Icon.png'
import OutsideClickHandler from 'react-outside-click-handler'
import './Header.scss'
import { timeSince } from './timeUtils.js'

const DesktopHeader = ({ setToggleSidebar, toggleSidebar }) => {
  const dispatch = useDispatch()
  const totalUnread = useSelector((state) => state.notification?.pendingNotificationList)
  const { getMyAllNotifications, markReadNotification, saveMyPendingNotifications } =
    NotificationAction

  useEffect(() => {
    startSocket()
    getAllNotifications()
  }, [])

  //MARK AS READ
  const onCallMarkReadAll = () => {
    markAsReadApi([])
  }

  const onCallMarkReadSingle = (id) => {
    let data = [id]
    markAsReadApi(data)
  }
  const markAsReadApi = (data) => {
    let record = {
      data: data,
    }
    dispatch(markReadNotification(record)).then((res) => {
      getAllNotifications()
    })
  }

  const getAllNotifications = () => {
    let data = {
      status: 0,
    }
    dispatch(getMyAllNotifications(data))
  }

  const startSocket = () => {
    //get real time sockets
    socket.on('connect', () => {
      if (socket.connected && socket.id) {
        socket.on(`user-notifications`, async (data) => {
          data.forEach((element) => {
            element.content = JSON.parse(element.content)
          })
          let finalData = {
            data: data,
            count: data?.length,
          }
          dispatch(saveMyPendingNotifications(finalData))
        })
      }
    })
  }

  const [isActive, setActive] = useState(false)
  const toggleClass = () => {
    setActive(!isActive)
  }
  const [idActive, setId] = useState(false)

  const toggleId = () => {
    setId(!idActive)
  }

  return (
    <React.Fragment>
      <div className='headerTop'>
        <div className='headerRight'>
          <Link to='/nfts' className='headerLogo desk' title='Dashboard'>
            <img src={logo} alt='logo' />
          </Link>
          <Link to='/nfts' className='headerLogo mob' title='Dashboard'>
            <img src={logoicon} alt='logo' />
          </Link>
          <BreadCrumbPage />

          <ul className='navRight'>
            <li
              className='navR_item notification-handle'
              id={idActive ? 'active' : null}
              onClick={toggleId}
            >
              <OutsideClickHandler
                onOutsideClick={() => {
                  setId(false)
                }}
              >
                <img src={notification} alt='notification_icon' className='notif_icon' />
                {totalUnread?.length > 0 && (
                  <div className='NotifiNumber'>
                    <p>{totalUnread.length}</p>
                  </div>
                )}
                <div className='notification-drop'>
                  <div className='head'>
                    <h5>NOTIFICATIONS</h5>
                    {totalUnread?.length > 0 && (
                      <span style={{ cursor: 'pointer' }} onClick={() => onCallMarkReadAll()}>
                        Mark all as read
                      </span>
                    )}
                  </div>
                  <p className='date'>Today</p>

                  <ul>
                    {totalUnread?.slice(0, 4).map((item) => (
                      <li key={`tu-${item.id}`}>
                        <div className='thumb'>
                          <img src={wineft_Icon} alt='' />
                        </div>
                        <div className='content'>
                          <p>{item.content?.msg}</p>
                          <Link
                            to={item.type === 1 ? `/panel/winery-requests` : `/panel/WineList`}
                            onClick={() => onCallMarkReadSingle(item.id)}
                            className='not-btn'
                          >
                            View details
                          </Link>
                        </div>
                        <div className='time'>
                          {/* 1- Winemaker page, 2 -Winery detail page	 */}
                          <span>
                            {(() => {
                              const time = timeSince(new Date(item.createdAt))
                              return time === 'Just Now' ? 'Just Now' : `${time}`
                            })()}
                          </span>
                        </div>
                      </li>
                    ))}
                    {totalUnread?.length > 0 && (
                      <Link to='/panel/notifications' className='not-btn'>
                        View all notifications
                      </Link>
                    )}
                    {totalUnread?.length === 0 && (
                      <Link to='#' className='not-btn'>
                        No new notifications
                      </Link>
                    )}
                  </ul>
                </div>
              </OutsideClickHandler>
            </li>
            <li className='navR_item admin-drop'>
              <Userdrop />
            </li>
            <li className='navR_item nav-toggle'>
              <button
                className='toggleicon'
                id={isActive ? 'toggleicon' : null}
                onClick={() => {
                  setToggleSidebar(!toggleSidebar)
                  toggleClass()
                }}
              >
                <img className='iconbar' src={dd} alt='Toggle icon' />
                <img className='iconclose' src={closeicon} alt='Toggle icon' />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DesktopHeader)
