import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import InputField from '../../../../components/common/InputField/InputField'
import { ReactComponent as IconBack } from '../../../../Assets/image/svgimages/icon_back.svg'
import CardGrid from '../../../../components/common/cardGrid/CardGrid'
import dummyImg from '../../../../Assets/image/dummyImg.jpg'
import redWineBackgroundH from '../../../../Assets/image/svgimages/red-wine-background-h.svg'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { toast } from '../../../../components/Toast/Toast'
import { Bottles, WinerMakerAction } from '../../../../redux/_actions'
import { createBottleSchema } from '../schema'

import 'antd/dist/antd.min.css'
import '../../CreateWinemakers/CreateWinemakers.scss'
import './CreateBottle.scss'
import { IconDelete } from '../../../../Assets/image/svgimages/sVGImg'
import RadioButton from '../../../../components/common/RadioButton/RadioButton'
import WineBottleName from '../components/WineBottleName'
import Notes from '../components/Notes'
import BottleSteps from '../BottleSteps'
import { bulkCreateBottles, bulkUploadBottleImages } from 'redux/_actions/bottles.action'
import ActionsBulk from 'components/common/ActionsBulk/ActionsBulk'

const BottleCreate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [wineryList, setWineryList] = useState([])
  const [userEmail, setUserEmail] = useState(false)

  const [colorList, setColorList] = useState([])
  const [allCountry, setAllCountry] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [sizeText, setSizeText] = useState('')
  const [sweetNessList, setSweetNessList] = useState([])
  const [stillList, setStillList] = useState([])
  const [sparklingList, setSparklingList] = useState([])
  const [winerNameText, setWinerNameText] = useState(
    history.location?.state?.creator_meta?.winery_name || '',
  )
  const [regionList, setRegionList] = useState([])
  const [userRegionId, setUserRegionId] = useState('')
  const [userAppellationId, setUserAppellationId] = useState('')
  const [appellationWithRegion, setAppellationWithRegion] = useState([])
  const [countryWithRegion, setCountryWithRegion] = useState([])
  const [selectedUserId, setSelectedUserId] = useState('')
  const [selectedUserIdText, setSelectedUserIdText] = useState('')

  const userId = history?.location?.state?.userId
  const winerName = history.location?.state?.creator_meta?.winery_name

  const email = history?.location?.state?.email

  const inputRefBottle = React.useRef()

  const { uploadFileGc } = WinerMakerAction

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const { getBottleDropDownSpecifications, addBottles, getAllWineryListForDropDown } = Bottles

  useEffect(() => {
    getBottlesSpecification()
  }, [])

  useEffect(() => {
    // if add bottle is from outside list
    if (!userId) {
      getAllWineryList()
    }
  }, [userId])

  const getAllWineryList = () => {
    dispatch(getAllWineryListForDropDown()).then((res) => {
      setWineryList(res?.data?.data)
    })
  }

  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      const details = res.data
      const keys = Object.keys(details.regionList)
      setCountryWithRegion(details.regionList)

      setSizeList(details.SizeList)
      const sizeList = details?.SizeList
      sizeList.forEach((element) => {
        if (element?.size === '75 cl Standerd bottle') {
          bottleFormik.setFieldValue('size_id', element?.id)
          setSizeText(element?.size)
        }
      })
      const colors = details?.colorList?.reverse()
      setColorList(colors)
      setAllCountry(keys)
      const still = details?.BottleSweetnessList?.filter(
        (b) => b?.wine_type === 'still' || b?.wine_type === null,
      )
      const sparkling = details?.BottleSweetnessList?.filter(
        (item) => item?.wine_type === 'sparkling' || item?.wine_type === null,
      )

      const sparklingSortOrder = [
        'Brut nature',
        'Extra-brut',
        'Brut',
        'Extra-dry',
        'Dry',
        'Semi-dry',
        'Sweet',
      ]

      sparkling.sort((a, b) => {
        const aIndex = sparklingSortOrder.indexOf(a.name)
        const bIndex = sparklingSortOrder.indexOf(b.name)
        return aIndex - bIndex
      })

      setStillList(still.length > 0 ? still : [])
      setSparklingList(sparkling.length > 0 ? sparkling : [])
      setSweetNessList(details.BottleSweetnessList)

      let regionId = history.location?.state?.creator_meta.regions?.id
      let appilationId = history.location?.state?.creator_meta.creator_appellation?.id
      if (userId) {
        setRegionsIfUserExist(
          history.location?.state?.creator_meta.country,
          details.regionList,
          regionId,
          appilationId,
        )
      }
    })
  }

  const handleCountryChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    if (field === 'country') {
      bottleFormik.setFieldValue('region_id', '')
      bottleFormik.setFieldValue('appellation', '')

      for (const key in countryWithRegion) {
        if (key === e.target.value) {
          setRegionList(countryWithRegion[key])
        }
      }
    }
    if (field === 'region_id') {
      setUserRegionId(parseInt(e.target.value))
      bottleFormik.setFieldValue('appellation', '')
      countryWithRegion[bottleFormik.values.country].forEach((element) => {
        if (element.id === e.target.value) {
          setAppellationWithRegion(element.wine_appellation)
        }
      })
    }
  }

  const handleAppilationChange = (e) => {
    bottleFormik.setFieldValue('appellation', e.target.value)
    setUserAppellationId(parseInt(e.target.value))
  }

  const setRegionsIfUserExist = (country, reigonCountry, regionId, appilationId) => {
    bottleFormik.setFieldValue('country', country)
    // setting initial value for regions and appilations
    for (let key in reigonCountry) {
      if (key === country) {
        setRegionList(reigonCountry[key])
        setUserRegionId(regionId)
        reigonCountry[key].forEach((element) => {
          if (element.id === regionId) {
            // saving dropdown for appellation
            setAppellationWithRegion(element.wine_appellation)
          }
          setUserAppellationId(appilationId)
        })
      }
    }
  }

  const handleSizeChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    sizeList.forEach((element) => {
      if (element.id === e.target.value) {
        setSizeText(element.size)
      }
    })
  }

  const badgeSelectList = ['Icon', 'Rising Star', 'Hidden Gem']

  const handleBadgeChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
  }

  const onSelectUser = (e, field) => {
    const el = document.getElementById('winery_name')
    const value = el?.value

    bottleFormik.setFieldValue('winery_name', e.target.value)
    const text = el.options[el.selectedIndex].text
    setSelectedUserIdText(text) // for setDefaultValue
    setWinerNameText(text) // for preview bottle
    setSelectedUserId(value)

    wineryList.forEach((element) => {
      if (element.userId === e.target.value) {
        setUserEmail(element.email)
      }
    })
  }

  const bottleFormik = useFormik({
    enableReinitialize: true,
    validationSchema: createBottleSchema,
    initialValues: {
      winery_name: userId ? winerName : '',
      brand_name: '',
      bottle_image: '',
      // label_image: "",
      wine_name: '',
      country: userId ? history.location?.state?.creator_meta?.country : '',
      region_id: userId ? history.location?.state?.creator_meta?.regions?.id : '',
      appellation: userId ? history.location?.state?.creator_meta?.creator_appellation?.id : '',
      wine_color_id: '',
      size_id: '',
      badge: '',
      description: '',
      wine_spectator_point: '0',
      wine_advocate_point: '0',
      wine_falstaff_point: '0',
      wine_vinous_point: '0',
      wine_james_suckling_point: '0',
      wine_burgbound_point: '0',
      sweetness_wine_id: '',
      grape: '',
      vineyard_name: '',
      classification: '',
      consist: '0',
      ageability: '',
      rarity: '',
      bottle_image_title: '',
      bottle_image_alt: '',
      strength: '',
      tannins: '',
      texture: '',
      review: '',
      about_winery: '',
      terroir_and_aging_method: '',
      tasting_notes: '',
      pairing: '',
    },
    onSubmit: (values) => {
      values['region_id'] = userRegionId

      const CONSIST_TYPES = {
        still: 1,
        sparking: 2,
      }
      values['consist'] = CONSIST_TYPES[values.consist] || 0
      values['user_id'] = userId ? userId : selectedUserId
      if (!userId) {
        values['winery_name'] = selectedUserIdText
      }
      if (!userAppellationId) {
        delete values['appellation']
      } else {
        values['appellation'] = userAppellationId
      }
      values['size_id'] = +values.size_id
      values['sweetness_wine_id'] = +values.sweetness_wine_id
      values['wine_advocate_point'] = +values.wine_advocate_point
      values['wine_burgbound_point'] = +values.wine_burgbound_point
      values['wine_color_id'] = +values.wine_color_id
      values['wine_falstaff_point'] = +values.wine_falstaff_point
      values['wine_james_suckling_point'] = +values.wine_james_suckling_point
      values['wine_spectator_point'] = +values.wine_spectator_point
      values['wine_vinous_point'] = +values.wine_vinous_point
      values['rarity'] = `${values.rarity}`
      values['badge'] = `${values.badge}`
      values['strength'] = `${values.strength}`
      values['tannins'] = `${values.tannins}`
      values['texture'] = `${values.texture}`
      console.log('values 288', values)
      addBottleUnderWiners(values)
    },
  })

  const addBottleUnderWiners = (data) => {
    dispatch(addBottles(data)).then((res) => {
      toast.success(res.data?.message)
      history.push('/panel/bottles')
    })
  }

  const uploadFileToS3Image = async (input) => {
    input.persist()

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      input.target.value = null
      toast.error('File extension not supported!')
    } else {
      // without validation
      uploadImageApiImage(file)
    }
  }

  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.SVG', '.svg', '.gif', '.GIF']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadImageApiImage = (file) => {
    let formData = new FormData()
    formData.append('folderName', 'testing')
    formData.append('file', file)

    dispatch(uploadFileGc(formData)).then((res) => {
      bottleFormik.setFieldValue('bottle_image', res?.data.data[0]?.url)
    })
  }

  const deleteBottleImage = () => {
    bottleFormik.setFieldValue('bottle_image', '')
    if (inputRefBottle.current !== undefined) {
      inputRefBottle.current.value = ''
    }
  }

  const handleChange = (value) => {
    bottleFormik.setFieldValue('consist', value)
  }

  const handlefilterOnWinerySelect = (userId) => {
    const selectedWinery = wineryList.find((el) => el.userId === userId)

    for (const key in countryWithRegion) {
      if (key === selectedWinery?.country) {
        setRegionList(countryWithRegion[key])
        break
      }
    }

    bottleFormik.setFieldValue('country', selectedWinery?.country)
    bottleFormik.setFieldValue('region_id', selectedWinery.region_obj?.id)
    bottleFormik.setFieldValue('appellation', selectedWinery.appellation_obj?.appellation || '')

    countryWithRegion[selectedWinery?.country].forEach((element) => {
      if (element.id === selectedWinery.region_obj?.id) {
        setAppellationWithRegion(element.wine_appellation)
      }
    })

    setUserRegionId(selectedWinery?.region_obj?.id)
    setUserAppellationId(selectedWinery?.appellation_obj?.id)
  }

  const bulkUploadBottleImagesContent = {
    trigger: { text: 'Upload Bottle Names Bulk' },
    body: { title: 'Bulk Uploading' },
  }

  return (
    <MainLayout>
      <div className='winemakers-main crtbtl'>
        <div className='left'>
          <BottleSteps data={bottleFormik.values} />
        </div>

        <div className='right'>
          <Row className='rightrow'>
            <Col xs={24}>
              <Link to='/panel/bottles' className='back-btn'>
                <IconBack /> Back to bottles list
              </Link>
              <h1 className='common-heading mb-25'>Add Wine Bottle</h1>
            </Col>

            <Col xl={14}>
              <ActionsBulk actionFn={bulkCreateBottles} />
              <ActionsBulk
                actionFn={bulkUploadBottleImages}
                content={bulkUploadBottleImagesContent}
              />
            </Col>
            <Col xl={14} className='crtbtl-left'>
              <Form onSubmit={bottleFormik.handleSubmit}>
                <div className='block' id='a'>
                  <WineBottleName bottleFormik={bottleFormik} />

                  <div className='genBottle_Id inputField__wrap '>
                    <Row gutter={20} className='w-100 bottle-files-handle'>
                      <Col md={12}>
                        <div className='inputField__label file-input__image'>
                          <div className='file-input__image-subheader'>
                            Image of the bottle<sup>*</sup>
                          </div>
                          <p>File types supported: JPG, PNG, GIF, SVG.</p>
                          <p>Max size: 5 MB , 1080x1920</p>
                        </div>
                        <div className='uploadField'>
                          <div className='profilePic'>
                            <div className='profilePic-inner'>
                              <input
                                type='file'
                                name=' profile_image'
                                id=' profile_image'
                                className='input__input'
                                onChange={uploadFileToS3Image}
                                formEncType='multipart/form-data'
                                ref={inputRefBottle}
                                disabled={bottleFormik.values.bottle_image !== ''}
                              />
                              <label
                                className={cn('file-input__prof', {
                                  'editImage IconHidd': bottleFormik.values.bottle_image !== '',
                                })}
                                htmlFor='file-input'
                              >
                                <img
                                  style={{ width: 64, height: 64 }}
                                  src={bottleFormik.values.bottle_image}
                                  alt=''
                                />
                              </label>
                              {bottleFormik.touched.bottle_image && (
                                <p
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    marginTop: '5px',
                                    width: '100%',
                                  }}
                                >
                                  {bottleFormik.errors.bottle_image}
                                </p>
                              )}
                            </div>
                          </div>
                          {bottleFormik.values.bottle_image && (
                            <div className='editand_DltBtn'>
                              <button
                                onClick={() => deleteBottleImage()}
                                type='button'
                                className='DeltBtnsIcon'
                              >
                                <IconDelete />
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <InputField
                      name='bottle_image_title'
                      label='Image Title (attribute title)'
                      placeholder='Wine name...'
                      id='bottle_image_title'
                      onChange={(event) => {
                        bottleFormik.setFieldValue('bottle_image_title', event.target.value)
                      }}
                      value={bottleFormik.values.bottle_image_title}
                      formik={bottleFormik}
                    />
                  </div>
                  <div>
                    <InputField
                      name='bottle_image_alt'
                      label='Image Alt (attribute alt)'
                      placeholder='Wine name...'
                      id='bottle_image_alt'
                      onChange={(event) => {
                        bottleFormik.setFieldValue('bottle_image_alt', event.target.value)
                      }}
                      value={bottleFormik.values.bottle_image_alt}
                      formik={bottleFormik}
                    />
                  </div>
                </div>
                <div className='block' id='b'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>2: Details</h3>
                    <p>
                      The information you provide below are key features that will be displayed on
                      our public web page highlighting the features of the labels
                    </p>
                  </div>

                  {userId ? (
                    <InputField
                      name='f'
                      label='Producer (Winery)'
                      isRequired
                      className='disabled-style'
                      placeholder='Bordeaux'
                      id='winery_name'
                      onChange={(event) => {
                        setWinerNameText(event.target.value)
                        bottleFormik.setFieldValue('winery_name', event.target.value)
                      }}
                      value={bottleFormik.values.winery_name}
                      formik={bottleFormik}
                      disabled
                    />
                  ) : (
                    <div className='cus_drop inputField__wrap'>
                      <label className='inputField__label'>
                        Producer (Winery)<sup>*</sup>
                      </label>
                      <select
                        id='winery_name'
                        className={cn({
                          empty: !bottleFormik.values.winery_name,
                        })}
                        value={bottleFormik.values.winery_name}
                        onChange={(e) => {
                          onSelectUser(e, 'winery_name')
                          handlefilterOnWinerySelect(e.target.value)
                        }}
                      >
                        <option value='' disabled>
                          Select producer of the wine
                        </option>
                        {wineryList.map((option) => (
                          <option
                            key={option.userId}
                            value={option.userId}
                            name={option.winery_name}
                          >
                            {option.winery_name}
                          </option>
                        ))}
                      </select>
                      {bottleFormik.touched.winery_name && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            marginRight: 'auto',
                            marginLeft: '0',
                          }}
                        >
                          {bottleFormik.errors.winery_name}
                        </p>
                      )}
                    </div>
                  )}

                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>
                      Country<sup>*</sup>
                    </label>
                    <select
                      name='Country'
                      label='Country'
                      placeholder='Country'
                      id='country'
                      className={cn({
                        empty: !bottleFormik.values.country,
                      })}
                      onChange={(e) => handleCountryChange(e, 'country')}
                      value={bottleFormik?.values.country}
                      formik={bottleFormik}
                      disabled={bottleFormik.values.winery_name === ''}
                    >
                      <option value='' disabled>
                        Winery's country of origin
                      </option>
                      {allCountry.map((option, index) => (
                        <option key={`c-${option}`} value={option.index}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>
                      Region<sup>*</sup>
                    </label>
                    <select
                      name='Region'
                      label='Region'
                      id='region_id'
                      placeholder='Region'
                      className={cn({
                        empty: !bottleFormik.values.region_id,
                      })}
                      onChange={(e) => handleCountryChange(e, 'region_id')}
                      value={bottleFormik?.values.region_id}
                      formik={bottleFormik}
                      disabled={bottleFormik.values.country === ''}
                    >
                      <option value='' disabled>
                        Select region
                      </option>
                      {regionList.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option.region_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>Appellation</label>
                    <select
                      name='Appellation'
                      label='Appellation'
                      id='appellation'
                      placeholder='Appellation'
                      className={cn({
                        empty: !bottleFormik.values.appellation,
                      })}
                      onChange={(e) => handleAppilationChange(e)}
                      value={bottleFormik?.values.appellation}
                      formik={bottleFormik}
                      disabled={bottleFormik.values.region_id === ''}
                    >
                      <option value=''>Select Appellation</option>
                      {appellationWithRegion.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option.appellation}
                        </option>
                      ))}
                    </select>
                  </div>

                  <InputField
                    name='vineyard_name'
                    label='Vineyard name or Cru'
                    placeholder='Ex. Jacuzzi Family Vineyards, Chateau Latour'
                    id='vineyard_name'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('vineyard_name', event.target.value)
                    }}
                    value={bottleFormik.values.vineyard_name}
                    formik={bottleFormik}
                  />
                  <InputField
                    name='wine_name'
                    label='Cuvee'
                    placeholder='Les combettles'
                    id='wine_name'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('wine_name', event.target.value)
                    }}
                    value={bottleFormik.values.wine_name}
                    formik={bottleFormik}
                  />
                  <InputField
                    name='grape'
                    label='Grape'
                    placeholder='Ex. Grape'
                    id='grape'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('grape', event.target.value)
                    }}
                    value={bottleFormik.values.grape}
                    formik={bottleFormik}
                  />
                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>
                      Color of the wine<sup>*</sup>
                    </label>
                    <select
                      id='wine_color_id'
                      value={bottleFormik.values.wine_color_id}
                      className={cn({
                        empty: !bottleFormik.values.wine_color_id,
                      })}
                      onChange={(e) => handleCountryChange(e, 'wine_color_id')}
                    >
                      <option value='' disabled>
                        Select wine color
                      </option>
                      {colorList.map((option) => (
                        <option key={`color-${option.id}`} value={option.id}>
                          {option.color_name}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.wine_color_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.wine_color_id}
                      </p>
                    )}
                  </div>

                  <div className='BtnRadio'>
                    <label className='inputField__label mb-25'>Consistency</label>

                    <RadioButton
                      label='Still'
                      className='btnSetRadio'
                      id='consist1'
                      name='consist'
                      value='still'
                      checked={bottleFormik.values.consist === 'still'}
                      onChange={handleChange}
                    />
                    <RadioButton
                      label='Sparkling'
                      className='btnSetRadio'
                      id='consist2'
                      name='consist'
                      value='sparking'
                      checked={bottleFormik.values.consist === 'sparking'}
                      onChange={handleChange}
                    />
                    {bottleFormik.touched.consist && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.consist}
                      </p>
                    )}
                  </div>

                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>
                      Sweetness of wine<sup>*</sup>
                    </label>
                    <select
                      id='sweetness_wine_id'
                      className={cn({
                        empty: !bottleFormik.values.sweetness_wine_id,
                      })}
                      value={bottleFormik.values.sweetness_wine_id}
                      onChange={(e) =>
                        bottleFormik.setFieldValue('sweetness_wine_id', e.target.value)
                      }
                    >
                      <option value='' disabled>
                        Select sweetness
                      </option>
                      {bottleFormik.values.consist !== 'still' &&
                        bottleFormik.values.consist !== 'sparking' &&
                        sweetNessList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      {bottleFormik.values.consist === 'still' &&
                        stillList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      {bottleFormik.values.consist === 'sparking' &&
                        sparklingList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    {bottleFormik.touched.sweetness_wine_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.sweetness_wine_id}
                      </p>
                    )}
                  </div>

                  <InputField
                    name='classification'
                    label='Classification'
                    placeholder='(Ex. Grand Cru, Premier Cru, DOCG)'
                    id='classification'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('classification', event.target.value)
                    }}
                    value={bottleFormik.values.classification}
                    formik={bottleFormik}
                  />
                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>
                      Size<sup>*</sup>
                    </label>
                    <select
                      id='size_id'
                      className={cn({
                        empty: !bottleFormik.values.size_id,
                      })}
                      value={bottleFormik.values.size_id}
                      onChange={(e) => handleSizeChange(e, 'size_id')}
                    >
                      <option value='' disabled>
                        Select size
                      </option>
                      {sizeList.map((option) => (
                        <option key={`sizeList-${option.id}`} value={option.id}>
                          {option.size}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.size_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.size_id}
                      </p>
                    )}
                  </div>
                  <div className='cus_drop inputField__wrap'>
                    <label className='inputField__label'>Badge</label>
                    <select
                      id='badge'
                      className={cn({
                        empty: !bottleFormik.values.badge,
                      })}
                      value={bottleFormik.values.badge}
                      onChange={(e) => handleBadgeChange(e, 'badge')}
                    >
                      <option value='' disabled>
                        Badge options
                      </option>
                      {badgeSelectList.map((option) => (
                        <option key={`badgeSelectList-${option}`} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.badge && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.badge}
                      </p>
                    )}
                  </div>
                </div>
                <Notes bottleFormik={bottleFormik} action='add' />
              </Form>
            </Col>
            <Col xl={10} className='crtbtl-right'>
              <div className='inner'>
                <div className='Header'>
                  <h3 className='common-sub-heading'>Bottle Preview</h3>
                </div>
                <CardGrid
                  bgColor='#e0ddff'
                  bgImage={redWineBackgroundH}
                  wineBottleImage={bottleFormik.values.bottle_image || ''}
                  wineTitle={bottleFormik.values?.brand_name?.toUpperCase()}
                  winerTitle={winerNameText}
                  winerCountry={bottleFormik.values.country || ''}
                  wineMakerImage={dummyImg}
                  wineMaker={email || userEmail}
                  size={sizeText || ''}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  )
}
export default BottleCreate
