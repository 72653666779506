import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import "./PreviewWiNeFT.scss";
import CustomAccordion from "../../../components/common/CustomAccordion/CustomAccordion";
import RoyaltyIcon from "../../../Assets/image/svgimages/RoyaltyIcon.svg";
import descriptionIcon from "../../../Assets/image/svgimages/descriptionIcon.svg";
import aboutIcon from "../../../Assets/image/svgimages/aboutIcon.svg";
import UserSelected from "./Common/UserSelected";
import NftLayout from "../../../components/HOC/NftLayout";
import { draftNftPrev } from "../../../redux/_actions/nft.action";
import { useDispatch } from "react-redux";
import { Bottles } from "../../../redux/_actions";

const { getBottleDropDownSpecifications } = Bottles;

const DraftNft = (props) => {
  let dropDetails = props?.location?.state;
  const [vintageList, setVintageList] = useState([]);
  const [vintageText, setVintageText] = useState("");

  const dispatch = useDispatch();
  const [draftdata, setDraftdata] = useState({});

  useEffect(() => {
    getBottlesSpecification();
  }, []);

  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      let details = res.data;
      setVintageList(details.wineVintageModelList);
      getDraftNftPriview();
    });
  };

  useEffect(() => {
    if (Object.keys(draftdata).length) {
      vintageList.forEach((element) => {
        if (element.id === draftdata.vintage) {
          setVintageText(element.vintage);
        }
      });
    }
  }, [draftdata]);

  const getDraftNftPriview = () => {
    if (dropDetails.id) {
      let data = {
        id: dropDetails?.id,
      };

      dispatch(draftNftPrev(data)).then((res) => {
        setDraftdata(res.data.data);
      });
    }
  };

  return (
    <>
      <NftLayout>
        <div className="DraftNft">
          <UserSelected
            className="ScreenforMb"
            userTitle="Chateau de Baucastel"
            headingTitle="CHÂTEAU DE BEAUCASTEL CÔTES DU RHÔNE COUDOULET BLANC 2020"
            selectedUser="DRAFT"
            classUserSe="draftIcon"
            amount="39"
            editNft
            drop={dropDetails}
            draftData={draftdata}
          />

          <Grid>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <div className="BottleView">
                <img
                  src={draftdata?.bottle_data?.bottle_image}
                  alt="wine_image"
                  className="BottleImg"
                />
              </div>
              <CustomAccordion
                active={0}
                index={0}
                title="Key Details"
                IconTitle={RoyaltyIcon}
              >
                <div className="Containt">
                  <ul className="KeyValue">
                    <li className="Vauleitem">
                      <label>Winery</label>
                      <h6>
                        {draftdata?.bottle_data?.creator_meta?.winery_name ??
                          ""}
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Cuvee</label>
                      <h6>{draftdata?.bottle_data?.wine_name ?? ""}</h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Country</label>
                      {/* <h6>{draftdata?.bottle_data?.country ?? ""}</h6> */}
                      <h6>
                        {draftdata?.bottle_data?.creator_meta?.country ?? ""}
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Region</label>
                      <h6>
                        {draftdata?.bottle_data?.creator_meta?.regions
                          ?.region_name ?? ""}
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Appellation</label>
                      <h6>
                        {
                          draftdata.bottle_data?.creator_meta
                            ?.creator_appellation?.appellation
                        }
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Color</label>
                      <h6>
                        {draftdata?.bottle_data?.wine_color?.color_name ?? ""}
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Vineyard name or Cru</label>
                      <h6>{draftdata.bottle_data?.vineyard_name ?? ""}</h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Size</label>
                      <h6>{draftdata?.bottle_data?.wine_size?.size ?? ""}</h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Vintage</label>
                      <h6>{vintageText}</h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Sweetness of wine</label>
                      <h6>
                        {draftdata.bottle_data?.wine_sweetness?.name ?? ""}
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Classification</label>
                      <h6>{draftdata.bottle_data?.classification ?? ""}</h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Consist</label>
                      <h6>
                        {draftdata.bottle_data?.consist === 1 ? <>Still</> : ""}
                        {draftdata.bottle_data?.consist === 2 ? (
                          <>Sparkling</>
                        ) : (
                          ""
                        )}
                      </h6>
                    </li>
                    <li className="Vauleitem">
                      <label>Availability</label>
                      <h6>---</h6>
                    </li>
                  </ul>
                </div>
              </CustomAccordion>
              <CustomAccordion
                active={1}
                index={1}
                title="About winery"
                IconTitle={aboutIcon}
              >
                <div className="Containt">
                  <p>
                    {draftdata?.bottle_data?.creator_meta
                      ?.creator_meta_our_stroy?.text ?? ""}
                  </p>
                </div>
              </CustomAccordion>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <UserSelected
                className="ScreenTbpluse"
                userTitle={draftdata?.bottle_data?.creator_meta?.winery_name}
                headingTitle={
                  <>
                    {draftdata?.bottle_data?.creator_meta?.winery_name}&nbsp;
                    {draftdata?.bottle_data?.creator_meta?.regions?.region_name}
                    &nbsp;
                    {
                      draftdata.bottle_data?.creator_meta?.creator_appellation
                        ?.appellation
                    }
                    &nbsp;
                    {vintageText}
                  </>
                }
                selectedUser={draftdata?.drop_event_status}
                classUserSe="draftIcon"
                amount="35"
                editNft
                drop={dropDetails}
                draftData={draftdata}
              />
              <CustomAccordion
                active={2}
                index={2}
                title="Royalty Payments for Secondary Sale"
                IconTitle={RoyaltyIcon}
              >
                <div className="Containt">
                  <p>
                    Please note that secondary sale of wine will involve paying
                    out royalties to the winery and the Winechain platform.
                  </p>
                  <div className="listine">
                    <div className="VauleSelect">
                      <label>Winery:</label>
                      <h6>{draftdata?.royality_payment_profilt}%</h6>
                    </div>
                    <div className="VauleSelect">
                      <label>WineChain:</label>
                      <h6>{draftdata?.admin_royality_fee}%</h6>
                    </div>
                  </div>
                  <ul className="BtnList">
                    <li>{/* <button>Learn more</button> */}</li>
                    <li>{/* <button>View exemple</button> */}</li>
                  </ul>
                </div>
              </CustomAccordion>
              <CustomAccordion
                active={3}
                index={3}
                title="Description"
                IconTitle={descriptionIcon}
              >
                <div className="Containt">
                  <p>{draftdata?.bottle_data?.description ?? ""}</p>
                </div>
              </CustomAccordion>
            </Grid.Column>
          </Grid>
        </div>
      </NftLayout>
    </>
  );
};

export default DraftNft;
