import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Col, Button } from 'antd'
import BillingFields from './BillingField'
import { WareHouseService } from '../../../../Services/warehouse.service'
import { useParams } from 'react-router-dom'
import { toast } from '../../../../components/Toast/Toast'
import './ShipmentDetails.scss'

const ShipmentDetails = ({ data }) => {
  const {
    email,
    shippingFirstName,
    shippingLastName,
    country,
    state,
    streetDetails,
    city,
    zip,
    phone,
  } = data
  const { id } = useParams()

  const initialValues = {
    email,
    first_name: shippingFirstName,
    last_name: shippingLastName,
    country,
    state,
    streetDetails,
    city,
    zip,
    phone,
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      city: '${label} is not a valid number!',
    },
  }

  const submitForm = async (values) => {
    try {
      const res = await WareHouseService.postShippOrder(id, values)

      if (res.data.error !== true) {
        toast.success(res.data.error)
      } else {
        toast.error(res.data.message)
      }
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <div>
      <div className='tabOver'>
        <h3 className='tabOver__ownerDetails'>Owner invormation</h3>
        <Col xl={18} className='tabOver__col'>
          <Form
            name='basic'
            layout='vertical'
            initialValues={initialValues}
            autoComplete='off'
            className='wcForm'
            onFinish={submitForm}
            validateMessages={validateMessages}
          >
            <Form.Item name='email' label='Email Address' rules={[{ type: 'email' }]}>
              <Input placeholder='Ex. info@gmail.com' disabled />
            </Form.Item>
            <Form.Item name='first_name' label='First Name' rules={[{ required: true }]}>
              <Input placeholder='First name...' />
            </Form.Item>
            <Form.Item name='last_name' label='Last Name' rules={[{ required: true }]}>
              <Input placeholder='Last name...' />
            </Form.Item>
            <div className='tabOver__options'>
              <h3 className='tabOver__options_title'>delivery option</h3>
              <h3 className='tabOver__options_text'>Standard</h3>
            </div>
            <h3 className='tabOver__ownerDetails'>Delivery Address</h3>
            <BillingFields type='profileData' />
            {/* <Form.Item label='Delivery preference note'>
              <TextArea
                className='tabOver__options_comments'
                maxLength={100}
                style={{
                  height: 120,
                  resize: 'none',
                }}
                placeholder='Please be carful with my Wine!'
              />
            </Form.Item> */}

            <Form.Item shouldUpdate className='profile-button'>
              {({ getFieldsError, isFieldsTouched }) => (
                <Button
                  htmlType='submit'
                  className='sellWineft__subButton'
                  disabled={
                    !isFieldsTouched() ||
                    getFieldsError().filter(({ errors }) => errors.length).length > 0
                  }
                >
                  Update Address
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </div>
    </div>
  )
}
export default ShipmentDetails

ShipmentDetails.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    shippingFirstName: PropTypes.string,
    shippingLastName: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    streetDetails: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
}
