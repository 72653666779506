import React, { useMemo } from 'react'
import { Input, Checkbox as Check, Dropdown } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const TextField = (props) => {
  const { meta = {} } = props

  const inputProps = {
    type: props.type || 'text',
    className: 'form__input',
    name: props.input.name,
    id: props.input.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
    onBlur: props.input.onBlur,
    action: props.action,
    disabled: props.disabled,
    onPaste: props.onPaste,
    // label: "as"
  }
  let errorClassName = meta.touched && meta.error ? 'error-inputs' : ''
  return (
    <React.Fragment>
      <Input {...inputProps} className={errorClassName} autoComplete='off' />
      {meta.touched && meta.error ? (
        <div className='form__field-error'>
          {meta.error === 'is required' ? 'This field is required.' : meta.error}
        </div>
      ) : null}
    </React.Fragment>
  )
}

const File = ({
  id,
  input,
  type,
  input: { name },
  meta: { touched, error },
  accept,
  className,
  onImageChange,
}) => {
  const handleChange = (event, input) => {
    event.preventDefault()
    let imageFile = event.target.files[0]
    if (imageFile) {
      input.onChange(imageFile)
      onImageChange(event)
    }
  }

  return (
    <div>
      <input
        id={id}
        name={name}
        type={type}
        accept={input.accept}
        className={className}
        onChange={(e) => handleChange(e, input)}
      />
      {touched && error ? (
        <div className='form__field-error text-danger'>
          {error === 'is required' && 'This field is required'}
        </div>
      ) : null}
    </div>
  )
}

const AmountFields = (props) => {
  const { meta = {} } = props

  const inputProps = {
    className: 'form__input',
    name: props.input.name,
    id: props.input.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
    onKeyDown: props.onKeyDown,
    onBlur: props.input.onBlur,
    action: props.action,
    disabled: props.disabled,
    onPaste: props.input.onPaste,

    // label: "as"
  }

  let errorClassName = meta.touched && meta.error ? 'error-input' : ''
  return (
    <React.Fragment>
      <Input {...inputProps} className={errorClassName} />
    </React.Fragment>
  )
}

const CheckBox = (props) => {
  const { meta = {} } = props

  const checboxProps = {
    type: 'checkbox',
    className: 'form__checkbox',
    name: props.input.name,
    id: props.input.name,
    value: props.input.value ? props.input.value : props.input.name,
    defaultChecked: meta.uncontrolled ? props.defaultChecked : undefined,
    onChange: props.input.onChange,
    checked: props.input.checked,
    label: props.input.label,
  }

  return (
    <React.Fragment>
      <input {...checboxProps} />
      <label htmlFor={props.input.name}>{props.label}</label>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </React.Fragment>
  )
}

const CheckBoxs = (props) => {
  const { meta = {} } = props
  const checboxProps = {
    type: 'checkbox',
    className: 'form__checkbox',
    name: props.input.name,
    id: props.input.name,
    value: props.input.value ? props.input.value : props.input.name,
    onChange: props.input.onChange,
    checked: props.input.checked,
    label: props.input.label,
  }
  //
  let errorClassName = meta.touched && meta.error ? 'error-input errorChkbox' : ''

  return (
    <React.Fragment>
      <Check {...checboxProps} className={errorClassName} />
      <label htmlFor={props.input.name}>{props.label}</label>
    </React.Fragment>
  )
}

const SelectField = ({
  input,
  meta: { touched, error },
  children,
  placeholder,
  values,
  disabled,
}) => {
  return (
    <React.Fragment>
      <select {...input} disabled={disabled}>
        <option value=''> {placeholder} </option>
        {children.map((x) => (
          <option key={`key-${x.value}`} value={x.value}>
            {x.label}
          </option>
        ))}
      </select>
      {touched && error && (
        <div className='form__field-error'>
          {error === 'is required' ? 'This field is required' : error}
        </div>
      )}
    </React.Fragment>
  )
}

const DateTimeField = ({
  input,
  handleChange,
  placeholder,
  min = 0,
  max,
  meta: { touched, error },
  // timeInputLabel="Time:"
  // dateFormat="MM/dd/yyyy h:mm aa"
  showTimeInput,
  label,
  isRequired,
  onFocus,
  dateFormat,
}) => {
  const onChange = (event) => {
    input.onChange(Math.round(new Date(event).getTime() / 1000))
  }

  const date = useMemo(() => {
    if (!input.value) return input.value

    const formatDate = (ms) => {
      const date = new Date(ms * 1000)
      const dates = `0${date.getDate()}`.slice(-2)
      const year = date.getFullYear()
      const month = `0${date.getMonth() + 1}`.slice(-2)
      const hours = `0${date.getHours()}`.slice(-2)
      const minutes = `0${date.getMinutes()}`.slice(-2)
      const seconds = `0${date.getSeconds()}`.slice(-2)
      return `${year}-${month}-${dates} ${hours}:${minutes}:${seconds}`
    }

    const dateNumber = parseInt(input.value)
    if (typeof dateNumber === 'number' && Math.floor(input.value) === input.value) {
      return formatDate(dateNumber)
    }
    return input.value
  }, [input.value])

  input.value = date ? new Date(date) : input.value

  const options = {
    ...(dateFormat && { dateFormat }),
  }

  return (
    <div>
      {label ? (
        <label className='inputField__label'>
          {label}
          {isRequired && <sup>*</sup>}
        </label>
      ) : null}
      <DatePicker
        value={input.value || placeholder}
        name={input.name}
        selected={input.value || new Date().setDate(new Date().getDate() + min)}
        minDate={min ? new Date().setDate(new Date().getDate() + min) : new Date().setDate()}
        onChange={(event) => onChange(event)}
        onKeyDown={(e) => {
          e.preventDefault()
        }}
        onFocus={onFocus}
        showTimeInput={showTimeInput}
        {...options}
      />
      {touched && error && (
        <div className='form__field-error'>
          {error === 'is required' ? 'This field is required' : error}
        </div>
      )}
    </div>
  )
}

const TextAreaField = ({
  input,
  type,
  maxLength,
  formValue,
  placeholder,
  className,
  textarea,
  rows,
  meta: { touched, error, warning, invalid },
}) => {
  const textareaType = (
    <textarea
      {...input}
      placeholder={placeholder}
      type={type}
      rows={rows}
      value={formValue}
      maxLength={maxLength}
      className={className}
    />
  )

  const inputType = <input {...input} placeholder={placeholder} type={type} />

  return (
    <div>
      <div>
        {textarea ? textareaType : inputType}
        {touched &&
          ((error && (
            <span className='form__field-error text-danger'>
              {' '}
              {error === 'is required' ? 'This field is required' : error}{' '}
            </span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}

const SelectFieldWithOpt = ({
  input,
  meta: { touched, error },
  data,
  placeholder,
  values,
  disabled,
}) => {
  return (
    <React.Fragment>
      <select {...input} disabled={disabled}>
        <option value=''> {placeholder} </option>
        <option value='ADMIN' key='ADMIN'>
          Admin
        </option>
        {Object.keys(data).map((key) => (
          <optgroup label={key} key={`group-${key}`}>
            {data[key].map((x) => (
              <option key={x.id} value={x.id}>
                {x.title}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
      {touched && error && (
        <div className='form__field-error'>
          {' '}
          {error === 'is required' ? 'This field is required' : error}
        </div>
      )}
    </React.Fragment>
  )
}

const SelectWithSearch = ({
  meta: { touched, error, props },
  data,
  placeholder,
  values,
  disabled,
  getValue,
  name,
  input,
}) => {
  return (
    <React.Fragment>
      <div>
        <Dropdown
          fluid
          search
          {...input}
          options={data}
          value={input.value ?? data.find((dd) => dd.value === input.value)}
          onChange={(param, data) => {
            input.onChange(data.value)
          }}
        />
      </div>
      {touched && error && (
        <div className='form__field-error'>
          {' '}
          {error === 'is required' ? 'This field is required' : error}
        </div>
      )}
    </React.Fragment>
  )
}

const FormField = (props) => {
  switch (props.type) {
    case 'checkbox':
      return <CheckBox {...props} />
    case 'checkboxs':
      return <CheckBoxs {...props} />
    case 'select':
      return <SelectField {...props} />
    case 'amount':
      return <AmountFields {...props} />
    case 'file':
      return <File {...props} />
    case 'textarea':
      return <TextAreaField {...props} />
    case 'datetime':
      return <DateTimeField {...props} />
    case 'selectWithOpt':
      return <SelectFieldWithOpt {...props} />
    case 'selectWithSearch':
      return <SelectWithSearch {...props} />
    case 'input':
    default:
      return <TextField {...props} />
  }
}

export { TextField, CheckBox, FormField, CheckBoxs }
