import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { toast } from '../../../components/Toast/Toast'
import { SENDPULSE_URLS, SENDPULSE_STORAGE_TOKEN } from '../constants/constants'

const setSendPulseTokenToStorage = (token) => {
  localStorage.setItem(SENDPULSE_STORAGE_TOKEN, token)
}

const getAccessTokenFromStorage = () => {
  return localStorage.getItem(SENDPULSE_STORAGE_TOKEN)
}

/*
function check if access token is existed and valid - return boolean
 */
const isSendPulseTokenValid = () => {
  const oldToken = getAccessTokenFromStorage()

  // first call if localStorage is empty
  if (!oldToken) {
    return false
  }

  const decodedOldToken = jwtDecode(oldToken)
  const expireTime = (decodedOldToken.exp || 3600 - 5 * 60) * 1000
  const isExpired = expireTime <= new Date().getTime()

  return !isExpired
}

export const getSendPulseAuthorization = async () => {
  try {
    const isTokenValid = isSendPulseTokenValid()

    if (isTokenValid) {
      // if existed token is valid - use it
      const oldToken = getAccessTokenFromStorage()
      return `Bearer ${oldToken}`
    }

    const sendpulseAccessData = await axios.get(SENDPULSE_URLS.AUTH)

    if (!sendpulseAccessData?.data?.data?.access_token) {
      toast.error('Something went wrong - no data for sendpulse access_token')
      return false
    }

    const newToken = sendpulseAccessData?.data?.data?.access_token
    setSendPulseTokenToStorage(newToken)

    return `Bearer ${newToken}`
  } catch (err) {
    toast.error(`Something went wrong with getting sendpulse access_token, ${err}`)
    return false
  }
}
