export const EDIT_ADMIN = 'Admin'
export const EDIT_USER = 'User'
export const NOTIFICATION_LIMT = 10
export const BOTTLE_HIGHT = 1920
export const BOTTLE_WIDTH = 1080

export const BOTTLE_LABEL_HIGHT = 1080
export const BOTTLE_LABEL_WIDTH = 1920
export const USER_TYPE = 1
export const WINER_TYPE = 2

export const Max = (20, 'Please enter no between 1-20')

export const BOTTLE_SENT = 1
export const BOTTLE_RECIEVED = 2
export const BOTTLE_PUBLISHED = 3
export const BOTTLE_UN_PUBLISHED = 1

export const MARKETPLACE_NFT_STATUSES = {
  SOLD: 'SOLD',
  LISTED: 'LISTED',
  DRAFT: 'DRAFT',
}

export const urlsOfFrontend = {
  local: 'http://localhost:3010',
  dev: 'https://dev.winechain.co',
  demo: 'https://demo.winechain.co',
  prod: 'https://winechain.co',
}
