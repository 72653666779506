import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MainLayout from 'components/HOC/MainLayout/MainLayout'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from '../../../../components/Toast/Toast'
import DropCollectionForm from './dropCollectionForm/DropCollectionForm'
import { createDropCollection, getAllDropCollections } from 'redux/_actions/dropCollection.action'
import updateOrder from '../utils/updateOrder'

const DropCollectionCreate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { entries } = useSelector((state) => state?.dropCollection)

  useEffect(() => {
    dispatch(getAllDropCollections())
  }, [dispatch])

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const onSubmit = async (values) => {
    const successFn = (res) => {
      toast.success(res.data?.message)
      history.push('/panel/drop-collections')
    }
    const errorFn = (res) => {
      toast.error(res?.data?.message || 'Failed')
    }
    // to update order of collection if order is set for new collection
    if (values.order && values.order <= entries.length) {
      await updateOrder({ order: entries.length + 1 }, { ...values, order: +values.order }, [
        ...entries,
        values,
      ])
    }
    const orderObj = values.order ? { order: Math.min(values.order, entries.length + 1) } : {}
    dispatch(createDropCollection({ ...values, ...orderObj }, successFn, errorFn))
  }
  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>Drop Collection Create</h3>
      <Row>
        <Col span={14} offset={5}>
          <DropCollectionForm onSubmit={onSubmit} />
        </Col>
      </Row>
    </MainLayout>
  )
}

export default DropCollectionCreate
