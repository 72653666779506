const START_LOADING = '[LOADING] START_LOADING'
const STOP_LOADING = '[LOADING] STOP_LOADING'

const SAVE_LOGIN_DETAILS = '[AUTH] SAVE_LOGIN_DETAILS'
const RESET_PERSIST_ON_LOGOUT = '[AUTH] RESET_PERSIST_ON_LOGOUT'
const SAVE_COLLECTION = '[COLLECTION] SAVE_COLLECTION'
const SAVE_BANNER_DETAILS = '[BANNER] SAVE_BANNER_DETAILS'
const SAVE_BRAND = '[BRAND] SAVE_BRAND'
const SAVE_ARTIST = '[ARTIST] SAVE_ARTIST'
const SAVE_NFT_DETAILS = '[NFT] SAVE_NFT_DETAILS'
const SAVE_PALLETS_DETAILS = '[PALLETS] SAVE_PALLETS_DETAILS'
const SAVE_RAFFLES_DETAILS = '[RAFFLES] SAVE_RAFFLES_DETAILS'
const SAVE_ADDTYPE_DETAILS = '[ADDTYPE] SAVE_ADDTYPE_DETAILS'
const SAVE_ADDWINER = '[ADDTYPE] SAVE_ADDTYPE_DETAILS'
const SAVE_PALLETSNFTS_DETAILS = '[PALLETSNFTS] SAVE_PALLETSNFTS_DETAILS'
const SAVE_SHIPPING_ORDERS = '[WAREHOUSE->ORDERS - API] SAVE_PALLETSNFTS_DETAILS'

const Types = {
  START_LOADING,
  STOP_LOADING,
  SAVE_LOGIN_DETAILS,
  RESET_PERSIST_ON_LOGOUT,
  SAVE_COLLECTION,
  SAVE_BANNER_DETAILS,
  SAVE_BRAND,
  SAVE_ARTIST,
  SAVE_NFT_DETAILS,
  SAVE_RAFFLES_DETAILS,
  SAVE_ADDTYPE_DETAILS,
  SAVE_ADDWINER,
  SAVE_PALLETS_DETAILS,
  SAVE_PALLETSNFTS_DETAILS,
  SAVE_SHIPPING_ORDERS,
}

export default Types
