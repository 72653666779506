import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getAllDiscountCodes = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/getAllDiscountCodes`, data, headers)
}

const createDiscountCode = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/createDiscountCode`, data, headers)
}
const updateDiscountCode = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/updateDiscountCode`, data, headers) // has to be put
}

const getDiscountCode = (params, headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/getDiscountCodeById`, {}, headers, {
    disountCodeId: params.id,
  })
}

const checkIfDiscountCodeExists = (params, headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/checkIsDiscountCodeValueExists`, {}, headers, {
    discountCodeValue: params.discountCodeValue,
  })
}

const deleteDiscountCode = (data, headers) => {
  return fetch('post', `${API_HOST}/admin/api/v1/deleteDiscountCode`, data, headers) // has to be delete
}

export const DiscountCodeService = {
  getAllDiscountCodes,
  createDiscountCode,
  updateDiscountCode,
  getDiscountCode,
  deleteDiscountCode,
  checkIfDiscountCodeExists,
}
