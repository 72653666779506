import getWineName from "../../../../Helpers/getWineName";

const DropsTableWineName = ({ data }) => {
  const wineNameData = {
    winery_name: data?.bottle_data?.creator_meta?.winery_name,
    cuvee: data?.bottle_data?.wine_name,
    cru: data?.bottle_data?.vineyard_name,
    classification: data?.bottle_data?.classification,
    nft_name: data?.nft_name,
  };

  return getWineName(wineNameData)
};

export default DropsTableWineName;
