import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { toast } from '../../../../../components/Toast/Toast'

import '../../../Label/labelTableComponent/LabelTableComponent.scss'
import {
  DeleteSolidIcon,
  EditProfileIcon,
  IconDiamond,
} from '../../../../../Assets/image/svgimages/sVGImg'
import { updateDropCollection } from 'redux/_actions/dropCollection.action'

const DropdownMenu = ({ data, getAllEntries }) => {
  const dispatch = useDispatch()

  const enableCollection = (values) => {
    const successFn = (res) => {
      toast.success(res.data?.message)
      getAllEntries()
    }
    const errorFn = (res) => {
      toast.error(res?.data?.message || 'Failed')
    }

    dispatch(
      updateDropCollection({ id: values.id, is_enabled: !values.is_enabled }, successFn, errorFn),
    )
  }
  return (
    <div className='labelDropdownMenu'>
      <Link
        to={{
          pathname: `/panel/drop-collections/edit/${data.id}`,
          state: data,
        }}
      >
        <EditProfileIcon />
        Edit Drop Collection
      </Link>

      <Link to='#' onClick={() => enableCollection(data)}>
        {data.is_enabled ? (
          <>
            <DeleteSolidIcon />
            Disable Drop Collection
          </>
        ) : (
          <>
            <IconDiamond />
            Enable Drop Collection
          </>
        )}
      </Link>
    </div>
  )
}

DropdownMenu.defaultProps = {}
DropdownMenu.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    is_enabled: PropTypes.bool,
  }).isRequired,
  getAllEntries: PropTypes.func.isRequired,
}

export default DropdownMenu
