import React from "react";
import { Icon, Input, Dropdown, Table, Pagination } from "semantic-ui-react";
import { Plusicon } from "../../../Assets/image/svgImg";
import { ButtonCustom } from "../../../components/button/ButtonCustom";
import DropdownEdit from "../../../components/common/DropdownEdit/DropdownEdit";
import "./WinemakerDetails.scss";

const options = [
  { key: "user", text: "User", value: "user" },
  { key: "Winers", text: "Winers", value: "Winers" },
];

const option = [
  { key: "all", text: "All", value: "" },
  { key: "pending", text: "Pending", value: 0 },
  { key: "approved", text: "Approved", value: 1 },
  { key: "rejected", text: "Rejected", value: 2 },
];

const option2 = [
  { key: "1", text: "1", value: "1" },
  { key: "2", text: "2", value: "2" },
  { key: "3", text: "3", value: "3" },
  { key: "4", text: "4", value: "4" },
  { key: "5", text: "5", value: "5" },
  { key: "6", text: "6", value: "6" },
  { key: "7", text: "7", value: "7" },
];

const BottlesTab = () => {
  return (
    <div className="detail-tab">
      <div className="filltersUser">
        <div className="filltersBottom">
          <div className="left_fill">
            <Dropdown placeholder="Last Updated" selection options={options} />
            <div className="sorting">
              <span>Sort by</span>
              <Dropdown placeholder="Oldest" selection options={option} />
            </div>
            <Input
              className="search_fld"
              icon={<Icon name="search" inverted link />}
              placeholder="Ex. Bottle id, winery, wine name"
            />
            <div className="sorting">
              <Dropdown placeholder="all" selection options={option} />
            </div>
          </div>
          <div className="rightfill">
            <div className="sorting">
              <span>Show</span>
              <Dropdown
                placeholder="10"
                selection
                options={option2}
                className="autowidth"
              />
            </div>
            <div className="sorting">
              <span>Entries</span>
              <Dropdown
                placeholder="10"
                selection
                options={option2}
                className="autowidth"
              />
            </div>
            <ButtonCustom
              // onClick={AddNewArtist}
              title="Add Bottle"
              leftIcon={<Plusicon />}
            />
          </div>
        </div>
      </div>
      <div className="Table_Editing">
        <Table className="custom_table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Bottle Id</Table.HeaderCell>
              <Table.HeaderCell colSpan="1">Winery</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Creation Date</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Wine Name</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Region</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Country</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Size</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Vintage</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Color</Table.HeaderCell>
              <Table.HeaderCell
                colSpan="1"
                className="TableEditBtn"
              ></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                0193234
              </Table.Cell>
              <Table.Cell collapsing colSpan="1" textAlign="left">
                Northern Lights Vineyard And Winery
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                10/01/2022
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                Penfolds
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                AVA
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                US
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                750 ml
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                1998
              </Table.Cell>
              <Table.Cell collapsing colSpan="2" textAlign="left">
                white
              </Table.Cell>
              <Table.Cell collapsing colSpan="1" textAlign="left">
                <DropdownEdit icon="ellipsis vertical" postion="left" />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div className="table_pagi new">
        <div className="sorting">
          <span>Show On Page &nbsp;</span>
          <Dropdown
            placeholder="10"
            selection
            options={option2}
            className="autowidth SortingIcon"
          />
        </div>

        <Pagination
          firstItem={false}
          lastItem={false}
          defaultActivePage={1}
        />
      </div>
    </div>
  );
};

export default BottlesTab;
