import React, { useEffect } from 'react'
import EditProfilePage from '../Profile/EditProfilePage/EditProfilePage'
import 'antd/dist/antd.min.css'
import './CreateWinemakers.scss'

const CreateWinemakers = (props) => {
  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  return (
    <>
      <EditProfilePage createsNew />
    </>
  )
}

export default CreateWinemakers
