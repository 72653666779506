import React from "react";
import { Link } from "react-router-dom";
import {
  IconBackLong,
  IconEditWhite,
} from "../../../../Assets/image/svgimages/sVGImg";
import { ButtonCustom } from "../../../../components/button/ButtonCustom";
import "../PreviewWiNeFT.scss";
import CountProgress from "./CountProgress";

const UserSelected = (props) => {
  let {
    userTitle,
    headingTitle,
    selectedUser,
    className,
    classUserSe,
    editNft,
    endTime,
    drop,
    draftData,
  } = props;

  return (
    <>
      <div className={`ChateauComponent ${className}`}>
        <button className="backBtn">
          <Link to="/panel/Dropevents">
            <IconBackLong /> Back to Drop list
          </Link>
        </button>
        <div className="Wraaping">
          <h4>{userTitle}</h4>
          <h1>{headingTitle}</h1>
          <span className={`UserSelectedIcon ${classUserSe}`}>
            {selectedUser}
          </span>
          <div className="PricingList">
            <div className="Pricing">
              <label>Price:</label>
              <h2>
                {draftData?.price}
                <span className="AmountCountry"> USDC</span>
                <span>
                  (<i class="dollar sign icon"></i>
                  {draftData?.usd_coin?.usd} /<i class="euro sign icon"></i>{" "}
                  {draftData?.usd_coin?.eur})
                </span>
              </h2>
            </div>
            {editNft &&
              (draftData?.drop_event_status === "DRAFT" ||
                draftData?.drop_event_status === "CURRENT") && (
                <div className="editbtn">
                  <Link
                    to={{
                      pathname: "/panel/EditWineft",
                      state: drop,
                    }}
                  >
                    <ButtonCustom
                      type="button"
                      title="Edit NFT"
                      customClass="Continue"
                      leftIcon={<IconEditWhite />}
                    />
                  </Link>
                </div>
              )}
          </div>
        </div>
        <hr className="LineSpacing" />

        {endTime && (
          <>
            <div className="TimeEnd">
              <label>
                DROP EVENT ENDS BY: <span>22 hours</span>
              </label>

              <CountProgress />
            </div>

            <hr className="LineSpacing" />
          </>
        )}
      </div>
    </>
  );
};

export default UserSelected;
