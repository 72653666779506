import React from "react";
import { useSelector } from "react-redux";
import { Icon, Input, Dropdown, Table, Pagination } from "semantic-ui-react";
import { Plusicon } from "../../../Assets/image/svgImg";
import { ButtonCustom } from "../../../components/button/ButtonCustom";
import { useHistory } from "react-router";
import "../WinemakerDetails/WinemakerDetails.scss";
import LabelTableComponent from "./labelTableComponent/LabelTableComponent";

const sortByOption = [
  { key: "1", text: "Newest", value: "desc" },
  { key: "2", text: "Oldest", value: "asc" },
];

const option2 = [
  { key: "1", text: "Last Updated", value: "0" },
  { key: "2", text: "Last Week", value: "1" },
  { key: "3", text: "Last Month", value: "2" },
];
const optionLimit = [
  { key: "1", text: 10, value: "10" },
  { key: "2", text: 15, value: "15" },
  { key: "3", text: 20, value: "20" },
];

const LabelTab = ({
  setStatus,
  setOrder,
  setSearch,
  search,
  limit,
  setOffset,
  onDeleteBottle,
  getVintageText,
  setLimit,
}) => {
  const bottleList = useSelector((state) => state?.bottles?.bottleList);
  const totalBottle = useSelector((state) => state?.bottles?.totalBottle);
  const history = useHistory();

  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage;
    setOffset(offset);
  };

  return (
    <div className="detail-tab">
      <div className="filltersUser">
        <div className="filltersBottom">
          <div className="left_fill">
            <Dropdown
              defaultValue="0"
              selection
              options={option2}
              onChange={(e, data) => {
                if (data?.value === "0") {
                  setStatus("");
                } else {
                  setStatus(data?.value);
                }
              }}
            />
            <div className="sorting">
              <span>Sort by</span>

              <Dropdown
                defaultValue="desc"
                placeholder="Oldest"
                selection
                options={sortByOption}
                onChange={(e, data) => {
                  if (data?.value === "desc") {
                    setOrder("");
                  } else {
                    setOrder(data?.value);
                  }
                }}
              />
            </div>
            <Input
              className="search_fld"
              icon={<Icon name="search" inverted link />}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Ex. Search id, country, Winery"
            />
          </div>
          <div className="rightfill">
            <div className="sorting">
              <span>Show</span>
              <Dropdown
                value={limit.toString()}
                selection
                options={optionLimit}
                className="autowidth"
                onChange={(e, data) => {
                  setLimit(data.value);
                }}
              />
            </div>
            <div className="sorting">
              <span>Entries</span>
              <Dropdown
                placeholder={totalBottle ? totalBottle.toString() : "0"}
                selection
                options={optionLimit}
                className="autowidth"
                disabled
              />
            </div>
            <ButtonCustom
              onClick={() => history.push("/panel/bottles/create-bottle")}
              title="Add Bottle"
              leftIcon={<Plusicon />}
            />
          </div>
        </div>
      </div>
      <div className="Table_Editing">
        <Table className="custom_table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Bottle Id</Table.HeaderCell>
              <Table.HeaderCell colSpan="1">Winery</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Creation Date</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Name Of Wine</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Country</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Region</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Size</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Vintage</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Color</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Notifications</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Status</Table.HeaderCell>
              <Table.HeaderCell
                colSpan="1"
                className="TableEditBtn"
              ></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bottleList?.map((item) => (
              <LabelTableComponent
                key={item.id}
                item={item}
                getVintageText={getVintageText}
                onDeleteBottle={onDeleteBottle}
              />
            ))}
            {totalBottle === 0 && (
              <Table.Row>
                <Table.Cell collapsing colSpan="20" textAlign="center">
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className="table_pagi new">
        <div className="sorting">
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={optionLimit}
            onChange={(e, data) => {
              setLimit(data.value);
            }}
            className="autowidth SortingIcon"
          />
        </div>

        {totalBottle > limit && (
          <Pagination
            className="ListPagi"
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            defaultActivePage={1}
            onPageChange={handlePageClick}
            totalPages={Math.ceil(parseInt(totalBottle) / limit)}
          />
        )}
      </div>
    </div>
  );
};

export default LabelTab;
