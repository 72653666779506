import moment from 'moment'
import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import { DeleteIcon, DocIcon } from '../../../Assets/image/svgimages/sVGImg'
import CheckBox from '../../../components/common/CheckBox/CheckBox'
import InputField from '../../../components/common/InputField/InputField'
import { addExclusiveMember, getDeleteMyFollowers } from '../../../redux/_actions/exclusive.action'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const FollowersTab = (props) => {
  const [isAll, setIsAll] = useState(false)
  let {
    followers,
    setFollowers,
    searchFollowers,
    setSearchFollowers,
    getFollowersList,
    getDeleteMyFollowers,
    addExclusiveMember,
    userId,
  } = props

  const setAllCheckedUnchecked = (e, data) => {
    setIsAll(data.checked)
    let selected = [...followers]
    selected.forEach((element) => {
      element.checked = data.checked
    })
    setFollowers(selected)
  }

  const onChooseFollowers = (index) => {
    let selected = [...followers]
    setIsAll(false) // so that seletcted all unchecked
    selected[index]['checked'] = !selected[index]['checked']
    setFollowers(selected)
  }

  const deleteMyRecords = () => {
    let selected = [...followers]
    let deletedItems = []
    selected.forEach((element) => {
      if (element.checked && element.is_member) {
        let item = {
          email: element.email,
        }
        deletedItems.push(item)
      }
    })
    if (deletedItems.length > 0) {
      let final = {
        userId: userId,
        data: deletedItems,
      }

      // call api here
      callDeleteFollowersApi(final)
    }
  }
  const showIfUserIsMember = () => {
    let selected = [...followers]
    let deletedItems = []
    selected.forEach((element) => {
      if (element.is_member) {
        let item = {
          email: element.email,
        }
        deletedItems.push(item)
      }
    })
    return deletedItems.length > 0
  }
  const provideAccess = () => {
    let selected = [...followers]
    let permisionItem = []
    selected.forEach((element) => {
      if (element.checked && !element.is_member) {
        permisionItem.push(element.email)
      }
    })
    if (permisionItem.length > 0) {
      let final = {
        userId: userId,
        data: permisionItem,
      }

      // call api here
      addExclusiveMemberApi(final)
    }
  }

  const deleteMeAsSingle = (user) => {
    let item = {
      userId: userId,
      data: [{ email: user.email }],
    }

    callDeleteFollowersApi(item)
  }

  const accessMeAsSingle = (email) => {
    let item = {
      userId: userId,

      data: [email],
    }

    addExclusiveMemberApi(item)
  }

  const callDeleteFollowersApi = (final) => {
    getDeleteMyFollowers(final).then((res) => {
      setIsAll(false)
      getFollowersList()
    })
  }

  const addExclusiveMemberApi = (final) => {
    addExclusiveMember(final).then((res) => {
      setIsAll(false)
      getFollowersList()
    })
  }

  return (
    <div className='tabs__content'>
      <h3>Followers ({followers.length})</h3>
      <InputField
        placeholder='Ex. infogmail@gmail.com'
        value={searchFollowers}
        onChange={(e) => setSearchFollowers(e.target.value)}
      />
      <Table className='custom_table'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='1'>
              <CheckBox checked={isAll} onChange={(e, data) => setAllCheckedUnchecked(e, data)} />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan='1'>ID</Table.HeaderCell>
            <Table.HeaderCell colSpan='2'>Email</Table.HeaderCell>
            <Table.HeaderCell colSpan='1'>Date</Table.HeaderCell>
            <Table.HeaderCell colSpan='2'>
              <span className='flex light-text fw-600' onClick={() => provideAccess()}>
                <DocIcon /> Provide access
              </span>
            </Table.HeaderCell>
            {showIfUserIsMember() && (
              <Table.HeaderCell colSpan='2' textAlign='right'>
                <span className='flex light-text fw-600' onClick={() => deleteMyRecords()}>
                  <DeleteIcon /> Delete all
                </span>
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {followers.map((data, index) => {
            return (
              <Table.Row key={data.id}>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  <CheckBox
                    checked={data.checked}
                    id={data.id}
                    onChange={() => onChooseFollowers(index)}
                  />
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  {data.id}
                </Table.Cell>
                <Table.Cell collapsing colSpan='2' textAlign='left'>
                  {data?.email}{' '}
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left' className='light-text'>
                  {data.createdAt ? moment(data.createdAt).format('DD/MM/YYYY') : 'N/A'}
                </Table.Cell>
                <Table.Cell
                  collapsing
                  colSpan='2'
                  textAlign='left'
                  className='light-text'
                ></Table.Cell>
                <Table.Cell collapsing colSpan='2' textAlign='right'>
                  <span className='flex deleteIcon'>
                    {/* use class "clickable-icon--disable" for disable icon */}

                    <Link
                      to='#'
                      className={data?.is_member ? 'clickable-icon--disable' : 'clickable-icon'}
                      onClick={() => {
                        if (!data?.is_member) {
                          accessMeAsSingle(data.email)
                        }
                      }}
                    >
                      <DocIcon />
                    </Link>
                    <Link
                      to='#'
                      className={
                        data?.is_member === false ? 'clickable-icon--disable' : 'clickable-icon'
                      }
                      onClick={() => {
                        if (data?.is_member) {
                          deleteMeAsSingle(data)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </Link>
                  </span>
                </Table.Cell>
              </Table.Row>
            )
          })}
          {followers.length === 0 && (
            <Table.Row>
              <Table.Cell collapsing colSpan='1' textAlign='left' data-title='Code'>
                No Records found
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeleteMyFollowers: (data) => dispatch(getDeleteMyFollowers(data)),
    addExclusiveMember: (data) => dispatch(addExclusiveMember(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowersTab)
