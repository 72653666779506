import React, { useState } from 'react'
import { IconRight } from '../../../../../Assets/image/svgimages/sVGImg'
import './ActualVersion.scss'
import Modalaction from './ModalAction/Modalaction'

const ActualVersion = (props) => {
  const [moreField, setMoreField] = useState({})
  let { data, countryWithRegion } = props
  const [open, setOpen] = useState(false)
  const setViewFieldData = (oldItem, newItem, title) => {
    let item = {
      oldItem: oldItem,
      newItem: newItem,
      title: title,
    }
    setMoreField(item)
    setOpen(true)
  }

  const getRegionOld = (location) => {
    let region = ''
    // countryWithRegion includes all the country with region
    if (Object.keys(countryWithRegion).length) {
      for (let key in countryWithRegion) {
        // finding the region that matches user country
        if (key === data?.oldValues?.country) {
          // comparing with the region id so that region name will display as logs
          countryWithRegion[key].forEach((element) => {
            if (element.id === location) {
              region = element.region_name
            }
          })
        }
      }
      return region
    }
  }

  const getRegionLatest = (location) => {
    let region = ''
    // countryWithRegion includes all the country with region
    if (Object.keys(countryWithRegion).length) {
      for (let key in countryWithRegion) {
        // finding the region that matches user country
        if (key === data?.newValues.country) {
          // comparing with the region id so that region name will display as logs
          countryWithRegion[key].forEach((element) => {
            if (element.id === location) {
              region = element.region_name
            }
          })
        }
      }
      return region
    }
  }
  return (
    <>
      <div className='actualVersion'>
        {data !== null && Object.keys(data).length > 0 && (
          <ul>
            {data?.newValues?.winery_name && (
              <li>
                <div className='InformShow'>
                  <strong>Winery name </strong>
                  <button
                    onClick={() =>
                      setViewFieldData(
                        data?.oldValues?.winery_name,
                        data?.newValues?.winery_name,
                        'Winery name',
                      )
                    }
                  >
                    ...More
                  </button>
                </div>
                <div className='listing'>
                  <p>{data?.oldValues?.winery_name}</p>
                  <IconRight />
                  <p>{data?.newValues?.winery_name}</p>
                </div>
              </li>
            )}

            {data?.newValues?.country && (
              <li>
                <div className='InformShow'>
                  <strong>Country</strong>
                  <button
                    onClick={() =>
                      setViewFieldData(
                        data?.oldValues?.country,
                        data?.newValues?.country,
                        'Country',
                      )
                    }
                  >
                    ...More
                  </button>
                </div>
                <div className='listing'>
                  <p>{data?.oldValues?.country}</p>
                  <IconRight />
                  <p>{data?.newValues?.country}</p>
                </div>
              </li>
            )}
            {data?.newValues?.location && (
              <li>
                <div className='InformShow'>
                  <strong>Region</strong>
                  <button
                    onClick={() =>
                      setViewFieldData(
                        getRegionOld(data?.oldValues?.location),
                        getRegionLatest(data?.newValues?.location),
                        'Region',
                      )
                    }
                  >
                    ...More
                  </button>
                </div>
                <div className='listing'>
                  <p>{getRegionOld(data?.oldValues?.location)}</p>
                  <IconRight />
                  <p>{getRegionLatest(data?.newValues?.location)}</p>
                </div>
              </li>
            )}

            {data?.newValues?.winemaker_name && (
              <li>
                <div className='InformShow'>
                  <strong>Winermaker name</strong>
                  <button
                    onClick={() =>
                      setViewFieldData(
                        data?.oldValues?.winemaker_name,
                        data?.newValues?.winemaker_name,
                        'Winermaker name',
                      )
                    }
                  >
                    ...More
                  </button>
                </div>
                <div className='listing'>
                  <p>{data?.oldValues?.winemaker_name}</p>
                  <IconRight />
                  <p>{data?.newValues?.winemaker_name}</p>
                </div>
              </li>
            )}

            {data?.newValues?.profile_image && (
              <li>
                <div className='InformShow'>
                  <strong>Profile Image</strong>
                  <button
                    onClick={() =>
                      setViewFieldData(
                        data?.oldValues?.profile_image,
                        data?.newValues?.profile_image,
                        'Profile Image',
                      )
                    }
                  >
                    ...More
                  </button>
                </div>
                <div className='listing Img_fixed'>
                  <img src={data?.oldValues?.profile_image} alt='' />
                  <IconRight />
                  <img src={data?.newValues?.profile_image} alt='' />
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
      {open && (
        <Modalaction
          data={moreField}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        />
      )}
    </>
  )
}

export default ActualVersion
