import React from "react";
import ModalStyle from "../../../../components/Modal/ModalStyle.jsx";
import "./WineftModal.scss";

const WineftModal = ({ wineftopen, onClose, onWineftopen, handleOnSubmit }) => {
  return (
    <ModalStyle
      onClose={onClose}
      onOpen={onWineftopen}
      open={wineftopen}
      handleOnSubmit={handleOnSubmit}
      cancelBtnSec
      CancelText="No"
      actionText="Yes"
      title="Send for approval"
      className="Wineft__modal"
      classFooter="BtnSendaccess"
    >
      <div className="Wineft__modal--content">
        <div className="Wineft__modal--head">
          {/* <h3>Add offers from CSV file</h3> */}
          <p>
            The NFT information will be sent to the winery for approval, if you
            do not receive the response within 48 hours, please try to contact
            the winery to confirm this NFT.
          </p>
        </div>
      </div>
    </ModalStyle>
  );
};

export default WineftModal;
