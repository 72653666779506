const API_HOST = process.env.REACT_APP_API_HOST;
const DATA_ENCRYPT_KEY = process.env.REACT_APP_DATA_ENCRYPT_KEY;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const REACT_APP_API_HOST_CORE = process.env.REACT_APP_API_HOST_CORE;

const Environments = {
  API_HOST,
  DATA_ENCRYPT_KEY,
  SOCKET_URL,
  REACT_APP_API_HOST_CORE,
};

export default Environments;
