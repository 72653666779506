const initialState = {
  bredCrum: '',
}

const breadCrum = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case 'SAVE_BREAD_CRUM':
      return { ...state, bredCrum: payload }

    default:
      return state
  }
}

export default breadCrum
