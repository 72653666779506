import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'

import { sliceDateMonthTo3Symbol, walletIdHide } from '../../../../utils/helper'
import './ItemOverCard.scss'

const ItemOverCard = ({ data }) => {
  const {
    wineryId,
    createdAt,
    dateOfArrival,
    address,
    requestedToBurnDate,
    burnDate,
    palleteId,
    physicalId,
    trackingId,
    trackingLink,
    estDeliveryDate,
    actDelDay,
    actShippDay,
    estShippedDay,
    packaging,
    id: nftId,
  } = data
  return (
    <div>
      <Row className='tabsOver_wrapp'>
        {wineryId && (
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Winery ID:</p>
            <p className='tabsOver_details'>{wineryId}</p>
          </Col>
        )}
        <Col span={9} className='tabsOver'>
          <p className='tabsOver_title'>Create bottle Date:</p>
          <p className='tabsOver_details'>{sliceDateMonthTo3Symbol(createdAt)}</p>
        </Col>
        <Col span={4} className='tabsOver'>
          <p className='tabsOver_title'>Date of Arrival:</p>
          <p className='tabsOver_details'>
            {dateOfArrival ? sliceDateMonthTo3Symbol(dateOfArrival) : '-'}
          </p>
        </Col>
      </Row>
      <Row className='tabsOver_wrapp'>
        {address && (
          <Col span={9} className='tabsOver'>
            <p className='tabsOver_title'>Owner:</p>
            <p className='tabsOver_details'>@{walletIdHide(address)}</p>
          </Col>
        )}

        <Col span={9} className='tabsOver'>
          <p className='tabsOver_title'>Requested to burn:</p>
          <p className='tabsOver_details'>
            {requestedToBurnDate ? sliceDateMonthTo3Symbol(requestedToBurnDate) : '-'}
          </p>
        </Col>
        <Col span={4} className='tabsOver'>
          <p className='tabsOver_title'>Burn Date:</p>
          <p className='tabsOver_details'>{burnDate ? sliceDateMonthTo3Symbol(burnDate) : '-'}</p>
        </Col>
      </Row>
      <Row className='tabsOver_wrapp'>
        <Col span={7} className='tabsOver'>
          <p className='tabsOver_title'>Pallete ID:</p>
          <p className='tabsOver_details'>{palleteId}</p>
        </Col>
        <Col span={6} className='tabsOver'>
          <p className='tabsOver_title'>Type of box:</p>
          <p className='tabsOver_details'>{packaging}</p>
        </Col>

        <Col span={5} className='tabsOver'>
          <p className='tabsOver_title'>NFT ID:</p>
          <p className='tabsOver_details'>{nftId}</p>
        </Col>

        <Col span={5} className='tabsOver'>
          <p className='tabsOver_title'>Physical Box ID:</p>
          <p className='tabsOver_details'>{physicalId ? `${physicalId.slice(0, 10)}...` : '---'}</p>
        </Col>
      </Row>
      <Row className='tabsOver_wrapp'>
        <Col span={7} className='tabsOver'>
          <p className='tabsOver_title'>Traking ID</p>
          <p className='tabsOver_details'>{trackingId ? trackingId : '---'}</p>
        </Col>
        <Col span={6} className='tabsOver'>
          <p className='tabsOver_title'>Traking Link</p>
          {trackingLink && (
            <a
              href={trackingLink}
              target='_blank'
              rel='noreferrer'
              className='tabsOver_details_link'
            >
              {trackingId}
            </a>
          )}
          {!trackingLink && <p className='tabsOver_details'>---</p>}
        </Col>
      </Row>
      <Row className='tabsOver_wrappLast'>
        <Col span={7} className='tabsOver'>
          <p className='tabsOver_title'>Est. delivery date</p>
          <p className='tabsOver_details'>{estDeliveryDate ? estDeliveryDate : '-'}</p>
        </Col>
        <Col span={6} className='tabsOver'>
          <p className='tabsOver_title'>Act. delivery date</p>
          <p className='tabsOver_details'>{actDelDay ? actDelDay : '-'}</p>
        </Col>
        <Col span={5} className='tabsOver'>
          <p className='tabsOver_title'>Act. shipped date</p>
          <p className='tabsOver_details'>{actShippDay ? actShippDay : '-'}</p>
        </Col>
        <Col span={5} className='tabsOver'>
          <p className='tabsOver_title'>Est. shipped date</p>
          <p className='tabsOver_details'>{estShippedDay ? estShippedDay : '-'}</p>
        </Col>
      </Row>
    </div>
  )
}
export default ItemOverCard

ItemOverCard.propTypes = {
  data: PropTypes.shape({
    wineryId: PropTypes.number,
    createdAt: PropTypes.string,
    dateOfArrival: PropTypes.string,
    address: PropTypes.string,
    requestedToBurnDate: PropTypes.string,
    burnDate: PropTypes.string,
    palleteId: PropTypes.string,
    typeBox: PropTypes.string,
    physicalId: PropTypes.string,
    trackingId: PropTypes.string,
    trackingLink: PropTypes.string,
    estDeliveryDate: PropTypes.string,
    actDelDay: PropTypes.string,
    actShippDay: PropTypes.string,
    estShippedDay: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
}
