import React from 'react'
import { Dropdown, Grid, Table } from 'semantic-ui-react'
import './PreviewWiNeFT.scss'
import wineimg2 from '../../../Assets/image/wineimg2.png'
import CustomAccordion from '../../../components/common/CustomAccordion/CustomAccordion'
import RoyaltyIcon from '../../../Assets/image/svgimages/RoyaltyIcon.svg'
import descriptionIcon from '../../../Assets/image/svgimages/descriptionIcon.svg'
import aboutIcon from '../../../Assets/image/svgimages/aboutIcon.svg'
import Timer from '../../../Assets/image/svgimages/Timer.svg'
import UserSelected from './Common/UserSelected'
import NftLayout from '../../../components/HOC/NftLayout'
import {
  IconCeleb,
  IconCreated,
  IconDoller,
  IconFinish,
} from '../../../Assets/image/svgimages/sVGImg'

const MarketPlaceNft = () => {
  const DetailsKeyContent = [
    {
      title: 'Winery:',
      Info: 'Chateau de Baucastel',
    },
    {
      title: 'Cuvee:',
      Info: 'Les Combettles',
    },
    {
      title: 'Country:',
      Info: 'France',
    },
    {
      title: 'Region:',
      Info: 'Sonoma or Bordeaux',
    },
    {
      title: 'Appellation:',
      Info: 'Russian river valley or Pomerol',
    },
    {
      title: 'Color:',
      Info: 'Red',
    },
    {
      title: 'Vineyard name or Cru:',
      Info: 'Jacuzzi Family Vineyards',
    },
    {
      title: 'Size:',
      Info: 'Jacuzzi Family Vineyards',
    },
    {
      title: 'Vintage:',
      Info: '2020',
    },
    {
      title: 'Sweetness of wine:',
      Info: 'Dry',
    },
    {
      title: 'Classification:',
      Info: 'Grand Cru',
    },
    {
      title: 'Classification:',
      Info: 'Still',
    },
    {
      title: 'Availability:',
      Info: 'Upcoming:',
    },
  ]

  const option = [
    { key: 'all', text: 'All', value: '' },
    { key: 'pending', text: 'Pending', value: 0 },
    { key: 'approved', text: 'Approved', value: 1 },
    { key: 'rejected', text: 'Rejected', value: 2 },
  ]
  return (
    <>
      <NftLayout>
        <div className='MarketPlaceNft'>
          <UserSelected
            className='ScreenforMb'
            userTitle='Chateau de Baucastel'
            headingTitle='CHÂTEAU DE BEAUCASTEL CÔTES DU RHÔNE COUDOULET BLANC 2020'
            selectedUser='MARKETPLACE'
            classUserSe='MarketIcon'
            amount='998'
          />
          <Grid>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <div className='BottleView'>
                <img src={wineimg2} alt='wine_image' className='BottleImg' />
              </div>
              <CustomAccordion active={0} index={0} title='Key Details' IconTitle={RoyaltyIcon}>
                <div className='Containt'>
                  <ul className='KeyValue'>
                    {DetailsKeyContent.map((item) => (
                      <li className='Vauleitem' key={`idx-${item.title}`}>
                        <label>{item?.title}</label>
                        <h6>{item?.Info}</h6>
                      </li>
                    ))}
                  </ul>
                </div>
              </CustomAccordion>
              <CustomAccordion active={1} index={1} title='About winery' IconTitle={aboutIcon}>
                <div className='Containt'>
                  <p>
                    In 1321, under the reign of Pope Jean XXII, four barrels were brought from the
                    papal cellar to be filled with wine in Châteauneuf. Then, the Popes planted new
                    vines and the legend of the papal wine began.
                  </p>
                </div>
              </CustomAccordion>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <UserSelected
                className='ScreenTbpluse'
                userTitle='Chateau de Baucastel'
                headingTitle='CHÂTEAU DE BEAUCASTEL CÔTES DU RHÔNE COUDOULET BLANC 2020'
                selectedUser='MARKETPLACE'
                classUserSe='MarketIcon'
                amount='998'
              />
              <CustomAccordion
                active={2}
                index={2}
                title='Royalty Payments for Secondary Sale'
                IconTitle={RoyaltyIcon}
              >
                <div className='Containt'>
                  <div className='Table_Editing'>
                    <Table className='custom_table'>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan='1' className='spaingTable'>
                            Event
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan='2' className='spaingTable'>
                            Price ($)
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan='2'>Trade Profit</Table.HeaderCell>
                          <Table.HeaderCell colSpan='2'>Royalty (20%)</Table.HeaderCell>
                          <Table.HeaderCell colSpan='2'>Fee (10%)</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            Drop Event
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1000
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            Secondary trade 1
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1100
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            100
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            20
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            10
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            Secondary trade 2
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1500
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            400
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            80
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            40
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </CustomAccordion>
              <CustomAccordion active={3} index={3} title='Item activity' IconTitle={Timer}>
                <div className='Containt'>
                  <div className='ItemActive'>
                    <Dropdown placeholder='All' selection options={option} className='autowidth' />
                  </div>
                  <div className='Table_Editing'>
                    <Table className='custom_table'>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan='2'>Date</Table.HeaderCell>
                          <Table.HeaderCell colSpan='1' className='spaingTable'>
                            Event
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan='2' className='spaingTable'>
                            Price
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan='2' className='spaingTable'>
                            QTY
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan='2'>From</Table.HeaderCell>
                          <Table.HeaderCell colSpan='2'>To</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1 hour ago
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            <IconFinish />
                            Finished
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1 hour ago
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            <IconDoller />
                            Sale
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            50 WNC
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            @03sa…1YT
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            @01jK…9Om
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            3 days ago
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            <IconCeleb />
                            Drop
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            50 WNC
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            1
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            @03sa…1YT
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            3 days ago
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='1' textAlign='left' className='IconSpace'>
                            <IconCreated />
                            Request
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            50 WNC
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            10
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            @03sa…1YT
                          </Table.Cell>
                          <Table.Cell collapsing colSpan='2' textAlign='left'>
                            -
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </CustomAccordion>
              <CustomAccordion active={4} index={4} title='Description' IconTitle={descriptionIcon}>
                <div className='Containt'>
                  <p>
                    Château de Beaucastel has long been considered one of the greatest wines in
                    France, known for its elegance, balance and great aging potential. Rich, rounded
                    with a deep, complex aroma and a high level of tannins, but well structured, and
                    remarkable acidity. An elegant, wonderfully balanced wine with great aging
                    potential, delightful finesse, length and richness typical of great vintages.
                    The wine is a blend of 30% Grenache, 30% Mourvedre, 10% Syrah, 10% Counoise and
                    5% Senso, with a small content of the other nine varieties permitted in the
                    Châteauneuf-du-Pap appellation.
                  </p>
                </div>
              </CustomAccordion>
            </Grid.Column>
          </Grid>
        </div>
      </NftLayout>
    </>
  )
}

export default MarketPlaceNft
