import { actionTypes } from '../_actions/discountCodes.action'

const initialState = {
  codes: [],
  total: 0,
}

const discountCodes = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_DISCOUNT_CODES:
      return { ...state, codes: payload.rows, total: payload.count }

    default:
      return state
  }
}

export default discountCodes
