export default function getWineDescription(data) {
  const { grape, appellation, region = '', vintage = '', nft_subtitle = '' } = data

  // nft_subtitle is used as custom name from admin panel - use it if exist
  if (nft_subtitle) {
    return nft_subtitle
  }

  const titleData = []

  if (grape) {
    titleData.push(grape)
  }

  if (appellation) {
    titleData.push(appellation)
  }

  if (region || vintage) {
    titleData.push(`${region ? region : ''} ${vintage ? vintage : ''}`)
  }

  return titleData.map((word) => word.trim()).join(', ')
}
