

export const leftTime = (startDate, endDate) => {
    const start = new Date(startDate).getTime()
    const end = new Date(endDate).getTime()
    const now = new Date().getTime()
    let timeStatus
    const futureMilliseconds = start - now
    const presentMilliseconds = end - now
 
    const time = (milliseconds) => {
      const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24))
      const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minute = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))
  
      return `${days}d ${Math.floor(hours)}h ${Math.floor(minute)}m`
    }
  
    if (end < now) {
      timeStatus = { status: 'past', title: 'DROP ENDED' }
    } else if (start > now) {
      timeStatus = {
        status: 'future',
        title: 'Drop starts in:',
        time: time(futureMilliseconds),
      }
    } else {
      timeStatus = {
        status: 'present',
        title: 'Drop ends in:',
        time: time(presentMilliseconds),
      }
    }
    return timeStatus
  }