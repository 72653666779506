import { Input } from 'antd'
import React from 'react'
import './InputField.scss'

const InputUpload = ({
  label,
  validate,
  placeholder,
  name,
  value,
  disabled,
  customClass,
  btnContain,
  type,
  id,
  accept,
  onChange,
  handleOnSubmit,
}) => {
  return (
    <>
      <div className='File_main'>
        <label className='inputField__label'>{label}</label>
        <div className='image_upload'>
          <Input
            type='text'
            className={`text_input ${customClass}`}
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            validate={validate}
            value={value?.name ? value.name : ''}
          />
          <div className='file_up'>
            <button type='button'>{btnContain}</button>
            <Input
              type={type}
              id={id}
              accept={accept}
              className={`custom-file-input ${customClass}`}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default InputUpload
