import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import ModalStyle from '../../../../components/Modal/ModalStyle'
import InputField from '../../../../components/common/InputField/InputField'
import './AccessModal.scss'
import InputUpload from '../../../../components/common/InputField/InputUpload'
import * as Yup from 'yup'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { addExclusiveMember } from '../../../../redux/_actions/exclusive.action'
import { connect } from 'react-redux'

const { TabPane } = Tabs

const exclusiveSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email cannot be empty')
    .max(128, 'Email name is too long')
    .email('Email has to be valid'),
})
const AccessModal = ({
  open,
  onClose,
  onOpen,
  userId,
  getExclusiveList,
  addExclusive,
  file,
  setFile,
  handleOnChangeCsv,
  handleOnSubmit,
}) => {
  useEffect(() => {
    setTab(1)
  }, [open])
  const [tab, setTab] = useState(1)
  const exclusiveFormik = useFormik({
    enableReinitialize: true,
    validationSchema: exclusiveSchema,
    initialValues: {
      email: '',
    },
  })
  const onChange = (key) => {
    setTab(key)
  }
  const onAddExclusive = () => {
    exclusiveFormik.setFieldTouched('email', true)
    if (validateEmail(exclusiveFormik.values.email)) {
      let data = {
        data: [exclusiveFormik.values.email],
        userId: userId,
      }
      addExclusive(data).then(() => {
        onClose()
        exclusiveFormik.resetForm()
        getExclusiveList()
      })
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const onSubmmitForm = (e) => {
    if (tab === 1) {
      onAddExclusive()
    } else {
      handleOnSubmit(e)
    }
  }

  return (
    <>
      <ModalStyle
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        actionText='Send Access'
        title='Exclusive access'
        className='access__modal'
        handleOnSubmit={onSubmmitForm}
      >
        <div className='access__modal--content'>
          <Tabs className='exclusive__tabs' defaultActiveKey='1' onChange={onChange}>
            <TabPane tab='Provide Exclusive Access' key='1'>
              <h3>Provide Exclusive Access</h3>
              <Form onSubmit={exclusiveFormik.handleSubmit}>
                <InputField
                  label='Email'
                  placeholder='Ex. info@gmail.com'
                  onChange={(event) => {
                    exclusiveFormik.setFieldValue('email', event.target.value)
                  }}
                  value={exclusiveFormik.values.email}
                />
                {exclusiveFormik.touched.email && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      margin: 'auto',
                      position: 'absolute',
                      textAlign: 'center',
                      marginTop: '5px',
                      width: '100%',
                    }}
                  >
                    {exclusiveFormik.errors.email}
                  </p>
                )}
              </Form>
            </TabPane>
            <TabPane tab='Add offers from CSV file' key='2'>
              <h3>Add offers from CSV file</h3>
              <InputUpload
                label='CSV File'
                placeholder='Add your CSV file'
                customClass='edit'
                btnContain='Select file'
                type='file'
                id='csvFileInput'
                accept='.csv'
                onChange={handleOnChangeCsv}
                handleOnSubmit={handleOnSubmit}
                value={file}
              />
            </TabPane>
          </Tabs>
        </div>
      </ModalStyle>
    </>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addExclusive: (data) => dispatch(addExclusiveMember(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessModal)
