import React, { useEffect, useState } from "react";
import "../mainLayout.scss";
import { useSelector } from "react-redux";
import LeftSideBlock from "../../LeftSide/LeftSideBlock";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import Header from "./Header";

const MainLayout = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const persist = useSelector((state) => state.persist);
  const { isLeftbar } = persist;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`mainBlock ${toggleSidebar ? "" : "smallSidebarWrap"}`}>
        <LeftSideBlock
          isLeftbar={isLeftbar}
          setToggleSidebar={setToggleSidebar}
          toggleSidebar={toggleSidebar}
        />
        <div className={`rightSection ${isLeftbar ? "isShow" : "isHide"}`}>
          <Header />
          <Container fluid className="content_view">
            {children}
          </Container>
        </div>
      </div>
    </>
  );
};

export default withRouter(MainLayout);
