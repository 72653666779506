import { Link } from 'react-router-dom'
import { EditProfileIcon, IconDiamond } from '../../../../../../Assets/image/svgimages/sVGImg'

const MarketplaceDropdownMenu = ({
  availableStatusMenu,
  availablePriceMenu,
  onEditStatusMenuClick,
  onEditPriceMenuClick,
}) => {
  return (
    <div className='labelDropdownMenu'>
      {availableStatusMenu && (
        <Link to='#' onClick={onEditStatusMenuClick}>
          <EditProfileIcon />
          Edit Status
        </Link>
      )}
      {availablePriceMenu && (
        <Link to='#' onClick={onEditPriceMenuClick}>
          <IconDiamond />
          Edit Price
        </Link>
      )}
    </div>
  )
}

export default MarketplaceDropdownMenu
