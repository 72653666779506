// do it later - organize redux with TS and move model into redux store after it

export enum WineStructureEnum {
  WINE_SPECTATOR_POINT = 'wine_spectator_point',
  WINE_ADVOCATE_POINT = 'wine_advocate_point',
  WINE_FALSTAFF_POINT = 'wine_falstaff_point',
  WINE_VINOUS_POINT = 'wine_vinous_point',
  WINE_JAMES_SUCKLING_POINT = 'wine_james_suckling_point',
  WINE_BURGBOUND_POINT = 'wine_burgbound_point',
  WINE_RARITY = 'rarity',
  WINE_AGEABILITY = 'ageability',
  WINE_STRENGTH = 'strength',
  WINE_TANNINS = 'tannins',
  WINE_TEXTURE = 'texture'
}
export type WineStructureType =
  | WineStructureEnum.WINE_SPECTATOR_POINT
  | WineStructureEnum.WINE_ADVOCATE_POINT
  | WineStructureEnum.WINE_FALSTAFF_POINT
  | WineStructureEnum.WINE_VINOUS_POINT
  | WineStructureEnum.WINE_JAMES_SUCKLING_POINT
  | WineStructureEnum.WINE_BURGBOUND_POINT
  | WineStructureEnum.WINE_RARITY
  | WineStructureEnum.WINE_AGEABILITY
  | WineStructureEnum.WINE_STRENGTH
  | WineStructureEnum.WINE_TANNINS
  | WineStructureEnum.WINE_TEXTURE
