export const CREATE_NFT_STATUSES = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  MINTED: 'MINTED',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  EMPTY_DRAFT: 'EMPTY_DRAFT',
  EMPTY_APPROVED: 'EMPTY_APPROVED',
  SOLD_OUT: 'SOLD_OUT',
};
