import { useState } from 'react'
import { Popup, Table } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ownerNameHide } from 'utils/helper'
import moment from 'moment'
import { toast } from 'components/Toast/Toast'
import LabelDropdownWiners from '../labelDropdownWiners/LabelDropdownWiners'

const WinersListTable = ({
  key,
  row,
  getPublicPageStatus,
  deleteUserOpenConfirm,
  unPublishPublicPage,
  createPublicPage,
}) => {
  const [offset, setOffset] = useState(0)
  const onMenuClick = (e) => {
    const element = e.target
    setOffset(element.offsetLeft)
  }
  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      <Popup
        closeOnTriggerClick={false}
        // className='labelTableModal'
        offset={offset}
        on={['click']}
        position='bottom left'
        trigger={
          <Table.Row onClick={onMenuClick}>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {ownerNameHide(row?.creator_meta.winery_name)
                ? ownerNameHide(row.creator_meta.winery_name)
                : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='1' textAlign='center'>
              {row.wallet_authorization === 1 && <span className='Table_Editing yes'>Yes</span>}
              {row.wallet_authorization === 0 && <span className='Table_Editing no'>No</span>}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {row.fullname ? row.fullname : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='3' textAlign='left'>
              {row.email ? row.email : 'N/A'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {row?.creator_meta.country ? row.creator_meta.country : '---'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : 'N/A'}
            </Table.Cell>
            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {getPublicPageStatus(row)}
            </Table.Cell>

            <Table.Cell collapsing colSpan='2' textAlign='left'>
              {row.countOfBottle}
            </Table.Cell>
            <CopyToClipboard
              text={row.wallet?.address !== null ? row.wallet?.address : ''}
              onCopy={() => {
                if (row.wallet?.address !== null) {
                  toast.success('Copied')
                }
              }}
            >
              <Table.Cell
                collapsing
                colSpan='2'
                textAlign='left'
                title={row.wallet?.address !== null ? `${row.wallet?.address}` : ''}
                onClick={stopPropagation}
              >
                {row.wallet?.address !== null ? `${row.wallet?.address.slice(0, 10)}..` : '----'}
              </Table.Cell>
            </CopyToClipboard>
          </Table.Row>
        }
      >
        <LabelDropdownWiners
          rowObject={row}
          deleteUserOpenConfirm={deleteUserOpenConfirm}
          unPublish={unPublishPublicPage}
          createPublicPage={createPublicPage}
        />
      </Popup>
    </>
  )
}

export default WinersListTable
