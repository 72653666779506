import { actionTypes } from '../_actions/bottles.action'

const initialState = {
  bottleList: [],
  totalBottle: 0,
}

const bottles = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_BOTTLES:
      return { ...state, bottleList: payload.data, totalBottle: payload.count }

    default:
      return state
  }
}

export default bottles
