import React from 'react'
import { Card, Col } from 'antd'
import './CreateNftCard.scss'
import bottleDemo from '../../../Assets/image/bottleDemo.png'
import ReactCountryFlag from 'react-country-flag'

const CreateNftCard = (props) => {
  return (
    <Card className='gridCardStyle'>
      <div className='gridCardStyle__bottleWrap'>
        <div className='winebottle editBottle'>
          <img src={props.wineBottleImage || bottleDemo} alt='bottle' />
        </div>
        <div className='winebottle editTime'>
          <div className='winebottle countryCode'>
            <ReactCountryFlag
              countryCode={props.countrycode || 'N/A'}
              svg
              style={{ width: '2em', height: '2em' }}
            />
          </div>
          <div className='winebottle sizeBottle'>
            {!props.nftDetails?.status && (
              <>
                <p className='winebottle sizeTitle'>Drop starts in:</p>
                <p className='winebottle timeColor'>{props?.startDate?.time || 'N/A'}</p>
              </>
            )}
            {props.nftDetails?.status === 'SENT' && (
              <>
                <p className='winebottle endTitle'>Drop event ends by:</p>
                <p className='winebottle timeColor'>{props?.finishDate?.time || 'N/A'}</p>
              </>
            )}

            {props.nftDetails?.status === 'PENDING' && (
              <>
                <p className='winebottle sizeTitle'>Drop starts in:</p>
                <p className='winebottle timeColor'>{props?.startDate?.time || 'N/A'}</p>
              </>
            )}
          </div>
          <div className='winebottle sizeBottle'>
            <p className='winebottle sizeTitle'>Bottle Size</p>
            <p className='winebottle sizeDetails'>
              {props.no_of_bottles || 'N/A'}&nbsp;x &nbsp;
              {props.size || 'N/A'}
            </p>
          </div>
        </div>
      </div>
      <Col className='gridCardStyle__details'>
        <div className='gridCardStyle__wineryTitles'>
          {props?.nft_name === null ? (
            <h2>
              {props.winerTitle || ' '}&nbsp; «{props.wine_name || ' '}&nbsp;
              {props.vineyard_name || ' '}&nbsp;
              {props.classification || ' '}»
            </h2>
          ) : (
            <h2>{props?.nft_name}</h2>
          )}
        </div>
        <div>
          <p>
            {props?.nft_subtitle ||
              [props.grape, props.appellation, props.regionName, props.vintage]
                .filter((el) => el)
                .join(', ')}
          </p>
        </div>
      </Col>
    </Card>
  )
}

CreateNftCard.defaultProps = {
  country: 'France',
  countrycode: 'FR',
  wineTitle: 'Example wine title, just for preview purpose.',
}

export default CreateNftCard
