import { useDispatch } from 'react-redux'
import { toast } from '../../../Toast/Toast'

import addPlus from '../../../../Assets/image/addPlus.svg'
import { Button } from 'semantic-ui-react'
import { updateComingSoonStatus } from '../../../../redux/_actions/winemaker.action'

const ComingSoon = ({ rowObject }) => {
  const dispatch = useDispatch()

  const comingSoonStatus = rowObject?.coming_soon_enabled // 0 - disable or 1 - enabled
  const actionName = comingSoonStatus ? 'Disable' : 'Enable'

  const onToggleStatus = () => {
    let data = {
      user_id: rowObject?.userId,
      coming_soon_enabled: !comingSoonStatus,
      winery_page_id: rowObject?.creator_meta?.winery_page_id,
    }

    //called if user has completed all the step
    dispatch(updateComingSoonStatus(data)).then((res) => {
      toast.success(res.data.message)
    })
  }

  return (
    <Button onClick={onToggleStatus}>
      <img src={addPlus} alt={addPlus} /> &nbsp; &nbsp; {actionName} Coming Soon
    </Button>
  )
}

export default ComingSoon
