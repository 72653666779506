import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import { ReactComponent as IconBack } from '../../../Assets/image/svgimages/icon_back.svg'

import { ButtonCustom } from '../../../components/button/ButtonCustom'

import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { transferNft, getTransferNftData } from '../../../redux/_actions/nft.action'

import { toast } from '../../../components/Toast/Toast'
import { useHistory } from 'react-router'

import './TransferNFT.scss'
import * as Yup from 'yup'

const CreateNftWine = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [transferData, setTransferData] = useState({})

  useEffect(() => {
    dispatch(getTransferNftData(props.location.state.drop_id)).then((res) => {
      setTransferData(res.data.data)
    })
  }, [])
  console.log(transferData)
  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const validationSchema = Yup.object().shape({
    destination_drop: Yup.number().required().notOneOf([0], 'This field is required'),
    // vintage: Yup.string().required('This field is required'),
    // abv: Yup.string().required("This field is required"),
    nft_list: Yup.array().required().min(1, 'Please select at least one NFT'),
  })
  const nftFormik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      destination_drop: 0,
      nft_list: [],
    },
    onSubmit: () => {
      const payload = {
        fromDrop: props.location.state.drop_id,
        toDrop: Number(nftFormik.values.destination_drop),
        nftList: nftFormik.values.nft_list,
      }
      dispatch(transferNft(payload)).then(
        (res) => {
          if (res.data.error) {
            toast.error(res.data.message)
            return
          }
          toast.success(res.data.message)
        },
        (error) => {
          toast.error('Something went wrong')
        },
      )
    },
  })

  const setParticularFieldValue = (e, field) => {
    nftFormik.setFieldValue(field, e.target.value)
  }

  const handleNftSelect = (id) => {
    if (nftFormik.values.nft_list.includes(id)) {
      const filteredArray = nftFormik.values.nft_list.filter((e) => e !== id)
      nftFormik.setFieldValue('nft_list', filteredArray)
    } else {
      nftFormik.setFieldValue('nft_list', [...nftFormik.values.nft_list, id])
    }
  }

  return (
    <>
      <MainLayout>
        <div className='winemakers-main crtbtl CreatWineft'>
          <div className='left'></div>

          <div className='right'>
            <Row className='rightrow'>
              <Col xl={12} className='crtbtl-left'>
                <div className='BtnPreview'>
                  <Link to='/panel/Dropevents' className='back-btn IconBack m-0'>
                    <IconBack /> Back to Drop list
                  </Link>
                </div>
                <h1 className='common-heading mb-4'>Transfer NFTs to another Drop</h1>

                <Form onSubmit={nftFormik.handleSubmit}>
                  <div className='block NftCreate' id='a'>
                    <div className='cus_drop inputField__wrap perameter_Inputs'>
                      <label className='TransferNFT__inputField__label'>Destination Drop</label>
                      <select
                        showSearch
                        placeholder='Select Destination Drop'
                        id='destination_drop'
                        value={nftFormik.values.destination_drop}
                        onChange={(e) => setParticularFieldValue(e, 'destination_drop')}
                      >
                        <option value={0} disabled>
                          {'Select Target Drop'}
                        </option>
                        {transferData.availableDrops?.map((item) => (
                          <option key={`avail-${item}`} value={item} label={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {nftFormik?.errors['destination_drop'] && (
                        <p className='TransferNFT__error'>
                          {nftFormik?.errors['destination_drop']}
                        </p>
                      )}
                    </div>

                    <label className='TransferNFT__inputField__label'>Available NFTs</label>
                    {transferData.leftNFTsIds?.map((item, key) => {
                      return (
                        <div key={`leftNft-${item}`} className='field TransferCheckboxBlock'>
                          <div style={{ display: 'flex' }}>
                            <input
                              className=''
                              id={key}
                              name={item}
                              type='checkbox'
                              // checked={nftFormik.values.rarible}
                              // value={nftFormik.values.rarible}
                              onChange={(e) => {
                                handleNftSelect(item)
                              }}
                            />
                            <label className='inputField__label'>{item}</label>
                          </div>
                        </div>
                      )
                    })}
                    {nftFormik?.errors['nft_list'] && (
                      <p className='TransferNFT__error'>{nftFormik?.errors['nft_list']}</p>
                    )}
                  </div>
                  <Row>
                    <Col lg={14}>
                      <div className='BtnListing'>
                        <ButtonCustom
                          type='button'
                          title='Cancel'
                          onClick={() => history.push('/panel/bottles')}
                          customClass='borderBtn addNew_Btn'
                        />
                      </div>
                    </Col>
                    <Col lg={10}>
                      <div className='BtnListing'>
                        <ButtonCustom type='submit' title='Transfer' customClass='saveBtn2' />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export default CreateNftWine
