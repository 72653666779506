import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Steps, Col } from "antd";
import MainLayout from "../../../../components/HOC/MainLayout/MainLayout";
import { ReactComponent as IconBack } from "../../../../Assets/image/svgimages/icon_back.svg";
import { ReactComponent as WOne } from "../../../../Assets/image/svgimages/w1.svg";
import { ReactComponent as WTwo } from "../../../../Assets/image/svgimages/w2.svg";
import { ReactComponent as WThree } from "../../../../Assets/image/svgimages/w3.svg";
import { ReactComponent as WFour } from "../../../../Assets/image/svgimages/w4.svg";
import { useHistory } from "react-router";
import AddWinStepFirst from "./AddWinStepFirst";
import OurStoryStepSec from "./OurStoryStepSec";
import GallaryStepThird from "./GallaryStepThird";
import OurTeamStepFour from "./OurTeamStepFour";
import DetailsStepSeven from "./DetailsStepSeven";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import { WinerMakerAction } from "../../../../redux/_actions";
import { useDispatch } from "react-redux";
import "./AddWinemaker.scss";
import { PrevIcon } from "../../../../Assets/image/svgimages/sVGImg";

const AddWinemaker = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [team, setTeam] = useState([]);
  const userId = history?.location?.state?.userId;
  const status = history?.location?.state?.profile_status;
  const [step, setStep] = useState(1);
  const [winerDetails, setwinerDetails] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
const [error, setError] = useState(false);
  const { Step } = Steps;
  const { getWineMakerDetails } = WinerMakerAction;

  useEffect(() => {
    if (userId === undefined) {
      history.push("/panel/WineList");
      return;
    }
    getWinerAllDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
  const getWinerAllDetails = () => {
    const postInfo = {
      userId: userId,
    };

    //get all details of winemaker
    dispatch(getWineMakerDetails(postInfo)).then((res) => {
      let winerAllDetails = res.data?.data?.winerDetails;
      setwinerDetails(winerAllDetails);
    });
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.body.classList.add("bg-white");
    return () => {
      body.classList.remove("bg-white");
    };
  }, []);

  const getStep = (viewedStep) => {
    if (viewedStep === step) {
      return "current";
    }
    if (viewedStep < step) {
      return "finish";
    }
    if (viewedStep > step) {
      return "wait";
    }
  };

  const gotToNthPage = (n) => {
    if (!error) {
      setStep(n);
    }
  };
  return (
    <>
      <LoaderComponent loading={isLoader} />
      <MainLayout>
        <Row>
          <Col xs={24}>
            <div className="winemakers-main">
              <div className="left">
                <Steps direction="vertical" current={1}>
                  <Step
                    onClick={() => gotToNthPage(1)}
                    currentstep={step}
                    title="Step 1:"
                    description="Winery Details"
                    status={getStep(1)}
                    icon={step > 1 ? <WOne /> : <WTwo />}
                  />
                  <Step
                    onClick={() => gotToNthPage(2)}
                    title="Step 2:"
                    description="Key Content Details"
                    steps={2}
                    currentstep={step}
                    status={getStep(2)}
                    icon={step > 2 ? <WOne /> : <WThree />}
                  />
                  <Step
                    onClick={() => gotToNthPage(3)}
                    title="Step 3:"
                    steps={3}
                    currentstep={step}
                    description="Gallery"
                    status={getStep(3)}
                    icon={step > 3 ? <WOne /> : <WThree />}
                  />
                  <Step
                    onClick={() => gotToNthPage(4)}
                    steps={4}
                    currentstep={step}
                    title="Step 4:"
                    description="People"
                    status={getStep(5)}
                    icon={step > 4 ? <WOne /> : <WFour />}
                  />
                  <Step
                    onClick={() => gotToNthPage(5)}
                    steps={4}
                    currentstep={step}
                    title="Step 5:"
                    description="About the winery"
                    status={getStep(5)}
                    icon={step > 5 ? <WOne /> : <WFour />}
                  />
                </Steps>
              </div>
              <div className="right">
                <div className="top-controll">
                  <Link to="/panel/WineList" className="back-btn">
                    <IconBack /> Back to winery list
                  </Link>
                  <Link
                    to={{
                      pathname: "/panel/public-page-prev",
                      state: history?.location?.state,
                      prevPath: history?.location.pathname,
                    }}
                    className={
                      history?.location?.state?.role_type === 2
                        ? "publicprev"
                        : "publicprev disabled"
                    }
                  >
                    <PrevIcon /> Preview public page
                  </Link>
                </div>
                {step === 1 && (
                  <AddWinStepFirst
                    userId={userId}
                    setStep={setStep}
                    winerDetails={winerDetails}
                    getWinerAllDetails={getWinerAllDetails}
                    status={status}
                    setIsLoader={setIsLoader}
                    setError={setError}
                    error={error}
                  />
                )}
                {step === 2 && (
                  <OurStoryStepSec
                    userId={userId}
                    setStep={setStep}
                    winerDetails={winerDetails}
                    getWinerAllDetails={getWinerAllDetails}
                    status={status}
                    setIsLoader={setIsLoader}
                    setError={setError}
                    error={error}
                  />
                )}
                {step === 3 && (
                  <GallaryStepThird
                    userId={userId}
                    setStep={setStep}
                    winerDetails={winerDetails}
                    getWinerAllDetails={getWinerAllDetails}
                    status={status}
                    setIsLoader={setIsLoader}
                    
                  />
                )}
                {step === 4 && (
                  <div className="block" id="s4">
                    <OurTeamStepFour
                      setStep={setStep}
                      setTeam={setTeam}
                      team={team}
                      userId={userId}
                      winerDetails={winerDetails}
                      getWinerAllDetails={getWinerAllDetails}
                      status={status}
                      setIsLoader={setIsLoader}
                     
                    />
                  </div>
                )}

                {step === 5 && (
                  <DetailsStepSeven
                    setStep={setStep}
                    userId={userId}
                    winerDetails={winerDetails}
                    getWinerAllDetails={getWinerAllDetails}
                    status={status}
                    setIsLoader={setIsLoader}
                    setError={setError}
                    error={error}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </MainLayout>
    </>
  );
};
export default AddWinemaker;
