import { actionTypes } from '../_actions/pallets.action'

const initialState = {
  palletsList: [],
  totalPallets: 0,
}

const pallets = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_PALLETS:
      return { ...state, palletsList: payload.data.list, totalPallets: payload.data.count }

    default:
      return state
  }
}

export default pallets
