import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import PublicRoutes from "./PublicRoutes/PublicRoute";
import AuthGuard from "./Guards/AuthGuard";
import NoGuard from "./Guards/NoGuard";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import LoaderComponent from "../components/LoaderComponent/LoaderComponent.jsx";
import { WinerMakerAction } from "../redux/_actions";

const Application = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getPendingWineryCount();
    getAllWineryCount();
  }, []);

  const getPendingWineryCount = () => {
    const { getPendingWinerCount } = WinerMakerAction;
    dispatch(getPendingWinerCount()).then((res) => {});
  };

  const getAllWineryCount = () => {
    const { getAllWineryCount } = WinerMakerAction;
    dispatch(getAllWineryCount()).then((res) => {});
  };
  return (
    <React.Fragment>
      <LoaderComponent></LoaderComponent>
      <Router basename="/admin">
        <Switch>
          <AuthGuard path="/panel" component={PrivateRoutes} />
          <NoGuard path="/" component={PublicRoutes} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default Application;
