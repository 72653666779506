import { Link } from 'react-router-dom'
import React from 'react'
import { Button } from 'semantic-ui-react'
import editProfile from '../../../../../Assets/image/editProfile.svg'
import ComingSoon from 'components/common/Popup/ComingSoon'
import addPlus from '../../../../../Assets/image/addPlus.svg'
import editWine from '../../../../../Assets/image/editWine.svg'
import deleteIcon from '../../../../../Assets/image/deleteIcon.svg'
import './LableDropdownWiners.scss'
import getCMSEntryUrl from 'Helpers/getCMSEntryUrl'

const LabelDropdownWiners = ({
  rowObject,
  deleteUserOpenConfirm,
  unPublish,
  editWinemaker,
  createPublicPage = () => {},
}) => {
  const showAddBottle = rowObject?.role_type === 2
  const showPublicPage = rowObject?.profile_status === 2 || rowObject?.profile_status === 3
  const showExclusive = rowObject?.role_type === 2
  const showModifyPublicPage = rowObject.role_type === 2
  const isPublished = rowObject.profile_status === 3
  const showComingSoon = rowObject?.creator_meta?.winery_page_id && !isPublished
  const link = getCMSEntryUrl(rowObject?.creator_meta?.winery_page_id)
  return (
    <div className='listWinersButtons'>
      <Link
        to={{
          pathname: `/panel/edit-profile-page`,
          state: rowObject,
        }}
      >
        <Button href=''>
          <img src={editProfile} alt={editProfile} /> &nbsp; &nbsp; Profile
        </Button>
      </Link>
      {showComingSoon && <ComingSoon rowObject={rowObject} />}
      {showAddBottle && (
        <Link
          to={{
            pathname: '/panel/bottles/create-bottle',
            state: rowObject,
          }}
        >
          <Button>
            <img src={addPlus} alt={addPlus} /> &nbsp; &nbsp; Add Bottle
          </Button>
        </Link>
      )}
      {showPublicPage && (
        <Link
          to={{
            pathname: '/panel/view-public-page',
            state: rowObject,
          }}
        >
          <Button>
            <img src={addPlus} alt={addPlus} /> &nbsp; &nbsp; Logs
          </Button>
        </Link>
      )}
      {showExclusive && (
        <Link
          to={{
            pathname: '/panel/exclusive-access',
            state: rowObject,
          }}
        >
          <Button>
            <img src={addPlus} alt={addPlus} /> &nbsp; &nbsp; Exclusive
          </Button>
        </Link>
      )}

      <Link to={`/panel/edit-profile-page`} />

      {showModifyPublicPage && !rowObject?.creator_meta?.winery_page_id && (
        <Button onClick={createPublicPage}>
          <img src={editWine} alt={editWine} /> &nbsp; &nbsp; <span>Create Public Page</span>
        </Button>
      )}
      {showModifyPublicPage && rowObject?.creator_meta?.winery_page_id && (
        <a href={link}>
          <Button>
            <img src={editWine} alt={editWine} /> &nbsp; &nbsp; <span>Edit Public Page</span>
          </Button>
        </a>
      )}
      {isPublished ? (
        <Button onClick={() => unPublish(rowObject.userId, rowObject.creator_meta?.winery_page_id)}>
          <img src={deleteIcon} alt={editWine} /> &nbsp; &nbsp; Unpublish Public Page
        </Button>
      ) : null}
      <Button onClick={() => deleteUserOpenConfirm(rowObject)}>
        <img src={deleteIcon} alt={deleteIcon} /> &nbsp; &nbsp; Delete
      </Button>
    </div>
  )
}

export default LabelDropdownWiners
