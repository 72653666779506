import { useState, useEffect } from 'react'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { Link, useParams } from 'react-router-dom'
import { BackTo } from '../../../../Assets/image/svgImg'
import { Row, Col } from 'antd'
import { Icon, Input, Dropdown, Pagination, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { walletsHide } from 'utils/helper'
import { getOrderPalletId } from 'redux/_actions/palletsNfts.action'
import useDebounce from 'hooks/useDebounce'
import './Pallet.scss'

const option2 = [
  { key: '1', text: 'Last Updated', value: '0' },
  { key: '2', text: 'Last Week', value: '1' },
  { key: '3', text: 'Last Month', value: '2' },
]
const showOptions = [
  { key: '1', text: '10', value: '10' },
  { key: '2', text: '15', value: '15' },
  { key: '3', text: '20', value: '20' },
]
const Pallet = () => {
  const [searchKey, setSearchKey] = useState('')
  const [status, setStatus] = useState('')
  const [limit, setLimit] = useState('10')
  const [offset, setOffset] = useState(1)
  const [triggerSearch] = useState(false)
  const debaounceWinerySearch = useDebounce(searchKey, 500)
  const { palletsNftsList, totalPalletsNfts } = useSelector((state) => state?.palletsNfts)
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    getAllPalletsNfst()
  }, [offset, limit, status, debaounceWinerySearch, triggerSearch])

  const getAllPalletsNfst = async () => {
    let dataFields = {
      dropId: id,
      limit: limit,
      offset: offset,
      searchKey: searchKey,
      status: status,
    }
    dispatch(getOrderPalletId(dataFields)).then((res) => {})
  }

  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage
    setOffset(offset)
  }

  return (
    <MainLayout>
      <div className='pallet'>
        <Link to='/panel/ware-house'>
          <div className='pallet_btn'>
            <span>
              <BackTo />
            </span>
            Back to list
          </div>
        </Link>

        <h3 className='pallet_wineName'>Detailed Pallet View</h3>

        <Row className='pallet_row'>
          <Col span={3}>
            <Row>
              <p className='pallet_headers'>Drop Id:</p>
              <p className='pallet_headers_details'>{id}</p>
            </Row>
          </Col>
        </Row>
        <div className='filltersBottom'>
          <div className='left_fill'>
            <Dropdown
              placeholder='Last Updated'
              selection
              options={option2}
              onChange={(e, data) => {
                if (data?.value === '0') {
                  setStatus('')
                } else {
                  setStatus(data?.value)
                }
              }}
            />
            <Input
              className='search_fldWare'
              icon={<Icon name='search' inverted link />}
              placeholder='Ex. search Nft ID, Nft Name'
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value)
                setOffset(1)
              }}
            />
          </div>
          <div className='rightfill'>
            <div className='sorting'>
              <span>Show</span>
              <Dropdown
                value={limit}
                selection
                options={showOptions}
                className='autowidth'
                onChange={(e, data) => {
                  setLimit(data.value)
                  setOffset(1)
                }}
              />
            </div>
            <div className='sorting'>
              <span>Entries</span>
              <Dropdown
                placeholder={totalPalletsNfts ? totalPalletsNfts.toString() : '0'}
                selection
                disabled
                options={showOptions}
                className='autowidth'
              />
            </div>
          </div>
        </div>
        <div className='Table_Editing'>
          <Table className='custom_table'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='1'>NFT ID</Table.HeaderCell>
                <Table.HeaderCell colSpan='1'>Owner Name</Table.HeaderCell>
                <Table.HeaderCell colSpan='1'>Wallet ID</Table.HeaderCell>
                <Table.HeaderCell colSpan='2'>Nft Name</Table.HeaderCell>
                <Table.HeaderCell colSpan='1'>Physical ID</Table.HeaderCell>
                <Table.HeaderCell colSpan='1'>Storage</Table.HeaderCell>
                <Table.HeaderCell colSpan='1'>Storage until date </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {palletsNftsList && palletsNftsList?.length > 0 ? (
                palletsNftsList?.map((itemWare, i) => (
                  <Link to={`/panel/PalletNftId/${itemWare.id}`} key={`PalletNftId-${itemWare.id}`}>
                    <Table.Row>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {itemWare.id ? itemWare.id : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {`${itemWare?.first_name} ${itemWare?.last_name}`}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {itemWare.address ? walletsHide(itemWare.address) : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {itemWare.nft_name ? itemWare.nft_name : '---'}
                      </Table.Cell>

                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {itemWare.physicalId ? itemWare.physicalId : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {itemWare?.storage_plan ? itemWare.storage_plan : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {itemWare.storage_end_time
                          ? moment(itemWare.storage_end_time).format('DD/MM/YYYY')
                          : '---'}
                      </Table.Cell>
                    </Table.Row>
                  </Link>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell collapsing colSpan='24' textAlign='center'>
                    <div
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bolder',
                        fontSize: '15px',
                      }}
                    >
                      No Record Found.
                    </div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        <div className='pallet_pagination'>
          <div className='sorting'>
            <span>Show On Page &nbsp;</span>
            <Dropdown
              value={limit}
              selection
              options={showOptions}
              className='autowidth SortingIcon'
              onChange={(e, data) => {
                setLimit(data.value)
                setOffset(1)
              }}
            />
          </div>
          {totalPalletsNfts && totalPalletsNfts >= limit && (
            <Pagination
              className='ListPagi'
              firstItem={false}
              lastItem={false}
              boundaryRange={1}
              siblingRange={0}
              defaultActivePage={1}
              onPageChange={handlePageClick}
              totalPages={Math.ceil(parseInt(totalPalletsNfts) / limit) || 1}
            />
          )}
        </div>
      </div>
    </MainLayout>
  )
}
export default Pallet
