import React, { useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import ReactCrop from 'react-image-crop'
import cn from 'classnames'
import useDebounce from '../../../../../src/hooks/useDebounce'
import { canvasPreview } from '../../../../components/canvasPreview'
import { useDispatch } from 'react-redux'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import BorderButton from '../../../../components/button/BorderButton'
import { toast } from '../../../../components/Toast/Toast'
import { WinerMakerAction } from '../../../../redux/_actions'
import { Plusicon } from '../../../../Assets/image/svgImg'
import { IconDelete, IconCrop, AddIcon } from '../../../../Assets/image/svgimages/sVGImg'

const winerSchema = Yup.object().shape({
  people: Yup.array().of(
    Yup.object().shape({
      fullName: Yup.string(),
      jobTitle: Yup.string(),
      imgUrl: Yup.mixed(),
    }),
  ),
})

const OurTeamStepFour = (props) => {
  const [crop, setCrop] = useState({})
  const [completedCrop, setCompletedCrop] = useState({})
  const [currentlyCropping, setCurrentlyCropping] = useState()
  const imgRef = useRef([])
  const previewCanvasRef = useRef([])
  const blobUrlRef = useRef([])

  const dispatch = useDispatch()
  const inputRefCoverImage = React.useRef({})
  const [people, setPeople] = useState([])
  const { uploadFileGc, updatTeamWinerDetail } = WinerMakerAction

  let { setStep, userId, getWinerAllDetails, winerDetails, setIsLoader } = props

  useDebounce(
    async () => {
      if (
        completedCrop[currentlyCropping]?.width &&
        completedCrop[currentlyCropping]?.height &&
        imgRef.current[currentlyCropping] &&
        previewCanvasRef.current[currentlyCropping]
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current[currentlyCropping],
          previewCanvasRef.current[currentlyCropping],
          completedCrop[currentlyCropping],
        )
      }
    },
    100,
    [completedCrop],
  )

  const uploadCropped = (index) => {
    if (!previewCanvasRef.current[index]) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current[index]?.toBlob(
      (blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }
        if (blobUrlRef.current[index]) {
          URL.revokeObjectURL(blobUrlRef.current[index])
        }
        blobUrlRef.current[index] = URL.createObjectURL(blob)
        uploadImageApi(blob, index)
      },
      { useCORS: true },
      'image/jpeg',
    )
  }

  useEffect(() => {
    setPeople(
      winerDetails.length > 0
        ? winerDetails[0].creator_team_meta
        : [
            {
              imgUrl: '',
            },
          ],
    )
  }, [winerDetails, props])

  const wineTeamFormik = useFormik({
    enableReinitialize: true,
    validationSchema: winerSchema,
    initialValues: {
      people: winerDetails[0].creator_team_meta,
    },
    onSubmit: (values) => {
      let finalObj = {
        data: values.people,
        userId: userId,
      }
      if (values.people.length > 0) {
        addTeamApi(finalObj)
      } else {
        setStep(5)
      }
    },
  })

  const addTeamApi = (finalObj) => {
    setIsLoader(true)

    dispatch(updatTeamWinerDetail(finalObj))
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((res) => {})
      .finally(() => {
        setIsLoader(false)
        getWinerAllDetails()
        setStep(5)
      })
  }

  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadImageApi = (file, index) => {
    let formData = new FormData()
    formData.append('folderName', 'testing')
    formData.append('file', file)

    dispatch(uploadFileGc(formData)).then((res) => {
      setIsLoader(false)
      wineTeamFormik.setFieldValue(`people.${index}.imgUrl`, res?.data.data[0]?.url)
    })
  }

  const uploadFileToS3 = async (input, index) => {
    input.persist()

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      toast.error('File extension not supported!')
      setIsLoader(false)
      input.target.value = null
    } else {
      uploadImageApi(file, index)
    }
  }

  const addPeople = () => {
    const copyAllImages = [...people]
    copyAllImages.push({
      img_url: '',
      jobTitle: '',
    })

    setPeople(copyAllImages)
  }

  const removeTeamMember = (i) => {
    const copyAllImages = [...people]
    const copyAllImagesData = [...wineTeamFormik.values.people]
    copyAllImages.splice(i, 1)
    copyAllImagesData.splice(i, 1)
    setPeople(copyAllImages)
    wineTeamFormik.setValues({
      people: copyAllImagesData,
    })
  }

  return (
    <>
      <div className='sectionInfo sectionInfo-people'>
        <h3 className='heading'>People</h3>
      </div>

      <Form onSubmit={wineTeamFormik.handleSubmit}>
        <ButtonCustom
          leftIcon={<Plusicon />}
          title='Add team member'
          customClass='addedTeamBtn'
          onClick={() => addPeople()}
          type='button'
        />

        {people.map((el, index) => (
          <div className='people' key={el.memberId || el.imgUrl}>
            <div className='people__imgSect'>
              <div className='uploadField'>
                <div className='profilePic'>
                  <div className='profilePic-inner'>
                    <input
                      id={'imgUrl' + index}
                      type='file'
                      name={'imgUrl' + index}
                      className='input__input'
                      onChange={(e) => uploadFileToS3(e, index)}
                      formEncType='multipart/form-data'
                      ref={(el) => (inputRefCoverImage.current[index] = el)}
                      // disabled={wineTeamFormik.values?.people[index]?.imgUrl}
                      title={wineTeamFormik.values?.people[index]?.imgUrl ? '' : 'No file chosen'}
                    />
                    {(wineTeamFormik.values.people[index]?.jobTitle &&
                      !wineTeamFormik.values.people[index]?.fullName &&
                      !wineTeamFormik.values?.people[index]?.imgUrl) ||
                    (wineTeamFormik.values.people[index]?.fullName &&
                      !wineTeamFormik.values.people[index]?.jobTitle &&
                      !wineTeamFormik.values?.people[index]?.imgUrl) ||
                    (wineTeamFormik.values.people[index]?.jobTitle &&
                      wineTeamFormik.values.people[index]?.fullName &&
                      !wineTeamFormik.values?.people[index]?.imgUrl) ? (
                      <p className='custom-error-text'>Error: This field is required</p>
                    ) : null}

                    <label
                      className={cn('file-input__prof', {
                        'hidden BAckImageSet':
                          wineTeamFormik.values?.people?.[index]?.imgUrl !== '',
                      })}
                      htmlFor={'imgUrl' + index}
                    />
                    <div className='down-button' />

                    <ReactCrop
                      crop={crop[index]}
                      onComplete={(c) => setCompletedCrop((prev) => ({ ...prev, [index]: c }))}
                      onChange={(c) => {
                        setCrop((prev) => ({ ...prev, [index]: c }))
                        setCurrentlyCropping(index)
                      }}
                      aspect={205 / 209}
                      style={{ zIndex: 9 }}
                    >
                      <img
                        ref={(el) => (imgRef.current[index] = el)}
                        src={`${wineTeamFormik.values?.people?.[index]?.imgUrl}`}
                        alt=''
                        crossOrigin='anonymous'
                        width='100%'
                        height='auto'
                      />
                    </ReactCrop>
                    {!!completedCrop[index] && (
                      <>
                        <div>
                          <canvas
                            ref={(el) => (previewCanvasRef.current[index] = el)}
                            style={{
                              border: '1px solid black',
                              objectFit: 'contain',
                              width: completedCrop[index]?.width,
                              height: completedCrop[index]?.height,
                            }}
                          />
                        </div>
                      </>
                    )}
                    {wineTeamFormik.touched?.people?.[index]?.imgUrl && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          margin: 'auto',
                          position: 'absolute',
                          textAlign: 'center',
                          marginTop: '5px',
                          width: '100%',
                        }}
                      >
                        {wineTeamFormik.errors?.people?.[index]?.imgUrl}
                      </p>
                    )}
                    <div className='imageActions'>
                      {wineTeamFormik.values?.people?.[index]?.imgUrl !== '' && (
                        <>
                          {!!completedCrop[index] && (
                            <button
                              className='imageActions__button'
                              type='button'
                              onClick={() => uploadCropped(index)}
                            >
                              <IconCrop />
                            </button>
                          )}
                          <button
                            className='imageActions__button'
                            type='button'
                            onClick={() => removeTeamMember(index)}
                          >
                            <IconDelete />
                          </button>
                          <button
                            className='imageActions__button'
                            type='button'
                            onClick={() => inputRefCoverImage.current[index]?.click()}
                          >
                            <AddIcon />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <p className='people__imgSize'>
                Image: <br /> Max size 1080 x 1080, max weight 30mb
                <br /> Crop ratio: 205/209
              </p>
              {wineTeamFormik?.errors?.people &&
                wineTeamFormik?.errors?.people[index] &&
                wineTeamFormik?.errors?.people[index].imgUrl && (
                  <p style={{ marginTop: 0 }} className='custom-error-text'>
                    This field is required
                  </p>
                )}
            </div>
            <div className='people__detailsSec'>
              <InputField
                id='fullName'
                label='Full Name'
                isRequired={
                  (!wineTeamFormik.values.people[index]?.jobTitle &&
                    !wineTeamFormik.values?.people[index]?.imgUrl &&
                    wineTeamFormik.values.people[index]?.fullName?.trim() === '') ||
                  (wineTeamFormik.values.people[index]?.jobTitle &&
                    wineTeamFormik.values?.people[index]?.imgUrl &&
                    wineTeamFormik.values.people[index]?.fullName?.trim() !== '')
                }
                placeholder='Full Name'
                type='text'
                onChange={(event) => {
                  wineTeamFormik.setFieldValue(`people.${index}.fullName`, event.target.value)
                }}
                value={wineTeamFormik.values.people[index]?.fullName}
                formik={wineTeamFormik}
              />
              {(wineTeamFormik.values?.people[index]?.imgUrl &&
                !wineTeamFormik.values.people[index]?.fullName &&
                !wineTeamFormik.values.people[index]?.jobTitle) ||
              (!wineTeamFormik.values.people[index]?.fullName &&
                wineTeamFormik.values.people[index]?.jobTitle) ? (
                <p className='custom-error-text'>This field is required</p>
              ) : null}

              <InputField
                id={`people.${index}.jobTitle`}
                label='Job title'
                isRequired={
                  (!wineTeamFormik.values.people[index]?.fullName &&
                    !wineTeamFormik.values?.people[index]?.imgUrl &&
                    wineTeamFormik.values.people[index]?.jobTitle?.trim() === '') ||
                  (wineTeamFormik.values.people[index]?.fullName &&
                    wineTeamFormik.values?.people[index]?.imgUrl &&
                    wineTeamFormik.values.people[index]?.jobTitle?.trim() !== '')
                }
                placeholder='Job title'
                type='text'
                onChange={(event) => {
                  wineTeamFormik.setFieldValue(`people.${index}.jobTitle`, event.target.value)
                }}
                value={wineTeamFormik.values.people[index]?.jobTitle}
              />
              {(wineTeamFormik.values?.people[index]?.imgUrl &&
                !wineTeamFormik.values.people[index]?.jobTitle &&
                !wineTeamFormik.values.people[index]?.fullName) ||
              (wineTeamFormik.values.people[index]?.fullName &&
                !wineTeamFormik.values.people[index]?.jobTitle) ? (
                <p className='custom-error-text'>This field is required</p>
              ) : null}
            </div>
          </div>
        ))}

        <div className='people__buttonSect'>
          <div className='btns-spacing continBtn'>
            <div>
              <BorderButton
                text='Previous step'
                className='addNew_Btn'
                type='button'
                onClick={() => setStep(3)}
              />
            </div>
            <ButtonCustom
              type='submit'
              title='Save'
              customClass='saveBtn'
              disabled={people.some(
                (person, index) =>
                  (wineTeamFormik.values.people[index]?.jobTitle &&
                    !wineTeamFormik.values?.people[index]?.imgUrl &&
                    !wineTeamFormik.values.people[index]?.fullName) ||
                  (wineTeamFormik.values.people[index]?.fullName &&
                    !wineTeamFormik.values?.people[index]?.imgUrl &&
                    !wineTeamFormik.values.people[index]?.jobTitle) ||
                  (wineTeamFormik.values.people[index]?.imgUrl &&
                    !wineTeamFormik.values?.people[index]?.fullName &&
                    !wineTeamFormik.values.people[index]?.jobTitle) ||
                  (!wineTeamFormik.values.people[index]?.jobTitle &&
                    wineTeamFormik.values?.people[index]?.fullName &&
                    wineTeamFormik.values.people[index]?.imgUrl) ||
                  (!wineTeamFormik.values.people[index]?.fullName &&
                    wineTeamFormik.values?.people[index]?.jobTitle &&
                    wineTeamFormik.values.people[index]?.imgUrl) ||
                  (!wineTeamFormik.values.people[index]?.imgUrl &&
                    wineTeamFormik.values?.people[index]?.jobTitle &&
                    wineTeamFormik.values.people[index]?.fullName),
              )}
            />
          </div>
        </div>
      </Form>
    </>
  )
}
export default OurTeamStepFour
