import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { InputNumber } from "antd";
import './EditPriceModal.scss';

import {
  getMarketplaceNft,
  updateMarketplaceNft
} from "../../../../../../../redux/_actions/marketplace.action";
import DoubleConfirmation from "../DoubleConfirmation/DoubleConfirmation";

const EditPriceModal = (props) => {
  let { setOpen, open, item, getAllMarketplaceNft } = props;
  const dispatch = useDispatch();
  const [newPrice, setNewPrice] = useState();
  const [confirmSave, setConfirmSave] = useState(false);
  const [showWarningDataChangedMessage, setShowWarningDataChangedMessage] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const onOpenConfirmSave = () => {
    setConfirmSave(true)
  }

  const onClosePopup = () => {
    setDisabledSaveButton(false)
    setOpen(false)
    setConfirmSave(false)
  }

  const onUpdatePrice = (e) => {
    setNewPrice(e)
  }

  const onSaveDataOrReject = async (currentPrice) => {
    if (currentPrice !== item?.sale_price) {
      setDisabledSaveButton(false)
      return setShowWarningDataChangedMessage(true) // reject popup
    }

    try {
      await dispatch(updateMarketplaceNft({ nft_id: item?.nft_id, price: newPrice }))
      onClosePopup()
    } catch (err) {
      onClosePopup()
    }
  }

  const onSave = async () => {
    setDisabledSaveButton(true)

    try {
      const currentNftData = await dispatch(getMarketplaceNft({ indexId: item?.indexId }))
      await onSaveDataOrReject(currentNftData?.data?.data?.sale_price)
    } catch (err) {
      console.log(err)
    }
  }

  const onCloseWithWarningMessage = () => {
    onClosePopup()
    setShowWarningDataChangedMessage(false)
    getAllMarketplaceNft() // update actual data
  }

  useEffect(() => {
    setNewPrice(item?.sale_price)
  }, [item?.sale_price])

  return (
    <Modal
      onClose={() => onClosePopup()}
      onOpen={() => setOpen(true)}
      open={open}
      className="editStatusModal"
    >
      <Modal.Header>Edit sale price</Modal.Header>
      {confirmSave ?
        <DoubleConfirmation
          onClick={onSave}
          text='Are you sure you want to edit price?'
          disabled={disabledSaveButton}
          showWarningDataChangedMessage={showWarningDataChangedMessage}
          onCloseWithWarningMessage={onCloseWithWarningMessage}
        /> :
        <Modal.Content scrolling>
          <div className='cus_drop inputField__wrap'>
            <label className='inputField__label'>
              Nft price
            </label>
            <InputNumber
              min={1}
              value={newPrice}
              step={1}
              onChange={onUpdatePrice}
              type="number"
            />
          </div>
          <div className="btns-spacing">
            <Button
              color="black"
              onClick={() => onClosePopup()}
              className="borderBtn addNew_Btn"
            >
              Cancel
            </Button>
            <Button
              onClick={onOpenConfirmSave}
              className="ui button btnCustom saveBtn"
              positive
            >
              Save
            </Button>
          </div>
        </Modal.Content>
      }
    </Modal>
  );
};
export default EditPriceModal;
