import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import "./MobileHeader.scss";
import { Link } from "react-router-dom";

export class Index extends Component {
  render() {
    return (
      <Menu>
        <main id="page-wrap">
          <ul className="bottom-menu-list">
            <Link to="/">Dashboard</Link>
            <a href="#!" target="_blank">
              Information{" "}
            </a>
            <Link to="/">Logout </Link>
          </ul>
        </main>
      </Menu>
    );
  }
}

export default Index;
