import React from "react";
import "../forgot/Reset.scss";
import {
  Container,
  Form,
  Header,
  Image,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import { UserActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import logo from "../../../Assets/image/Brand_Logo.png"; 
import { Link, useHistory } from "react-router-dom";

const Confirm = ({ handleSubmit }) => {
  const token_id = window.location.pathname.split("/");
  const dispatch = useDispatch();
  const history = useHistory();
  const submitForm = async (data) => {
    const { confirm } = UserActions;
    data.token = token_id[2];
    await dispatch(confirm(data));
    history.push("/");
  };

  return (
    <Container fluid className="loginContainer">
      <Container className="main_loginForm">
        <div className="loginForm">
          <div className="loginformBox">
            <Image src={logo} className="logoStyle" />
            <Header as="h2" content="Reset Password" className="regTitle" />

            <Form
              autoComplete="off"
              autoFocus="off"
              onSubmit={handleSubmit(submitForm)}
            >
              <Form.Field>
                <label>New Password</label>
                <Field
                  component={FormField}
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  validate={[required()]}
                  autoComplete="off"
                />
              </Form.Field>

              <Form.Field>
                <label>Confirm Password</label>

                <Field
                  component={FormField}
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm password"
                  validate={[required()]}
                  autoComplete="off"
                />
              </Form.Field>
              <Form.Field className="loginBtn">
                <button type="submit" primary="true">
                  {" "}
                  Submit{" "}
                </button>
              </Form.Field>
              <Form.Field className="loginBtn">
                <Link to="/">Back</Link>
              </Form.Field>
            </Form>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default withRouter(
  reduxForm({ form: "LoginForm", enableReinitialize: true })(Confirm)
);
