import { useSelector } from "react-redux";
import { Pagination } from "semantic-ui-react";
import './MarketplacePagination.scss'

const MarketplacePagination = ({ limit, setOffset }) => {
  const { totalMarketplaceNfts } = useSelector((state) => state?.marketplace);

  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage - 1;
    setOffset(offset);
  };

  return (
    <div className="marketplace-pagination table_pagi new">
      <Pagination
        firstItem={false}
        lastItem={false}
        boundaryRange={1}
        siblingRange={0}
        defaultActivePage={1}
        onPageChange={handlePageClick}
        totalPages={Math.ceil(parseInt(totalMarketplaceNfts) / limit)}
      />
  </div>
  )
};

export default MarketplacePagination;
