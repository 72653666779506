import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Icon, Pagination, Table, Input, Dropdown, Confirm } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import { Plusicon } from '../../../../Assets/image/svgImg'
import { toast } from '../../../../components/Toast/Toast'
import '../wineList.scss'
import {
  addWineryPageCMS,
  updateComingSoonStatus,
  updateProfileStatus,
} from '../../../../redux/_actions/winemaker.action'
import WinersListTable from './winersListComponent/WinersListTable'
import getCMSEntryUrl from 'Helpers/getCMSEntryUrl'

const WinersList = ({
  history,
  search,
  setSearch,
  setPage,
  setStatus,
  setOrder,
  setLimit,
  limit,
  deleteWinemaker,
  getWinerList,
}) => {
  const { winerMakers, winerTotal } = useSelector((state) => state?.wineMakers)
  const [userId, setUserId] = useState('')
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const dispatch = useDispatch()
  const AddNewArtist = () => {
    history.push('/panel/CreateWinemakers')
  }
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage
    setPage(page)
  }

  const showOptions = [
    { key: '1', text: '10', value: '10' },
    { key: '2', text: '15', value: '15' },
    { key: '3', text: '20', value: '20' },
  ]

  const sortByOption = [
    { key: '1', text: 'Newest', value: 'desc' },
    { key: '2', text: 'Oldest', value: 'asc' },
  ]

  const option2 = [
    // { key: "1", text: "Last Updated", value: "0" },
    { key: '1', text: 'Last Week', value: '0' },
    { key: '2', text: 'Last Month', value: '1' },
  ]

  const deleteUserOpenConfirm = (data) => {
    setUserId(data.userId)
    setOpenDeleteConfirm(true)
  }
  const unPublishPublicPage = (id, pageId) => {
    let data = {
      userId: id,
      status: 0,
      isUnPublish: true,
      winery_page_id: pageId,
    }

    dispatch(updateProfileStatus(data)).then((res) => {
      const comingSoonData = {
        user_id: id,
        coming_soon_enabled: 0,
      }

      dispatch(updateComingSoonStatus(comingSoonData)).then((_res) => {
        toast.success(res.data.message)
        getWinerList()
      })
    })
  }

  const createPublicPage = (winery) => () => {
    const data = {
      email: winery?.email,
      winery_name: winery?.creator_meta?.winery_name,
      region: winery?.creator_meta?.regions?.region_name || '',
      userId: winery?.userId,
      creator_meta_id: winery?.creator_meta?.id,
    }
    dispatch(addWineryPageCMS(data))
      .then((res) => {
        toast.success(res.data?.message)
        const link = getCMSEntryUrl(res.data?.data?.winery_page_id)
        if (link) {
          window.location.href = link
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error?.message)
      })
  }

  const onClose = () => {
    setOpenDeleteConfirm(false)
  }
  const onConfirm = () => {
    setOpenDeleteConfirm(false)
    let data = {
      userId: userId,
    }
    deleteWinemaker(data)
  }

  const getPublicPageStatus = (wineryData) => {
    let label = ''
    let type = ''

    const winery_page_id = wineryData?.creator_meta?.winery_page_id
    if (winery_page_id && (wineryData.coming_soon_enabled || wineryData.profile_status === 1)) {
      label = 'Coming Soon'
      type = 'pending'
    } else if (winery_page_id && wineryData.profile_status === 2) {
      label = 'Published'
      type = 'published'
    } else {
      label = 'Draft'
      type = 'sent'
    }

    return <span className={`table-status ${type}`}>{label}</span>
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className='hd_title'>Winery List</h3>
      </div>

      <div className='filltersUser'>
        <div className='filltersBottom'>
          <div className='left_fill'>
            <Dropdown
              defaultValue='0'
              selection
              options={option2}
              onChange={(e, data) => {
                if (data.value === '0') {
                  setStatus('')
                } else {
                  setStatus(data.value)
                }
              }}
            />
            <div className='sorting'>
              <span>Sort by</span>
              <Dropdown
                defaultValue='desc'
                placeholder={sortByOption[0].value.toString()}
                selection
                options={sortByOption}
                onChange={(e, data) => {
                  setOrder(data.value)
                }}
              />
            </div>
            <Input
              className='search_fld'
              icon={<Icon name='search' inverted link />}
              value={search}
              onInput={(e) => {
                setSearch(e.target.value)
              }}
              placeholder='Ex. Search id, email, country'
            />
          </div>
          <div className='rightfill'>
            <div className='sorting'>
              <span>Show</span>
              <Dropdown
                value={limit.toString()}
                selection
                options={showOptions}
                className='autowidth'
                onChange={(e, data) => {
                  setLimit(data.value)
                }}
              />
            </div>
            <div className='sorting'>
              <span>Entries</span>
              <Dropdown
                disabled
                placeholder={winerTotal ? winerTotal.toString() : ''}
                selection
                options={showOptions}
                className='autowidth'
              />
            </div>
            <ButtonCustom onClick={AddNewArtist} title='Add Winery' leftIcon={<Plusicon />} />
          </div>
        </div>
      </div>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell colSpan="1">ID</Table.HeaderCell> */}
              <Table.HeaderCell colSpan='2'>Winery</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Authorized</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Winemaker</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Email</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Country</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Created</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>PP Status</Table.HeaderCell>
              {/* <Table.HeaderCell colSpan="2">Last Updated</Table.HeaderCell> */}
              <Table.HeaderCell colSpan='2'>Bottles</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Wallet Id</Table.HeaderCell>
              {/* <Table.HeaderCell colSpan="2">Notifications</Table.HeaderCell> */}
              <Table.HeaderCell colSpan='1' className='TableEditBtn'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {winerMakers && winerMakers.length > 0 ? (
              winerMakers?.map((row, i) => (
                <WinersListTable
                  key={row.unique_id}
                  row={row}
                  getPublicPageStatus={getPublicPageStatus}
                  deleteUserOpenConfirm={deleteUserOpenConfirm}
                  unPublishPublicPage={unPublishPublicPage}
                  createPublicPage={createPublicPage(row)}
                />
              ))
            ) : (
              <Table.Row>
                <Table.Cell collapsing colSpan='24' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className='table_pagi new'>
        <div className='sorting'>
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={showOptions}
            className='autowidth SortingIcon'
            onChange={(e, data) => {
              setLimit(data.value)
              setPage(1)
            }}
          />
        </div>
        {winerTotal && winerTotal >= limit && (
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            onPageChange={handlePageClick}
            defaultActivePage={1}
            totalPages={Math.ceil(parseInt(winerTotal) / limit) || 1}
          />
        )}
      </div>
      <Confirm open={openDeleteConfirm} onCancel={onClose} onConfirm={onConfirm} />
    </>
  )
}

export default withRouter(WinersList)
