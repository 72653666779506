import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./header.scss";

import MobileHeader from "../../../MobileHeader";
import DesktopHeader from "../../../DesktopHeader/DesktopHeader";

const Header = () => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const persist = useSelector((state) => state.persist);
  const { isLeftbar } = persist;

  return (
    <>
      <DesktopHeader
        isLeftbar={isLeftbar}
        setToggleSidebar={setToggleSidebar}
        toggleSidebar={toggleSidebar}
      />
      <div className="for_mobile">
        <MobileHeader />
      </div>
    </>
  );
};

export default Header;