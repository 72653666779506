import { fetch } from "./Fetch";
import Environments from "../_constants/Environment";

const { API_HOST } = Environments;

const getAllMarketplaceNfts = (data, headers) => {
  let path = `${API_HOST}/admin/api/v1/searchMarketplaceItem?`

  if (data?.offset) {
    path += `&offset=${data?.offset}`
  }

  if (data?.limit) {
    path += `&limit=${data?.limit}`
  }

  if (data?.searchKey) {
    path += `&searchKey=${data?.searchKey}`
  } else {
    path += `&limit=0`
  }

  return fetch(
    "get",
    path,
    data,
    headers
  );
};

const getMarketplaceNft = (data, headers) => {
  let path = `${API_HOST}/admin/api/v1/getMarketplaceItem?indexId=${data?.indexId}`

  return fetch(
    "get",
    path,
    data,
    headers
  );
};

const updateMarketplaceNft = (data, headers) => {
  let path = `${API_HOST}/admin/api/v1/updateMarketplaceItem?nft_id=${data?.nft_id}`

  if (data?.status) {
    path += `&status=${data?.status}`
  }

  if (data?.price) {
    path += `&price=${data?.price}`
  }

  return fetch(
    "get",
    path,
    data,
    headers
  );
};


export const MarketplaceService = {
  getAllMarketplaceNfts,
  getMarketplaceNft,
  updateMarketplaceNft,
}
