import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import {
  DeleteSolidIcon,
  EditProfileIcon,
  IconDiamond,
} from "../../../Assets/image/svgimages/sVGImg";
import "./DropdownEdit.scss";

const DropdownEdit = ({ icon, postion, data, deleteuserBottle }) => {
  return (
    <Dropdown
      icon={icon}
      className="dropdown-edit"
    >
      <Dropdown.Menu className={postion}>
        <Dropdown.Item>
          <Link
            to={{
              pathname: "/panel/bottles/edit-bottle",
              state: data,
            }}
          >
            <EditProfileIcon />
            Edit Bottle
          </Link>
        </Dropdown.Item>
        {!data.is_drop_exist && data?.wallet_authorization === 1 && (
          <Dropdown.Item>
            <Link
              to={{
                pathname: "/panel/createNftwine",
                state: data,
              }}
            >
              <IconDiamond />
              Create NFT
            </Link>
          </Dropdown.Item>
        )}
        <Dropdown.Item>
          <Link to="#" onClick={() => deleteuserBottle(data.id)}>
            <DeleteSolidIcon />
            Delete
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownEdit;
