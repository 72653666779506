import React, { useState } from 'react'
import { Tabs } from 'antd'
import man from '../../../Assets/image/man.png'
import dualArrow from '../../../Assets/image/dualArrow.svg'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import BorderButton from '../../../components/button/BorderButton'
import DropdownEdit from '../../../components/common/DropdownEdit/DropdownEdit'
import './WinemakerDetails.scss'
import BottlesTab from './BottlesTab'
import NftTabs from './NftTabs'
import DeclineModal from '../../../components/Modal/DeclineModal/DeclineModal'

const { TabPane } = Tabs
const onChange = (key) => {}

const WinemakerDetails = () => {
  const [open, setOpen] = useState(false)
  return (
    <MainLayout>
      <div className='detail-wrap'>
        <div className='detail-wrap__heading'>
          <h3>Winemaker Details</h3>
          <DropdownEdit icon='ellipsis horizontal' postion='left' />
        </div>
        <div>
          <a
            class='action-btn'
            href='/'
            onClick={(e) => {
              setOpen(true)
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            Diclined
          </a>
          <DeclineModal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} />
        </div>
        <div className='detail-card mb30'>
          <ul className='detail-card__item detail-card__item--flex1'>
            <li>
              <img src={man} alt='profile_img' className='detail-card__profImg' />
            </li>
            <li>
              <h3>Winemaker Name</h3>
              <h5 className='fw-700'>France</h5>
              <BorderButton icon={dualArrow} text='0X7821…D784' />
            </li>
          </ul>
          <ul className='detail-card__item detail-card__item--flex2 detail-card__item--border'>
            <li>
              <h5>Winery</h5>
              <p>Harvest Moon Winery And Vineyard</p>
            </li>
            <li>
              <h5>Location</h5>
              <p>
                49 Rue La Boétie <br /> Paris, Île-De-France(Il), 75014
              </p>
            </li>
          </ul>
          <ul className='detail-card__item detail-card__item--flex3'>
            <li className='pr-40'>
              <h5>Commercial Email</h5>
              <p>winerybusinessemail@gmail.com</p>
            </li>
            <li>
              <h5>Commercial Phone Number</h5>
              <p>+ 1 900 892 83 8320</p>
            </li>
          </ul>
        </div>
        <Tabs className='exclusive__tabs' defaultActiveKey='1' onChange={onChange}>
          <TabPane tab='Bottles' key='1'>
            <BottlesTab />
          </TabPane>
          <TabPane tab='NFTs' key='2'>
            <NftTabs />
          </TabPane>
        </Tabs>
      </div>
    </MainLayout>
  )
}

export default WinemakerDetails
